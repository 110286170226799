// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState, useCallback } from 'react';
// import { Container } from 'react-bootstrap';
// import { FiSearch } from "react-icons/fi";
// import { MdOutlineLocationOn } from 'react-icons/md';
// import { useNavigate } from 'react-router-dom';
// import { Form, Input } from 'reactstrap';
// import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import '../../style/swiper.css';
// import { allHomeBanners } from '../../api/coupon';
// import { useDispatch, useSelector } from 'react-redux';
// import { setHomeBanners } from '../../redux/services';
// import debounce from 'lodash.debounce';

// const HomeHeader2 = () => {
//     const [searchQuery, setSearchQuery] = useState('');
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const [banners, setbanners] = useState([]);
//     // const userData = useSelector((state) => state.auth?.userData)
//     const userZipCide = useSelector((state) => state.auth?.userZipcode)
//     const homeBanners = useSelector((state) => state.services?.homeBanners);
//     const [loading, setLoading] = useState(true);

//     const fetchData = useCallback(
//         debounce(async () => {
//             try {
//                 const res = await allHomeBanners('home');
//                 if (res?.data) {
//                     setbanners(res?.data?.banners);
//                     dispatch(setHomeBanners(res?.data?.banners));
//                     setLoading(false);
//                 }
//             } catch (error) {
//                 console.error("Error fetching banners", error);
//                 setLoading(false)
//             }
//         }, 500),
//         []
//     );

//     useEffect(() => {
//         if (!homeBanners?.length) {
//             fetchData();
//         } else {
//             setbanners(homeBanners);
//             setLoading(false);
//         }
//         return () => {
//             fetchData.cancel();
//         };
//     }, [homeBanners]);

//     const handleSearchSubmit = (event) => {
//         event.preventDefault();
//         navigate('/nearby-services', { state: { searchQuery: searchQuery || userZipCide } });
//     };

//     return (
//         <div className="m-auto home_header_width home_swiper_detail relative">
//             <div className="absolute bottom-0 w-full flex justify-center z-50 py-4">
//                 <Form onSubmit={handleSearchSubmit} style={{ border: '1.2px solid #003F7D' }} className="bg_white rounded-xl overflow-hidden max-w-2xl flex gap-3 items-center w-full">
//                     <div className="inputs-container w-full">
//                         <div className="input-wrapper" style={{ flex: 6 }}>
//                             <Input
//                                 onChange={(e) => setSearchQuery(e.target.value)}
//                                 style={{ padding: "16px 0px 16px 24px" }}
//                                 placeholder="What are you looking for?"
//                                 className="plusJakara_regular max-md:text-sm text_secondary2"
//                             />
//                         </div>
//                         <div className="input-wrapper" style={{ flex: 2.5 }}>
//                             <MdOutlineLocationOn size={24} color="#003F7D" className="input-icon" />
//                             <Input
//                                 onChange={(e) => setSearchQuery(e.target.value)}
//                                 style={{ padding: "16px 0px 16px 40px" }}
//                                 placeholder="Zipcode"
//                                 defaultValue={userZipCide}
//                                 className="plusJakara_regular max-md:text-sm text_secondary2"
//                             />
//                         </div>
//                     </div>
//                     <div className="p-1 pe-2">
//                         <button type='submit' style={{ padding: "11px" }} className="bg_primary text-center text_white plusJakara_medium flex gap-2 items-center rounded-3">
//                             <FiSearch size={18} color='#fff' />
//                         </button>
//                     </div>
//                 </Form>
//             </div>
//             {loading ? (
//                 <div className="skeleton-loader cleaning_header2"></div>
//             ) : (
//                 <Swiper
//                     spaceBetween={10}
//                     navigation={true}
//                     freeMode={true}
//                     speed={1000}
//                     modules={[Navigation, Autoplay, FreeMode, Pagination]}
//                     className="mySwiper"
//                     slidesPerView={"auto"}
//                 >
//                     {banners.map((item, i) => (
//                         <SwiperSlide key={i}>
//                             <div style={{
//                                 backgroundImage: `url(${item?.image})`,
//                                 backgroundSize: 'cover',
//                                 backgroundRepeat: "no-repeat",
//                                 backgroundPosition: 'top center',
//                                 overflow: 'hidden',
//                                 // borderRadius: '20px'
//                             }}
//                                 className='px-3 px-md-5 relative flex justify-start items-center cleaning_header2 mb-5'>
//                                 <div className="bg-overlay3"></div>
//                                 <Container className='flex w-full relative flex-col z-20 gap-1 justify-start items-start'>
//                                     <h1 className="plusJakara_semibold text-2xl md:text-3xl max-w-3xl xl:text-4xl min-[1900px]:text-5xl text_white">{item?.name}</h1>
//                                     <h6 style={{ color: '#f4f4f4' }} className="plusJakara_regular max-w-3xl text-sm xl:text-lg">{item?.description}</h6>
//                                     <button onClick={() => navigate('/all-services')} className="bg_primary mt-md-3 w-fit flex gap-2 gap-md-3 items-center px-3 px-md-5 py-2 py-md-3 max-md:text-sm rounded-3 text_white plusJakara_medium">Explore now</button>
//                                 </Container>
//                             </div>
//                         </SwiperSlide>
//                     ))}
//                 </Swiper>
//             )}
//         </div>
//     );
// };

// export default HomeHeader2;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Container } from 'react-bootstrap';
import { FiSearch } from "react-icons/fi";
import { MdOutlineLocationOn } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Form, Input } from 'reactstrap';
import '../../style/swiper.css';
import { allHomeBanners } from '../../api/coupon';
import { useDispatch, useSelector } from 'react-redux';
import { setHomeBanners } from '../../redux/services';
import debounce from 'lodash.debounce';

const HomeHeader2 = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [randomBanner, setRandomBanner] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const homeBanners = useSelector((state) => state.services?.homeBanners);
    const userZipCide = useSelector((state) => state.auth?.userZipcode);

    const fetchData = useCallback(
        debounce(async () => {
            try {
                const res = await allHomeBanners('home', '');
                if (res?.data) {
                    const shuffledBanners = res?.data?.banners.sort(() => Math.random() - 0.5);
                    dispatch(setHomeBanners(shuffledBanners));
                    setRandomBanner(shuffledBanners[0]); // Always select a new random banner
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching banners", error);
                setLoading(false);
            }
        }, 500),
        []
    );

    useEffect(() => {
        if (!homeBanners?.length) {
            fetchData();
        } else {
            const random = homeBanners[Math.floor(Math.random() * homeBanners.length)];
            setRandomBanner(random);
            setLoading(false);
        }
        return () => {
            fetchData.cancel();
        };
    }, [homeBanners]);

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        navigate('/nearby-services', { state: { searchQuery: searchQuery || userZipCide } });
    };

    return (
        <div className="m-auto home_header_width home_swiper_detail relative">
            <div className="absolute -bottom-12 w-full flex justify-center z-50 py-4">
                <Form onSubmit={handleSearchSubmit} style={{ border: '1.2px solid #003F7D' }} className="bg_white rounded-xl overflow-hidden max-w-2xl flex gap-3 items-center w-full">
                    <div className="inputs-container w-full">
                        <div className="input-wrapper" style={{ flex: 6 }}>
                            <Input
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ padding: "16px 0px 16px 24px" }}
                                placeholder="What are you looking for?"
                                className="plusJakara_regular max-md:text-sm text_secondary2"
                            />
                        </div>
                        <div className="input-wrapper" style={{ flex: 2.5 }}>
                            <MdOutlineLocationOn size={24} color="#003F7D" className="input-icon" />
                            <Input
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ padding: "16px 0px 16px 40px" }}
                                placeholder="Zipcode"
                                defaultValue={userZipCide}
                                className="plusJakara_regular max-md:text-sm text_secondary2"
                            />
                        </div>
                    </div>
                    <div className="p-1 pe-2">
                        <button type='submit' style={{ padding: "11px" }} className="bg_primary text-center text_white plusJakara_medium flex gap-2 items-center rounded-3">
                            <FiSearch size={18} color='#fff' />
                        </button>
                    </div>
                </Form>
            </div>
            {loading ? (
                <div className="skeleton-loader cleaning_header2"></div>
            ) : (
                randomBanner && (
                    <div
                        style={{
                            backgroundImage: `url(${randomBanner?.image})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: 'top center',
                            overflow: 'hidden',
                        }}
                        className='px-3 px-md-5 relative flex justify-start items-center cleaning_header2 mb-5'
                    >
                        <div className="bg-overlay3"></div>
                        <Container className='flex w-full relative flex-col z-20 gap-1 justify-start items-start'>
                            <h1 className="plusJakara_semibold text-2xl md:text-3xl max-w-3xl xl:text-4xl min-[1900px]:text-5xl text_white">{randomBanner?.name}</h1>
                            <h6 style={{ color: '#f4f4f4' }} className="plusJakara_regular max-w-3xl text-sm xl:text-lg">{randomBanner?.description}</h6>
                            {/* <button
                                onClick={() => navigate('/all-services')}
                                className="bg_primary mt-md-3 w-fit flex gap-2 gap-md-3 items-center px-3 px-md-5 py-2 py-md-3 max-md:text-sm rounded-3 text_white plusJakara_medium"
                            >
                                Explore now
                            </button> */}
                        </Container>
                    </div>
                )
            )}
        </div>
    );
};

export default HomeHeader2;
