/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { FaStar } from 'react-icons/fa6';
import { IoLocationSharp } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Autoplay, FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { avataruser, coveravatar } from '../../icons/icon';
import { setServiceProvider } from '../../redux/generalRedux';
import { encryptData } from '../../redux/localStorageSecure';
import '../../style/swiper.css';

const ProviderPortfolioCard = ({ category }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()

    const handleNavigate = (item) => {
        const params = new URLSearchParams(searchParams)
        const data = {
            user: item,
        }
        params.set('detail-user', encryptData(data))
        navigate(`/chat?${params.toString()}`)
    }

    return (

        <div className="flex flex-col rounded-2 overflow-hidden border gap-3">
            <div className="home_swiper relative" style={{ width: '100%', height: 'auto' }}>
                <div
                    // onClick={() => {
                    //     dispatch(setServiceProvider(category));
                    //     navigate(`/services/${category?._id}?selected=reviews`);
                    // }}
                    className="flex absolute mirrored-bg p-2 m-2 left-0 top-0 z-50 flex-col gap-1 justify-start items-end">
                    <div
                        className="flex items-center gap-1"
                    >
                        <div className="flex items-center">
                            {[...Array(5)].map((_, i) => (
                                <FaStar
                                    key={i}
                                    color={i < category?.rating ? '#F6921E' : '#d3d3d3'}
                                    size={16}
                                />
                            ))}
                        </div>
                        <span className="text_white whitespace-nowrap text-xs plusJakara_medium mb-0">
                            ({category?.total_reviews})
                        </span>
                    </div>
                </div>
                <Swiper
                    freeMode={true}
                    modules={[FreeMode, Autoplay, Navigation]}
                    navigation={true}
                    pagination={{ clickable: true }}
                    loop={true}
                    className="mySwiper d-flex w-100 gap-3 relative"
                    slidesPerView={"auto"}
                >
                    <div className="bg-overlay3"></div>
                    {category?.portfolio[0]?.urls?.length > 0 ? (
                        category?.portfolio[0]?.urls?.map((cat, index) => (
                            <SwiperSlide
                                className='relative'
                                key={index}
                                onClick={() => {
                                    dispatch(setServiceProvider(category))
                                    navigate(`/services/${category?._id}?selected=reviews`)
                                }}
                                style={{ width: '100%', height: '13rem', overflow: "hidden", cursor: 'pointer' }}
                            >
                                {cat?.type === 'video' && cat?.url ? (
                                    <video
                                        src={cat.url}
                                        alt="Preview"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            cursor: 'pointer',
                                            objectFit: 'cover',
                                        }}
                                        className="rounded-4 w-full bg_light object-cover"
                                        controls
                                    />
                                ) : cat?.url ? (
                                    <img
                                        src={cat.url}
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            cursor: 'pointer',
                                            objectFit: 'cover',
                                        }}
                                        alt="Project"
                                    />
                                ) : (
                                    <img
                                        src={coveravatar}
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            cursor: 'pointer',
                                            objectFit: 'cover',
                                        }}
                                        alt="Project"
                                    />
                                )}
                            </SwiperSlide>
                        ))
                    ) : (
                        <SwiperSlide
                            onClick={() => {
                                dispatch(setServiceProvider(category))
                                navigate(`/services/${category?._id}?selected=reviews`)
                            }}
                            style={{ width: '100%', height: '13rem', overflow: "hidden", cursor: 'pointer' }}
                        >
                            <img
                                src={coveravatar}
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    cursor: 'pointer',
                                    objectFit: 'cover',
                                }}
                                alt="Project"
                            />
                        </SwiperSlide>
                    )}
                </Swiper>
                <div onClick={() => {
                    dispatch(setServiceProvider(category))
                    navigate(`/services/${category?._id}?selected=reviews`)
                }} className="p-3 flex justify-between cursor-pointer items-start gap-2">
                    <div className="flex gap-2 items-start w-full">
                        <div style={{ minWidth: "55px" }}>
                            <img src={category?.profilePicture || avataruser} style={{ width: '50px', height: "50px", objectFit: 'cover', borderRadius: '8px' }} alt="" />
                        </div>
                        <div className="flex gap-1 flex-col">
                            <h5 className="text_black plusJakara_medium hover:text-[#F6921E] text-lg mb-0 line-clamp-1">{category?.name}</h5>
                            <div className="flex items-center">
                                <div className="w-5">
                                    <IoLocationSharp size={18} />
                                </div>
                                <span className="text_secondary text-xs line-clamp-1 plusJakara_regular">{category?.address || 'Not found'}</span>
                            </div>
                            <span className="text_black text-sm plusJakara_medium">{category?.portfolio?.length || 0} Project Completed</span>
                        </div>
                    </div>
                    {/* <div className="flex flex-col gap-1 justify-start items-end">
                        <button onClick={() => handleNavigate(category)} className="bg_primary w-full text_white plusJakara_regular text-xs px-2 py-2 rounded-2">Message</button>
                        <div onClick={() => {
                            dispatch(setServiceProvider(category))
                            navigate(`/services/${category?._id}?selected=reviews`)
                        }} className="flex items-center gap-1">
                            <div className="flex items-center">
                                {[...Array(5)].map((_, i) => (
                                    <FaStar
                                        key={i}
                                        color={i < category?.rating ? '#F6921E' : '#d3d3d3'}
                                        size={16}
                                    />
                                ))}
                            </div>
                        </div>
                        <span className="text_secondary2 whitespace-nowrap text-xs plusJakara_medium mb-0">({category?.total_reviews} Reviews)</span>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ProviderPortfolioCard;