import axiosInstance from "../redux/axiosInstance";

export const paymentCheckOutSession = async ({ data }) => {
    try {
        const res = await axiosInstance.post(`payment/checkoutsession`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const completeSubscription = async ({ data }) => {
    try {
        const res = await axiosInstance.post(`payment/complete-subscription`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const createPayment = async ({ data }) => {
    try {
        const res = await axiosInstance.post(`payment/create`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const paymentSubscriptions = async () => {
    try {
        const res = await axiosInstance.get(`payment/subscriptions`,)
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const getClientId = async ({ data }) => {
    try {
        const res = await axiosInstance.post(`payment/checkoutsession`, data)
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const cancelSubscription = async ({ data }) => {
    try {
        const res = await axiosInstance.put(`payment/cancelSubscription`, {})
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const updateSubscription = async ({ data }) => {
    try {
        const res = await axiosInstance.put(`payment/updateSubscription`, data)
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const getSubscription = async (lastId) => {
    try {
        const res = await axiosInstance.get(`users/subscriptions/${lastId}`)
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};