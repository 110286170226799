/* eslint-disable array-bracket-newline */
/* eslint-disable quote-props */
/* eslint-disable prefer-template */
/* eslint-disable semi */
/* eslint-disable object-shorthand */
/* eslint-disable comma-dangle */
/* eslint-disable no-unused-vars */
import React from 'react';
import Chart from 'react-apexcharts';

const EarningChart = () => {
    const options = {
        chart: {
            width: '100vw',
            type: 'line',
            stacked: false
        },
        dataLabels: {
            enabled: false,
            style: {
                fontSize: '16px',
            }
        },
        // stroke: {
        //     curve: 'straight',
        // },
        markers: {
            size: 5,
            strokeColors: '#828282',
            backgroundColor: '#828282',
            shape: "circle",
            color: '#828282',
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            hover: {
                size: 6,
                sizeOffset: 3
            }
        },
        colors: ['#828282'],
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                gradientToColors: ['linear-gradient(180deg, #828282 50%, rgba(39, 49, 66, 0.0293652) 88.92%)'],
                opacityFrom: 0.7,
                opacityTo: 0.5,
                stops: [0, 100]
            },
            inverseColors: false,
            shadow: {
                enabled: false,
                color: '#828282',
                opacity: 1,
                offsetX: 0,
                offsetY: 0
            },
            padding: {
                top: 10,
                bottom: 10
            },
        },
        xaxis: {
            labels: {
                style: {
                    colors: '#000',
                },
            },
        },
        yaxis: {
            tickAmount: 5,
            labels: {
                style: {
                    colors: '#000',
                },
                formatter: function (val) {
                    if (val >= 1000) {
                        return '$ ' + (val / 1000).toFixed(1) + 'k';
                    }
                    return val;
                }
            }
        },
        responsive: [{
            breakpoint: 500,
            options: {
                chart: {
                    height: 250
                },
                legend: {
                    show: false
                }
            }
        }],
        legend: {
            show: false,
        },
    }

    const data = [
        { "x": "Jan", "y": 60000 },
        { "x": "Feb", "y": 20000 },
        { "x": "Mar", "y": 30000 },
        { "x": "Apr", "y": 30000 },
        { "x": "May", "y": 56000 },
        { "x": "Jun", "y": 39000 },
        { "x": "Jul", "y": 30000 },
        { "x": "Aug", "y": 48000 },
        { "x": "Sep", "y": 67000 },
        { "x": "Oct", "y": 46000 },
        { "x": "Nov", "y": 40000 },
        // { "x": "Dec", "y": 66000 },
        // { "x": "Vex", "y": 86000 },
        // { "x": "Dea", "y": 56000 },
        // { "x": "Dew", "y": 96000 },
    ]
    const seriesArea = [{ data: data }]

    return (
        <div className='w-full p-3'>
            <h4 className="text_dark plusJakara_semibold">
                Total Earniings
            </h4>
            <div style={{ height: '300px' }}>
                <Chart options={options} series={seriesArea} type="area" height={350} />
            </div>
        </div>
    )
}

export default EarningChart
