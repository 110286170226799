import { createSlice } from '@reduxjs/toolkit';

export const GeneralReduxSlice = createSlice({
  name: 'generalRedux',
  initialState: {
    serviceProvider: {}
  },
  reducers: {
    setServiceProvider: (state, action) => {
      state.serviceProvider = action.payload
    },
  },
});

export const { setServiceProvider, } = GeneralReduxSlice.actions;
export default GeneralReduxSlice.reducer;