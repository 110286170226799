/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col } from "antd";
import { useEffect } from "react";
import { ChevronLeft, Search } from "react-feather";
import { BiSolidMessageRounded } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { decryptData } from "../../redux/localStorageSecure";
import HeaderNavigation from "../common/headerNavigation";
import "./chat.css";
import ChatList from "./chatList";
import ChatMessageList from "./chatMessageList";
import {
  ActiveChatProvider,
  ChatProvider,
  ChatUserProvider,
  ResponsiveChatProvider,
  useActiveChat,
  useChatUser,
  useResponsiveChat,
} from "./context";

const ChatMessage = () => {
  const { location } = useLocation();
  const [searchParams] = useSearchParams();
  const { activeChatId, setActiveChatId } = useActiveChat();
  const { setChatUser } = useChatUser();
  const usertype = useSelector((state) => state.auth?.userData?.type);
  const { responsiveChat } = useResponsiveChat();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    const nData = params.get("detail-user");

    if (nData) {
      const vData = decryptData(nData);
      setActiveChatId(vData?.user?._id);
      setChatUser({ otherUser: vData?.user });
    }
  }, [location]);

  return (
    <>
      {usertype !== "company" && (
        <HeaderNavigation
          page="Messages"
          serviceName="All Message"
        />
      )}
      <main className=" min-h-[80vh] md:container flex justify-center py-4 mx-auto">
        <Col xs={24} xl={22}>
          <div className="chat_grid">
            <div
              className={`chat_screen overflow-hidden ${!responsiveChat ? "" : "d_chat_none"
                }`}
            >
              <div className="pb-1">
                <div className="flex items-center justify-between px-3"></div>
                <div className="w-100 py-3 px-2 border-b border-b-gray-200 gap-1 flex bg_white">
                  {/* <div className="flex justify-center gap-2 items-center w-full">
                  <button
                    onClick={() => setactive("buyer")}
                    className={`w-100 text-sm py-[12px] rounded-4 plusJakara_semibold flex justify-center items-center shadow-sm border ${active === 'buyer' ? 'text_primary bg_light' : 'text_black bg_white'} `}>
                    Buyer
                  </button>
                  <button
                    onClick={() => setactive("seller")}
                    className={`w-100 text-sm py-[12px] rounded-4 plusJakara_semibold flex justify-center items-center shadow-sm border ${active === 'seller' ? 'text_primary bg_light' : 'text_black bg_white'} `}>
                    Seller
                  </button>
                </div> */}
                  <button
                    onClick={() => navigate(-1)}
                    className="d_left_button"
                  >
                    <ChevronLeft />
                  </button>
                  <div className="position-relative w-full">
                    <span
                      className="position-absolute ms-2"
                      style={{ marginTop: "12px" }}
                    >
                      {" "}
                      <Search style={{ color: "#d3d3d3" }} size={24} />{" "}
                    </span>
                    <input
                      style={{ padding: "12px", paddingLeft: "2.5rem" }}
                      type="text"
                      placeholder="Search"
                      className="form-control plusJakara_regular text-sm border rounded-3 w-100"
                      name=""
                      id=""
                    />
                  </div>
                </div>
                <hr style={{ color: "#EDEEF0" }} className="my-1" />
                <ChatList />
              </div>
            </div>
            <div
              className={`chat_screen ${responsiveChat ? "" : "d_chat_none"} `}
              id="chatScreen"
            >
              {activeChatId ? (
                <ChatMessageList />
              ) : (
                <div className="display_flex2 flex-column h-100 w-100">
                  <BiSolidMessageRounded style={{ fontSize: "30px" }} />
                  <h4 className="ms-2 my-0 msg_s00">Select a message</h4>
                  <h6
                    style={{ color: "#2D3D38" }}
                    className="text-center plusJakara_regular mt-2"
                  >
                    Choose from your existing conversations, start a new one, or
                    just keep swimming.
                  </h6>
                </div>
              )}
            </div>
          </div>
        </Col>
      </main>
    </>
  );
};

const Messages = () => {
  return (
    <>
      <ChatProvider>
        <ActiveChatProvider>
          <ChatUserProvider>
            <ResponsiveChatProvider>
              <ChatMessage />
            </ResponsiveChatProvider>
          </ChatUserProvider>
        </ActiveChatProvider>
      </ChatProvider>
    </>
  );
};

export default Messages;