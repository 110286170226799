/* eslint-disable no-unused-vars */
import React from 'react';
import { Container } from 'react-bootstrap';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa6';
import { RiInstagramFill, RiTwitterXLine } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { applelogo, playstorelogo } from '../icons/icon';
import { setAccessToken, setLogin, setUserData, setUserType } from '../redux/loginForm';
export default function Footer() {
    const dispatch = useDispatch()
    const categories = useSelector((state) => state.services?.categories)
    const navigate = useNavigate()

    const year = new Date().getFullYear();
    const handleProviderSignup = () => {
        dispatch(setLogin(false))
        dispatch(setUserData(null))
        dispatch(setUserType('company'))
        navigate('/signup', { replace: true });
        dispatch(setAccessToken(null))
    }

    const handleCategoryClick = (category) => {
        navigate('/nearby-services', { state: { category: category._id } });
    };


    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    const groupedCategories = chunkArray(categories, 8);

    return (
        <footer className='bg-[#1c2e4a] mb-0'>
            <Container className='pt-5 pb-4'>
                <div className='flex flex-col max-w-3xl items-center md:items-start'>
                    <h4 className="text_primary2 plusJakara_medium">Request Service</h4>
                </div>
            </Container>
            <Container className='grid3 w-full pb-[5rem]'>
                <div className='flex gap-2 flex-col items-center md:items-start'>
                    <h1 className='text_white text-xl whitespace-nowrap plusJakara_semibold mb-3'>Industries We Serve</h1>
                    <div className='flex w-full items-center justify-center gap-4'>
                        {(groupedCategories?.slice(0, 2))?.map((group, groupIndex) => (
                            <div key={groupIndex} className='flex flex-wrap w-full items-center justify-center gap-2'>
                                {group.map((category, categoryIndex) => {
                                    if (groupIndex === 1 && categoryIndex === 7) {
                                        return (
                                            <button
                                                key="check-more"
                                                style={{ width: '8rem' }}
                                                onClick={() => navigate('/all-services')}
                                                className='plusJakara_medium text-start flex justify-start text-sm text_primary2'
                                            >
                                                Check More...
                                            </button>
                                        );
                                    }
                                    return (
                                        <button
                                            onClick={() => handleCategoryClick(category)}
                                            key={category.id}
                                            style={{ width: '8rem' }}
                                            className='no-underline text-start line-clamp-1 plusJakara_regular text-sm text_white'
                                        >
                                            {category.name}
                                        </button>
                                    );
                                })}
                            </div>
                        ))}
                    </div>


                </div>
                {/* <div></div> */}
                <div className='gap-2 flex flex-col items-center md:items-start'>
                    <h1 className='text_white text-xl plusJakara_semibold mb-3'>Features</h1>
                    <Link to='/scheduling-instruction' className='no-underline plusJakara_regular text-sm text_white'>Scheduling</Link>
                    <Link to='/invoice-instruction' className='no-underline plusJakara_regular text-sm text_white'>Invoicing</Link>
                    {/* <Link className='no-underline plusJakara_regular text-sm text_white'>Service Providers</Link> */}
                    <Link to='/estimate-instruction' className='no-underline plusJakara_regular text-sm text_white'>Estimates</Link>
                    <Link to='/contract-instruction' className='no-underline plusJakara_regular text-sm text_white'>Contracts</Link>
                </div>
                <div className='gap-2 flex flex-col items-center md:items-start'>
                    <h1 className='text_white text-xl plusJakara_semibold mb-3'>Pages</h1>
                    <Link to='/about' className='no-underline plusJakara_regular text-sm text_white'>About</Link>
                    <Link to='/all-services' className='no-underline plusJakara_regular text-sm text_white'>Services</Link>
                    <Link to='/all-blogs' className='no-underline plusJakara_regular text-sm text_white'>Blogs</Link>
                    <Link to='/our-team' className='no-underline plusJakara_regular text-sm text_white'>Our Team</Link>
                </div>
                <div className='gap-2 flex flex-col items-center md:items-start'>
                    <h1 className='text_white text-xl plusJakara_semibold mb-3'>Resources</h1>
                    <Link to='/subscription-plan' className='no-underline plusJakara_regular text-sm text_white'>Subscription Plans</Link>
                    <Link to='/faqs' className='no-underline plusJakara_regular text-sm text_white'>FAQ</Link>
                    <Link to='/contact' className='no-underline plusJakara_regular text-sm text_white'>Support</Link>
                </div>
                <div className='gap-2 flex flex-col items-center md:items-start'>
                    <h1 className='text_white text-xl plusJakara_semibold mb-3'>Company</h1>
                    <Link to='/about' className='no-underline plusJakara_regular text-sm text_white'>Our Story</Link>
                    <button onClick={handleProviderSignup} className='no-underline text-start plusJakara_regular text-sm text_white'>Become an Service Provider</button>
                    <Link to='/terms' className='no-underline plusJakara_regular text-sm text_white'>Terms & conditions</Link>
                    <Link to='/contact' className='no-underline plusJakara_regular text-sm text_white'>Contact</Link>
                </div>
                <div className='gap-2 flex flex-col justify-center items-center md:items-start'>
                    <h1 className='text_white text-xl plusJakara_semibold'>Download App</h1>
                    <div className="flex flex-wrap gap-3 w-full items-start justify-center md:justify-start my-3">
                        <button className="flex gap-2 items-center bg-black border border-white rounded-lg py-2 px-3">
                            <img src={applelogo} style={{ height: '24px', width: 'auto' }} alt="" />
                            <div className="flex flex-col gap-0 items-start">
                                <span style={{ fontSize: '9px' }} className="text_white poppins_regular uppercase">get it on</span>
                                <span className="text_white text-sm poppins_regular">App Store</span>
                            </div>
                        </button>
                        <button className="flex gap-2 items-center bg-black border border-white rounded-lg py-2 px-3">
                            <img src={playstorelogo} style={{ height: '24px', width: 'auto' }} alt="" />
                            <div className="flex flex-col gap-0 items-start">
                                <span style={{ fontSize: '9px' }} className="text_white poppins_regular uppercase">get it on</span>
                                <span className="text_white text-sm poppins_regular">Play Store</span>
                            </div>
                        </button>
                    </div>
                    <div className="flex gap-2 items-center">
                        <button style={{ border: '1.2px solid #f4f4f4' }} className="p-2 rounded-3 text-[#f4f4f4]">
                            <FaFacebookF size={18} />
                        </button>
                        <button style={{ border: '1.2px solid #f4f4f4' }} className="p-2 rounded-3 text-[#f4f4f4]">
                            <RiInstagramFill size={18} />
                        </button>
                        <button style={{ border: '1.2px solid #f4f4f4' }} className="p-2 rounded-3 text-[#f4f4f4]">
                            <RiTwitterXLine size={18} />
                        </button>
                        <button style={{ border: '1.2px solid #f4f4f4' }} className="p-2 rounded-3 text-[#f4f4f4]">
                            <FaLinkedinIn size={18} />
                        </button>
                    </div>
                </div>
            </Container>
            <hr className='text_white m-0' />
            <div className="py-4 bg-[#1c2e4a]">
                <Container className='flex justify-end w-full'>
                    <div className="flex items-center gap-2 gap-md-3 flex-wrap">
                        <p className='whitespace-nowrap mb-0 text-center text-sm plusJakara_regular text_white'>&copy; Copyright Request Service {year}.</p>
                        <div className="flex justify-end gap-2">
                            <Link to='/privacy' className='plusJakara_regular text-sm text_white'>Privacy</Link>
                            <Link to='/terms' className='plusJakara_regular text-sm text_white'>Term of Services</Link>
                        </div>
                    </div>
                </Container>
            </div>
        </footer>
    )
}