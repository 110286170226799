/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { customerSideServices } from '../../api/service';
import '../../style/swiper.css';
import ServiceItem2 from '../serviceItem2';
import { FaArrowRight } from 'react-icons/fa';
import ServiceItem2Skeleton from '../common/serviceItem2Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { setservices } from '../../redux/services';

const HomeServices = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setloading] = useState(false);
    const services = useSelector((state) => state?.services?.services);
    const [page, setpage] = useState(1)

    const fetchServices = async () => {
        if (services?.length > 0) return;
        setloading(true);
        await customerSideServices({ data: {} }, page)
            .then((res) => {
                if (res.success === true) {
                    dispatch(setservices(res.services));
                    setservices(res.services);
                    setloading(false);
                } else {
                    setloading(false);
                    setservices([]);
                }
            }).catch((err) => {
                setloading(false);
                setservices([]);
            });
    };

    useEffect(() => {
        fetchServices();
    }, []);

    return (
        <>
            <Container className="pb-4 pb-md-5">
                {loading ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                        {Array(4)
                            .fill(0)
                            .map((_, index) => (
                                <ServiceItem2Skeleton key={index} />
                            ))}
                    </div>
                ) : !services || services.length === 0 ? (
                    <div className="flex justify-center items-center w-full my-5">
                        <span className="plusJakara_medium md:text-lg">No Service Found</span>
                    </div>
                ) : (
                    <div className="items_swiper w-full relative">
                        <div className="flex justify-between absolute top-0 w-full flex-wrap gap-3 items-start">
                            <h6 className="text-xl md:text-3xl min-[1900px]:text-4xl mb-0 plusJakara_semibold text_black">
                                Fix <span className="text-xl md:text-3xl min-[1900px]:text-4xl plusJakara_semibold text_primary">Services</span>
                            </h6>
                        </div>
                        <Swiper
                            freeMode={true}
                            modules={[FreeMode, Autoplay, Navigation, Pagination]}
                            navigation={true}
                            className="mySwiper d-flex w-100 gap-3 relative"
                            breakpoints={{
                                320: { slidesPerView: 1 },
                                768: { slidesPerView: 2 },
                                1024: { slidesPerView: 3 },
                                1280: { slidesPerView: 4 },
                            }}
                        >
                            {services?.map((category, i) => (
                                <SwiperSlide
                                    key={i}
                                    style={{ height: 'auto', maxWidth: '400px' }}
                                    className="pe-3 relative gap-2"
                                >
                                    <ServiceItem2 key={i} category={category} />
                                </SwiperSlide>
                            ))}
                            <SwiperSlide>
                                <div
                                    className="flex w-full items-center justify-center"
                                    style={{ height: '350px', width: '200px' }}
                                >
                                    <button
                                        onClick={() => navigate('/all-services')}
                                        style={{ border: '1px solid #F6921E' }}
                                        className="text_primary2 flex gap-2 items-center max-md:text-xs justify-center rounded-2 plusJakara_medium py-[6px] px-2 px-md-3 mt-3"
                                    >
                                        See More <FaArrowRight />
                                    </button>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                )}
            </Container>
        </>
    );
};

export default HomeServices;