/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../style/swiper.css';
// import * as Yup from "yup"
import { Spinner } from 'react-bootstrap';
import { html2pdf } from 'html2pdf.js';
import moment from 'moment';
import { FaEye } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { getInvoices } from '../api/order';
import DTables from "../DataTable/DTable";
import UpdrageModal from './common/upgradeModal';

const InvoiceTablePaid = () => {
    const [modalOpen, setmodalOpen] = useState(false)
    const navigate = useNavigate();
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const userData = useSelector((state) => state.auth?.userData)
    const [loading, setloading] = useState([])
    const [recieptData, setrecieptData] = useState(null)
    const [invoices, setinvoices] = useState([])
    const [labourData, setlabourData] = useState([])
    const [materialData, setmaterialData] = useState([])
    const [modal, setmodal] = useState(false)

    useEffect(() => {
        if (userData?.sub_type === 'free' && usertype === 'company') {
            setmodal(true)
        } else {
            setmodal(false)
        }
    }, [userData])

    const handleViewDetails = (row) => {
        navigate(`/invoices/${row?._id}`, { state: { invoiceData: row } })
    };

    const fetchinvoices = async () => {
        setloading(true)
        await getInvoices('invoice', usertype === 'company' ? 'employee' : 'customer', 'complete')
            .then((res) => {
                if (res.success === true) {
                    setinvoices(res.invoices)
                    setloading(false)
                } else {
                    setloading(false)
                    setinvoices([])
                }
            }).catch((err) => {
                setloading(false)
                setinvoices([])
            })
    }

    useEffect(() => {
        fetchinvoices()
    }, [])

    const handleViewReciept = (row) => {
        setmodalOpen(true)
        setrecieptData(row)
    }

    useEffect(() => {
        if (recieptData?.order?.estimate) {
            const estimateLabour = recieptData?.order?.estimate?.labour || [];
            const additionalLabour = recieptData?.labour || [];
            setlabourData([...estimateLabour, ...additionalLabour]);
            setmaterialData([recieptData?.order?.estimate?.material, ...recieptData?.material]);
        }
    }, [recieptData]);

    const columns = [
        {
            name: 'Invoice #',
            sortable: true,
            cell: row => (
                <button className='hover:text-blue-300 hover:underline' onClick={() => handleViewDetails(row)}>{row?.invoice_id}</button>
            ),
            minWidth: '120px'
        },
        {
            name: 'Invoice Date',
            sortable: true,
            selector: row => moment(row?.date).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        {
            name: 'Due Date',
            sortable: true,
            selector: row => moment(row?.invoiceDueDate).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        {
            name: 'Customer Name',
            sortable: true,
            selector: row => row?.user?.name || row?.to_id?.name,
            minWidth: '180px'
        },
        {
            name: 'Address',
            sortable: true,
            selector: row => row?.user?.address || row?.to_id?.address,
            minWidth: '280px'
        },
        {
            name: 'Amount',
            sortable: true,
            selector: row => row?.totalPrice,
            minWidth: '100px'
        },
        {
            name: 'Action',
            sortable: false,
            cell: row => (
                <div className="flex">
                    <button onClick={() => handleViewReciept(row)} className="bg_primary rounded-3 text_white p-2 plusJakara_medium">e-Receipt</button>
                </div >
            ),
            minWidth: '150px'
        },
        {
            name: 'View Invoice',
            cell: row => (
                <button className="btn-view-details ms-3 bg_light rounded-2 p-2" onClick={() => handleViewDetails(row)}>
                    <FaEye />
                </button>
            ),
            minWidth: '50px'
        }
    ];

    const columns3 = [
        {
            name: 'Labour & Material',
            sortable: true,
            minWidth: '400px',
            maxWidth: '400px',
            cell: row => (<span className='plusJakara_medium text-[16px]'>{row?.labourName}</span>)
        },
        {
            name: 'Units',
            sortable: true,
            maxWidth: '90px',
            minWidth: '90px',
            // maxWidth: '250px',
            selector: row => row?.labourUnit
        },
        {
            name: 'Type',
            sortable: true,
            maxWidth: '90px',
            minWidth: '90px',
            // maxWidth: '250px',
            selector: row => row?.laboutUnitType
        },
        {
            name: 'Price',
            sortable: true,
            maxWidth: '90px',
            minWidth: '90px',
            // maxWidth: '250px',
            selector: row => '$ ' + row?.labourPrice
        },
        {
            name: 'Total',
            sortable: true,
            maxWidth: '90px',
            minWidth: '90px',
            cell: row => (<span className='plusJakara_medium'>${parseInt(row?.labourPrice) * parseInt(row?.labourUnit)}</span>)
        },
    ]

    const downloadInvoiceAsPDF = () => {
        const invoiceContent = document.getElementById('invoice-content');
        const options = {
            filename: `Invoice_${recieptData?.order?.order_id || 'download'}.pdf`,
            jsPDF: { format: 'a4' },
        };

        // Generate PDF and download
        html2pdf().set(options).from(invoiceContent).save();
    };

    return (
        <main className={`${usertype === 'company' ? 'p-3 p-md-5' : 'pb-5'} w-full`}>
            <Container fluid={usertype === 'company'} className={`${modal && 'blur-sm'} ${usertype === 'company' ? '' : 'mt-5'}`}>
                <div className="flex w-full flex-col gap-4">
                    {usertype === 'company' &&
                        <div className="flex gap-3 items-center justify-between w-full">
                            <h4 className="text_black plusJakara_semibold">Paid Invoices</h4>
                        </div>
                    }
                    <div className="flex w-full flex-col">
                        {loading ? (
                            <div className='my-5 flex justify-center w-full items-center'>
                                <Spinner size="sm" className='text_primary' style={{ color: "#000" }} />
                            </div>) :
                            (!invoices || invoices.length === 0) ? (
                                <div className='flex justify-center items-center w-full my-5'>
                                    <span className="plusJakara_medium md:text-lg">No Invoice Found</span>
                                </div>
                            ) : <DTables columns={columns} data={invoices} />}
                    </div>
                </div>
            </Container>

            <Modal
                centered
                open={modalOpen}
                footer={null}
                width={900}
                zIndex={9999}
                onCancel={() => setmodalOpen(false)}
            >
                <div className={`flex w-full ${!recieptData?.order?.estimate && ' gap-3 gap-md-5'} flex-col`} style={{ height: '90vh', overflowY: 'auto' }}>
                    <div id="invoice-content" className='p-4'>
                        <h1 className="text_secondary2 text-center plusJakara_medium text-2xl md:text-4xl">INVOICE</h1>
                        <div className="flex justify-between mb-4 items-center w-full">
                            <img src={userData?.coverPic || recieptData?.user?.coverPic} className='rounded-3' style={{ width: '120px', height: '100px', objectFit: 'cover' }} alt="" />
                            {/* <h1 className="text_secondary2 plusJakara_medium text-2xl md:text-4xl">INVOICE</h1> */}
                        </div>
                        <div className="flex justify-between mb-4 items-start w-full">
                            <div className="flex flex-col gap-2 justify-center w-full">
                                <h6 className="text_black mb-0 plusJakara_bold">BILLED TO:</h6>
                                <div className="flex flex-col w-full">
                                    <span className="text_secondary mb-0 whitespace-nowrap plusJakara_semibold">{recieptData?.to_id?.name || userData?.name}</span>
                                    <span className="text_secondary mb-0 whitespace-nowrap plusJakara_semibold">{recieptData?.to_id?.phone || userData?.phone}</span>
                                    <span className="text_secondary mb-0 whitespace-nowrap plusJakara_semibold">{recieptData?.to_id?.address || userData?.address}</span>
                                </div>
                            </div>
                            <div className="flex justify-end w-full">
                                <div className="flex flex-col gap-2 items-end justify-end">
                                    <div className="flex gap-3 items-center w-full justify-between">
                                        <h6 className="text_black mb-0 plusJakara_bold text-start w-full whitespace-nowrap">Invoice No:</h6>
                                        <h6 className="text_secondary mb-0 whitespace-nowrap text-end w-full plusJakara_semibold">#{recieptData?.order?.order_id}</h6>
                                    </div>
                                    <div className="flex gap-3 items-center w-full justify-between">
                                        <h6 className="text_black mb-0 plusJakara_bold text-start w-full whitespace-nowrap">Invoice Date:</h6>
                                        <h6 className="text_secondary mb-0 whitespace-nowrap text-end w-full plusJakara_semibold">{moment(recieptData?.date).format('ddd DD MMM YYYY')}</h6>
                                    </div>
                                    <div className="flex gap-3 items-center w-full justify-between">
                                        <h6 className="text_black mb-0 plusJakara_bold text-start w-full whitespace-nowrap">Invoice Due Date:</h6>
                                        <h6 className="text_secondary mb-0 whitespace-nowrap text-end w-full plusJakara_semibold">{moment(recieptData?.invoiceDueDate).format('ddd DD MMM YYYY')}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="flex w-full flex-col gap-4">
                            {recieptData?.order?.estimate &&
                                <DTables columns={columns3} data={labourData} />}
                            {recieptData?.order?.service ?
                                <div className="flex flex-col gap-2 items-start justify-start w-full">
                                    <div className="flex w-full justify-start">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h6 className="text_secondary2 mb-0 plusJakara_semibold">Sub Total </h6>
                                            <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                ${parseInt(recieptData?.order?.service?.totalPrice || 0)}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="flex w-full justify-start">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h6 className="text_secondary2 mb-0 plusJakara_semibold">Discount ({recieptData?.order?.service?.dis_price || 0}$)</h6>
                                            <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                ${parseInt(recieptData?.order?.service?.dis_price)}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="flex w-full justify-start">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h6 className="text_secondary2 mb-0 plusJakara_semibold">Tax ({(recieptData?.order?.service?.tax) || 0}%)</h6>
                                            <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                ${parseInt(recieptData?.order?.service?.totalPrice) * parseInt(recieptData?.order?.service?.tax) / 100}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="flex mt-3 w-full justify-start">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h5 className="text_primary mb-0 plusJakara_semibold">Total Price </h5>
                                            <h5 className="text_black plusJakara_semibold mb whitespace-nowrap-0">
                                                ${recieptData?.totalPrice}
                                            </h5>
                                        </div>
                                    </div>
                                </div> :
                                <div className="flex flex-col gap-2 items-end justify-end w-full">
                                    <div className="flex w-full justify-end">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h6 className="text_secondary2 mb-0 plusJakara_semibold">Sub Total </h6>
                                            <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">${recieptData?.totalPrice}</h6>
                                        </div>
                                    </div>
                                    <div className="flex w-full justify-end">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h6 className="text_secondary2 mb-0 plusJakara_semibold">Discount &ensp;({recieptData?.discount}%)</h6>
                                            <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0"> ${(recieptData?.totalPrice * (parseInt(recieptData?.discount) || 0)) / 100}</h6>
                                        </div>
                                    </div>
                                    <div className="flex w-full justify-end">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h6 className="text_secondary2 mb-0 plusJakara_semibold">Tax &ensp; ({recieptData?.tax}%) </h6>
                                            <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">${(recieptData?.totalPrice * (parseInt(recieptData?.tax) || 0)) / 100}</h6>
                                        </div>
                                    </div>
                                    <div className="flex mt-3 w-full justify-end">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h5 className="text_primary mb-0 plusJakara_semibold">Total Price </h5>
                                            <h5 className="text_black plusJakara_semibold whitespace-nowrap mb-0">${recieptData?.subtotal}</h5>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <div className={`flex justify-center ${!recieptData?.order?.estimate && 'mt-[5rem]'} pe-4 w-full`}>
                        <button onClick={downloadInvoiceAsPDF} style={{ maxWidth: '300px', width: '100%' }} className="bg_primary rounded-5 px-5 py-[12px] plusJakara_medium text_white">
                            Download Invoice
                        </button>
                    </div>
                </div>
            </Modal>
            <UpdrageModal setmodalOpen={setmodal} modalOpen={modal} />
        </ main>
    );
};
export default InvoiceTablePaid;
