/* eslint-disable no-unused-vars */
// ** React Imports
import { Fragment, useEffect, useState } from 'react'

import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'


import { useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { avataruser, notify } from '../../icons/icon'
import axiosInstance from '../../redux/axiosInstance'
import { useSocket } from '../messages/socketProvider'
import { encryptData } from '../../redux/localStorageSecure'

const NotificationDropdown = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const usertype = useSelector((state) => state.auth?.userData?.type)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const pagesCount = useSelector((state) => state.services?.pagesCount);
  const socket = useSocket();

  useEffect(() => {
    if (socket) {
      socket.emit('seen-notification', {});
    }
  }, [socket])

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get("notification/all");
      if (res.data?.success === true) {
        setData(res.data?.notifications);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "err in getting notifications");
    }
  };

  const handleDetailClick = (item) => {
    if (item?.type === "message") {
      const params = new URLSearchParams(searchParams)
      const data = {
        user: item?.user,
      }
      params.set('detail-user', encryptData(data))
      navigate(`/chat?${params.toString()}`)
    } else if (item?.type === "order") {
      navigate(`orders/${item?.order?._id}`);
    } else if (item?.type === "estimate") {
      navigate(`estimates/${item?.estimate?._id}`);
    } else if (item?.type === "contract") {
      navigate(`contracts/${item?.contract?._id}`)
    } else if (item?.type === "invoice") {
      navigate(`invoices/${item?.invoice?._id}`)
    } else if (item?.type === "proposal") {
      if (usertype === 'company') {
        navigate(`Schedule-admin`)
      } else {
        navigate(`schedule`)
      }
    }
  };

  useEffect(() => {
    fetchData()
  }, [])

  const renderNotificationItems = () => {
    return (
      <PerfectScrollbar
        component='li'
        className='media-list scrollable-container'
        options={{
          wheelPropagation: false
        }}
      >
        {
          !data || data.length === 0 ?
            <div className="flex justify-center w-full my-5 items-center">
              <span className="text_primary plusJakara_medium">No Notification Found</span>
            </div>
            : data?.map((item, index) => {
              return (
                <button
                  onClick={() => handleDetailClick(item)}
                  key={index}
                  className='d-flex justify-start text-start w-full no-underline'
                >
                  <div
                    className={classnames('list-item d-flex', {
                      'align-items-start': !item.switch,
                      'align-items-center': item.switch
                    })}
                  >
                    {!item.switch ? (
                      <Fragment>
                        <div className='me-2'>
                          {/* Check if image exists, otherwise show avatar content */}
                          {item.user ? (
                            <div style={{ minWidth: '45px' }}>
                              <img
                                src={item?.user?.profilePicture || avataruser}
                                style={{
                                  width: '40px',
                                  height: '40px',
                                  objectFit: 'cover',
                                  borderRadius: '8px'
                                }}
                                alt=""
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '8px',
                                backgroundColor: item.color || '#828282', // default background color if not specified
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#fff',
                                fontWeight: 'bold'
                              }}
                            >
                              {item.avatarContent}
                            </div>
                          )}
                        </div>
                        <h6 className='list-item-body plusJakara_bold text_dark'>
                          {item.title}
                          <span className='plusJakara_medium text-sm no-underline text_secondary2'>
                            {item.description}
                          </span>
                        </h6>
                      </Fragment>
                    ) : (
                      <Fragment>
                        {item.title}
                        {item.switch}
                      </Fragment>
                    )}
                  </div>
                </button>
              )
            })}
      </PerfectScrollbar>
    )
  }

  return (
    <UncontrolledDropdown tag='li' className='dropdown-notification nav-item me-25'>
      <DropdownToggle tag='a' className='nav-link' href='/' onClick={e => e.preventDefault()}>
        <img src={notify} style={{ height: '40px', width: '40px', borderRadius: '8px' }} className='relative' alt="" />
        {pagesCount?.notification > 0 && (
          <span
            className={`rounded-5 text-sm text_white ${pagesCount?.notification > 9 ? 'px-1 py-[2px]' : 'px-[6px]'} plusJakara_medium`}
            style={{ position: 'absolute', zIndex: 99, top: '0px', right: '-4px', backgroundColor: '#003F7D' }}
          >
            {pagesCount?.notification > 9 ? '+9' : pagesCount?.notification}
          </span>
        )}
      </DropdownToggle>
      <DropdownMenu end tag='ul' className='dropdown-menu-media mt-0'>
        <li className='dropdown-menu-header p-2'>
          <DropdownItem className='d-flex' tag='div' header>
            <h5 className='text_primary plusJakara_medium mb-0 me-auto'>Notifications</h5>
          </DropdownItem>
        </li>
        {renderNotificationItems()}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default NotificationDropdown
