import React, { useState } from 'react';
import { avataruser } from '../../icons/icon';
import { Input } from 'reactstrap';

const EmployeeCard = ({ item, handleSelectEmployee, labor }) => {
    const [checked, setChecked] = useState(false);
    const [selectLabour, setSelectLabour] = useState(false);
    const [selectedTasks, setSelectedTasks] = useState([]);

    const handleCheck = () => {
        const newChecked = !checked;
        setChecked(newChecked);
        handleSelectEmployee(item, newChecked, selectedTasks);
    };

    const handleButtonClick = (e) => {
        setSelectLabour(true);
        e.stopPropagation();
    };

    const handleTaskSelect = (task, isSelected) => {
        const updatedTasks = isSelected
            ? [...selectedTasks, task]
            : selectedTasks.filter((t) => t._id !== task._id);
        setSelectedTasks(updatedTasks);
        handleSelectEmployee(item, true, updatedTasks);
    };

    return (
        <div className="flex flex-col gap-2 w-full">
            <div
                className='flex gap-3 items-start border justify-between shadow-sm bg_white rounded-4 p-4 w-full cursor-pointer'

                onClick={handleCheck}
            >
                <div className="flex gap-3 items-center">
                    <img src={item?.profilePicture || avataruser} style={{ borderRadius: '50%', objectFit: 'cover', width: '45px', height: "45px" }} alt="" />
                    <div className="flex flex-col items-start">
                        <h1 className="plusJakara_semibold mb-0 text-lg text_primary">{item?.name}</h1>
                        <h6 className="plusJakara_regular text_secondary">{item?.email}</h6>
                    </div>
                </div>
                <div className='flex flex-col items-center gap-2'>
                    <Input type="checkbox" checked={checked} onChange={handleCheck} onClick={(e) => e.stopPropagation()} />
                    {checked && (
                        <button
                            type='button'
                            className="bg_primary text_white plusJakara_medium rounded-5 px-3 py-2 text-xs"
                            onClick={handleButtonClick}
                        >
                            Assign Work
                        </button>)}
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                {selectLabour && (
                    labor.map((task) => (
                        <div
                            key={task._id}
                            className="flex items-center p-2 border shadow-sm rounded-3 justify-between cursor-pointer"

                            onClick={() => handleTaskSelect(task, !selectedTasks.includes(task))}
                        >
                            <span className='plusJakara_medium text_primary'>{task.labourName}</span>
                            <Input
                                type="checkbox"
                                checked={selectedTasks.some((t) => t._id === task._id)}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => handleTaskSelect(task, e.target.checked)}
                            />
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default EmployeeCard;
