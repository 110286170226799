/* eslint-disable no-unused-vars */
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import StatisticsChart from "../../common/statisticsChart";
import { employeeDashboard } from "../../api/employee";


const EmployeeDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([1, 2, 3, 4, 5, 6]);
  const [modalOpen, setmodalOpen] = useState(false)

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await employeeDashboard()
      if (res?.data) {
        setCategories(res?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(categories);


  return (
    <main style={{ minHeight: '100%' }} className="container-fluid p-5 mx-auto">
      <Row gutter={16}>
        <Col xs={24} lg={16} style={{ minHeight: '88vh' }} className="p-4 border-r-2 border-r-gray-300">
          <div className="gridWork1 mb-3 w-100">
            <div className="w-100 p-4 rounded-4 gap-3 gap-md-5 flex justify-between flex-col items-center bg_white" style={{ border: '2px solid #FFE2E5' }}>
              <h5 className="plusJakara_semibold">Completed Tasks</h5>
              <h3 className="plusJakara_bold mb-0">232</h3>
            </div>
            <div className="w-100 p-4 rounded-4 gap-3 gap-md-5 flex justify-between flex-col items-center bg_white" style={{ border: '2px solid #FFF4DE' }}>
              <h5 className="plusJakara_semibold">Assigned Tasks</h5>
              <h3 className="plusJakara_bold mb-0">232</h3>
            </div>
            <div className="w-100 p-4 rounded-4 gap-3 gap-md-5 flex justify-between flex-col items-center bg_white" style={{ border: '2px solid #DCFCE7' }}>
              <h5 className="plusJakara_semibold">Pending Tasks</h5>
              <h3 className="plusJakara_bold mb-0">232</h3>
            </div>
            <div className="w-100 p-4 rounded-4 gap-3 gap-md-5 flex justify-between flex-col items-center bg_white" style={{ border: '2px solid #FBFFD4' }}>
              <h5 className="plusJakara_semibold">Total Projects</h5>
              <h3 className="plusJakara_bold mb-0">232</h3>
            </div>
            <div className="w-100 p-4 rounded-4 gap-3 gap-md-5 flex justify-between flex-col items-center bg_white" style={{ border: '2px solid #F3E8FF' }}>
              <h5 className="plusJakara_semibold">Total Tasks</h5>
              <h3 className="plusJakara_bold mb-0">232</h3>
            </div>
          </div>
          <StatisticsChart />
        </Col>
        <Col xs={24} lg={8}>
          <div className="flex flex-col p-4 gap-3 gap-md-5 w-full">
            <div className="flex flex-col gap-2">
              <div className="flex gap-2 mb-1 items-center justify-between w-full">
                <h5 className="text_dark mb-0 plusJakara_semibold">Recent Tasks</h5>
              </div>
              <div className="flex flex-col px-3 gap-2 w-full">
                {categories.map((item, i) => (
                  <div className="flex gap-4 border-b border-b-gray-300 py-2 items-start w-full">
                    <div className="flex flex-col gap-1">
                      <span className="text_secondary2 whitespace-nowrap plusJakara_medium">Tue, 12 July</span>
                      <span className="text_secondary whitespace-nowrap plusJakara_bold">10:00 am</span>
                    </div>
                    <div className="flex flex-col w-full">
                      <h6 className="text_primary plusJakara_semibold">Home cleaning Service</h6>
                      <div className="flex gap-3 items-center w-full justify-between">
                        <div className="border px-2 w-fit py-1 rounded-2 text-xs bg_light plusJakara_medium">Pending</div>
                        {/* <button onClick={() => setmodalOpen(true)} className="bg_primary2 text_white rounded-2 plusJakara_medium text-xs px-2 py-1">Create Contract</button> */}
                      </div>
                    </div>
                  </div>
                ))}
                <div className="flex w-full justify-center mt-2 gap-1">
                  <button onClick={() => navigate('/all-tasks')} className="text_secondary2 flex items-center text-sm plusJakara_medium">See all tasks <MdKeyboardArrowRight size={20} /> </button>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </main>
  );
};

export default EmployeeDashboard;
