import axios from 'axios';
import axiosInstance from '../redux/axiosInstance';

export const createUser = async ({ data }, type) => {
    try {
        const res = await axiosInstance.post(`users/signup/${type}`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const sendCode = async (phone) => {
    try {
        const res = await axiosInstance.post('users/send-code', {
            phone,
        });
        return res;
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const changePassword = async ({ data }) => {
    try {
        const res = await axiosInstance.put(`users/change-password`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const forgotPassword = async (phone) => {
    try {
        const res = await axiosInstance.post('users/forget-password', {
            phone,
        });
        return res;
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const verfiyPassCode = async (code, token) => {
    try {
        const res = await axiosInstance.put('users/code-verify', {
            code, token,
        },)
        return res;
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const verifyForgotEmail = async (code, token) => {
    try {
        const res = await axiosInstance.post('users/verify-otp/forget-password', {
            code, token,
        },)
        return res;
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const verifyPhoneLogin = async (code, token) => {
    try {
        const res = await axiosInstance.post('auth/verify-login', {
            code, token,
        },)
        return res;
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const updateUserPassword = async (password, token, code) => {
    try {
        const res = await axiosInstance.put('users/update-password', {
            password, token, code
        });
        return res;
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const loginUser = async ({ data }) => {
    try {
        const res = await axiosInstance.post('auth',
            data);
        // console.log(res.data, "res");
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const loginUserPhone = async ({ data }) => {
    try {
        const res = await axiosInstance.post('auth/phone',
            data);
        // console.log(res.data, "res");
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const checkEmail = async (email) => {
    try {
        const res = await axiosInstance.post('users/check-email', {
            email,
        });
        return res.data;
    } catch (error) {
        console.log(error, "error");
        throw error; // Rethrow the error to handle it in the component
    }
};

export const checkPhone = async (phone) => {
    try {
        const res = await axiosInstance.post('users/check-phone', {
            phone,
        });
        return res.data;
    } catch (error) {
        console.log(error, "error");
        throw error; // Rethrow the error to handle it in the component
    }
};
export const getSpecificUser = async (id) => {
    try {
        const res = await axios.post(global.BASEURL + '/getSpecificUser', {
            id: id
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return res;
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};


export const updateUser = async ({ data }) => {
    try {
        const res = await axiosInstance.put('users/update-user', data);
        return res;
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};
