/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { Col, Modal, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { BiShoppingBag } from 'react-icons/bi';
import { FaFacebookF, FaLinkedinIn, FaStar } from 'react-icons/fa6';
import { FiPhoneCall } from 'react-icons/fi';
import { IoHelpCircleOutline, IoSettingsOutline } from 'react-icons/io5';
import { MdOutlineMailOutline, MdOutlineReviews } from 'react-icons/md';
import { RiInstagramFill } from 'react-icons/ri';
import { SlLocationPin } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Input } from 'reactstrap';
import { Autoplay, FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getRating } from '../api/rating';
import { avataruser, cleaning2, coveravatar, marker } from '../icons/icon';
import axiosInstance from '../redux/axiosInstance';
import { encryptData } from '../redux/localStorageSecure';
import '../style/swiper.css';
import ServiceItem2 from './serviceItem2';

const ServiceProviderDetail = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [active, setActive] = useState('reviews');
    const { state } = useLocation()
    const navigate = useNavigate()
    const [rating, setrating] = useState([])
    const [ratingLoading, setratingLoading] = useState(false)
    const [availableDays, setavailableDays] = useState([])
    // const [portfolio, setportfolio] = useState(state?.portfolioData || null)
    const [companyServices, setCompanyServices] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef()
    const portfolio = useSelector((state) => state.generalRedux?.serviceProvider)
    const [show, setshow] = useState(false)
    const [selectedItem, setselectedItem] = useState(null)
    const [loading, setLoading] = useState(false)

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_APIKEY,
        libraries: ['places', "maps"]
    });
    console.log(portfolio);

    useEffect(() => {
        if (portfolio) {
            // setportfolio(portfolio)
            const availableDays = portfolio?.availablity?.filter(day => day.status === true);
            setavailableDays(availableDays)
            axiosInstance.get(`service/company/${portfolio?._id}`).then((res) => {
                if (res?.data) {
                    setCompanyServices(res?.data?.services);
                    setLoading(false);
                } else {
                    setCompanyServices([]);
                    setLoading(false);
                }
            }).catch((err) => {
                setLoading(false);
                console.log(err);
            })

        }
    }, [portfolio])

    const handleThumbnailClick = (item, index) => {
        setActiveIndex(index);
        swiperRef.current.slideTo(index);
    };
    const handleClickPortfolio = (row) => {
        setshow(true)
        setselectedItem(row)
    }

    const handleGetRating = async () => {
        setratingLoading(true)
        await getRating(portfolio?._id)
            .then((res) => {
                console.log(res);
                if (res.success === true) {
                    setratingLoading(false)
                    setrating(res?.ratings)
                } else {
                    setratingLoading(false)
                    setrating([])
                }
            }).catch((err) => {
                setratingLoading(false)
                console.log(err);

            })
    }

    const handleTab = (tab) => {
        const data = {
            selected: tab
        }
        setSearchParams(data)
    };

    useEffect(() => {
        if (active === 'reviews') {
            handleGetRating()
        }
    }, [active])

    useEffect(() => {
        if (searchParams.get('selected')) {
            setActive(searchParams.get('selected'))
        } else {
            const data = {
                selected: 'reviews'
            }
            setSearchParams(data)
        }
    }, [searchParams]);

    const handleNavigate = (item) => {
        const params = new URLSearchParams(searchParams)
        const data = {
            user: item,
        }
        params.set('detail-user', encryptData(data))
        navigate(`/chat?${params.toString()}`)
    }
    console.log(portfolio);

    return (
        <main className='pb-5 pt-[5rem]'>
            <Container className='mb-4'>
                <div className="relative mb-5 w-full">
                    <div className="w-full mb-4 relative">
                        <img src={portfolio?.coverPic || coveravatar} className='relative overflow-hidden rounded-3' style={{ height: '22rem', width: '100%', objectFit: "cover" }} alt="" />
                        <div className="bg-overlay rounded-3"></div>
                    </div>
                    <img src={portfolio?.profilePicture || avataruser} className='bottom-0 left-10 z-50 absolute' style={{ width: '200px', height: "200px", borderRadius: '50%', objectFit: 'cover', border: '11.57px solid #FFFFFF' }} alt="" />
                    <div className="flex flex-col relative mb-5 gap-4 ps-[17rem] pe-5">
                        <div className="flex justify-between w-full gap-3 items-start">
                            <div className="flex flex-col gap-1 w-full">
                                <h6 className="plusJakara_medium text-xl mb-0">{portfolio?.name}</h6>
                                <div className="flex gap-1 items-center">
                                    <div className="flex gap-1 items-center">
                                        {[...Array(5)].map((_, index) => (
                                            <FaStar
                                                key={index}
                                                color={index < portfolio?.rating ? '#F6921E' : '#dcdcdc'}
                                                size={16}
                                            />
                                        ))}
                                    </div>
                                    <span className="text_black plusJakara_medium text-sm">{portfolio?.total_reviews} Reviews</span>
                                </div>
                                <div className="px-4 w-fit py-2 plusJakara_regular bg-[#f6911e2b] text-xs text_primary2 rounded-2">{portfolio?.category?.name}</div>
                            </div>
                            <div className="flex justify-center gap-2">
                                <a href={portfolio?.fblink} target='__blank' style={{ border: '1.5px solid #63696d' }} className="p-2 rounded-2 hover:text-black text-[#63696d]">
                                    <FaFacebookF size={16} />
                                </a>
                                <a href={portfolio?.linkedInlink} target='__blank' style={{ border: '1.5px solid #63696d' }} className="p-2 rounded-2 hover:text-black text-[#63696d]">
                                    <FaLinkedinIn size={16} />
                                </a>
                                <a href={portfolio?.instalink} target='__blank' style={{ border: '1.5px solid #63696d' }} className="p-2 rounded-2 hover:text-black text-[#63696d]">
                                    <RiInstagramFill size={16} />
                                </a>
                            </div>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="flex items-start gap-2">
                                <div className="w-6">
                                    <SlLocationPin className='text_primary rounded-full p-1' style={{ border: '1px solid #003F7D', width: '25px', height: '25px' }} size={24} />
                                </div>
                                <h6 className="text_secondar2 text-sm plusJakara_medium mb-0">{portfolio?.address || "Not found"}</h6>
                            </div>
                            <div className="flex items-start gap-2">
                                <div className="w-6">
                                    <FiPhoneCall className='text_primary rounded-full p-1' style={{ border: '1px solid #003F7D', width: '25px', height: '25px' }} size={24} />
                                </div>
                                <h6 className="text_secondar2 text-sm plusJakara_medium mb-0">{portfolio?.phone || "Not found"}</h6>
                            </div>
                            <div className="flex items-start gap-2">
                                <div className="w-6">
                                    <MdOutlineMailOutline className='text_primary rounded-full p-1' style={{ border: '1px solid #003F7D', width: '25px', height: '25px' }} size={24} />
                                </div>
                                <h6 className="text_secondar2 text-sm plusJakara_medium mb-0">{portfolio?.email || "Not found"}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <Row gutter={16} className='justify-between'>
                    <Col xs={24} md={9} xl={7}>
                        <div className="flex flex-col gap-2 items-center">
                            <button onClick={() => handleTab('reviews')}
                                style={{ minWidth: '20rem' }}
                                className={`px-3 py-[14px] rounded-1 plusJakara_medium ${active === 'reviews' ? 'text_white bg_primary' : 'bg-[#FAFAFA] border border-[#FAFAFA] text_black'} flex items-center gap-3`}>
                                <MdOutlineReviews size={22} />
                                Reviews
                            </button>
                            <button onClick={() => handleTab('about')}
                                style={{ minWidth: '20rem' }}
                                className={`px-3 py-[14px] rounded-1 plusJakara_medium ${active === 'about' ? 'text_white bg_primary' : 'bg-[#FAFAFA] border border-[#FAFAFA] text_black'} flex items-center gap-3`}>
                                <IoHelpCircleOutline size={22} />
                                About
                            </button>
                            <button onClick={() => handleTab('services')}
                                style={{ minWidth: '20rem' }}
                                className={`px-3 py-[14px] rounded-1 plusJakara_medium ${active === 'services' ? 'text_white bg_primary' : 'bg-[#FAFAFA] border border-[#FAFAFA] text_black'} flex items-center gap-3`}>
                                <IoSettingsOutline size={22} />
                                Services
                            </button>
                            <button onClick={() => handleTab('portfolio')}
                                style={{ minWidth: '20rem' }}
                                className={`px-3 py-[14px] rounded-1 plusJakara_medium ${active === 'portfolio' ? 'text_white bg_primary' : 'bg-[#FAFAFA] border border-[#FAFAFA] text_black'} flex items-center gap-3`}>
                                <BiShoppingBag size={22} />
                                Portfolio
                            </button>
                        </div>
                    </Col>
                    <Col xs={24} md={15} xl={17}>
                        <>
                            <div style={{ overflowY: 'auto' }} className="flex flex-col gap-4 items-start w-full">
                                {active === 'reviews' &&
                                    <>
                                        <div style={{ backgroundColor: '#fff' }} className="flex gap-4 items-center w-full shadow-sm border rounded-2 p-3">
                                            <div className="flex justify-center items-center" style={{ backgroundColor: "#FFF8E0", width: "80px", height: "80px", borderRadius: '5px' }}>
                                                <h3 className="text_black plusJakara_medium mb-0">{portfolio?.total_reviews}</h3>
                                            </div>
                                            <div className="flex flex-col items-start">
                                                <div className="flex gap-1 mb-2 items-center">
                                                    {[...Array(5)].map((_, index) => (
                                                        <FaStar
                                                            key={index}
                                                            color={index < portfolio?.rating ? '#F6921E' : '#dcdcdc'}
                                                            size={18}
                                                        />
                                                    ))}
                                                </div>
                                                <h6 className="text_black plusJakara_medium mb-0">{portfolio?.rating} Star Average Rating</h6>
                                                <span className="text_secondary2 plusJakara_regular">{portfolio?.total_reviews} People Writed Review</span>
                                            </div>
                                        </div>
                                        <div style={{ backgroundColor: '#fff', maxHeight: "75vh", overflowY: 'hidden', height: '100%' }} className="flex gap-4 flex-col w-full items-center shadow-sm border rounded-2 p-3">
                                            {ratingLoading ? (
                                                <div className="flex my-4 w-full justify-center items-center">
                                                    <Spinner size="sm" className="text_primary" />
                                                </div>
                                            ) : (
                                                <>
                                                    {!rating || rating?.length === 0 ? (
                                                        <div className="flex my-4 w-full justify-center items-center">
                                                            <span className="text_black plusJakara_medium">No Rating found</span>
                                                        </div>
                                                    ) : (
                                                        rating?.map((item, i) => (
                                                            <div key={i} className="flex items-start gap-3 border w-full rounded-4 p-3 justify-start flex-col lg:flex-row">
                                                                <div style={{ minWidth: '85px' }}>
                                                                    <img
                                                                        src={item?.user?.profilePicture || avataruser}
                                                                        style={{
                                                                            width: '80px',
                                                                            height: '80px',
                                                                            borderRadius: '12px',
                                                                            objectFit: 'cover',
                                                                        }}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="flex flex-col gap-1 w-full">
                                                                    <div className="flex items-center gap-2">
                                                                        <div className="flex items-center">
                                                                            {[...Array(5)].map((_, i) => (
                                                                                <FaStar
                                                                                    key={i}
                                                                                    color={i < item?.rating ? '#F6921E' : '#d3d3d3'}
                                                                                    size={20}
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex items-center mb-2 gap-2">
                                                                        <h6 className="text_black plusJakara_medium mb-0 whitespace-nowrap">{item?.user?.name}</h6>
                                                                        <div className="flex gap-1 items-center">
                                                                            <div
                                                                                style={{
                                                                                    width: '4px',
                                                                                    height: '4px',
                                                                                    borderRadius: '50%',
                                                                                    backgroundColor: '#939AAD',
                                                                                }}
                                                                            ></div>
                                                                            <span className="text_secondary2 text-sm plusJakara_regular mb-0">Review at:</span>
                                                                        </div>
                                                                        <span className="text_secondary text-xs plusJakara_regular mb-0">
                                                                            {moment(item?.createdAt).format('MMM DD,YYYY')}
                                                                        </span>
                                                                    </div>
                                                                    <span className="text_secondary2 plusJakara_regular">
                                                                        {item?.review}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </>
                                }
                                {active === 'about' &&
                                    <div style={{ backgroundColor: '#fff' }} className="flex gap-4 flex-col items-start w-full shadow-sm border rounded-2 p-3">
                                        <div className="flex flex-col gap-2 w-full">
                                            <h6 className="text_primary mb-0 plusJakara_semibold">Description</h6>
                                            <span className="text_secondary2 plusJakara_regular">
                                                {portfolio?.about || 'Not found'} </span>
                                        </div>
                                        <div className="flex flex-col gap-2 w-full">
                                            <h6 className="text_primary mb-0 plusJakara_semibold">Venue & Location</h6>
                                            {isLoaded ? (
                                                <div style={{ width: '100%', overflow: 'hidden', borderRadius: "20px" }}>
                                                    <GoogleMap
                                                        mapContainerStyle={{ width: '100%', height: '15rem' }}
                                                        center={{
                                                            lat: portfolio?.location?.coordinates[1],
                                                            lng: portfolio?.location?.coordinates[0],
                                                        }}
                                                        zoom={16}
                                                        apiKey={process.env.REACT_APP_APIKEY}
                                                    >
                                                        <MarkerF
                                                            icon={{
                                                                url: marker,
                                                                scaledSize: new window.google.maps.Size(35, 35),
                                                                className: 'custom-marker-icon',
                                                            }}
                                                            position={{ lat: portfolio?.location?.coordinates[1], lng: portfolio?.location?.coordinates[0] }}
                                                        />
                                                    </GoogleMap>
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="flex flex-col w-full gap-2">
                                            <h6 className="text_primary mb-0 plusJakara_semibold">Categories</h6>
                                            <span className="text_secondary2 text-sm plusJakara_medium">
                                                {portfolio?.category?.name}
                                            </span>
                                        </div>
                                        <div className="flex flex-col w-full gap-2">
                                            <h6 className="text_primary mb-0 plusJakara_semibold">Sub categories</h6>
                                            <div className="flex gap-2 items-center w-full flex-wrap">
                                                {portfolio?.subcategories?.map((item, i) => (
                                                    <span key={i} className="text_secondary2 text-sm plusJakara_medium">
                                                        {item?.name}
                                                        {i < portfolio?.subcategories.length - 1 && (
                                                            <span className="mx-1"> →</span>
                                                        )}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full gap-2">
                                            <h6 className="text_primary mb-0 plusJakara_semibold">Working Hours</h6>
                                            <div className="flex flex-wrap w-full justify-start mb-3 gap-3">
                                                {availableDays?.map((day, index) => (
                                                    <div
                                                        key={index}
                                                        style={{ maxWidth: '10rem', width: "100%" }}
                                                        className="px-4 rounded-3 py-2 flex flex-col gap-2 justify-start"
                                                    >
                                                        <h6 className="text_black mb-0 plusJakara_semibold">
                                                            {day.day}
                                                        </h6>
                                                        <h6 style={{ whiteSpace: "nowrap" }} className="text_secondary2 mb-0 plusJakara_medium text-sm">
                                                            {new Date(day.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(day.end_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                        </h6>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>}
                                {active === 'services' && (
                                    <div style={{ backgroundColor: '#fff' }} className="flex gap-4 items-center w-full shadow-sm border rounded-2 p-3">
                                        {loading ? (
                                            <Spinner size="sm" className='text_primary' />
                                        ) : (
                                            <>
                                                {!companyServices || companyServices?.length === 0 ? (
                                                    <span className="text_black plusJakara_medium">No Service Found</span>
                                                ) : (
                                                    <div className="grid6">
                                                        {companyServices.map((item, i) => (
                                                            <ServiceItem2 showView={false} key={i} category={item} portfolioPage={true} />
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                                {active === 'portfolio' && (
                                    <div style={{ backgroundColor: '#fff' }} className="flex gap-4 items-center w-full shadow-sm border rounded-2 p-3">
                                        <div className="flex w-full flex-col">
                                            {portfolio?.portfolio?.length === 0 ?
                                                <div className="flex justify-center w-full items-center">
                                                    <span className="text_black plusJakara_medium">No Portfolio Found</span>
                                                </div> :
                                                <div className="grid6">
                                                    {portfolio?.portfolio?.map((item, i) => (
                                                        <div key={i} className="flex flex-col rounded-3 overflow-hidden border gap-3">
                                                            <div className="home_swiper" style={{ width: '100%', height: 'auto' }}>
                                                                <Swiper
                                                                    freeMode={true}
                                                                    modules={[FreeMode, Autoplay, Navigation]}
                                                                    // navigation={true}
                                                                    // pagination={{ clickable: true }}
                                                                    loop={true}
                                                                    className="mySwiper d-flex w-100 gap-3 relative"
                                                                    slidesPerView={1}
                                                                // onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                                                                // initialSlide={activeIndex}
                                                                >
                                                                    {item?.urls?.length > 0 ? (
                                                                        item.urls.map((cat, index) => (
                                                                            <SwiperSlide
                                                                                key={index}
                                                                                style={{ width: '100%', height: '18rem', overflow: 'hidden' }}
                                                                            >
                                                                                <div onClick={() => handleClickPortfolio(item, index)} className="bg-overlay cursor-pointer"></div>
                                                                                <div className="absolute bottom-4 flex justify-center w-full z-50">
                                                                                    <h5 className="text_white plusJakara_semibold mb-0 text-center">{item.title}</h5>
                                                                                </div>
                                                                                {cat?.type === 'video' && cat?.url ? (
                                                                                    <video
                                                                                        src={cat.url}
                                                                                        alt="Preview"
                                                                                        onClick={() => handleClickPortfolio(item, index)}
                                                                                        style={{
                                                                                            position: 'relative',
                                                                                            // zIndex: 99,
                                                                                            height: '100%',
                                                                                            width: '100%',
                                                                                            cursor: 'pointer',
                                                                                            objectFit: 'cover',
                                                                                        }}
                                                                                        className="rounded-4 w-full bg_light object-cover"
                                                                                        controls
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => handleClickPortfolio(item, index)}
                                                                                        src={cat.url || coveravatar}
                                                                                        style={{
                                                                                            position: 'relative',
                                                                                            // zIndex: 99,
                                                                                            height: '100%',
                                                                                            width: '100%',
                                                                                            cursor: 'pointer',
                                                                                            objectFit: 'cover',
                                                                                        }}
                                                                                        alt="Project"
                                                                                    />
                                                                                )}
                                                                            </SwiperSlide>
                                                                        ))
                                                                    ) : (
                                                                        <SwiperSlide
                                                                            style={{ width: '100%', height: '18rem', overflow: 'hidden' }}
                                                                        >
                                                                            <img
                                                                                src={coveravatar}
                                                                                // onClick={() => handleClickPortfolio(item, 0)}
                                                                                style={{
                                                                                    height: '100%',
                                                                                    width: '100%',
                                                                                    cursor: 'pointer',
                                                                                    objectFit: 'cover',
                                                                                }}
                                                                                alt="Default Project"
                                                                            />
                                                                        </SwiperSlide>
                                                                    )}
                                                                </Swiper>
                                                                {/* <div onClick={() => handleClickPortfolio(item, 0)} className="p-2 flex flex-col cursor-pointer gap-2">
                                                                    <div className="flex justify-between gap-1 items-center">
                                                                        <h6 className="text_black plusJakara_semibold mb-0 whitespace-nowrap">{item.title}</h6>
                                                                    </div>
                                                                    <span className="text_secondary2 plusJakara_regular line-clamp-2">{item.description}</span>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    </Col>
                </Row>

                <Modal
                    open={show}
                    footer={null}
                    centered
                    width={1200}
                    zIndex={9999}
                    style={{ height: '95vh', overflow: 'scroll', }}
                    onCancel={() => setshow(false)}
                >
                    <div>
                        <h4 className="text_primary text-center mb-4 plusJakara_bold">View Portfolio Detail</h4>
                        <div className="flex flex-col w-full overflow-hidden gap-3">
                            <div className="home_swiper" style={{ width: '100%', height: 'auto' }}>
                                <Swiper
                                    freeMode={true}
                                    modules={[FreeMode, Autoplay, Navigation]}
                                    navigation={true}
                                    pagination={{ clickable: true }}
                                    loop={true}
                                    className="mySwiper d-flex w-full gap-3 relative"
                                    slidesPerView={1}
                                    onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                                    initialSlide={activeIndex}
                                >
                                    {selectedItem?.urls?.length > 0 ? (
                                        selectedItem.urls.map((cat, index) => (
                                            <SwiperSlide
                                                key={index}
                                                className='rounded-4'
                                                style={{ width: '100%', height: '34rem', overflow: 'hidden' }}
                                            >
                                                {cat?.type === 'video' && cat?.url ? (
                                                    <video
                                                        src={cat.url}
                                                        alt="Preview"
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                            cursor: 'pointer',
                                                            objectFit: 'contain',
                                                            backgroundColor: "#f4f4f4"
                                                        }}
                                                        className="rounded-4 w-full bg_light object-cover"
                                                        controls
                                                    />
                                                ) : (
                                                    <img
                                                        // onClick={() => handleClickPortfolio(cat, index)}
                                                        src={cat.url || cleaning2}
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                            cursor: 'pointer',
                                                            objectFit: 'contain',
                                                            backgroundColor: "#f4f4f4"
                                                        }}
                                                        alt="Project"
                                                    />
                                                )}
                                            </SwiperSlide>
                                        ))
                                    ) : (
                                        <SwiperSlide
                                            className='rounded-4'
                                            style={{ width: '100%', height: '34rem', overflow: 'hidden' }}
                                        >
                                            <img
                                                src={coveravatar}
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    cursor: 'pointer',
                                                    objectFit: 'contain',
                                                    backgroundColor: "#f4f4f4"
                                                }}
                                                alt="Default Project"
                                            />
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                                <div className="thumbnails flex justify-start gap-3 mt-4">
                                    {selectedItem?.urls?.map((cat, index) => (
                                        <div
                                            key={index}
                                            className={`thumbnail relative cursor-pointer overflow-hidden rounded-2 ${activeIndex === index ? 'border-2 border-[#003F7D]' : 'border'}`}
                                            onClick={() => handleThumbnailClick(cat, index)}
                                            style={{
                                                width: '140px',
                                                height: '120px',
                                                overflow: 'hidden',
                                                transition: 'border-color 0.3s ease',
                                            }}
                                        >
                                            {cat?.type === 'video' ?
                                                <video
                                                    src={cat.url}
                                                    alt="Preview"
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        cursor: 'pointer',
                                                        objectFit: 'cover',
                                                    }}
                                                    controls
                                                /> :
                                                <img
                                                    src={cat.url || cleaning2} style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        cursor: 'pointer',
                                                        objectFit: 'cover',
                                                    }} alt="Project" />}
                                        </div>
                                    ))}
                                </div>
                                <div className="p-3 flex flex-col gap-2">
                                    <div className="flex justify-between gap-2 items-center">
                                        <h4 className="text_black plusJakara_bold mb-0 whitespace-nowrap">{selectedItem?.title}</h4>
                                    </div>
                                    <span className="text_secondary2 plusJakara_regular">{selectedItem?.description}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </Container>
        </ main >
    );
};

export default ServiceProviderDetail;