import axiosInstance from "../redux/axiosInstance";

export const createService = async ({ data }) => {
    try {
        const res = await axiosInstance.post(`service/create`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const customerSideServices = async ({ data }, page) => {
    try {
        const res = await axiosInstance.post(`service/website/filter/${page}`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const getMyServices = async (catid) => {
    try {
        const res = await axiosInstance.get(`service/me`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};
export const deleteService = async (id) => {
    try {
        const res = await axiosInstance.delete(`service/${id}`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const updateService = async ({ data }, id) => {
    try {
        const res = await axiosInstance.put(`service/edit/${id}`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};
