import axiosInstance from "../redux/axiosInstance";

export const getCoupon = async (page) => {
    try {
        const res = await axiosInstance.get(`coupon/me/${page}`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};


export const allHomeBanners = async (type, query) => {
    try {
        const res = await axiosInstance.get(`banner/all?type=${type}&category=${query}`);
        return res;
    } catch (error) {
        console.log(error, "error");
    }
}