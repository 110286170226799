/* eslint-disable no-unused-vars */
import React from 'react';
import { Check } from 'react-feather';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setAccessToken, setLogin, setUserData, setUserType } from '../../redux/loginForm';

const PricingCard = ({ title, subTitle, monthly = true, list, price, selected = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleProviderSignup = () => {
        dispatch(setLogin(false));
        dispatch(setUserData(null));
        dispatch(setUserType('company'));
        navigate('/login', { replace: true });
        dispatch(setAccessToken(null));
    };

    return (
        <div
            className={`flex flex-col h-100 ${selected && "selected-package"} max-w-[20rem] min-w-[15rem] mx-auto justify-between rounded-3xl border border-solid border-subtle px-[23px] pb-[23px] pt-[21px] shadow-[0px_1px_2px_-1px_rgba(28,_40,_64,_0.08),_0px_2px_4px_0px_rgba(28,_40,_64,_0.04)]`}
        >
            <div className="flex flex-col">
                <header className="text-xl text_secondary plusJakara_bold">{title}</header>
                <span className="text_secondary2 plusJakara_medium text-sm">{subTitle}</span>
                <div className="mt-4 lg:mt-8">
                    <h3 className="text_primary plusJakara_semibold">{price}</h3>
                    <div className="mt-0.5 text-xs plusJakara_medium text-accent">
                        Per user/month, billed{' '}
                        <span>{monthly ? 'monthly' : 'annually'}</span>
                    </div>
                </div>
                <ul className="mt-4 px-0 flex flex-col gap-y-2.5">
                    {list?.map((item, index) => (
                        <li
                            className="flex items-start gap-2 relative group"
                            key={index}
                        >
                            <div className="h-[24px] w-[24px] flex justify-center items-center rounded-md bg-light p-0.5 text-accent">
                                <Check size={16} />
                            </div>
                            <div className="text-sm plusJakara_medium">{item.name}</div>
                            <div className="absolute left-[30px] bottom-[100%] plusJakara_medium hidden group-hover:block bg_primary text_white text-xs p-2 rounded-md shadow-md">
                                {item.description}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="mt-1 flex flex-col items-stretch">
                <button
                    onClick={handleProviderSignup}
                    className="bg_primary text_white rounded-[10px] py-[10px] text-sm"
                >
                    Get Started
                </button>
            </div>
        </div>
    );
};

export default PricingCard;
