/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Spinner } from 'react-bootstrap';
import { DatePicker, message, Modal } from 'antd';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FaEye } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Row
} from "reactstrap";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import * as Yup from "yup";
import { getEstimate } from '../api/estimateApi';
import { createOrder } from '../api/order';
import DTables from "../DataTable/DTable";
import '../style/swiper.css';
import CreateContract from './common/createContract';
import HeaderNavigation from './common/headerNavigation';
import { calculateFinalPrice } from './estimate/calculateFinalPrice';
import { useSocket } from './messages/socketProvider';
import UpdrageModal from './common/upgradeModal';
import StripeConnectError from './common/stripeConnectError';
import TableSkeleton from './common/tableSkeleton';
const { RangePicker } = DatePicker;

const EstimateTable = () => {
    const { state } = useLocation()
    const [modalOpen, setmodalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItem, setselectedItem] = useState(null)
    const [showNotifyModal, setshowNotifyModal] = useState(false)
    const [statusId, setstatusId] = useState(null)
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const navigate = useNavigate();
    const [loading, setloading] = useState([])
    const [estimates, setestimates] = useState([])
    const socket = useSocket();
    const [modal, setmodal] = useState(false)
    const userData = useSelector((state) => state.auth?.userData)

    useEffect(() => {
        if (socket) {
            socket.emit('seen-estimate', {});
        }
    }, [socket])

    const fetchEstimates = async () => {
        setloading(true)
        await getEstimate(usertype === 'company' ? 'employee' : 'customer')
            .then((res) => {
                if (res.success === true) {
                    setestimates(res.estimates)
                    setloading(false)
                } else {
                    setloading(false)
                    setestimates([])
                }
            }).catch((err) => {
                setloading(false)
                setestimates([])
            })
    }

    useEffect(() => {
        if (userData?.sub_type === 'free' && usertype === 'company') {
            setmodal(true)
        } else {
            setmodal(false)
        }
        fetchEstimates()
    }, [userData])

    const handleSubmit = async (row) => {
        setstatusId(row?._id)
        const data = {
            bookingDate: row?.date,
            to_id: row?.user?._id || row?.user,
            estimate: row?._id,
            type: (row?.user?.sub_type === 'free' || row?.user?.sub_type === 'basic') ? 'order' : (row?.totalPrice >= 1000 ? "contract" : "order"),
        }
        setIsLoading(true)
        await createOrder({ data: data }).then((res) => {
            if (res.success === true) {
                message.success("Order created successfully");
                setIsLoading(false)
                fetchEstimates()
            } else {
                message.error("Failed to Order created");
                setIsLoading(false)
            }
        }).catch((err) => {
            console.error("Error Order created:", err);
            message.error(err.response.data.message)
            setIsLoading(false)
        })
    }

    const validationSchema = Yup.object().shape({
        notification: Yup.string()
            .required("Please enter a Message")
    });
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            notification: "",
        },
        validationSchema,
    });

    const handleSendNotify = (row) => {
        setshowNotifyModal(true)
        setselectedItem(row)
    };

    const handleViewDetails = (row) => {
        navigate(`/estimates/${row?._id}`, { state: { estimateData: row } })
    };

    const columns = [
        {
            name: usertype === 'company' ? 'Customer' : 'Service Provider',
            sortable: true,
            selector: row => usertype === 'company' ? row?.to_id?.name : row?.user?.name,
            minWidth: '150px'
        },
        {
            name: 'Project Start Date',
            sortable: true,
            selector: row => moment(row?.date).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        {
            name: 'Project End Date',
            sortable: true,
            selector: row => moment(row?.endDate).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        {
            name: 'Status',
            // sortable: true,
            cell: row => (
                <div className="px-2 py-1 w-fit text-center rounded-2" style={{ backgroundColor: '#E6E9F4', color: '#5A607F' }}>
                    {usertype === 'company' ? 'Waiting for Customer Approval' : 'Pending'}
                </div>
            ),
            // minWidth: '200px'
        },
        {
            name: 'Discount (%)',
            sortable: true,
            cell: row => (
                <span className='plusJakara_medium '>{row?.dis_price || 0}%
                </span>
            ),
            minWidth: '100px'
        },
        {
            name: 'Tax (%)',
            sortable: true,
            cell: row => (
                <span className='plusJakara_medium '>{row?.tax || 0}%
                </span>
            ),
            minWidth: '100px'
        },
        {
            name: 'Amount',
            sortable: true,
            cell: row => (
                <span className='plusJakara_medium '>${calculateFinalPrice(parseInt(row?.totalPrice),
                    parseInt(row?.dis_price || 0),
                    parseInt(row?.tax || 0))}
                </span>
            ),
            minWidth: '100px'
        },
        ...(usertype === 'customer' ? [{
            name: 'Action',
            sortable: false,
            cell: row => (
                <div className="flex gap-2">
                    {usertype !== 'company' &&
                        <>
                            {/* // <button onClick={() => navigate('/estimates/contract/123432221')} style={{ backgroundColor: '#f4f4f4', minWidth: '120px' }} className="flex justify-center px-2 py-2 gap-1 items-center rounded-2">
                        //     <span className="text_black whitespace-nowrap plusJakara_regular"> View Contract</span>
                        // </button>
                        // <button className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2" style={{ minWidth: '120px' }} onClick={() => setmodalOpen(true)}>
                        //     <span className="text_white whitespace-nowrap plusJakara_regular"> Create Contract</span>
                        // </button>
                        // : <> */}
                            {/* <button style={{ backgroundColor: '#f4f4f4', }} className="flex justify-center  px-3 py-2 gap-1 items-center rounded-2">
                                <span className="text_black whitespace-nowrap plusJakara_regular">Cancel</span>
                            </button> */}
                            <button disabled={isLoading} onClick={() => handleSubmit(row)} className="flex gap-1 items-center justify-center rounded-2 bg_primary px-3 py-2">
                                <span className="text_white whitespace-nowrap plusJakara_regular">{row?._id === statusId && isLoading ? <Spinner size="sm" className='text_white' style={{ color: "white" }} /> : 'Confirm'}</span>
                            </button>
                        </>}
                </div>
            ),
            minWidth: '220px'
        }] : []),
        {
            name: 'View Estimate',
            cell: row => (
                <button className="btn-view-details ms-3 bg_light rounded-2 p-2" onClick={() => handleViewDetails(row)}>
                    <FaEye />
                </button>
            ),
            minWidth: '50px'
        }
    ];

    return (
        <>
            {usertype === 'company' &&
                <StripeConnectError />}
            <main className={`${usertype === 'company' ? ((!userData?.accountId_verified && (userData?.sub_type !== 'free' && userData?.sub_type !== 'basic')) ? 'px-3 px-md-5 py-2' : 'p-3 p-md-5') : 'pb-5'} w-full`}>
                {usertype !== 'company' &&
                    <HeaderNavigation page='Estimate' serviceName='All Estimates' />}
                <Container fluid={usertype === 'company'} className={`p-4 ${modal && 'blur-sm'} ${usertype === 'company' ? '' : 'mt-5'}`}>
                    <div className="flex w-full flex-col gap-4">
                        {usertype === 'company' &&
                            <div className="flex gap-3 items-center justify-between w-full">
                                <h4 className="text_black plusJakara_semibold">All Estimates</h4>
                            </div>}
                        <div className="flex w-full flex-col">
                            {loading ? (
                                <div className="" style={{ display: "flex", justifyContent: "center" }}>
                                    <TableSkeleton />
                                </div>) :
                                (!estimates || estimates.length === 0) ? (
                                    <div className='flex justify-center items-center w-full my-5'>
                                        <span className="plusJakara_medium md:text-lg">No Estimate Found</span>
                                    </div>
                                ) : <DTables columns={columns} data={estimates} />}
                        </div>
                    </div>
                </Container>

                <Modal
                    centered
                    open={showNotifyModal}
                    footer={null}
                    maskClosable={false}
                    // width={800}
                    zIndex={9999}
                    onCancel={() => setshowNotifyModal(false)}
                >
                    <div>
                        <h4 className="text_primary text-center mb-4 plusJakara_bold">Send Notification</h4>
                        <Form
                            style={{ maxWidth: '600px', width: '100%' }}
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                            className=" mt-4 auth_form">
                            <Row>
                                <div className="my-3 px-0">
                                    {/* <Label className="form-label text_secondary2 max-md:text-sm 
max-md:text-sm plusJakara_regular" htmlFor="notification">
                                    Notification
                                </Label> */}
                                    <Input
                                        type="text"
                                        className="form-control rounded-3"
                                        name="notification"
                                        id="notification"
                                        style={{ padding: '12px 16px', }}
                                        placeholder="Enter your notification"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.notification || ""}
                                        invalid={
                                            validation.touched.notification && validation.errors.notification ? true : false
                                        }
                                    />
                                    {validation.touched.notification && validation.errors.notification ? (
                                        <FormFeedback type="invalid">{validation.errors.notification}</FormFeedback>
                                    ) : null}
                                </div>
                                <Col lg={12} className="mb-0 px-0">
                                    <div className="d-grid">
                                        <button className="py-[12px] rounded-3 bg_primary plusJakara_medium text_white" disabled={isLoading} type="submit">
                                            {isLoading ? <Spinner size="sm" className='text_white' /> :
                                                "Send"}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal>
                <CreateContract setmodalOpen={setmodalOpen} modalOpen={modalOpen} />
                <UpdrageModal setmodalOpen={setmodal} modalOpen={modal} />
            </ main >
        </>
    );
};
export default EstimateTable;
