/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { message, Modal } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import randomColor from "randomcolor";
import React, { useEffect, useRef, useState } from "react";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Container } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-feather";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosInstance from "../redux/axiosInstance";
import HeaderNavigation from "./common/headerNavigation";
import ClientCard from "./estimate/clientCard";
import { encryptData } from "../redux/localStorageSecure";

const Schedule = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const { searchParams } = useSearchParams()
    const [modalOpen, setModalOpen] = useState(false)
    const [status, setstatus] = useState('')
    const [modalOpen2, setModalOpen2] = useState(false)
    const [moreData, setMoreData] = useState(null)
    const [scheduleData, setscheduleData] = useState(null)
    const [selected, setSelected] = useState(new Date());
    const [data1, setData1] = useState([]);
    const [activeView, setActiveView] = useState(Views.WEEK);

    const handleMoreData = (row) => {
        console.log(row);
        setMoreData(row)
        if (row?.type === 'schedule') {
            setModalOpen2(true)
            setModalOpen(false)
        } else {
            setModalOpen(true)
        }
    }

    const handlePrev = () => {
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate);
            activeView === Views.WEEK
                ? newDate.setDate(prevDate.getDate() - 7)
                : newDate.setMonth(prevDate.getMonth() - 1);
            return newDate;
        });
    };
    const handleNext = () => {
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate);
            activeView === Views.WEEK
                ? newDate.setDate(prevDate.getDate() + 7)
                : newDate.setMonth(prevDate.getMonth() + 1);
            return newDate;
        });
    };

    const localizer = momentLocalizer(moment);

    const colorMapping = useRef({}); // Persistent color mapping

    const fetchData = async () => {
        const month = currentDate.toLocaleString("default", {
            month: "long",
        });

        setLoading(true);

        try {
            const [scheduleResponse, ordResponse] = await Promise.all([
                axiosInstance.put(`schedule/all/`, { startDate: month.toLowerCase() }),
                axiosInstance.put(`order/byDate/customer/all/pending/`, { bookingDate: month.toLowerCase() }),
            ]);

            const schedules = scheduleResponse?.data?.schedules?.map((item) => {
                const id = item?._id;
                if (!colorMapping.current[id]) {
                    colorMapping.current[id] = randomColor({ hue: "#F6921E", luminosity: "light" });
                }
                return {
                    title: item?.title,
                    start: dayjs(item?.startDate).toDate(),
                    end: dayjs(item?.startDate).toDate(),
                    type: "schedule",
                    item: item,
                    bg: colorMapping.current[id],
                };
            });

            const ordersFromResponse = ordResponse?.data?.orders?.map((item) => {
                const id = item?.order_id;
                if (!colorMapping.current[id]) {
                    colorMapping.current[id] = randomColor({ hue: "#003F7D", luminosity: "light" });
                }

                if (item?.estimate) {
                    return item?.estimate?.labour?.map((laborItem) => ({
                        title: laborItem?.labourName,
                        start: dayjs(laborItem?.startDate).toDate(),
                        end: dayjs(laborItem?.endDate).toDate(),
                        type: item?.type,
                        item: item,
                        orderId: item?.order_id,
                        bg: colorMapping.current[id],
                    }));
                } else {
                    return {
                        title: item?.service?.name,
                        start: dayjs(item?.bookingDate).toDate(),
                        end: dayjs(item?.bookingDate).toDate(),
                        type: item?.type,
                        item: item,
                        orderId: item?.order_id,
                        bg: colorMapping.current[id],
                    };
                }
            });

            const flattenedOrders = ordersFromResponse.flat();
            setData([...schedules, ...flattenedOrders]);
            setData1([...schedules, ...flattenedOrders]);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data", error);
        }
    };

    const getDataByDate = (e) => {
        const selectedDate = new Date(e).toISOString().split("T")[0];
        const filterData = data1.filter((item) => {
            const eventDate = new Date(item?.start).toISOString().split("T")[0];
            return eventDate === selectedDate;
        });
        setData(filterData);
        setSelected(e);
    };

    const handleNavigate = (item) => {
        const params = new URLSearchParams(searchParams)
        const data = {
            user: item?.otherUser,
        }
        params.set('detail-user', encryptData(data))
        navigate(`/chat?${params.toString()}`)
    }

    useEffect(() => {
        fetchData();
    }, [selected, currentDate, activeView]);

    const handleSelectSlot = (slotInfo) => {
        setSelected(new Date(slotInfo.start));
        setCurrentDate(new Date(slotInfo.start));
    };

    const handleNavigateEvent = (e) => {
        if (e.type === 'schedule') {
            setscheduleData(e)
            setModalOpen2(true)
            setModalOpen(false)
        } else {
            navigate(`/orders/${e.item?._id}`, { state: { orderData: e.item } })
        }
    }

    const handleStatus = async (status) => {
        setstatus(status)
        try {
            setLoading(true);
            const response = await axiosInstance.put(`schedule/change/${status}/${scheduleData?.item?._id}`)
            if (response.data?.message) {
                if (response.data?.schedule?.status === "reject") {
                    message.success('Schedule Rejected successfully')
                } else {
                    navigate('/create-estimate', { state: { estimateUser: scheduleData?.item } })
                    message.success('Schedule Confirm successfully')
                }
            }
            setLoading(false);
            setModalOpen2(false);
        } catch (error) {
            console.log(error.response.data, "err in status change");
            setLoading(false);
            setModalOpen2(false);
        }
    };
    const CustomEvent = ({ event }) => {
        return (
            <div
                className="rounded-1 px-2 py-1"
                style={{ backgroundColor: event.bg }}
            >
                <span className="plusJakara_regular text-[10px]">
                    {event.type === "contract" ? "# " + event?.orderId : ""}
                </span>
                <h6 className="plusJakara_regular text-[10px]">{event.title}</h6>
            </div>
        );
    };

    return (
        <main className='pb-5'>
            <HeaderNavigation page='Schedule' serviceName='My Schedules' />
            <Container>
                <div className="flex flex-col relative gap-2">
                    <div className="flex gap-2 mt-3 items-center justify-between w-full">
                        <h5 className="text_dark mb-0 plusJakara_semibold">My Schedules</h5>
                    </div>
                    <div className="flex w-full justify-end mb-3 items-center gap-3">
                        <button
                            onClick={() => setActiveView(Views.WEEK)}
                            className={`py-[12px] px-4 ${activeView === Views.WEEK ? 'bg_primary text_white' : 'bg_light text_black'} text-sm plusJakara_medium border rounded-lg`}
                        >
                            Weekly
                        </button>
                        <button
                            onClick={() => setActiveView(Views.MONTH)}
                            className={`py-[12px] px-4 ${activeView === Views.MONTH ? 'bg_primary text_white' : 'bg_light text_black'} text-sm plusJakara_medium border rounded-lg`}
                        >
                            Monthly
                        </button>
                    </div>
                    <div className="flex justify-between w-full items-center">
                        <button
                            onClick={handlePrev}
                            className="p-1 bg_primary text_white flex justify-center items-center rounded-full"
                            style={{ width: '30px', height: '30px' }}
                        >
                            <ChevronLeft size={20} />
                        </button>

                        <button
                            onClick={handleNext}
                            className="p-1 bg_primary text_white flex justify-center items-center rounded-full"
                            style={{ width: '30px', height: '30px' }}
                        >
                            <ChevronRight size={20} />
                        </button>
                    </div>
                    <Calendar
                        localizer={localizer}
                        events={data}
                        onShowMore={(e) => handleMoreData(e)}
                        onSelectEvent={(e) => handleNavigateEvent(e)}
                        date={currentDate}
                        view={activeView}
                        onNavigate={(date) => {
                            setCurrentDate(date);
                            setSelected(date);
                        }}
                        onSelectSlot={handleSelectSlot}
                        selectable
                        startAccessor="start"
                        endAccessor="end"
                        components={{
                            event: CustomEvent,
                        }}
                        style={{ height: 500 }}
                    />
                </div>

                <Modal width={500} open={modalOpen} centered onCancel={() => setModalOpen(false)} footer={false}>
                    <div className="">
                        <h5 className="text_dark mb-4 text-center plusJakara_semibold">My Schedules</h5>
                        {moreData?.map((item, i) => (
                            <>
                                <div key={i} className="flex border rounded-1 p-2 flex-col w-full gap-2 mb-2">
                                    <div className="flex items-center justify-between gap-2">
                                        <div className="flex flex-col w-full">
                                            {item?.type !== 'schedule' &&
                                                <span className="plusJakara_semibold text-xs">#{item?.orderId}</span>}
                                            <span className="plusJakara_semibold text_primary text-sm">{item?.title}</span>
                                            <span className="plusJakara_medium text_secondary2 text-xs">{moment(item?.start).format('ddd DD MMM YYYY')}</span>
                                        </div>
                                        {item?.type === 'schedule' ?
                                            <button style={{ minWidth: '100px' }}
                                                onClick={() => {
                                                    setscheduleData(item)
                                                    setModalOpen2(true)
                                                    setModalOpen(false)
                                                }}
                                                className="bg_primary text_white rounded-2 px-3 py-2 text-xs plusJakara_medium">Schedule</button>
                                            : <button style={{ minWidth: '100px' }} onClick={() => navigate(`/orders/${item?.item?._id}`, { state: { orderData: item?.item } })} className="bg_primary text_white rounded-2 px-3 py-2 text-xs plusJakara_medium">Order</button>}
                                    </div>
                                </div>

                            </>
                        ))}
                    </div>
                </Modal>
                <Modal width={500} open={modalOpen2} centered onCancel={() => setModalOpen2(false)} footer={false}>
                    <h5 className="text_dark mb-4 text-center plusJakara_semibold">Schedule Detail</h5>
                    <div style={{ marginBottom: '20px' }}>
                        <ClientCard item={scheduleData?.item?.otherUser} />
                    </div>
                    <div className="flex justify-end w-full">
                        <button onClick={() => handleNavigate(scheduleData?.item)} className="bg_primary text_white plusJakara_regular text-xs py-2 px-3 rounded-2">Message</button>
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                        <div className="flex flex-col w-full">
                            <h6 className="plusJakara_semibold text_primaru">Project Location</h6>
                            <div className="flex gap-2 items-start">
                                <FaMapMarkerAlt size={20} color="#003F7D" />
                                <h6 className="plusJakara_medium text-sm text_secondary2">{scheduleData?.item?.otherUser?.address}</h6>
                            </div>
                        </div>
                        <div className="flex flex-col w-full">
                            <h6 className="plusJakara_semibold text_primaru">Project Start Date</h6>
                            <h6 className="plusJakara_medium text-sm text_secondary2">{moment(scheduleData?.item?.startDate).format('ddd DD MMM YYYY')}</h6>
                        </div>
                    </div>
                </Modal>
            </Container>
        </ main>
    );
};

export default Schedule;