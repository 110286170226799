/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Skeleton } from 'antd';
import '../../style/swiper.css';
import { getCoupon } from '../../api/coupon';
import { setCoupons } from '../../redux/services';
import CouponCard from './couponCard';

const HomeCoupons = () => {
    const dispatch = useDispatch();
    const coupons = useSelector((state) => state.services?.coupons);
    const [loading, setLoading] = useState(true);

    const handleFetchCoupons = async () => {
        if (coupons?.length > 0) {
            setLoading(false); // Stop loading if coupons are already available
            return;
        }

        setLoading(true);
        try {
            const res = await getCoupon(1);
            const fetchedCoupons = res.success ? res.coupons : [];
            dispatch(setCoupons(fetchedCoupons));
        } catch (error) {
            console.error('Failed to fetch coupons:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!coupons?.length) {
            handleFetchCoupons();
        } else {
            setLoading(false);
        }
    }, [coupons]);


    return (
        <Container className="pb-5">
            {loading ? (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-5">
                    {Array.from({ length: 3 })?.map((_, i) => (
                        <Skeleton key={i} active avatar paragraph={{ rows: 3 }} />
                    ))}
                </div>
            ) : coupons?.length === 0 ? (
                <div className="flex justify-center items-center w-full my-5">
                    <span className="plusJakara_medium md:text-lg">No Coupons Found</span>
                </div>
            ) : (
                <div className="items_swiper w-full relative">
                    <div className="flex justify-between absolute  top-0 w-full flex-wrap gap-3 items-start">
                        <h6 className="text-xl md:text-3xl min-[1900px]:text-4xl mb-0 plusJakara_semibold text_black">Special Offers</h6>
                    </div>
                    <Swiper
                        freeMode
                        modules={[FreeMode, Navigation, Pagination]}
                        navigation
                        breakpoints={{
                            320: { slidesPerView: 1 },
                            768: { slidesPerView: 2 },
                            1024: { slidesPerView: 3 },
                        }}
                        className="mySwiper d-flex w-100 gap-3 relative"
                    >
                        {coupons?.map((category, i) => (
                            <SwiperSlide key={i} style={{ height: 'auto' }} className="relative pe-4 gap-2">
                                <CouponCard category={category} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
        </Container>
    );
};

export default HomeCoupons;
