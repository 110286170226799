/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { BsFillInfoCircleFill, BsReceiptCutoff } from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import {
  MdOutlineMiscellaneousServices,
  MdOutlineSecurity
} from "react-icons/md"; // Importing icons
import { PiUsersThreeBold } from "react-icons/pi";
import { RiCalendarScheduleFill, RiOrderPlayFill } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { TbFileInvoice, TbHistory } from "react-icons/tb";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../redux/auth/useAuth";
import { Tooltip } from "antd";

const SidebarMenu = ({ children, setToggled, toggled, setBroken }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [show, setShow] = useState(false);
  const usertype = useSelector((state) => state.auth?.userData?.type)
  const userData = useSelector((state) => state.auth?.userData)
  const navigate = useNavigate();
  const location = useLocation();
  const pagesCount = useSelector((state) => state.services?.pagesCount);
  const isLogin = useAuth();

  const handleLinkClick = (itemId, path) => {
    setToggled(false);
    setShow(false);
    navigate(path);
  };

  const isChildPath = (parentPath, childPath) => {
    return childPath.startsWith(parentPath);
  };

  let menuItems;
  if (usertype === "company") {
    menuItems = [
      {
        items: "Dashboard",
        path: "/dashboard",
        icon: <RxDashboard size={22} />,
        badge: false,
      },
      {
        items: "My Schedules",
        path: "/schedule-admin",
        icon: <RiCalendarScheduleFill size={22} />,
        badge: pagesCount?.schedule > 0 ? pagesCount?.schedule : null
      },
      // {
      //   items: "Estimates",

      //   path: "/estimates",
      //   icon: <BsReceiptCutoff size={22} />,
      //    badge: pagesCount?.order > 0 ? pagesCount?.order : null
      // },
      {
        items: "Estimates",
        suffix: userData?.sub_type === 'free' && <BsFillInfoCircleFill className="text-red-400" />,
        icon: <BsReceiptCutoff size={22} />,
        subItems: [
          {
            label: "Create Estimate",
            path: "/create-estimate",
          },
          {
            label: "Estimate List",
            path: "/estimates",
          },
        ],
        badge: pagesCount?.estimate > 0 ? pagesCount?.estimate : null
      },
      {
        items: "Contracts",
        suffix: (userData?.sub_type === 'free' || userData?.sub_type === 'basic') && <BsFillInfoCircleFill className="text-red-400" />,
        path: "/contracts",
        icon: <FaTasks size={22} />,
        badge: pagesCount?.contract > 0 ? pagesCount?.contract : null
      },
      {
        items: "Fixed Services",
        icon: <MdOutlineMiscellaneousServices size={22} />,
        subItems: [
          {
            label: "Create Service",
            path: "/add-service",
          },
          {
            label: "Service List",
            path: "/service-list",
          },
        ],
        badge: false,
      },
      {
        items: "Employee",
        suffix: (userData?.sub_type !== 'premium') && <BsFillInfoCircleFill className="text-red-400" />,
        icon: <PiUsersThreeBold size={22} />,
        subItems: [
          {
            label: "Add Employee",
            path: "/add-employee",
          },
          {
            label: "Employee List",
            path: "/employee-list",
          },
        ],
        badge: false,
      },
      {
        items: "Orders",
        path: "/orders",
        icon: <RiOrderPlayFill size={22} />,
        badge: pagesCount?.order > 0 ? pagesCount?.order : null
      },
      {
        items: "Invoices",
        suffix: (userData?.sub_type === 'free') && <BsFillInfoCircleFill className="text-red-400" />,
        icon: <TbFileInvoice size={22} />,
        subItems: [
          {
            label: "Paid Invoices",
            suffix: (userData?.sub_type === 'free' || userData?.sub_type === 'basic') && <BsFillInfoCircleFill className="text-red-400" />,
            path: "/paid-invoices",
          },
          {
            label: "Un Paid Invoices",
            suffix: (userData?.sub_type === 'free') && <BsFillInfoCircleFill className="text-red-400" />,
            path: "/invoices",
          },
        ],
        badge: pagesCount?.invoice > 0 ? pagesCount?.invoice : null
      },
      {
        items: "Subscription History",
        path: "/subscription-history",
        icon: <TbHistory size={22} />,
        badge: false,
      },
      {
        items: "Change Password",
        path: "/change-password",
        icon: <MdOutlineSecurity size={22} />,
        badge: false,
      },
    ];
  } else if (usertype === "employee") {
    menuItems = [
      {
        items: "Dashboard",
        path: "/dashboard",
        icon: <RxDashboard size={22} />,
        badge: false,
      },
      {
        items: "Tasks",
        path: "/all-tasks",
        icon: <FaTasks size={22} />,
        badge: pagesCount?.order > 0 ? pagesCount?.order : null
      },
      {
        items: "Change Password",
        path: "/change-password",
        icon: <MdOutlineSecurity size={22} />,
        badge: false,
      },
    ]
  }

  return (
    <>
      {isLogin ? (
        <div className="flex h-screen min-h-screen">
          <div className="h-screen relative" style={{ zIndex: 9999 }}>
            <Sidebar
              width="260px"
              style={{ height: "100%", zIndex: 9999, backgroundColor: '#F5F7FA' }}
              collapsed={collapsed}
              toggled={toggled}
              onBackdropClick={() => {
                setToggled(false);
                setShow(false);
              }}
              onBreakPoint={setBroken}
              breakPoint="xl"
            >
              <div
                className="scrolbar"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                  height: "100%",
                  paddingTop: "1rem",
                }}
              >
                <div className="flex items-center mb-3 justify-center">
                  <button
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                    className=""
                  >
                    <h5 className="text_primary2 plusJakara_semibold">
                      Request Service
                    </h5>
                  </button>
                </div>
                <Menu className="container mx-auto flex flex-col justify-between h-full">
                  <div>
                    {menuItems?.map((item, i) => (
                      <Fragment key={i}>
                        {item.subItems ? (
                          <SubMenu
                            icon={item.icon}
                            suffix={
                              item.suffix ? (
                                <Tooltip
                                  zIndex={99999}
                                  color="#f45f45"
                                  className="plusJakara_medium"
                                  title="This page is not included in your plan"
                                >
                                  <span>{item.suffix}</span>
                                </Tooltip>
                              ) : null
                            }
                            label={item.items}
                            className={`w-full plusJakara_semibold text_secondary`}
                          >
                            {item.subItems.map((subItem, j) => (
                              <MenuItem
                                suffix={
                                  item.suffix ? (
                                    <Tooltip
                                      zIndex={99999}
                                      color="#f45f45"
                                      className="plusJakara_medium"
                                      title="This page is not included in your plan"
                                    >
                                      <span>{item.suffix}</span>
                                    </Tooltip>
                                  ) : null
                                }
                                key={`${i}-${j}`}
                                onClick={() =>
                                  handleLinkClick(`${i}-${j}`, subItem.path)
                                }
                                component={<Link to={subItem.path} />}
                                className={`w-full py-1 text-sm ${isChildPath(subItem.path, location.pathname)
                                  ? "bg_lightprimary text_white plusJakara_medium"
                                  : "text_secondary"
                                  }`}
                              >
                                <div className="flex items-center gap-4">
                                  <div className="plusJakara_medium">
                                    {subItem.label}
                                  </div>
                                </div>
                              </MenuItem>
                            ))}
                          </SubMenu>
                        ) : (
                          <MenuItem
                            key={i}
                            suffix={
                              item.suffix ? (
                                <Tooltip
                                  zIndex={99999}
                                  color="#f45f45"
                                  className="plusJakara_medium"
                                  title="This page is not included in your plan"
                                >
                                  <span>{item.suffix}</span>
                                </Tooltip>
                              ) : null
                            }
                            onClick={() =>
                              handleLinkClick(i.toString(), item.path)
                            }
                            component={<Link to={item.path} />}
                            className={`w-full mb-2 py-1 ${isChildPath(item.path, location.pathname)
                              ? "bg_lightprimary text_white plusJakara_medium"
                              : "text_secondary"
                              }`}
                          >
                            <div className="flex relative items-center gap-4">
                              {item.icon}
                              <div className="plusJakara_medium">
                                {item.items}
                              </div>
                              {item.badge && (
                                <span
                                  className="ml-auto bg-red-600 text-white rounded-full plusJakara_medium"
                                  style={{ fontSize: "0.75rem", zIndex: 999999, padding: '2px 8px' }}
                                >
                                  {item.badge}
                                </span>
                              )}
                            </div>
                          </MenuItem>
                        )}
                      </Fragment>
                    ))}
                  </div>
                </Menu>
              </div>
            </Sidebar>
          </div>
          <main style={{ backgroundColor: "#f5f5f5" }} className="w-full overflow-auto">{children}</main>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default SidebarMenu;
