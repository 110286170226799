import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from './useAuth'
import { useSelector } from 'react-redux'

function PublicRoutes() {
    const auth = useAuth()
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const isMobileLogin = useSelector((state) => state.auth?.isMobileLogin)
    return auth ? <Navigate to={`${isMobileLogin ? '/business-profile?selected=subscription' : (usertype === 'company' ? '/dashboard' : '/')}`} /> : <Outlet />
}
export default PublicRoutes