import axiosInstance from "../redux/axiosInstance";

export const createEmployee = async ({ data }) => {
    try {
        const res = await axiosInstance.post(`users/create/employee`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const getMyEmployee = async () => {
    try {
        const res = await axiosInstance.get(`users/me/employee`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const getEmployeeTasks = async (employeeId) => {
    try {
        const res = await axiosInstance.get(`order/assign-users-orders/${employeeId}`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const getEmployeeTaskSummary = async (orderId, employeeId) => {
    try {
        const res = await axiosInstance.get(`order/employee/checking-time/${orderId}/${employeeId}`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const employeeDashboard = async () => {
    try {
        const res = await axiosInstance.get(`users/employee/dashboard`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};