/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */

import { message } from "antd";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../redux/axiosInstance";
import { setUserData } from "../../redux/loginForm";

const LinkStripeAccount = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.auth?.userData);
    const [loading, setLoading] = useState(false);
    const [accountLink, setAccountLink] = useState("");

    const getProfile = async () => {
        try {
            const res = await axiosInstance.get("users/me");
            dispatch(setUserData(res?.data?.user));
        } catch (error) {
            message.error(error?.response?.data?.message);
            console.error("Error updating user:", error.response.data);
        }
    };

    const checkAccount = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get("payment/createAccount/website");
            setAccountLink(response.data?.accountLink?.url);
            getProfile();
            setLoading(false);
        } catch (error) {
            console.error("Error creating account:", error);
            setLoading(false);
        }
    };

    const openPaymentPage = (paymentPageLink) => {
        const childWindow = window.open(paymentPageLink, 'PaymentPage', 'width=600,height=800');
        if (childWindow) {
            const checkTabClosed = setInterval(() => {
                if (childWindow.closed) {
                    clearInterval(checkTabClosed);
                    getProfile()
                }
            }, 500);
        }
    };

    useEffect(() => {
        if (!userData?.accountId_verified) {
            checkAccount();
        }
    }, []);

    return (
        <div style={{ maxWidth: "700px", width: "100%" }} className="w-full overflow-hidden">
            {loading ? (
                <div className="flex justify-center items-center gap-3 flex-col w-full">
                    <Spinner size='sm' />
                </div>
            ) : !userData?.accountId_verified ? (
                <div className="flex justify-center items-center mt-5 gap-3 flex-col w-full">
                    <h6 className="plusJakara_medium text-center text_secondary2">
                        You don't have a Stripe account. To receive payments, you must create a Stripe account.
                    </h6>
                    <button
                        style={{ minWidth: '200px' }}
                        className="plusJakara_medium text_white bg_primary rounded-5 px-5 py-[12px]"
                        onClick={() => {
                            openPaymentPage(accountLink)
                        }}
                    >
                        Connect Stripe Account
                    </button>
                </div>
            ) : (
                <div className="flex justify-center items-center mt-5 gap-3 flex-col w-full">
                    <h5 className="plusJakara_medium text-center text_secondary2">
                        Your Stripe account has already been connected.
                    </h5>
                    <button
                        style={{ minWidth: '200px' }}
                        className="plusJakara_medium text_white bg_primary rounded-5 px-5 py-[12px]"
                        onClick={() => navigate(-1)}
                    >
                        Done
                    </button>
                </div>
            )}
        </div>
    );
};

export default LinkStripeAccount;