/* eslint-disable no-unused-vars */
// React Basic and Bootstrap
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";

import { message } from "antd";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { Check } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Label
} from "reactstrap";
import { updateUser } from "../../api/signup";
import { setUserData } from "../../redux/loginForm";

const initialState = [
  { day: 'Monday', status: true, start_time: '', end_time: '' },
  { day: 'Tuesday', status: false, start_time: '', end_time: '' },
  { day: 'Wednesday', status: false, start_time: '', end_time: '' },
  { day: 'Thursday', status: false, start_time: '', end_time: '' },
  { day: 'Friday', status: false, start_time: '', end_time: '' },
  { day: 'Saturday', status: false, start_time: '', end_time: '' },
  { day: 'Sunday', status: false, start_time: '', end_time: '' },
];

const AvailabilityInfoForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setisLoading] = useState(false)
  const userData = useSelector((state) => state.auth?.userData)
  const isLogin = useSelector((state) => state.auth?.isLogin)
  const [selectedDays, setSelectedDays] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState(initialState);

  const handleSwitchChange = (index) => {
    const updatedDaysOfWeek = daysOfWeek.map(day => ({ ...day }));
    updatedDaysOfWeek[index].status = !updatedDaysOfWeek[index].status;
    setDaysOfWeek(updatedDaysOfWeek);
  };

  const handleStartTime = (value, index) => {
    const parsedTime = moment(value, 'h:mm A').toISOString();
    const updatedTime = [...daysOfWeek];
    updatedTime[index].start_time = parsedTime;
    setDaysOfWeek(updatedTime);
  };

  const handleEndTime = (value, index) => {
    const parsedTime = moment(value, 'HH:mm').toISOString();
    const updatedTime = [...daysOfWeek];
    updatedTime[index].end_time = parsedTime;

    if (updatedTime[index].start_time && updatedTime[index].end_time) {
      const startTime = moment(updatedTime[index].start_time, moment.ISO_8601);
      const endTime = moment(updatedTime[index].end_time, moment.ISO_8601);
      if (startTime.isSameOrAfter(endTime)) {
        updatedTime[index].end_time = '';
        message.error('Invalid time. End time must be greater than start time');
        setDaysOfWeek(updatedTime);
        return;
      }
    }
    setDaysOfWeek(updatedTime);
  };
  useEffect(() => {
    if (userData?.availablity.length > 0) {
      setDaysOfWeek(userData?.availablity);
    }
    else {
      setDaysOfWeek(initialState)
    }
  }, [userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = daysOfWeek.map(day => ({
      day: day.day.slice(0, 3),
      status: day.status,
      start_time: day.start_time || new Date(),
      end_time: day.end_time || new Date()
    }));
    const data = {
      availablity: dataToSend
    };
    setisLoading(true);
    await updateUser({ data: data })
      .then((res) => {
        if (res.data.success === true) {
          setisLoading(false);
          dispatch(setUserData(res.data.user));
          if (isLogin) {
            message.success('Profile updated successfully');
            navigate('/business-profile?selected=overview', { replace: true })
          } else {
            navigate('/portfolio-info', { replace: true });
          }
        }
      })
      .catch((err) => {
        message.error('Failed to update profile');
        setisLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit} style={{ maxWidth: '800px', width: '100%' }}>
        <Label className="form-label text-lg mb-4 text_secondary2 plusJakara_medium">
          Select your Availability
        </Label>
        <div className="flex gap-3 mb-4 w-full max-md:flex-col justify-start">
          <div className="flex items-center flex-col gap-4 w-full">
            {daysOfWeek.map((day, index) => (
              <div
                key={index}
                className="flex justify-between max-md:flex-col gap-4 md:items-center w-full"
              >
                <div className="flex justify-between items-center w-full">
                  <button
                    type='button'
                    style={{ minWidth: '9rem', padding: '12px' }}
                    className={`border cursor-pointer rounded-full gap-2 plusJakara_medium text-sm flex items-center ${day.status ? 'bg-[#F5F7FA] text_primary' : 'bg_white text_secondary'}`}
                    onClick={() => handleSwitchChange(index)}
                  >
                    {day.status && (
                      <Check className='text_primary' size={16} />
                    )}
                    {day.day}
                  </button>
                </div>
                {day.status && (
                  <div className="w-full flex-wrap justify-end flex-md-nowrap gap-2 flex items-center">
                    <div>
                      <Input
                        required
                        type="time"
                        className="text-black"
                        onChange={(e) => handleStartTime(e.target.value, index)}
                        value={moment(day.start_time, moment.ISO_8601).format('HH:mm')}
                        style={{ borderRadius: "12px", color: "#000", padding: '10px' }}
                        id="start_time"
                      />
                    </div>
                    <span className="plusJakara_semibold text_primary">to</span>
                    <div>
                    <Input
                        required
                        type="time"
                        className="text-black"
                        style={{ borderRadius: "12px", color: "#000", padding: '10px' }}
                        value={moment(day.end_time, moment.ISO_8601).format('HH:mm')}
                        onChange={(e) => handleEndTime(e.target.value, index)}
                        id="end_time"
                      />
                    </div>
                  </div>
                )}
                {!day.status && (
                  <div className="w-full flex-wrap justify-end flex-md-nowrap gap-2 flex items-center">
                    <div>
                      <Input
                        style={{ borderRadius: "12px", padding: '10px' }}
                        disabled
                        type="time"
                        id="time"
                      />
                    </div>
                    <span className="plusJakara_semibold text_primary">to</span>
                    <div>
                      <Input
                        style={{ borderRadius: "12px", padding: '10px' }}
                        disabled
                        type="time"
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="d-grid mt-4">
          <button className="py-3 rounded-3 bg_primary plusJakara_medium text_white" disabled={isLoading} type="submit">
            {isLoading ? <Spinner size="sm" /> :
              "Submit"}
          </button>
        </div>
      </Form>
    </React.Fragment >
  );
};
export default AvailabilityInfoForm;
