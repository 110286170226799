
export const MapClick = (lat, lng) => {
    const latitude = parseFloat(lat);
    const longitude = parseFloat(lng);

    if (!isNaN(latitude) && !isNaN(longitude)) {
        window.open(`https://www.google.com/maps?q=${latitude},${longitude}`, '_blank');
    } else {
        console.error('Invalid latitude or longitude');
    }
};