/* eslint-disable no-unused-vars */
import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { cleaning13, cleaning5, coveravatar } from '../../icons/icon';

const HeaderNavigationImage = ({ page, serviceName, subPage, subPageAvail = false, mainPage, data }) => {
    return (
        <div
            className='container flex justify-center flex-col'
            style={{
                marginTop: '5rem',
                backgroundImage: `url(${data?.image})`,
                backgroundSize: 'cover',
                backgroundRepeat: "no-repeat",
                height: '25rem',
                backgroundPosition: 'top center',
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            <div className="bg-overlay"></div>
            {/* <div className="container pt-3 ">
                <Link to='/' className="text_secondary text-sm no-underline plusJakara_medium">Home &nbsp;/&nbsp;</Link>
                {subPageAvail === true ?
                    <Link to={mainPage} className="text_secondary max-md:text-lg no-underline text-xl mb-0 plusJakara_medium ms-1"> {page} </Link>
                    : <h5 className="text_primary text-sm mb-0 plusJakara_medium ms-1">{page} </h5>}
                {subPageAvail &&
                    <h5 className="text_primary text-sm mb-0 plusJakara_medium ms-1">/{subPage} </h5>
                }
            </div> */}
            <Container className="py-4 relative flex gap-5">
                <div className="z-50 max-w-4xl left-0 flex flex-col md:pe-5 gap-3">
                    <h6 className="text-3xl md:text-4xl mb-0 plusJakara_bold text_white">
                        {data?.name}
                    </h6>
                    <h6 className="text_white text-lg plusJakara_regular">
                        {data?.description}
                    </h6>
                </div>
                {/* <div className="w-full md:w-[80%] hidden relative md:flex flex-col gap-1">
                    <div className="bg-overlay rounded-2"></div>
                    <img src={data?.image} className='rounded-2 overflow-hidden relative' style={{ height: '24rem', width: '100%', objectFit: 'cover' }} alt='' />
                </div> */}
            </Container>
        </div>
    );
};

export default HeaderNavigationImage;
