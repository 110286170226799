/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { getBlogs } from '../api/blogs';
import '../style/swiper.css';
import BlogItem from './blogItem';
import HeaderNavigation from './common/headerNavigation';
import BlogCategories from './homeComponents2/blogCategories';
import { Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setBlogBanner, setBlogData } from '../redux/services';
import HeaderNavigationImage from './common/headerNavigationImage';
import { allHomeBanners } from '../api/coupon';
import { debounce } from 'lodash';

const BlogSection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const blogs = useSelector((state) => state.services?.blogData);
    const [loading, setloading] = useState(false);
    const blogBanner = useSelector((state) => state.services?.blogBanner);
    const { state } = useLocation();
    const categoryData = state?.categoryData || null;
    const [selectedCategory, setSelectedCategory] = useState('all');

    const handleSelectCategory = async (category) => {
        if (blogs?.length > 0 && selectedCategory?._id === category._id) return;
        setSelectedCategory(category);
        setloading(true);
        try {
            const res = await getBlogs(category?._id, 1);
            const fetchedBlogs = res?.success ? res?.blogs : [];
            dispatch(setBlogData(fetchedBlogs));
        } catch (error) {
            console.error('Failed to fetch blogs:', error);
        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        if (categoryData) {
            setSelectedCategory(categoryData);
            handleSelectCategory(categoryData);
        } else {
            handleSelectCategory({ _id: 'all', name: 'All' });
        }
    }, [categoryData]);


    const fetchData = useCallback(
        debounce(async () => {
            try {
                const res = await allHomeBanners('blog', '');
                if (res?.data) {
                    dispatch(setBlogBanner(res?.data?.banners));
                }
            } catch (error) {
                console.error("Error fetching banners", error);
            }
        }, 500),
        []
    );

    useEffect(() => {
        if (!blogBanner?.length) {
            fetchData();
        } else {
            dispatch(setBlogBanner(blogBanner));
        }
        return () => {
            fetchData.cancel();
        };
    }, [blogBanner]);

    return (
        <main className="pb-5">
            <HeaderNavigationImage data={blogBanner[0]} page="Blogs" serviceName={`${selectedCategory?.name || 'All'} Blogs`} />
            <BlogCategories
                onSelectCategory={handleSelectCategory}
                selectedCategory={selectedCategory}
            />
            <Container>
                {loading ? (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-5">
                        {Array.from({ length: 4 }).map((_, i) => (
                            <div className="bg_white flex flex-col w-full rounded-2 overflow-hidden border relative animate-pulse">
                                <div className="relative h-48 w-full bg-[#f4f4f4]">
                                </div>
                                <div className="p-3 flex gap-2 flex-col w-full">
                                    <div className="h-6 w-3/4 bg-[#ccc] rounded"></div>
                                    <div className="flex gap-2 items-center mt-2">
                                        <div className="h-5 w-1/4 bg-[#ccc] rounded"></div>
                                        <div className="h-5 w-1/6 bg-[#ccc] rounded"></div>
                                    </div>
                                    <div className="h-4 w-full bg-[#ccc] rounded mt-2"></div>
                                    <div className="h-4 w-5/6 bg-[#ccc] rounded"></div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : !blogs || blogs?.length === 0 ? (
                    <div className="flex flex-col my-5 items-center">
                        <span className="plusJakara_medium text_secondary2 md:text-[16px]">
                            No Blog Found
                        </span>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                        {blogs?.map((item, i) => (
                            <BlogItem showView={false} key={i} category={item} />
                        ))}
                    </div>
                )}
            </Container>
        </main>
    );
};

export default BlogSection;
