/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Col, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import DTables from "../DataTable/DTable";
import '../style/swiper.css';
// import * as Yup from "yup"
import moment from 'moment';
import { FaArrowLeft, FaLocationDot, FaPhone } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import { useSelector } from 'react-redux';
import HeaderNavigation from './common/headerNavigation';
import LabourMaterialTableDesign from './estimate/labourMaterialTableDesign';
import { getSpeceficDetail } from '../api/order';
import { Spinner } from 'react-bootstrap';
import { MapClick } from '../common/mapClick';

const ContractDetailProvider = ({ showButtons = true }) => {
    const { state } = useLocation()
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const [contractData, setContractData] = useState(state?.contractData || null)
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!contractData && id) {
            getSpeceficDetail('contract', id)
                .then((res) => {
                    if (res?.contract) {
                        setContractData(res.contract);
                    }
                })
                .catch((err) => {
                    message.error(err.response.data.message);
                });
        }
    }, [id]);

    return (
        <main className={`${usertype === 'company' ? 'p-3 p-md-5' : 'pb-5'} w-full`}>
            {usertype !== 'company' &&
                <HeaderNavigation mainPage={'/contracts'} subPage={`Contract Detail`} subPageAvail={true} page={`Contracts`} serviceName='House Cleaning Service' />}
            {!contractData ? (
                <div className="flex justify-center w-full items-center my-5">
                    <Spinner size="sm" style={{ color: 'black' }} />
                </div>
            ) : (
                <Container fluid={usertype === 'company'} className='bg_white rounded-3 p-4 w-full'>
                    <div className="flex gap-4 items-center justify-between w-full mb-4">
                        <div className="flex flex-col gap-3 w-full">
                            {usertype === 'company' &&
                                <div className="flex gap-4 mb-3 items-center w-full">
                                    <button
                                        onClick={() => navigate(-1)}
                                        className="bg_primary rounded-3 p-2"
                                    >
                                        <FaArrowLeft className='text_white' />
                                    </button>
                                    <h4 className="text_primary mb-0 plusJakara_semibold">Contract Detail</h4>
                                </div>}
                            <div className="flex gap-3 items-start w-full">
                                <img src={usertype === 'company' ? contractData?.user?.profilePicture : contractData?.to_id?.profilePicture} style={{ width: '120px', height: '100px', objectFit: 'cover', borderRadius: '12px' }} alt="" />
                                <div className="flex flex-col w-full">
                                    <h5 className="text_black plusJakara_semibold">{usertype === 'company' ? contractData?.user?.name : contractData?.to_id?.name}</h5>
                                    <div className="flex gap-2 items-center">
                                        <FaLocationDot className='text_primary' />
                                        <span
                                            onClick={() =>
                                                MapClick(usertype === 'company' ? contractData?.user?.location?.coordinates[1] : contractData?.to_id?.location?.coordinates[1],
                                                    usertype === 'company' ? contractData?.user?.location?.coordinates[0] : contractData?.to_id?.location?.coordinates[0]
                                                )}
                                            className="text_secondary2  cursor-pointer hover:underline whitespace-nowrap plusJakara_medium">{usertype === 'company' ? contractData?.user?.address : contractData?.to_id?.address}</span>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <FaPhone className='text_primary' />
                                        <span className="text_secondary2 plusJakara_medium">{usertype === 'company' ? contractData?.user?.phone : contractData?.to_id?.phone}</span>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <MdEmail className='text_primary' />
                                        <span className="text_secondary2 plusJakara_medium">{usertype === 'company' ? contractData?.user?.email : contractData?.to_id?.email}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style={{ color: "#828282" }} />
                    <div className="flex mb-5 mt-4 flex-wrap gap-3 flex-col flex-md-nowrap justify-between w-full">
                        <div className="flex gap-3 items-center justify-between w-full">
                            <Col xs={24} md={11} xl={14}>
                                <div className="flex justify-between gap-3 p-md-5 items-center flex-wrap">
                                    <div className="flex flex-col gap-2">
                                        <h6 className="text_secondary2 plusJakara_semibold">Contract No</h6>
                                        <div className="px-2 py-1 w-fit text-center rounded-2" style={{ backgroundColor: '#f6911e1b' }}>
                                            <span className="plusJakara_medium text_primary2">#{contractData?.order_id}</span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <h6 className="text_secondary2 plusJakara_semibold">Contract Start Date</h6>
                                        <span className="text_black plusJakara_bold">{moment(contractData?.estimate?.startDate).format('ddd DD MMM YYYY')}</span>
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <h6 className="text_secondary2 plusJakara_semibold">Contract End Date</h6>
                                        <span className="text_black plusJakara_bold">{moment(contractData?.estimate?.endDate).format('ddd DD MMM YYYY')}</span>
                                    </div>
                                </div>
                            </Col>
                        </div>
                        <hr style={{ color: "#828282" }} />
                        <Col xs={24} md={11} xl={18}>
                            <div className="flex flex-col mb-3">
                                <h5 className="text_primary plusJakara_semibold">Address</h5>
                                <h6
                                    // onClick={() =>
                                    //     MapClick(contractData?.user?.location?.coordinates[1],
                                    //         contractData?.user?.location?.coordinates[0]
                                    //     )}
                                    className="text_secondary plusJakara_regular">{contractData?.address}</h6>
                            </div>
                            <div className="flex flex-col mb-3">
                                <h5 className="text_primary plusJakara_semibold">Description</h5>
                                <h6 className="text_secondary plusJakara_regular">{contractData?.estimate?.description}</h6>
                            </div>
                        </Col>
                    </div>
                    <LabourMaterialTableDesign
                        labour={contractData?.estimate?.labour}
                        material={contractData?.estimate?.material}
                        dis_price={contractData?.estimate?.dis_price}
                        tax={contractData?.estimate?.tax}
                    />
                </Container >)}
        </ main >
    );
};
export default ContractDetailProvider;
