/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getChatList } from "../../api/message_api";
import { avatar } from "../../icons/icon";
import axiosInstance from "../../redux/axiosInstance";
import { useActiveChat, useChatList, useChatUser, useResponsiveChat } from "./context";

const ChatUsers = ({ name, discrip, type, img, id, timestamp, status, data, }) => {
  const [badge, setBadge] = useState(false);
  const userData = useSelector((state) => state?.auth?.userData)
  const { activeChatId, setActiveChatId } = useActiveChat()
  const { setChatUser } = useChatUser()
  const { setResponsiveChat } = useResponsiveChat()

  const toggleData = async (chatData) => {
    chatData.unseen = 0;
    setChatUser(chatData)
    setResponsiveChat(true)
    setActiveChatId(chatData?.otherUser?._id);
  };
  const isActive = id === activeChatId;
  useEffect(() => {
    if (isActive) {
      toggleData(data)
    }
  }, [activeChatId, isActive])

  useEffect(() => {
    setBadge(((data?.lastMsg?.sender !== userData?._id) && data?.lastMsg?.seen === false))
  }, [data]);

  return (
    <>
      <div
        className={`_link_  border-0 `}
        style={{ cursor: "pointer" }}
        onClick={() => toggleData(data)}
      >
        <div
          className={`d-flex align-items-center chat-list-link border-b-2 border-b-[#E5E9EB] px-3 py-3 w-100 ${isActive ? "active" : ""
            }`}
        >
          <div>
            <div className={`${status ? "status_div00" : ""}`}>
              <div className="position-relative">
                <div style={{ minWidth: '60px' }}>
                  <img src={img || avatar} alt="" className="chat_profile_img" />
                </div>
                {data?.unseen > 0 && (
                  <span
                    className={`rounded-5  ${data?.unseen > 0 ? 'd-block' : 'd-none'} text_white ${data?.unseen > 9 ? 'px-1 py-[3px] text-xs' : 'px-[6px] text-sm'} plusJakara_medium`}
                    style={{ position: 'absolute', zIndex: 99, top: '0px', right: '-4px', backgroundColor: '#003F7D' }}
                  >
                    {data?.unseen > 9 ? '+9' : data?.unseen}
                  </span>
                )}
                <span>
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center w-100 pe-1">
            <div className="ps-3 mt-1">
              <h4 className="my-0 chat_name00 line-clamp-1 plusJakara_regular">{name}</h4>
              <div className="chat_detail00 plusJakara_regular line-clamp-1">{type === 'text' ? discrip : type === 'video' ? 'Video' : 'File'}</div>
            </div>
            <div className="time_div00">
              <h6 className="chat_detail00 plusJakara_regular line-clamp-1" style={{ whiteSpace: "nowrap" }}>
                {moment(timestamp).fromNow()}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


const ChatList = () => {
  const { chatListData, setChatListData } = useChatList();
  const chatContainerRef = useRef(null);
  const [count, setCount] = useState(0);
  const [loading, setloading] = useState(false)
  const [lastId, setLastId] = useState(0);
  const handleChatList = async (id) => {
    setloading(true)
    await axiosInstance.get(`msg/conversations`)
      .then((result) => {
        if (result?.data?.success) {
          setCount(result.data.count)
          setloading(false)
          setChatListData(
            result?.data?.conversations?.sort((a, b) => {
              const lastMsgA = a?.lastMsg;
              const lastMsgB = b?.lastMsg;
              if (!lastMsgA || !lastMsgB) {
                return 0;
              }
              const createdAtA = new Date(lastMsgA.createdAt);
              const createdAtB = new Date(lastMsgB.createdAt);
              return createdAtB - createdAtA;
            })
          );
        }
      }).catch((err) => {
        console.log(err);
        setloading(false)
      })
  }

  async function handleScroll() {
    const { scrollTop, clientHeight, scrollHeight } = chatContainerRef.current;
    if (lastId <= count) {
      if (Math.ceil(scrollHeight - scrollTop) - 1 < clientHeight) {
        try {
          const result = await getChatList();
          if (result?.data?.success) {
            setLastId(lastId + 10);
            const newConversations = result?.data?.conversations.filter(conversation => (
              !chatListData.find(chat => chat._id === conversation._id)
            ));

            // Combine filtered new data with previous chatList
            setChatListData(prevChatList => [
              ...prevChatList,
              ...newConversations
            ].sort((a, b) => {
              const lastMsgA = a?.lastMsg;
              const lastMsgB = b?.lastMsg;
              if (!lastMsgA || !lastMsgB) {
                return 0;
              }
              const createdAtA = new Date(lastMsgA.createdAt);
              const createdAtB = new Date(lastMsgB.createdAt);
              return createdAtB - createdAtA; // Sort in ascending order for oldest first
            }));
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  }
  useEffect(() => {
    handleChatList(0)
  }, []);
  return (
    <>
      <div className="chat_height_contol scrolbar" ref={chatContainerRef} onScroll={handleScroll}>
        {loading ?
          <div className="w-full my-4 text-center flex justify-center items-center">
            <Spinner style={{ width: '24px', height: '24px' }} />
          </div> :
          chatListData?.length > 0 && chatListData?.map((chat, index) => (
            <Fragment key={chat?._id}>
              <ChatUsers
                id={chat?.otherUser?._id}
                img={chat?.otherUser?.profilePicture}
                loading={loading}
                data={chat}
                type={chat?.lastMsg?.type}
                name={chat?.otherUser?.name}
                discrip={chat?.lastMsg?.message}
                timestamp={chat?.lastMsg?.createdAt}
              />
            </Fragment>
          ))}
      </div>
    </>
  )
}

export default ChatList