import React from 'react';
import { FaCheck, FaMapMarkerAlt } from 'react-icons/fa';
import { avatar } from '../../icons/icon';

const ClientCard = ({ item, }) => {
    return (
        <div
            // onClick={onPress}
            style={{
                position: 'relative',
                width: '99%',
                height: '120px',
                backgroundColor: '#fff',
                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                borderRadius: '15px',
                padding: '12px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid #d3d3d3',
                marginBottom: `$10px`,
                marginTop: `10px`,
                // cursor: 'pointer',
            }}
        >
            <img
                src={item?.profilePicture || avatar}
                alt="Profile"
                style={{
                    width: '28%',
                    height: '100%',
                    borderRadius: '12px',
                    objectFit: 'cover',
                }}
            />
            <div style={{ width: '68%' }}>
                <div
                    className='bg_primary2'
                    style={{
                        width: '22px',
                        height: '22px',
                        borderRadius: '5px',
                        // backgroundColor: va,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                    }}
                >
                    <FaCheck size={14} color="#fff" />
                </div>

                <div
                    className='plusJakara_medium'
                    style={{
                        fontWeight: '600',
                        fontSize: '16px',
                        marginBottom: '5px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {item?.name}
                </div>

                <div
                    className='plusJakara_regular'
                    style={{
                        color: '#7D7D7D',
                        fontSize: '14px',
                        marginBottom: '10px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {item?.email}
                </div>

                <div
                    className='plusJakara_regular'
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingTop: '8px',
                        width: '90%',
                        borderTop: '1px solid #d3d3d3', // Light gray border
                    }}
                >
                    <FaMapMarkerAlt size={20} color="#003F7D" />
                    <div
                        className='plusJakara_regular'
                        style={{
                            fontSize: '12px',
                            color: '#7D7D7D', // Input label color
                            marginLeft: '10px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {item?.address}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientCard;
