/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../style/swiper.css';
import DTables from "../../DataTable/DTable";
import { FaEye } from 'react-icons/fa6';
import { getMyEmployee } from '../../api/employee';
import { Spinner } from 'react-bootstrap';
import { avataruser } from '../../icons/icon';
import { useSelector } from 'react-redux';
import UpdrageModal from '../common/upgradeModal';

const EmployeeList = () => {
    const navigate = useNavigate()
    const [loading, setloading] = useState([])
    const [employee, setemployee] = useState([])
    const [modal, setmodal] = useState(false)
    const userData = useSelector((state) => state.auth?.userData)

    useEffect(() => {
        if (userData?.sub_type !== 'professional') {
            setmodal(true)
        } else {
            setmodal(false)
        }
    }, [userData])

    const handleViewDetails = (row) => {
        navigate(`/employee-list/${row?._id}`, { state: { employeeData: row } })
    };

    const fetchEmployee = async () => {
        setloading(true)
        await getMyEmployee()
            .then((res) => {
                if (res.success === true) {
                    setemployee(res.users)
                    setloading(false)
                } else {
                    setloading(false)
                    setemployee([])
                }
            }).catch((err) => {
                setloading(false)
                setemployee([])
            })
    }

    useEffect(() => {
        fetchEmployee()
    }, [])

    const columns = [
        {
            name: 'Employee',
            sortable: true,
            minWidth: '180px',
            cell: row => (
                <div className="flex gap-2 items-center">
                    <div style={{ minWidth: '40px' }}>
                        <img src={(row?.profilePicture) || avataruser} style={{ width: '35px', height: '35px', borderRadius: '12px', objectFit: 'cover' }} alt="" />
                    </div>
                    <span style={{ fontSize: '14px' }} className="plusJakara_semibold">{row.name}</span>
                </div>
            )
        },
        {
            name: 'Email',
            sortable: true,
            selector: row => row?.email,
            minWidth: '150px'
        },
        {
            name: 'Phone Number',
            sortable: true,
            selector: row => row?.phone,
            minWidth: '100px'
        },
        {
            name: 'Address',
            sortable: true,
            selector: row => row?.address || 'Not Found',
            minWidth: '200px'
        },
        {
            name: 'State',
            sortable: true,
            selector: row => row?.state || 'Not Found',
            minWidth: '100px'
        },
        {
            name: 'Country',
            sortable: true,
            selector: row => row?.country || 'Not Found',
            minWidth: '100px'
        },
        {
            name: 'City',
            sortable: true,
            selector: row => row?.city || 'Not Found',
            minWidth: '100px'
        },
        {
            name: 'View Task Details',
            cell: row => (
                <button className="btn-view-details ms-3 bg_light rounded-2 p-2" onClick={() => handleViewDetails(row)}>
                    <FaEye />
                </button>
            ),
            minWidth: '50px'
        }
    ];


    return (
        <main className='p-5'>
            <Container fluid className={`${modal && 'blur-sm'}`}>
                <div className="flex mb-4 justify-between w-full flex-wrap gap-3 items-center">
                    <h6 className="text-2xl lg:text-3xl mb-0 plusJakara_semibold text_black">All <span className="plusJakara_semibold text_primary"> Employees</span> </h6>
                </div>
                {loading ? (
                    <div className='my-5 flex justify-center w-full items-center'>
                        <Spinner size="sm" className='text_primary' style={{ color: "#000" }} />
                    </div>) :
                    (!employee || employee.length === 0) ? (
                        <div className='flex justify-center items-center w-full my-5'>
                            <span className="plusJakara_medium md:text-lg">No Employee Found</span>
                        </div>
                    ) : <DTables columns={columns} data={employee} />}
            </Container>
            <UpdrageModal setmodalOpen={setmodal} modalOpen={modal} />
        </main>
    );
};

export default EmployeeList;
