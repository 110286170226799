/* eslint-disable no-unused-vars */
import { Modal } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const UpdrageModal = ({ setmodalOpen, modalOpen, assignUser }) => {
    const navigate = useNavigate()
    const userData = useSelector((state) => state.auth?.userData);

    return (
        <Modal
            centered
            open={modalOpen}
            footer={null}
            maskClosable={false}
            // zIndex={9999}
            closeIcon={false}
            onCancel={() => setmodalOpen(false)}
        >
            <div className='flex flex-col gap-2 items-center w-full p-2'>
                <h3 className="text_black text-center plusJakara_bold">
                    Upgrade Plan
                </h3>
                <div className="flex flex-col gap-2 w-full">
                    <h5 className="plusJakara_regular leading-8 text_black">You are using "{
                        userData?.sub_type || "basic"
                    }"plan so in that plan limited features are available if you want to use more features upgrade your plan</h5>
                    <div className="w-full">
                        <button
                            onClick={() => navigate('/business-profile?selected=subscription')}
                            className="py-[14px] w-full text-[16px] rounded-3 bg_primary plusJakara_medium text_white"
                            type="submit"
                        >Upgrade your Plan
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default UpdrageModal;