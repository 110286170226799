import axiosInstance from "../redux/axiosInstance";

export const createRating = async ({ data }) => {
    try {
        const res = await axiosInstance.post(`rating/create`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const getRating = async (id) => {
    try {
        const res = await axiosInstance.get(`rating/all/${id}`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};