/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { ThemeProvider } from 'react-bootstrap';
import { useGeolocated } from 'react-geolocated';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './App.css';
import './app.scss';
import UserLayout from './components/layout/layout';
import { useSocket } from './components/pages/messages/socketProvider';
import {
  getCurrentLatLng,
  setIsMobileLogin,
  setUserType,
  setUserZipcode
} from './components/redux/loginForm';
import Routing from './components/routes/routes';
import SidebarMenu from './components/sidebar/sidebar';
import './components/style/main.css';

function App() {
  const location = useLocation();
  const socket = useSocket();
  const dispatch = useDispatch(null);
  const [toggled, setToggled] = useState(false);
  const [typeFromMobile, settypeFromMobile] = useState('');
  const [broken, setBroken] = useState(false);
  const theme = useSelector((state) => state.themeDart.theme);
  const usertype = useSelector((state) => state.auth?.userData?.type);
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');

  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
  });


  const fetchZipcode = async (latitude, longitude) => {
    const apiKey = process.env.REACT_APP_APIKEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.results.length > 0) {
        const addressComponents = data.results[0].address_components;
        const postalCodeObj = addressComponents.find((comp) =>
          comp.types.includes('postal_code')
        );
        return postalCodeObj ? postalCodeObj.long_name : null;
      }
    } catch (error) {
      console.error('Error fetching zipcode:', error);
    }
    return null;
  };

  useEffect(() => {
    if (coords?.latitude && coords?.longitude) {
      dispatch(getCurrentLatLng(coords));
      fetchZipcode(coords.latitude, coords.longitude)
        .then((zipcode) => {
          if (zipcode) {
            dispatch(setUserZipcode(zipcode));
          }
        });
    }
  }, [coords]);

  useEffect(() => {
    if (type) {
      settypeFromMobile(type);
      dispatch(setIsMobileLogin(true));
      dispatch(setUserType(type));
    }
  }, [type]);

  useEffect(() => {
    document.documentElement.setAttribute('data-bs-theme', theme);
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  useEffect(() => {
    global.BASEURL = `http://192.168.18.134:5200/api`;
    global.BASEURL2 = `http://192.168.18.134:5200`;
    global.TOKEN = window.localStorage.getItem('react_template_token');
    window.scroll(0, 0);
  }, [location]);

  return (
    <ThemeProvider theme={{ mode: theme }}>
      {(usertype === 'company' || usertype === 'employee') && (
        <SidebarMenu toggled={toggled} setBroken={setBroken} broken={broken} setToggled={setToggled}>
          <UserLayout toggled={toggled} setBroken={setBroken} broken={broken} setToggled={setToggled}>
            <Routing />
          </UserLayout>
        </SidebarMenu>
      )}
      {usertype !== 'company' && usertype !== 'employee' && (
        <UserLayout>
          <Routing />
        </UserLayout>
      )}
    </ThemeProvider>
  );
}

export default App;
