/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Col, Modal } from 'antd';
import html2pdf from 'html2pdf.js';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../style/swiper.css';
// import * as Yup from "yup"
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscription } from '../api/stripeApi';
import { MapClick } from '../common/mapClick';
import ProductTable from '../DataTable/productTable';
import { FaLocationDot } from 'react-icons/fa6';

const SubscriptionList = () => {
    const [modalOpen, setmodalOpen] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [lastId, setLastId] = useState(1);
    const [lastId2, setLastId2] = useState(0);
    const [count, setcount] = useState(0);
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const userData = useSelector((state) => state.auth?.userData)
    const [loading, setloading] = useState([])
    const [subscriptionData, setsubscriptionData] = useState(null)
    const [subscription, setSubscription] = useState([])


    const fetchSubscription = async () => {
        setloading(true)
        await getSubscription(lastId)
            .then((res) => {
                if (res.success === true) {
                    setSubscription(res.subscriptions)
                    setcount(res?.count?.totalPage)
                    setloading(false)
                } else {
                    setloading(false)
                    setSubscription([])
                }
            }).catch((err) => {
                setloading(false)
                setSubscription([])
            })
    }

    useEffect(() => {
        fetchSubscription()
    }, [lastId])

    const handleViewReciept = (row) => {
        setmodalOpen(true)
        setsubscriptionData(row)
    }

    const columns = [
        {
            name: 'Plan Name',
            sortable: true,
            selector: row => row?.sub_type?.replace(/\b\w/g, char => char.toUpperCase()) || '',
            minWidth: '180px'
        },
        {
            name: 'Type',
            sortable: true,
            selector: row => row?.user_type?.replace(/\b\w/g, char => char.toUpperCase()) || '',
            minWidth: '150px'
        },
        {
            name: 'Status',
            sortable: true,
            cell: row => (
                <button className="btn-view-details ms-3 bg_light rounded-2 p-2" disabled >
                    {row?.sub_status}
                </button>
            ),
            minWidth: '150px'
        },
        {
            name: 'Amount',
            sortable: true,
            selector: row => '$' + (Number(row?.amount / 100 || 0)?.toFixed(2)),
            minWidth: '150px'
        },
        {
            name: 'Purchase Date',
            sortable: true,
            selector: row => moment(row?.sub_date).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        {
            name: 'Expire Date',
            sortable: true,
            selector: row => {
                if (row?.user_type?.toLowerCase() === 'monthly') {
                    return moment(row?.sub_date).add(30, 'days').format('ddd DD MMM YYYY');
                } else if (row?.user_type?.toLowerCase() === 'yearly') {
                    return moment(row?.sub_date).add(1, 'year').format('ddd DD MMM YYYY');
                }
                return 'Not Found';
            },
            minWidth: '150px'
        },
        {
            name: 'e-Receipt',
            sortable: false,
            cell: row => (
                <div className="flex">
                    <button onClick={() => handleViewReciept(row)} className="bg_primary rounded-3 text_white p-2 plusJakara_medium">e-Receipt</button>
                </div>
            ),
            minWidth: '150px'
        },
    ];

    const downloadInvoiceAsPDF = () => {
        const invoiceContent = document.getElementById('invoice-content');
        const options = {
            filename: `subscription_${subscriptionData?.order?.order_id || 'download'}.pdf`,
            jsPDF: { format: 'a4' },
        };
        html2pdf().set(options).from(invoiceContent).save();
    };

    return (
        <main className={`p-5`}>
            <Container fluid={usertype === 'company'} className={`${usertype === 'company' ? '' : 'mt-5'}`}>
                <div className="flex w-full flex-col gap-4">
                    {usertype === 'company' &&
                        <div className="flex gap-3 items-center justify-between w-full">
                            <h4 className="text_black plusJakara_semibold">Subscription History</h4>
                        </div>
                    }
                    <div className="flex w-full flex-col">
                        {loading ? (
                            <div className='my-5 flex justify-center w-full items-center'>
                                <Spinner size="sm" className='text_primary' style={{ color: "#000" }} />
                            </div>) :
                            (!subscription || subscription.length === 0) ? (
                                <div className='flex justify-center items-center w-full my-5'>
                                    <span className="plusJakara_medium md:text-lg">No subscription Found</span>
                                </div>
                            ) : <ProductTable
                                count={count}
                                loading={loading}
                                setCurrentPage={setLastId2}
                                currentPage={lastId2}
                                columns={columns}
                                data={subscription}
                                setLastId={setLastId}
                            />}
                    </div>
                </div>
            </Container>

            <Modal
                centered
                open={modalOpen}
                footer={null}
                width={750}
                zIndex={9999}
                onCancel={() => setmodalOpen(false)}
            >
                <div className="flex w-full justify-between flex-col gap-3 gap-md-5" style={{ height: '85vh', overflowY: 'auto' }}>
                    <div id="invoice-content" className='p-3'>
                        <h1 className="text_primary plusJakara_bold text-3xl">INVOICE</h1>
                        <div className="flex justify-between mt-5 mb-4 items-start w-full">
                            <Col span={9} className="flex flex-col gap-2 justify-center w-full">
                                <h6 className="text_secondary2 mb-0 text-sm plusJakara_bold">FROM:</h6>
                                <div className="flex flex-col w-full">
                                    <h6 className="text_primary whitespace-nowrap plusJakara_bold">Request Service</h6>
                                    <span className="text_secondary mb-0 plusJakara_semibold">
                                        Thank you for choosing Request Service. This invoice confirms your subscription purchase.
                                    </span>
                                </div>
                            </Col>
                            <Col span={9} className="flex flex-col gap-2 justify-end w-full">
                                <h6 className="text_secondary2 mb-0 text-sm plusJakara_bold">TO:</h6>
                                <div className="flex flex-col w-full">
                                    <h6 className="text_primary whitespace-nowrap plusJakara_bold">{userData?.name}</h6>
                                    <div className="flex gap-1 items-start">
                                        <FaLocationDot className="text_primary mt-1" />
                                        <span className="text_secondary mb-0 plusJakara_semibold">{userData?.address}</span>
                                    </div>
                                </div>
                            </Col>
                        </div>
                        <hr />
                        <div className="mt-4">
                            <h6 className="text_black plusJakara_bold mb-3">Subscription Details</h6>
                            <table className="w-full text_left">
                                <thead>
                                    <tr className="border-b">
                                        <th style={{ minWidth: '140px' }} className="py-3 text_secondary plusJakara_semibold">Plan Name</th>
                                        <th style={{ minWidth: '140px' }} className="py-3 text_secondary plusJakara_semibold">Type</th>
                                        <th style={{ minWidth: '140px' }} className="py-3 text_secondary plusJakara_semibold">Amount</th>
                                        <th style={{ minWidth: '140px' }} className="py-3 text_secondary plusJakara_semibold">Purchase Date</th>
                                        <th style={{ minWidth: '140px' }} className="py-3 text_secondary plusJakara_semibold">Expiry Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="py-3 text_black">{subscriptionData?.sub_type?.replace(/\b\w/g, char => char.toUpperCase()) || 'N/A'}</td>
                                        <td className="py-3 text_black">{subscriptionData?.user_type?.replace(/\b\w/g, char => char.toUpperCase()) || 'N/A'}</td>
                                        <td className="py-3 text_black">${(subscriptionData?.amount / 100 || 0)?.toFixed(2) || '0.00'}</td>
                                        <td className="py-3 text_black">{moment(subscriptionData?.sub_date).format('ddd DD MMM YYYY')}</td>
                                        <td className="py-3 text_black">
                                            {subscriptionData?.user_type?.toLowerCase() === 'monthly' ? (
                                                moment(subscriptionData?.sub_date).add(30, 'days').format('ddd DD MMM YYYY')
                                            ) : subscriptionData?.user_type?.toLowerCase() === 'yearly' ? (
                                                moment(subscriptionData?.sub_date).add(1, 'year').format('ddd DD MMM YYYY')
                                            ) : (
                                                'N/A'
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <hr />
                        <div className="flex flex-col gap-2 items-end justify-end w-full">
                            <div className="flex w-full justify-end">
                                <div className="flex bg-[#f4f4f4] rounded-2 my-2 items-center p-2 justify-center" style={{ width: '250px' }}>
                                    <h6 className="text_black mb-0 plusJakara_semibold">Total Summary </h6>
                                </div>
                            </div>
                            <div className="flex w-full justify-end">
                                <div className="flex justify-between mb-2 px-2" style={{ width: '250px' }}>
                                    <h6 className="text_secondary2 mb-0 plusJakara_semibold">Sub Total </h6>
                                    <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">${(subscriptionData?.amount / 100 || 0)?.toFixed(2) || '0.00'}</h6>
                                </div>
                            </div>

                            <div className="flex w-full justify-end">
                                <div className="flex justify-between px-2" style={{ width: '250px' }}>
                                    <h6 className="text_secondary2 mb-0 plusJakara_semibold">Total Amount </h6>
                                    <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">${(subscriptionData?.amount / 100 || 0)?.toFixed(2) || '0.00'}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center mt-[4rem]">
                        <button onClick={downloadInvoiceAsPDF} style={{ maxWidth: '300px', width: '100%' }} className="bg_primary rounded-5 px-5 py-[12px] plusJakara_medium text_white">
                            Download Receipt
                        </button>
                    </div>
                </div>
            </Modal>

        </ main>
    );
};
export default SubscriptionList;
