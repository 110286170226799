import axiosInstance from "../redux/axiosInstance";

export const getProviders = async ({ data }, page) => {
    try {
        const res = await axiosInstance.post(`users/website/nearBy/companies/${page}`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};