import React from 'react'
import { Container } from 'react-bootstrap'
import { FiTwitter } from 'react-icons/fi'
import HeaderNavigation from './common/headerNavigation'
import { avatar, avatar1 } from '../icons/icon'

const OurTeam = () => {


    const categories = [
        { image: avatar, designation: 'Founder & CEO', name: 'Philippe' },
        { image: avatar1, designation: 'Founder & CTO', name: 'Fahad' },
        { image: avatar1, designation: 'Founder & CTO', name: 'Fahad' },
        { image: avatar1, designation: 'Founder & CTO', name: 'Fahad' },
    ]

    return (
        <main className='pb-5'>
            <HeaderNavigation page='Our Team' serviceName='Our Team' />
            <Container className='py-5'>
                <h1 className="plusJakara_semibold text-4xl lg:text-5xl text-center text_dark">Our Team of Experts</h1>
                <div className="flex flex-col items-center gap-1 my-5 w-full justify-center">
                    <div style={{ border: '1.13px solid #F45734', width: '100px', height: '2px' }}></div>
                    <div style={{ border: '1.13px solid #F45734', width: '100px', height: '2px' }}></div>
                </div>
                <div className="my-5 w-100 ">
                    <div className='d-flex justify-center gap-3 flex-wrap'>
                        {categories.map((item, i) => (
                            <div key={i} className='' >
                                <div className='bg-[#f4f4f4] rounded-xl w-[20rem]'>
                                    <img src={item?.image} className='h-[18rem] w-100 object-cover rounded-t-xl' alt="" style={{ objectPosition: '50% 40%' }} />
                                    <div className="flex items-center gap-4 justify-between p-3">
                                        <div className="flex flex-col">
                                            <span className="plusJakara_semibold text-lg text_dark">{item.name}</span>
                                            <span className="plusJakara_medium text_dark">{item.designation}</span>
                                        </div>
                                        <button className="bg_primary p-2 rounded-md text_white flex justify-center">
                                            <FiTwitter />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </main>
    )
}

export default OurTeam