/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import * as Yup from "yup"
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import { FaArrowLeft } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { completeOrder, getSpeceficDetail } from '../api/order';
import DTables from "../DataTable/DTable";
import '../style/swiper.css';
import HeaderNavigation from './common/headerNavigation';
import { MapClick } from '../common/mapClick';
import { createPayment } from '../api/stripeApi';
import { setInvoiceData } from '../redux/services';
import { loadStripe } from '@stripe/stripe-js';
import { avataruser, coveravatar } from '../icons/icon';
import { Input } from 'reactstrap';
const InvoiceDetailProvider = ({ showButtons = true }) => {
    const { state } = useLocation()
    const [modalType, setModalType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setopenModal] = useState(false)
    const [completeLoading, setcompleteLoading] = useState(false)
    const [statusId, setstatusId] = useState(null)
    const [newLabourData, setNewLabourData] = useState([])
    const dispatch = useDispatch();
    const [newMaterialData, setNewMaterialData] = useState([])
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const [stripeModal, setstripeModal] = useState(false)
    const [clientSecret, setClientSecret] = useState("");
    const userData = useSelector((state) => state.auth?.userData)
    const [invoiceData, setinvoiceData] = useState(state?.invoiceData || null)
    const [grandTotal, setGrandTotal] = useState(0);
    const navigate = useNavigate();
    const [labourData, setLabourData] = useState([]);
    const [materialData, setMaterialData] = useState(invoiceData?.order?.estimate?.material || []);
    const { id } = useParams();
    const stripePromise = loadStripe(
        "pk_test_51QIDSRGK4FiXYXIBCKsFSn3uJVuYOLnC2PK7XJhUeiClvRFt5LnbCERqG15Lmkrqc9yDM5sMer6XloxdieaI2UTu00odA67e1q"
    );


    useEffect(() => {
        if (!invoiceData && id) {
            getSpeceficDetail('order/invoice', id)
                .then((res) => {
                    if (res?.invoice) {
                        setinvoiceData(res.invoice);
                    }
                })
                .catch((err) => {
                    message.error(err.response.data.message);
                });
        }
    }, [id]);

    useEffect(() => {
        if (invoiceData?.order?.service?.features) {
            const total = invoiceData?.order?.service?.features?.reduce((sum, feature) => sum + (feature.totalPrice || 0), 0);
            setGrandTotal(total);
        }
        const estimateLabour = invoiceData?.order?.estimate?.labour || [];
        const additionalLabour = invoiceData?.labour || [];
        const materials = [
            ...(invoiceData?.order?.estimate?.material || []),
            ...(invoiceData?.material || [])
        ].map(material => ({
            id: material.id,
            labourName: material.materialName,
            totalPrice: material.totalPrice,
            labourUnit: material.materialUnit,
            labourPrice: material.materialPrice,
            laboutUnitType: material.materialUnitType,
        }));
        setLabourData([...estimateLabour, ...additionalLabour, ...materials]);
    }, [invoiceData])

    const columns = [
        {
            name: 'Service Name',
            sortable: true,
            minWidth: '680px',
            maxWidth: '680px',
            cell: row => (<span className='plusJakara_medium text-[16px]'>{row.title}</span>)
        },
        {
            name: 'Units',
            sortable: true,
            maxWidth: '130px',
            minWidth: '130px',
            // maxWidth: '250px',
            selector: row => row.unit
        },
        {
            name: 'Unit Type',
            sortable: true,
            maxWidth: '130px',
            minWidth: '130px',
            // maxWidth: '250px',
            selector: row => row.unitType
        },
        {
            name: 'Price',
            sortable: true,
            maxWidth: '130px',
            minWidth: '130px',
            // maxWidth: '250px',
            selector: row => '$ ' + row.price
        },
        {
            name: 'Total Price',
            sortable: true,
            maxWidth: '130px',
            minWidth: '130px',
            cell: row => (<span className='plusJakara_medium'>${row.totalPrice}</span>)
        },
    ]
    const columns3 = [
        {
            name: 'Labour & Material',
            sortable: true,
            minWidth: '600px',
            maxWidth: '600px',
            cell: row => (<span className='plusJakara_medium text-[16px]'>{row?.labourName}</span>)
        },
        {
            name: 'Units',
            sortable: true,
            maxWidth: '120px',
            minWidth: '120px',
            // maxWidth: '250px',
            selector: row => row?.labourUnit
        },
        {
            name: 'Type',
            sortable: true,
            maxWidth: '120px',
            minWidth: '120px',
            // maxWidth: '250px',
            selector: row => row?.laboutUnitType
        },
        {
            name: 'Price',
            sortable: true,
            maxWidth: '120px',
            minWidth: '120px',
            // maxWidth: '250px',
            selector: row => '$ ' + row?.labourPrice
        },
        {
            name: 'Total',
            sortable: true,
            maxWidth: '120px',
            minWidth: '120px',
            cell: row => (<span className='plusJakara_medium'>${parseInt(row?.labourPrice) * parseInt(row?.labourUnit)}</span>)
        },
    ]


    const handleCreatePayment = async (row) => {
        setinvoiceData(row)
        if (!row?.user?.accountId_verified) {
            message.info("You can't pay invoice because that Provider didn't verify payment method. Contact that provider");
            return;
        }
        const data = {
            amount: parseInt(row?.totalPrice || 0).toFixed(),
            accountId: row?.user?.accountId,
        }
        await createPayment({ data: data })
            .then((response) => {
                dispatch(setInvoiceData(row))
                setClientSecret(response.paymentIntent);
                setstripeModal(true)
            }).catch((err) => {
                console.error(err.message);
            });
    };

    const handleSubmit = async (row) => {
        setstatusId(row?._id)
        setcompleteLoading(true)
        await completeOrder(row?.order?._id).then((res) => {
            if (res.success === true) {
                message.success("Order completed successfully");
                setcompleteLoading(false)
                navigate(-1)
            } else {
                message.error("Failed to Order completiing");
                setcompleteLoading(false)
            }
        }).catch((err) => {
            console.error("Error Order completiing:", err);
            message.error(err.response.data.message)
            setcompleteLoading(false)
        })
    }

    return (
        <main className={`${usertype === 'company' ? 'p-3 p-md-5' : 'pb-5'} w-full`}>
            {usertype !== 'company' &&
                <HeaderNavigation mainPage={'/invoices'} subPage={`Invoice Detail`} subPageAvail={true} page={`Invoices`} serviceName={invoiceData?.order?.service?.name || invoiceData?.order?.estimate?.name} />}
            {!invoiceData ? (
                <div className="flex justify-center w-full items-center my-5">
                    <Spinner size="sm" style={{ color: 'black' }} />
                </div>
            ) : (
                <Container fluid={usertype === 'company'} className='bg_white rounded-3 p-4 w-full'>
                    <div className="flex gap-4 items-center justify-between w-full mb-4">
                        <div className="flex flex-col gap-3 w-full">
                            {usertype === 'company' &&
                                <div className="flex gap-4 mb-3 items-center w-full">
                                    <button
                                        onClick={() => navigate(-1)}
                                        className="bg_primary rounded-3 p-2"
                                    >
                                        <FaArrowLeft className='text_white' />
                                    </button>
                                    <h4 className="text_primary mb-0 plusJakara_semibold">Invoice Detail</h4>
                                </div>}
                        </div>
                    </div>
                    <div className="flex mt-4 flex-wrap gap-3 flex-col flex-md-nowrap justify-between w-full">
                        <div className="flex justify-between mb-4 items-center w-full">
                            <img src={userData?.coverPic || invoiceData?.user?.coverPic || coveravatar} className='rounded-3' style={{ width: '120px', height: '100px', objectFit: 'cover' }} alt="" />
                            <h1 className="text_secondary2 plusJakara_medium text-2xl md:text-4xl">INVOICE</h1>
                        </div>
                        <div className="flex justify-between mb-4 items-start w-full">
                            <div className="flex flex-col gap-2 justify-center w-full">
                                <h6 className="text_black mb-0 plusJakara_bold">BILLED TO:</h6>
                                <div className="flex flex-col w-full">
                                    <span className="text_secondary mb-0 whitespace-nowrap plusJakara_semibold">{invoiceData?.to_id?.name || userData?.name}</span>
                                    <span className="text_secondary mb-0 whitespace-nowrap plusJakara_semibold">{invoiceData?.to_id?.phone || userData?.phone}</span>
                                    <span
                                        onClick={() =>
                                            MapClick(invoiceData?.to_id?.location?.coordinates[1] || userData?.location?.coordinates[1],
                                                invoiceData?.to_id?.location?.coordinates[0] || userData?.location?.coordinates[0]
                                            )}
                                        className="text_secondary mb-0 cursor-pointer hover:underline whitespace-nowrap plusJakara_semibold">{invoiceData?.to_id?.address || userData?.address}</span>
                                </div>
                            </div>
                            <div className="flex justify-end w-full">
                                <div className="flex flex-col gap-2 items-start justify-end">
                                    <div className="flex gap-3 items-center">
                                        <h6 className="text_black mb-0 plusJakara_bold">Invoice No:</h6>
                                        <span className="text_secondary mb-0 whitespace-nowrap plusJakara_semibold">#{invoiceData?.order?.order_id}</span>
                                    </div>
                                    <div className="flex gap-3 items-center">
                                        <h6 className="text_black mb-0 plusJakara_bold">Invoice Date:</h6>
                                        <span className="text_secondary mb-0 whitespace-nowrap plusJakara_semibold">{moment(invoiceData?.date).format('ddd DD MMM YYYY')}</span>
                                    </div>
                                    <div className="flex gap-3 items-center">
                                        <h6 className="text_black mb-0 plusJakara_bold">Invoice Due Date:</h6>
                                        <span className="text_secondary mb-0 whitespace-nowrap plusJakara_semibold">{moment(invoiceData?.invoiceDueDate).format('ddd DD MMM YYYY')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="flex flex-col">
                        <div className="w-full flex flex-col gap-3 mb-4">
                            {invoiceData?.order?.estimate &&
                                <>
                                    <h5 className="plusJakara_semibold mb-3 text_dark">Services:</h5>
                                    <DTables columns={columns3} data={labourData} />
                                </>}
                            {invoiceData?.order?.service ?
                                <div className="flex flex-col gap-2 items-end justify-end w-full">
                                    <div className="flex w-full justify-end">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h6 className="text_secondary2 mb-0 plusJakara_semibold">Sub Total </h6>
                                            <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                ${parseInt(invoiceData?.order?.service?.totalPrice || 0)}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="flex w-full justify-end">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h6 className="text_secondary2 mb-0 plusJakara_semibold">Discount ({invoiceData?.order?.service?.dis_price || 0}$)</h6>
                                            <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                ${parseInt(invoiceData?.order?.service?.dis_price)}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="flex w-full justify-end">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h6 className="text_secondary2 mb-0 plusJakara_semibold">Tax ({(invoiceData?.order?.service?.tax) || 0}%)</h6>
                                            <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                ${parseInt(invoiceData?.order?.service?.totalPrice) * parseInt(invoiceData?.order?.service?.tax) / 100}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="flex mt-3 w-full justify-end">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h5 className="text_primary mb-0 plusJakara_semibold">Total Price </h5>
                                            <h5 className="text_black plusJakara_semibold mb whitespace-nowrap-0">
                                                ${invoiceData?.totalPrice}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="flex flex-col gap-2 items-end justify-end w-full">
                                    <div className="flex w-full justify-end">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h6 className="text_secondary2 mb-0 plusJakara_semibold">Sub Total </h6>
                                            <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                ${invoiceData?.subtotal}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="flex w-full justify-end">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h6 className="text_secondary2 mb-0 plusJakara_semibold">Discount ({invoiceData?.discount || 0}%)</h6>
                                            <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                ${parseInt(invoiceData?.subtotal) * parseInt(invoiceData?.discount) / 100}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="flex w-full justify-end">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h6 className="text_secondary2 mb-0 plusJakara_semibold">Tax ({(invoiceData?.tax) || 0}%)</h6>
                                            <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                ${parseInt(invoiceData?.subtotal) * parseInt(invoiceData?.tax) / 100}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="flex mt-3 w-full justify-end">
                                        <div className="flex justify-between" style={{ width: '250px' }}>
                                            <h5 className="text_primary mb-0 plusJakara_semibold">Total Price </h5>
                                            <h5 className="text_black plusJakara_semibold mb whitespace-nowrap-0">
                                                ${invoiceData?.totalPrice}
                                            </h5>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <div className="flex justify-end w-full">
                        <div className='flex flex-col w-full items-end gap-2'>
                            {invoiceData?.status !== 'complete' &&
                                <div
                                    style={{ minWidth: '280px' }}
                                    className="bg-[#f4f4f4] p-[0.3rem] rounded-[6px]">
                                    <div className="flex">
                                        <Input placeholder="Coupon Code" style={{ backgroundColor: "#f4f4f4" }} className="text_primary text-[0.8rem] border-0 bg-[#f4f4f4] sm:text-[1rem]" />
                                        <button className="bg_primary md:py-[0.4rem] px-[0.9rem] text_white rounded-[6px] whitespace-nowrap text-[0.7rem] md:text-[1rem]">Apply</button>
                                    </div>
                                </div>}
                            {invoiceData?.user?.sub_type === 'free' || invoiceData?.user?.sub_type === 'basic' ? (
                                invoiceData?.status === 'pending' ? <button
                                    style={{ minWidth: '280px' }}
                                    disabled={completeLoading}
                                    onClick={() => handleSubmit(invoiceData)}
                                    className="flex gap-1 items-center justify-center rounded-2 bg_primary px-3 py-[10px]"
                                >
                                    <span className="text_white whitespace-nowrap plusJakara_regular">
                                        {invoiceData?._id === statusId && completeLoading ? (
                                            <Spinner size="sm" className="text_white" style={{ color: 'white' }} />
                                        ) : (
                                            'Complete Order'
                                        )}
                                    </span>
                                </button> :
                                    <button
                                        disabled
                                        className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] px-5 py-[10px]"
                                        style={{ minWidth: '280px' }}
                                    >
                                        <span className="text_black plusJakara_regular">Invoice Paid</span>
                                    </button>
                            ) : (invoiceData?.status === 'complete' ? (
                                <button
                                    disabled
                                    className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] px-5 py-[10px]"
                                    style={{ minWidth: '280px' }}
                                >
                                    <span className="text_black plusJakara_regular">Invoice Paid</span>
                                </button>
                            ) : (
                                <button
                                    className="flex gap-1 items-center w-fit justify-center rounded-2 bg_primary px-5 py-[10px]"
                                    style={{ minWidth: '280px' }}
                                    onClick={() => handleCreatePayment(invoiceData)}
                                >
                                    <span className="text_white whitespace-nowrap plusJakara_regular">Pay Invoice</span>
                                </button>
                            ))}
                        </div>
                    </div>
                </Container>)
            }
        </ main >
    );
};

export default InvoiceDetailProvider;
