import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Footer from '../footer/footer';
import NavHeader2 from '../navHeader/navHeader2';
import NavHeader from '../navHeader/navHeader';

const UserLayout = ({ children, toggled, setBroken, broken, setToggled }) => {
    const location = useLocation();
    const theme = useSelector((state) => state.themeDart.theme);
    const isLogin = useSelector((state) => state.auth.isLogin);
    const routes = ['/login', '/signup', '/forgot-password', '/reset-password', '/redirect-page', '/code-verify', '/pricing-info', '/personal-info', '/success-page', '/business-info', '/portfolio-info', '/availability-info', '/personal-portfolio'];
    const routes2 = ['/personal-info', '/pricing-info', '/service-info', '/redirect-page', '/availability-info', '/success-page', '/portfolio-info'];
    const usertype = useSelector((state) => state.auth?.userData?.type);

    return (
        <>
            {!routes2.includes(location.pathname) && (
                ((usertype !== 'company' && usertype !== 'employee') || !isLogin) ?
                    <NavHeader toggled={toggled} setBroken={setBroken} broken={broken} setToggled={setToggled} /> :
                    <NavHeader2 toggled={toggled} setBroken={setBroken} broken={broken} setToggled={setToggled} />
            )}
            <main className={`${theme}`}>
                {children}
            </main>
            {(!routes.includes(location.pathname)) && usertype !== 'company' && usertype !== 'employee' && <Footer />}
        </>
    );
}
export default UserLayout;
