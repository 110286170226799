/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Autoplay, FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getBlogCategory } from '../../api/blogs';
import '../../style/swiper.css';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from 'react-redux';
import { setBlogCategories } from '../../redux/services';

const BlogCategories = ({ onSelectCategory, showTitle = true, selectedCategory }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const categoriesFromRedux = useSelector((state) => state.services?.blogCategories);

    const handleFetchCategories = async () => {
        if (categoriesFromRedux?.length > 0) {
            setLoading(false);
            return;
        }
        setLoading(true);
        try {
            const res = await getBlogCategory();
            setLoading(false);
            const fetchedCategories = res.success ? res.categories : [];
            const allCategories = [{ _id: 'all', name: 'All' }, ...fetchedCategories];
            dispatch(setBlogCategories(allCategories))
        } catch (error) {
            setLoading(false);
            console.error('Failed to fetch categories:', error);
        }
    };

    useEffect(() => {
        handleFetchCategories();
    }, []);

    return (
        <Container style={{ paddingTop: showTitle ? '2rem' : '' }}>
            {loading ? (
                <div className="my-4 flex gap-3 overflow-hidden">
                    {Array.from({ length: 8 }).map((_, index) => (
                        <Skeleton
                            key={index}
                            height={50}
                            width={100}
                            className="rounded-lg"
                            style={{ flexShrink: 0 }}
                        />
                    ))}
                </div>
            ) : (
                categoriesFromRedux?.length === 0 ? (
                    <div className="flex justify-center items-center w-full my-5">
                        <span className="plusJakara_medium md:text-lg">No Category Found</span>
                    </div>
                ) : (
                    <div className="items_swiper relative">
                        {showTitle && (
                            <h6 className="text-xl lg:text-2xl w-fit absolute mb-0 plusJakara_semibold text_primary">
                                Blog Categories
                            </h6>
                        )}
                        <Swiper
                            freeMode={true}
                            modules={[FreeMode, Autoplay, Navigation]}
                            navigation={true}
                            spaceBetween={10}
                            pagination={{ clickable: true }}
                            className="mySwiper d-flex w-100 gap-3 relative"
                            slidesPerView={'auto'}
                        >
                            {categoriesFromRedux.map((category) => (
                                <SwiperSlide
                                    key={category._id}
                                    style={{
                                        marginRight: '10px',
                                        border: selectedCategory?._id === category._id ? '1.5px solid #003F7D' : '0.25px solid #ccc',
                                        width: 'fit-content',
                                    }}
                                    className="pe-4 relative gap-2 cursor-pointer whitespace-nowrap w-fit rounded-2 flex justify-center px-4 py-2 plusJakara_medium text_primary"
                                    onClick={() => onSelectCategory(category)}
                                >
                                    {category.name}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                )
            )}
        </Container>
    );
};

export default BlogCategories;