import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLogin: false,
    isMobileLogin: false,
    userType: '',
    code: '',
    updated: 0,
    applicationDetail: null,
    accessToken: '',
    isForgotPassword: false,
    forgotCode: null,
    userZipcode: null,
    refreshToken: '',
    latlng: {},
    subscriptionData: null,
    userData: null,
    currentLocation: null
  },
  reducers: {
    setLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    getCurrentLatLng(state, action) {
      state.latlng = action.payload;
    },
    setCode: (state, action) => {
      state.code = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setIsMobileLogin: (state, action) => {
      state.isMobileLogin = action.payload;
    },
    setApplicationDetail: (state, action) => {
      state.applicationDetail = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setIsForgotPassword: (state, action) => {
      state.isForgotPassword = action.payload;
    },
    setForgotCode: (state, action) => {
      state.forgotCode = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setSubscriptionData: (state, action) => {
      state.subscriptionData = action.payload;
    },
    setCurrentLocation: (state, action) => {
      state.currentLocation = action.payload;
    },
    setUserZipcode: (state, action) => {
      state.userZipcode = action.payload;
    },
    handleLogin: (state, action) => {
      state.userData = action.payload
    },
    handleUserData: (state, action) => {
      state.updated = action.payload
    },
  },
});

export const { setLogin, handleLogin, getCurrentLatLng, setUserZipcode, setCode, setIsMobileLogin, code, setUserType, userType, setForgotCode, setIsForgotPassword, setAccessToken, setRefreshToken, setCurrentLocation, setSubscriptionData, handleUserData, setApplicationDetail, setUserData } = authSlice.actions;

export default authSlice.reducer;