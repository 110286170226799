/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// React Basic and Bootstrap
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";

import { Result } from "antd";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Container
} from "reactstrap";
import { payInvoice } from "../../api/order";
import { Spinner } from "react-bootstrap";


const SuccessPage = () => {
    const navigate = useNavigate()
    const state = useLocation()
    const [loading, setloading] = useState(false)
    const [paymentSuccess, setpaymentSuccess] = useState(false)
    const invoiceData = useSelector((state) => state?.services?.invoiceData)

    useEffect(() => {
        if (invoiceData) {
            setloading(true)
            payInvoice(invoiceData?._id)
                .then((res) => {
                    if (res.success === true) {
                        setpaymentSuccess(true)
                        setloading(false)
                    } else {
                        setloading(false)
                        setpaymentSuccess(false)
                    }
                })
                .catch((err) => {
                    setpaymentSuccess(false)
                    setloading(false)
                    console.log(err);
                });
        }
    }, [invoiceData]);

    return (
        <React.Fragment>
            <Container fluid className="flex justify-between px-0 w-full">
                <div className='flex items-center w-100 justify-center h-screen px-2'>
                    {loading ?
                        <div className="flex w-full justify-center">
                            <Spinner style={{ color: "#000" }} />
                        </div> :
                        <div className="card shadow-sm md:max-w-[700px] w-auto border min-h-[100px] h-auto py-7 px-4 rounded-sm">
                            {paymentSuccess ?
                                <Result
                                    status="success"
                                    title={<h4 className="plusJakara_semibold text_primary">Payment Successful</h4>}
                                    subTitle={<span className="text_secondary2 plusJakara_medium">Thank you for your payment! We’re pleased to welcome you onboard and look forward to delivering a cleaner, brighter experience.</span>}
                                    extra={[
                                        <button onClick={() => navigate('/invoices')} className='bg_primary text_white plusJakara_medium px-5 py-[12px] rounded-2'>Back</button>,
                                    ]}
                                /> : <Result
                                    status="error"
                                    title={<h4 className="plusJakara_semibold text_primary">Payment Failed</h4>}
                                    subTitle={
                                        <span className="text_secondary2 plusJakara_medium">
                                            Unfortunately, your payment did not go through. Please try again or contact our support team if you need assistance.
                                        </span>
                                    }
                                    extra={[
                                        <button onClick={() => navigate('/invoices')} className='bg_primary text_white plusJakara_medium px-5 py-[12px] rounded-2'>
                                            Back
                                        </button>,
                                    ]}
                                />}
                        </div>}
                </div>
            </Container>

        </React.Fragment>
    );
};
export default SuccessPage;
