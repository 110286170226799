/* eslint-disable no-unused-vars */
import { Col, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
// import * as Yup from "yup"
import dayjs from 'dayjs';
import { ErrorMessage, Field, FieldArray, Formik } from 'formik';
import { FaArrowLeft } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { Form, Input } from 'reactstrap';
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuidv4 } from 'uuid';
import { createInvoiceOrder } from '../api/order';
import { labor1, material1 } from '../icons/icon';
import '../style/swiper.css';
import HeaderNavigation from './common/headerNavigation';
import { calculateFinalPrice } from './estimate/calculateFinalPrice';
import LabourMaterialTableDesign from './estimate/labourMaterialTableDesign';
const CreateInvoice = ({ showButtons = true }) => {
    const { state } = useLocation()
    const [modalType, setModalType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setopenModal] = useState(false)
    const [newLabourData, setNewLabourData] = useState([])
    const [newMaterialData, setNewMaterialData] = useState([])
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const [dueDate, setdueDate] = useState(null)
    const invoiceData = state?.invoiceData || null
    const [grandTotal, setGrandTotal] = useState(0);
    const navigate = useNavigate();
    const [labourData, setLabourData] = useState(invoiceData?.estimate?.labour || []);
    const [materialData, setMaterialData] = useState(invoiceData?.estimate?.material || []);

    const calculateTotal = (row) => {
        const tax = (row?.totalPrice * Number(row?.tax)) / 100;
        const taxedPrice = row?.totalPrice + tax;
        return (taxedPrice - row?.dis_price)?.toFixed(2);
    };

    const createInvoice = async () => {
        if (!dueDate) {
            message.error("Please select due date");
            return
        } else {
            const materialTotal = materialData?.reduce((sum, material) => sum + (parseInt(material.materialTotal) || 0), 0);
            const labourTotal = labourData?.reduce((sum, labour) => sum + (parseInt(labour.labourTotal) || 0), 0);
            const subTotalPrice = invoiceData?.service?.totalPrice || (materialTotal + labourTotal);
            const estimatePrice = calculateFinalPrice(
                subTotalPrice || 0,
                invoiceData?.estimate?.dis_price || 0,
                invoiceData?.estimate?.tax || 0
            ).toFixed(2);
            const totalGrandPrice2 = calculateTotal(invoiceData?.service)
            const body = {
                date: invoiceData?.estimate?.date || invoiceData?.date,
                time: new Date(),
                invoiceDueDate: new Date(dueDate),
                totalPrice: invoiceData?.service ? totalGrandPrice2 : estimatePrice,
                tax: invoiceData?.service?.tax || invoiceData?.estimate?.tax,
                discount: invoiceData?.service?.dis_price || invoiceData?.estimate?.dis_price,
                subtotal: subTotalPrice,
                material: newMaterialData,
                labour: newLabourData,
            };
            setIsLoading(true);
            try {
                const res = await createInvoiceOrder({ data: body }, invoiceData?._id)
                if (res.success === true) {
                    message.success("Invoice created successfully");
                    navigate('/invoices')
                    setIsLoading(false);
                } else {
                    message.error("Invoice did not create");
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        if (invoiceData?.service?.features) {
            const total = invoiceData?.service?.features?.reduce((sum, feature) => sum + (feature.totalPrice || 0), 0);
            setGrandTotal(total);
        }
    }, [invoiceData])

    const columns = [
        {
            name: 'Service Name',
            sortable: true,
            minWidth: '580px',
            maxWidth: '580px',
            cell: row => (<span className='plusJakara_medium text-[16px]'>{row.title}</span>)
        },
        {
            name: 'Units',
            sortable: true,
            maxWidth: '130px',
            minWidth: '130px',
            // maxWidth: '250px',
            selector: row => row.unit
        },
        {
            name: 'Unit Type',
            sortable: true,
            maxWidth: '130px',
            minWidth: '130px',
            // maxWidth: '250px',
            selector: row => row.unitType
        },
        {
            name: 'Price',
            sortable: true,
            maxWidth: '130px',
            minWidth: '130px',
            // maxWidth: '250px',
            selector: row => '$ ' + row.price
        },
        {
            name: 'Total Price',
            sortable: true,
            maxWidth: '130px',
            minWidth: '130px',
            cell: row => (<span className='plusJakara_medium'>${row.totalPrice}</span>)
        },
    ]

    const handleCloseModal = () => {
        setopenModal(false)
        setModalType('')
    }

    return (
        <main className={`${usertype === 'company' ? 'p-3 p-md-5' : 'pb-5'} w-full`}>
            {usertype !== 'company' &&
                <HeaderNavigation mainPage={'/orders?selected=all'} subPage={`Order Detail`} subPageAvail={true} page={`Orders`} serviceName={invoiceData?.service?.name || invoiceData?.estimate?.name} />}
            <Container fluid={usertype === 'company'} className='bg_white rounded-3 p-4 w-full'>
                <div className="flex gap-4 items-center justify-between w-full mb-4">
                    <div className="flex flex-col gap-3 w-full">
                        {usertype === 'company' &&
                            <div className="flex gap-4 mb-3 items-center w-full">
                                <button
                                    onClick={() => navigate(-1)}
                                    className="bg_primary rounded-3 p-2"
                                >
                                    <FaArrowLeft className='text_white' />
                                </button>
                                <h4 className="text_primary mb-0 plusJakara_semibold">Create Invoice</h4>
                            </div>}
                    </div>
                </div>
                <div className="flex flex-wrap w-full justify-between">
                    <Col xs={24} lg={11}>
                        <div className="w-full detail_page_swiper overflow-hidden mb-4">
                            <Swiper
                                spaceBetween={10}
                                navigation={true}
                                freeMode={true}
                                modules={[Navigation, Autoplay, FreeMode, Pagination]}
                                className="mySwiper"
                                autoplay={{
                                    delay: 2000,
                                    disableOnInteraction: true,
                                }}
                                slidesPerView={"auto"}
                            >
                                {(() => {
                                    const images = invoiceData?.estimate?.images || invoiceData?.service?.images;
                                    return (
                                        images && images.map((image, index) => (
                                            <SwiperSlide key={index} className="bg_img w-full">
                                                <img src={image} style={{ height: '30rem' }} className='object-cover object-center w-full rounded-2' alt="" />
                                            </SwiperSlide>
                                        ))
                                    );
                                })()
                                }
                            </Swiper>
                        </div>
                    </Col>
                    <Col xs={24} lg={12}>
                        <div className="flex flex-col mb-3">
                            <h5 className="text_primary plusJakara_semibold">Title</h5>
                            <h6 className="text_secondary text-sm plusJakara_medium">{invoiceData?.estimate?.name || invoiceData?.service?.name}</h6>
                        </div>
                        <div className="flex flex-col mb-3">
                            <h5 className="text_primary plusJakara_semibold">Project Location</h5>
                            <h6 className="text_secondary text-sm plusJakara_medium">{(invoiceData?.service?.address ? invoiceData?.service?.address : invoiceData?.user?.address) || invoiceData?.estimate?.location?.address}</h6>
                        </div>
                        <div className="flex flex-col mb-3">
                            <h5 className="text_primary plusJakara_semibold">Description</h5>
                            <h6 className="text_secondary text-sm plusJakara_medium">{invoiceData?.estimate?.description || invoiceData?.service?.description}</h6>
                        </div>
                        <div className="flex flex-col mb-3">
                            <h5 className="text_primary plusJakara_semibold">Due Date</h5>
                            <Input
                                type="date"
                                className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                required
                                value={dueDate}
                                min={dayjs().format('YYYY-MM-DD')}
                                onChange={(e) => setdueDate(e.target.value)}
                                style={{ padding: '12px' }}
                            />
                        </div>
                    </Col>
                </div>
                <hr style={{ color: "#828282" }} />
                {invoiceData?.estimate ?
                    <div className="flex my-4 gap-3 w-full">
                        <button
                            className="bg_light text-blue-500 rounded-3 flex gap-2 items-center px-5 plusJakara_medium py-[12px]"
                            onClick={() => { setModalType("labour"); setopenModal(true); }}
                        >
                            <img src={labor1} style={{ height: "18px", width: 'auto' }} alt="" /> Add Labour
                        </button>
                        <button
                            className="bg_light text-blue-500 rounded-3 flex gap-2 items-center px-5 plusJakara_medium py-[12px]"
                            onClick={() => { setModalType("material"); setopenModal(true); }}
                        >
                            <img src={material1} style={{ height: "18px", width: 'auto' }} alt="" /> Add Material
                        </button>
                    </div> : null}
                {invoiceData?.estimate ?
                    <LabourMaterialTableDesign
                        labour={labourData}
                        material={materialData}
                        dis_price={invoiceData?.estimate?.dis_price}
                        tax={invoiceData?.estimate?.tax}
                    /> :
                    <div className="flex flex-col">

                        <div className="flex flex-col gap-2 items-end justify-end w-full">
                            <div className="flex w-full justify-end">
                                <div className="flex justify-between" style={{ width: '250px' }}>
                                    <h6 className="text_secondary2 mb-0 plusJakara_semibold">Sub Total </h6>
                                    <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                        ${invoiceData?.service?.totalPrice || 0}
                                    </h6>
                                </div>
                            </div>
                            <div className="flex w-full justify-end">
                                <div className="flex justify-between" style={{ width: '250px' }}>
                                    <h6 className="text_secondary2 mb-0 plusJakara_semibold">Discount ({invoiceData?.service?.dis_price || 0}$)</h6>
                                    <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                        ${parseInt(invoiceData?.service?.dis_price)}
                                    </h6>
                                </div>
                            </div>
                            <div className="flex w-full justify-end">
                                <div className="flex justify-between" style={{ width: '250px' }}>
                                    <h6 className="text_secondary2 mb-0 plusJakara_semibold">Tax ({invoiceData?.service?.tax || 0}%)</h6>
                                    <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                        ${grandTotal * parseInt(invoiceData?.service?.tax) / 100}
                                    </h6>
                                </div>
                            </div>
                            <div className="flex mt-3 w-full justify-end">
                                <div className="flex justify-between" style={{ width: '250px' }}>
                                    <h5 className="text_primary mb-0 plusJakara_semibold">Total Price </h5>
                                    <h5 className="text_black plusJakara_semibold mb whitespace-nowrap-0">
                                        ${calculateTotal(invoiceData?.service)}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>}
                {usertype === 'company' &&
                    <div className="flex justify-end w-full my-4">
                        <button type='submit' disabled={isLoading} onClick={createInvoice} className="bg_primary text_white plusJakara_medium px-4 rounded-2 py-[12px]">{isLoading ? <Spinner size='sm' color='white' /> : 'Send Invoice'}</button>
                    </div>
                }

            </Container>

            <Modal
                width={1200}
                zIndex={999999}
                maskClosable={false}
                footer={false}
                open={openModal}
                onCancel={handleCloseModal}
                centered
            >
                <h5 className="plusJakara_semibold mb-2 text_primary">{modalType === "labour" ? "Add Labour" : "Add Material"}</h5>
                <div style={{ backgroundColor: "#f4f4f4" }} className="flex flex-col w-full relative shadow-sm p-2">
                    <Formik
                        initialValues={{
                            labour: modalType === "labour" ? [{
                                id: uuidv4(),
                                labourName: '',
                                labourUnit: '',
                                laboutUnitType: 'unit',
                                labourPrice: '',
                                labourTotal: ''
                            }] : [],
                            material: modalType === "material" ? [{
                                id: uuidv4(),
                                materialName: '',
                                materialUnit: '',
                                materialUnitType: 'unit',
                                materialPrice: '',
                                materialTotal: ''
                            }] : [],
                        }}
                        enableReinitialize={true}
                        onSubmit={(values, { resetForm }) => {
                            if (modalType === "labour") {
                                setLabourData([...labourData, ...values.labour]);
                                setNewLabourData((prevData) => [...prevData, ...values.labour]);
                            } else {
                                setMaterialData([...materialData, ...values.material]);
                                setNewMaterialData((prevData) => [...prevData, ...values.material]);
                            }
                            setopenModal(false);
                            resetForm();
                        }}
                    >
                        {({ values, setFieldValue, handleSubmit }) => (
                            <Form>
                                <FieldArray name={modalType}>
                                    {({ push, remove }) => (
                                        <>
                                            {values[modalType].map((entry, index) => (
                                                <div className="w-full relative" key={index}>
                                                    <Col xs={24}>
                                                        <div className="flex justify-between mb-2 bg_white items-start rounded-2 p-2 w-full">
                                                            <Col xs={12} className="w-full mt-[22px]">
                                                                <div className="flex gap-2 items-start">
                                                                    <span className="text_secondary2 mt-[10px] whitespace-nowrap plusJakara_medium text-xs">
                                                                        {modalType === "labour" ? "LABOUR" : "MATERIAL"}
                                                                    </span>
                                                                    <div className="flex flex-col w-full">
                                                                        <Field
                                                                            name={`${modalType}[${index}].${modalType}Name`}
                                                                            className="form-control border text-xs rounded-1 w-full"
                                                                            style={{ backgroundColor: "#fefefe" }}
                                                                            placeholder="Description"
                                                                        />
                                                                        <ErrorMessage
                                                                            name={`${modalType}[${index}].${modalType}Name`}
                                                                            component="div"
                                                                            className="text-danger text-xs plusJakara_regular"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={2} className="w-full">
                                                                <span className="text_secondary2 plusJakara_medium text-xs">UNIT TYPE</span>
                                                                <Field
                                                                    as="select"
                                                                    className="border text-sm rounded-1 w-full"
                                                                    style={{ backgroundColor: "#fefefe", padding: "8px" }}
                                                                    name={`${modalType}[${index}].${modalType === 'labour' ? 'labout' : 'material'}UnitType`}
                                                                >
                                                                    <option value="unit">Unit</option>
                                                                    <option value="sqrft">Sqrft</option>
                                                                    <option value="inch">Inch</option>
                                                                </Field>
                                                                <ErrorMessage
                                                                    name={`${modalType}[${index}].${modalType === 'labour' ? 'labout' : 'material'}UnitType`}
                                                                    component="div"
                                                                    className="text-danger text-xs plusJakara_regular"
                                                                />
                                                            </Col>
                                                            <Col xs={2} className='w-full'>
                                                                <Col xs={2} className='w-full'>
                                                                    <span className='text_secondary2 plusJakara_medium whitespace-nowrap text-xs'>
                                                                        {entry?.[`${modalType === 'labour' ? 'labout' : 'material'}UnitType`]?.toUpperCase()}
                                                                    </span>
                                                                </Col>
                                                                <Field
                                                                    name={`${modalType}[${index}].${modalType}Unit`}
                                                                    className="form-control border text-sm rounded-1 w-full"
                                                                    style={{ backgroundColor: '#fefefe' }}
                                                                    placeholder="Unit"
                                                                    type="number"
                                                                    onChange={(e) => {
                                                                        const unit = e.target.value;
                                                                        const price = values[modalType][index][`${modalType}Price`];
                                                                        setFieldValue(`${modalType}[${index}].${modalType}Unit`, unit);
                                                                        setFieldValue(`${modalType}[${index}].${modalType}Total`, unit * price || 0);
                                                                    }}
                                                                />
                                                                <ErrorMessage name={`${modalType}[${index}].${modalType}Unit`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                            </Col>
                                                            <Col xs={2} className='w-full'>
                                                                <span className='text_secondary2 plusJakara_medium text-xs'>PER PRICE</span>
                                                                <Field
                                                                    name={`${modalType}[${index}].${modalType}Price`}
                                                                    className="form-control border text-sm rounded-1"
                                                                    style={{ backgroundColor: '#fefefe' }}
                                                                    placeholder="Price"
                                                                    type="number"
                                                                    onChange={(e) => {
                                                                        const price = e.target.value;
                                                                        const unit = values[modalType][index][`${modalType}Unit`];
                                                                        setFieldValue(`${modalType}[${index}].${modalType}Price`, price);
                                                                        setFieldValue(`${modalType}[${index}].${modalType}Total`, price * unit || 0);
                                                                    }}
                                                                />
                                                                <ErrorMessage name={`${modalType}[${index}].${modalType}Price`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                            </Col>
                                                            <Col xs={2} className='w-full'>
                                                                <span className='text_secondary2 plusJakara_medium text-xs'>TOTAL PRICE</span>
                                                                <Field
                                                                    name={`${modalType}[${index}].${modalType}Total`}
                                                                    className="form-control border text-sm rounded-1"
                                                                    style={{ backgroundColor: '#f4f4f4' }}
                                                                    placeholder="Total Price"
                                                                    type="number"
                                                                    disabled
                                                                />
                                                                <ErrorMessage name={`${modalType}[${index}].${modalType}Total`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                            </Col>
                                                            <Col xs={3}>
                                                                <div className="flex gap-2 w-full items-center justify-start mt-3">
                                                                    {index === 0 && (
                                                                        <button
                                                                            style={{ backgroundColor: "#f4f4f4" }}
                                                                            className="text-blue-500 px-2 flex gap-1 items-center whitespace-nowrap py-[10px] rounded-1 plusJakara_medium"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                push(
                                                                                    modalType === "labour"
                                                                                        ? {
                                                                                            id: uuidv4(),
                                                                                            labourName: "",
                                                                                            labourUnit: "",
                                                                                            laboutUnitType: "unit",
                                                                                            labourPrice: "",
                                                                                            labourTotal: "",
                                                                                        }
                                                                                        : {
                                                                                            id: uuidv4(),
                                                                                            materialName: "",
                                                                                            materialUnit: "",
                                                                                            materialUnitType: "unit",
                                                                                            materialPrice: "",
                                                                                            materialTotal: "",
                                                                                        }
                                                                                )
                                                                            }
                                                                        >
                                                                            Add {modalType === "labour" ? "Labour" : "Material"}
                                                                        </button>
                                                                    )}
                                                                    {index > 0 && (
                                                                        <button
                                                                            style={{ backgroundColor: "#f4f4f4" }}
                                                                            type="button"
                                                                            className="px-2 text-red-500 whitespace-nowrap flex gap-1 items-center py-[10px] rounded-1 plusJakara_medium"
                                                                            onClick={() => remove(index)}
                                                                        >
                                                                            Delete {modalType === "labour" ? "Labour" : "Material"}
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    </Col>
                                                </div>
                                            ))}

                                            {/* Submit Button */}
                                            <div className="flex justify-end mt-4">
                                                <button
                                                    type="submit"
                                                    className="bg_primary text_white px-3 py-2 rounded-2 plusJakara_medium"
                                                    onClick={handleSubmit}
                                                >
                                                    Add {modalType === "labour" ? "Labour" : "Material"}
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </FieldArray>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>

        </ main >
    );
};
export default CreateInvoice;
