import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';  // Make sure to import skeleton styles

const TableSkeleton = () => {
    return (
        <div className="custom-table-skeleton ">
            <div className="skeleton-header">
                <Skeleton width="100%" height={30} />
                <Skeleton width="100%" height={30} />
                <Skeleton width="100%" height={30} />
                <Skeleton width="100%" height={30} />
                <Skeleton width="100%" height={30} />
            </div>
            <div className="skeleton-body">
                {[...Array(5)].map((_, idx) => (
                    <div key={idx} className="skeleton-row">
                        <Skeleton width="100%" height={40} />
                        <Skeleton width="100%" height={40} />
                        <Skeleton width="100%" height={40} />
                        <Skeleton width="100%" height={40} />
                        <Skeleton width="100%" height={40} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TableSkeleton;
