/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FaCircleCheck } from "react-icons/fa6";
import { IoIosCloseCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cancelSubscription, getClientId, paymentSubscriptions, updateSubscription } from "../../api/stripeApi";
import PaymentCheckCheckout from "../stripe/PaymentCheckCheckout";
import { setUserData } from "../../redux/loginForm";
import axiosInstance from "../../redux/axiosInstance";
import { message } from "antd";

import StripeLinkModal from "../stripe/stripeLinkModal";

const planDetails = {
  free: {
    monthlyPrice: "Free",
    yearlyPrice: "Free",
    features: ["Service Schedule", "Service Booking"],
  },
  basic: {
    monthlyPrice: "$5.95/month",
    yearlyPrice: "$69.95/year",
    features: ["Service Schedule", "Service Booking", "Estimates", "Invoice"],
  },
  standard: {
    monthlyPrice: "$9.95/month",
    yearlyPrice: "$119.95/year",
    features: [
      "Service Schedule",
      "Service Booking",
      "Estimates",
      "Invoice",
      "Service Timing Schedule",
      "Sign Contract",
      "Payment Online",
    ],
  },
  premium: {
    monthlyPrice: "$15.95/month",
    yearlyPrice: "$189.95/year",
    features: [
      "Service Schedule",
      "Service Booking",
      "Estimates",
      "Invoice",
      "Service Timing Schedule",
      "Sign Contract",
      "Payment Online",
      "Track the Employee Time",
    ],
  },
};

const PricingInfoForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [plan, setPlan] = useState("free");
  const [show, setShow] = useState(false);
  const userData = useSelector((state) => state.auth?.userData)
  const [openLinkStripeModal, setopenLinkStripeModal] = useState(false)
  const isLogin = useSelector((state) => state.auth?.isLogin)
  const [billingCycle, setBillingCycle] = useState("monthly");
  const stripePromise = loadStripe(
    "pk_test_51QIDSRGK4FiXYXIBCKsFSn3uJVuYOLnC2PK7XJhUeiClvRFt5LnbCERqG15Lmkrqc9yDM5sMer6XloxdieaI2UTu00odA67e1q"
  );
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionId, setsubscriptionId] = useState('')
  const currentPlan = planDetails[plan];
  const [subscriptionData, setsubscriptionData] = useState(null)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getProfile = async () => {
    try {
      const res = await axiosInstance.get("users/me");
      dispatch(setUserData(res?.data?.user));
    } catch (error) {
      // message.error(error?.response?.data?.message);
      console.error("Error updating user:", error.response.data);
    }
  };

  useEffect(() => {
    getProfile()
  }, [])

  useEffect(() => {
    if (userData) {
      setBillingCycle(userData?.user_type === 'free' ? 'monthly' : userData?.user_type)
      setPlan(userData?.sub_type)
    }
  }, [userData])

  useEffect(() => {
    paymentSubscriptions()
      .then((res) => {
        if (res.subscriptions) {
          setsubscriptionData(res.subscriptions)
        }
      }).catch((err) => {
        console.log(err);
      })
  }, [])

  const currentPrice = planDetails[plan][billingCycle === "monthly" ? "monthlyPrice" : "yearlyPrice"];
  const currentPriceId = subscriptionData?.find(
    (item) => item.product.name === plan.charAt(0).toUpperCase() + plan.slice(1) &&
      item.recurring.interval === (billingCycle === "monthly" ? "month" : "year")
  )?.id;

  const handleSubscribe = async () => {
    if (plan === 'free' && !isLogin) {
      navigate('/personal-info')
      return
    } else if (plan === 'free' && isLogin) {
      navigate('/dashboard')
      message.success('You are now subscribed to the Free plan.');
      return
    }
    setIsLoading(true);
    const data = {
      priceId: currentPriceId,
    };
    await getClientId({ data: data })
      .then((res) => {
        if (res) {
          setsubscriptionId(res.subscriptionId)
          setClientSecret(res.clientSecret);
          setIsLoading(false);
          handleShow();
        } else {
          console.error("Client secret not received from the server.");
          setIsLoading(false);
        }
      }).catch((err) => {
        console.log(err);
        setIsLoading(false);
      })
  };

  const handleSubscribeUpdate = async () => {
    if (plan === 'free' && !isLogin) {
      navigate('/personal-info')
      return
    } else if (plan === 'free' && isLogin) {
      navigate('/dashboard')
      message.success('You are now subscribed to the Free plan.');
      return
    }
    setIsLoading(true);
    const data = {
      priceId: currentPriceId,
    };
    await updateSubscription({ data: data })
      .then((res) => {
        if (res.status === true) {
          setIsLoading(false);
          message.success(res.message)
        } else {
          console.error("Subscription not Updated");
          setIsLoading(false);
        }
      }).catch((err) => {
        console.log(err);
        setIsLoading(false);
      })
  };

  const handleSubscribeCancel = async () => {
    setIsLoading(true);
    await cancelSubscription()
      .then((res) => {
        if (res.success === true) {
          setIsLoading(false);
          message.success(res.message)
        } else {
          setIsLoading(false);
        }
      }).catch((err) => {
        console.log(err);
        setIsLoading(false);
      })
  };

  const allFeatures = planDetails.premium.features;

  console.log(userData);

  return (
    <React.Fragment>
      <div style={{ maxWidth: "700px", width: "100%" }} className="w-full overflow-hidden">
        <div className="flex gap-2 gap-md-3 items-center justify-between overflow-auto w-full mb-3" style={{ borderBottom: "1px solid #EBEEF7" }}>
          {Object.keys(planDetails).map((planKey) => (
            <button
              key={planKey}
              onClick={() => {
                setPlan(planKey)
              }}
              style={{ borderBottom: plan === planKey ? "2px solid #F6921E" : "" }}
              className={`px-3 py-2 md:text-lg plusJakara_medium ${plan === planKey ? "text_primary" : "text_secondary"} flex justify-center items-center gap-2`}
            >
              {planKey.charAt(0).toUpperCase() + planKey.slice(1)}
            </button>
          ))}
        </div>
        {plan !== "free" && (
          <div className="flex gap-3 items-center justify-center mb-4">
            <button
              onClick={() => setBillingCycle("monthly")}
              className={`px-4 py-2 rounded ${billingCycle === "monthly" ? "bg_primary text_white" : "bg-gray-200 text_secondary"}`}
            >
              Monthly
            </button>
            <button
              onClick={() => setBillingCycle("yearly")}
              className={`px-4 py-2 rounded ${billingCycle === "yearly" ? "bg_primary text_white" : "bg-gray-200 text_secondary"}`}
            >
              Yearly
            </button>
          </div>
        )}
        <div style={{ maxHeight: "700px", }} className="border rounded-3 p-4 overflow-auto flex flex-col gap-2 items-center">
          <h2 className="plusJakara_semibold text-xl md:text-2xl text-center text_primary">{currentPrice}</h2>
          {userData?.sub_type === 'free' ?
            <p className="text_secondary2 plusJakara_medium text-center">
              Select the {plan.charAt(0).toUpperCase() + plan.slice(1)} plan for access to the following features:
            </p>
            : <h5 className="text_primary max-md:text-lg plusJakara_semibold mb-3 text-center">
              You are currently subscribed to the "{userData?.sub_type} {userData?.user_type}" plan.
            </h5>}
          {(userData?.sub_type === 'free' && plan !== 'free') &&
            <button
              disabled={isLoading}
              onClick={handleSubscribe}
              className="bg_primary px-[2.5rem] py-[12px] mb-4 rounded-3 plusJakara_medium text_white"
            >
              {isLoading ? <Spinner size="sm" style={{ color: "white" }} /> : 'Subscribe'}
            </button>}
          {plan !== 'free' &&
            (((userData?.sub_type !== plan && userData?.sub_status !== 'active') && (userData?.sub_status === 'active' || userData?.sub_status === 'cancel')) && (
              <button
                disabled={isLoading}
                onClick={handleSubscribeUpdate}
                className="bg_primary px-[2.5rem] py-[12px] mb-4 rounded-3 plusJakara_medium text_white"
              >
                {isLoading ? <Spinner size="sm" style={{ color: "white" }} /> : 'Update Subscription'}
              </button>
            )
              // :
              //   <button
              //     onClick={handleSubscribeCancel}
              //     disabled={isLoading}
              //     className="bg-red-400 px-[2.5rem] py-[12px] mb-4 rounded-3 plusJakara_medium text_white"
              //   >
              //     {isLoading ? <Spinner size="sm"  style={{ color: "white" }} /> : 'Cancel Subscription'}
              //   </button>
            )}
          {allFeatures.map((feature, index) => (
            <div key={index} className="border-t border-t-gray-200 p-3 p-md-4 w-full flex gap-3 items-center justify-start">
              {currentPlan.features.includes(feature) ? (
                <FaCircleCheck size={22} className="text_primary" />
              ) : (
                <IoIosCloseCircle size={24} className="text_secondary2" />
              )}
              <span className={`plusJakara_medium ${currentPlan.features.includes(feature) ? "text_primary" : "text_secondary2"} md:text-lg`}>
                {feature}
              </span>
            </div>
          ))}
        </div>
      </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <h4 className="plusJakara_semibold mb-0 text_primary">Make your Payment</h4>
        </Modal.Header>
        <Modal.Body>
          {" "}
          {clientSecret ? (
            <Elements stripe={stripePromise}>
              <PaymentCheckCheckout
                showStripe={show}
                setopenLinkStripeModal={setopenLinkStripeModal}
                setShowStripe={setShow}
                clientSecret={clientSecret}
                subscriptionId={subscriptionId}
              />
            </Elements>
          ) : <p>Loading...</p>}
        </Modal.Body>
      </Modal>

      <StripeLinkModal stripeModal={openLinkStripeModal} setstripeModal={setopenLinkStripeModal} />

    </React.Fragment>
  );
};

export default PricingInfoForm;
