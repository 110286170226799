/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Col, message, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../../style/swiper.css";
// import * as Yup from "yup"
import moment from "moment";
import { FaArrowLeft, FaLocationDot } from "react-icons/fa6";
import { useSelector } from "react-redux";
import DTable from "../../DataTable/DTable";
import { getEmployeeTaskSummary } from "../../api/employee";
import axiosInstance from "../../redux/axiosInstance";


const getTime = (start, end) => {
    if (!end) {
        return "...";
    }
    const checkIn = moment(start);
    const checkOut = moment(end);
    const duration = moment.duration(checkOut.diff(checkIn));
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const timeDiff = `${days > 0 ? days + "d " : ""}${String(hours).padStart(
        2,
        "0"
    )}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    return timeDiff;
};
const EmployeeServiceDetail = ({ showButtons = true }) => {
    const { state } = useLocation();
    const [taskSummary, settaskSummary] = useState([])
    const userData = useSelector((state) => state.auth?.userData)
    const [item, setItem] = useState({});
    const [data, setData] = useState([]);
    const flatListRef = useRef();
    const intervalRef = useRef(null);
    const [timer, setTimer] = useState(0);
    const [loading2, setLoading2] = useState(true);
    const [assignData, setAssignData] = useState({});
    const [currentIndex, setCurrentIndex] = useState(0);
    const [checkLoading, setCheckLoading] = useState(false);
    const [completeLoading, setCompleteLoading] = useState(false);
    const usertype = useSelector((state) => state.auth?.userData?.type);
    const [employeeTaskDetail, setemployeeTaskDetail] = useState(state?.employeeTaskDetail || null);
    const [grandTotal, setGrandTotal] = useState(0);
    const navigate = useNavigate();
    const { id } = useParams();

    // useEffect(() => {
    //     if (!employeeTaskDetail && id) {
    //         getSpeceficDetail("order", id)
    //             .then((res) => {
    //                 if (res?.order) {
    //                     setemployeeTaskDetail(res.order);
    //                 }
    //             })
    //             .catch((err) => {
    //                 message.error(err.response.data.message);
    //             });
    //     }
    // }, [id]);

    const completeTask = async () => {
        if (timer > 0) {
            return message.error("Please clock out first before complete task");
        }
        setCompleteLoading(true);
        try {
            const res = await axiosInstance.put(`order/assign-users/update/${id}`);
            navigate(-1);
            setCompleteLoading(false);
        } catch (error) {
            setCompleteLoading(false);
            console.log("==================error", error.response.data);
        }
    };

    const handleClock = async () => {
        const body = { date: new Date() };
        setCheckLoading(true);
        try {
            const url = `order/employee/${assignData?.checkIn?._id ? "checkout" : "checkIn"
                }/${id}`;
            if (assignData?.checkIn?._id) {
                body.checkId = assignData?.checkIn?._id;
            }
            const res = await axiosInstance.put(url, body);
            setCheckLoading(false);
            fetchData();
            fetchSummary();
        } catch (error) {
            setCheckLoading(false);
        }
    };

    const formatTime = () => {
        return moment.utc(timer * 1000).format("HH:mm:ss");
    };

    const toggleTimer = (checkIn) => {
        const startTime = checkIn
            ? moment(checkIn).valueOf()
            : Date.now() - timer * 1000;

        intervalRef.current = setInterval(() => {
            setTimer(Math.floor((Date.now() - startTime) / 1000));
        }, 1000);
    };

    const fetchData = async () => {
        try {
            setLoading2(true);
            const res = await axiosInstance.get(`/order/detail/${id}`);
            const order = res?.data?.order;
            if (order) {
                console.log(order);
                setemployeeTaskDetail(order);
                setAssignData(order?.assign_users);
                setItem(order);
                const checkIn = order?.assign_users?.checkIn;
                if (checkIn) {
                    toggleTimer(checkIn);
                } else {
                    setTimer(0);
                    clearInterval(intervalRef.current);
                }
            }
            setLoading2(false);
        } catch (error) {
            console.log("==================error fetch", error);
            setLoading2(false);
        }
    };

    const fetchSummary = async () => {
        await getEmployeeTaskSummary(id, userData?.type === 'employee' ? userData?._id : employeeTaskDetail?.assign_users?.user)
            .then((res) => {
                if (res.success === true) {
                    settaskSummary(res.checkIns)
                } else {
                    settaskSummary([])
                }
            }).catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        fetchSummary()
        fetchData()
    }, [])

    const columns3 = [
        {
            name: 'Task Name',
            sortable: true,
            minWidth: '600px',
            maxWidth: '600px',
            cell: row => (<span className='plusJakara_medium text-[16px]'>{row?.labourName}</span>)
        },
        {
            name: 'Units',
            sortable: true,
            maxWidth: '110px',
            minWidth: '110px',
            // maxWidth: '250px',
            selector: row => row?.labourUnit
        },
        {
            name: 'Type',
            sortable: true,
            maxWidth: '110px',
            minWidth: '110px',
            // maxWidth: '250px',
            selector: row => row?.laboutUnitType
        },
        {
            name: 'Price',
            sortable: true,
            maxWidth: '110px',
            minWidth: '110px',
            // maxWidth: '250px',
            selector: row => '$ ' + row?.labourPrice
        },
        {
            name: 'Total',
            sortable: true,
            maxWidth: '110px',
            minWidth: '110px',
            cell: row => (<span className='plusJakara_medium'>${parseInt(row?.labourPrice) * parseInt(row?.labourUnit)}</span>)
        },
    ]

    const columns4 = [
        {
            name: 'Date',
            sortable: true,
            minWidth: '200px',
            maxWidth: '250px',
            cell: row => (<span className='plusJakara_medium text-[16px]'>{moment(row?.checkIn).format("YYYY-MM-DD")}</span>)
        },
        {
            name: 'Time In',
            sortable: true,
            maxWidth: '250px',
            minWidth: '200px',
            // maxWidth: '250px',
            cell: row => (<span className='plusJakara_medium text-[16px]'>  {row?.checkIn ? moment(row?.checkIn).format("HH:mm:ss") : "..."}</span>)
        },
        {
            name: 'Time Out',
            sortable: true,
            maxWidth: '250px',
            minWidth: '200px',
            // maxWidth: '250px',
            cell: row => (<span className='plusJakara_medium text-[16px]'>  {row?.checkOut ? moment(row?.checkOut).format("HH:mm:ss") : "..."}</span>)
        },
        {
            name: 'Total',
            sortable: true,
            maxWidth: '250px',
            minWidth: '200px',
            cell: row => {
                return (
                    <span className='plusJakara_medium text-[16px]'>{getTime(row?.checkIn, row?.checkOut)}</span>
                );
            }
        }
    ]

    return (
        <main className={`p-5 w-full`}>
            <Container
                fluid
                className="bg_white rounded-3 p-4 w-full"
            >
                <div className="flex gap-4 items-center justify-between w-full mb-4">
                    <div className="flex flex-col gap-3 w-full">
                        <div className="flex gap-4 mb-3 items-center w-full">
                            <button
                                onClick={() => navigate(-1)}
                                className="bg_primary rounded-3 p-2"
                            >
                                <FaArrowLeft className="text_white" />
                            </button>
                            <h4 className="text_primary mb-0 plusJakara_semibold">
                                Task Detail
                            </h4>
                        </div>
                    </div>
                </div>
                <hr style={{ color: "#828282" }} />
                <Row gutter={16}>
                    <Col xs={24} lg={12}>
                        <div className="flex flex-col gap-2 mb-4">
                            <h5 className="text_primary plusJakara_semibold">Title</h5>
                            <h6 className="text_black plusJakara_medium">{employeeTaskDetail?.estimate?.name}</h6>
                        </div>
                        <div className="flex flex-col gap-2 mb-4">
                            <h5 className="text_primary plusJakara_semibold">Project Location</h5>
                            <div className="flex gap-2 items-center">
                                <FaLocationDot size={20} className="text_secondary2" />
                                <h6 className="text_secondary2 mb-0 plusJakara_medium">{employeeTaskDetail?.address}</h6>
                            </div>
                        </div>
                        <div className="flex gap-3 gap-md-5 w-full">
                            <div className="flex flex-col gap-2 mb-4">
                                <h5 className="text_primary plusJakara_semibold">Project Start Date</h5>
                                <h6 className="text_black plusJakara_medium">  {moment(employeeTaskDetail?.estimate?.date).format("ddd DD MMM YYYY")}</h6>
                            </div>
                            <div className="flex flex-col gap-2 mb-4">
                                <h5 className="text_primary plusJakara_semibold">project End Date</h5>
                                <h6 className="text_black plusJakara_medium">{moment(employeeTaskDetail?.estimate?.endDate).format("ddd DD MMM YYYY")}</h6>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 mb-4">
                            <h5 className="text_primary plusJakara_semibold">Description</h5>
                            <h6 className="text_black plusJakara_medium">{employeeTaskDetail?.description}</h6>
                        </div>
                    </Col>
                    <Col xs={24} lg={10}>
                        <div className="flex flex-col items-end gap-2 w-full">
                            {assignData?.status !== 'complete' && usertype === 'employee' &&
                                <h4 className="plusJakara_medium bg_light rounded-2 px-5 py-2 text_secondary2">{formatTime()}</h4>}
                            {usertype === 'employee' &&
                                <div className="flex flex-col gap-3 items-end justify-end w-full">
                                    <div className="flex gap-3 items-center justify-end w-full">
                                        {assignData?.status !== 'complete' && (assignData?.checkIn?._id ?
                                            <button
                                                style={{ minWidth: "210px" }}
                                                onClick={handleClock}
                                                disabled={loading2}
                                                className={`bg_primary2 whitespace-nowrap px-3 py-[12px] rounded-2 text-sm plusJakara_medium text_white`}>
                                                {loading2 ? <Spinner size="sm" style={{ color: 'white' }} /> : 'Clock Out'}
                                            </button> :
                                            <button
                                                style={{ minWidth: "210px" }}
                                                onClick={handleClock}
                                                disabled={loading2}
                                                className={`bg_primary2 whitespace-nowrap px-3 py-[12px] rounded-2 text-sm plusJakara_medium text_white`}>
                                                {loading2 ? <Spinner size="sm" style={{ color: 'white' }} /> : 'Clock In'}
                                            </button>)}
                                    </div>
                                    {assignData?.status !== 'complete' &&
                                        <button
                                            style={{ minWidth: "210px" }}
                                            onClick={completeTask}
                                            disabled={completeLoading}
                                            className={`bg_primary whitespace-nowrap px-3 py-[12px] rounded-2 text-sm plusJakara_medium text_white`}>
                                            {completeLoading ? <Spinner size="sm" style={{ color: 'white' }} /> : 'Complete'}
                                        </button>}
                                </div>}
                        </div>
                    </Col>
                </Row>
                {/* <div className="flex justify-between items-center flex-wrap flex-md-nowrap w-full mt-4"> */}
                <div className="flex flex-col gap-2 w-full mt-4">
                    <h5 className="text_primary plusJakara_semibold">Employee Tasks</h5>
                    <DTable columns={columns3} data={userData?.type === 'employee' ? employeeTaskDetail?.assign_users?.task : employeeTaskDetail?.assign_users[0]?.task} />
                </div>
                <div className="flex flex-col gap-2 w-full mt-4">
                    <h5 className="text_primary plusJakara_semibold">Time Summary</h5>
                    <DTable columns={columns4} data={taskSummary} />
                </div>
                {/* </div> */}
            </Container>
        </main >
    );
};

export default EmployeeServiceDetail;
