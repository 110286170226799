/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getEmployeeTasks } from '../../api/employee';
import DTable from '../../DataTable/DTable';
const AllTaskss = () => {
    const [tasks, setTasks] = useState([])
    const [loading, setloading] = useState(false)
    const userData = useSelector((state) => state.auth?.userData)
    const navigate = useNavigate()


    const getTasks = async () => {
        setloading(true)
        getEmployeeTasks(userData?._id)
            .then((res) => {
                if (res.success === true) {
                    setTasks(res?.orders);
                    setloading(false)
                } else {
                    setTasks([]);
                    setloading(false)
                }
            }).catch((err) => {
                setloading(false)
                setTasks([]);
            })
    }

    useEffect(() => {
        getTasks()
    }, []);

    const handleViewDetails = (row) => {
        navigate(`/employee-list/${row?._id}/detail`, { state: { employeeTaskDetail: row } })
    };


    const columns = [
        {
            name: 'Order Id',
            sortable: true,
            selector: row => '#' + row?.order_id,
            minWidth: '100px'
        },
        {
            name: 'Title',
            sortable: true,
            selector: row => row?.estimate?.name,
            minWidth: '150px'
        },
        {
            name: 'Address',
            sortable: true,
            selector: row => row?.address || 'Not Found',
            minWidth: '250px'
        },
        {
            name: 'Project Start Date',
            sortable: true,
            selector: row => moment(row?.estimate?.date).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        {
            name: 'Project End Date',
            sortable: true,
            selector: row => moment(row?.estimate?.endDate).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        {
            name: 'Price',
            sortable: true,
            selector: row => "$" + (row?.estimate?.totalPrice || 0),
            minWidth: '150px'
        },
        {
            name: 'Status',
            sortable: true,
            cell: row => (
                <div
                    className="px-2 py-1 w-fit text-center rounded-2"
                    style={{ backgroundColor: "#f6911e1b" }}
                >
                    <span className="plusJakara_medium text_primary2">
                        {row?.status === 'pending' ? 'In Progress' : 'Completed'}
                    </span>
                </div>
            ),
            minWidth: '100px'
        },
        {
            name: 'View Detail',
            cell: row => (
                <button className="btn-view-details ms-3 bg_light rounded-2 p-2" onClick={() => handleViewDetails(row)}>
                    <FaEye />
                </button>
            ),
            minWidth: '50px'
        }
    ];

    return (
        <main className='p-3 p-md-5'>
            <Container fluid>
                <h6 className="text-2xl lg:text-3xl plusJakara_semibold text_black">All Tasks </h6>
                <div className="w-full mt-4">
                    {loading ? (
                        <div className='my-5 flex justify-center w-full items-center' >
                            <Spinner size="sm" className='text_primary' style={{ color: "#000" }} />
                        </div>) :
                        (!tasks || tasks.length === 0) ? (
                            <div className='flex justify-center items-center w-full my-5'>
                                <span className="plusJakara_medium md:text-lg">No Task Found</span>
                            </div>) : <DTable columns={columns} data={tasks} />}
                </div>
            </Container>
        </ main>
    );
};

export default AllTaskss;
