/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { message, Modal } from 'antd';
import html2pdf from 'html2pdf.js';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../style/swiper.css';
// import * as Yup from "yup"
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import { FaEye } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { completeOrder, getInvoices } from '../api/order';
import DTables from "../DataTable/DTable";
import HeaderNavigation from './common/headerNavigation';
import { useSocket } from './messages/socketProvider';
import { MapClick } from '../common/mapClick';
import UpdrageModal from './common/upgradeModal';
import { Elements } from '@stripe/react-stripe-js';
import PaymentCheckCheckoutCustomer from './stripe/paymentCheckOutCustomer';
import { createPayment } from '../api/stripeApi';
import { loadStripe } from '@stripe/stripe-js';
import { setInvoiceData } from '../redux/services';
import StripeConnectError from './common/stripeConnectError';

const InvoiceTable = () => {
    const [modalOpen, setmodalOpen] = useState(false)
    const navigate = useNavigate();
    const [completeLoading, setcompleteLoading] = useState(false)
    const [statusId, setstatusId] = useState(null)
    const dispatch = useDispatch();
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const userData = useSelector((state) => state.auth?.userData)
    const [isProcessing, setIsProcessing] = useState(false);
    const [loading, setloading] = useState([])
    const [recieptData, setrecieptData] = useState(null)
    const [isTriger, setIsTriger] = useState(false);
    const [stripeModal, setstripeModal] = useState(false)
    const [clientSecret, setClientSecret] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [invoices, setinvoices] = useState([])
    const [labourData, setlabourData] = useState([])
    const [invoiceData, setinvoiceData] = useState(null)
    const [materialData, setmaterialData] = useState([])
    const [active, setactive] = useState('unPaid')
    const socket = useSocket();
    const [modal, setmodal] = useState(false)
    const stripePromise = loadStripe(
        "pk_test_51QIDSRGK4FiXYXIBCKsFSn3uJVuYOLnC2PK7XJhUeiClvRFt5LnbCERqG15Lmkrqc9yDM5sMer6XloxdieaI2UTu00odA67e1q"
    );

    useEffect(() => {
        if (userData?.sub_type === 'free' && usertype === 'company') {
            setmodal(true)
        } else {
            setmodal(false)
        }
    }, [userData])


    useEffect(() => {
        if (socket) {
            socket.emit('seen-invoice', {});
        }
    }, [socket])


    const appearance = {
        theme: 'minimal',
    };

    const options = {
        clientSecret,
        appearance,
        loader: 'auto'
    };

    const handleCreatePayment = async (row) => {
        setinvoiceData(row)
        if (!row?.user?.accountId_verified) {
            message.info("You can't pay invoice because that Provider didn't verify payment method. Contact that provider");
            return;
        }
        const data = {
            amount: parseInt(row?.totalPrice || 0).toFixed(),
            accountId: row?.user?.accountId,
        }
        await createPayment({ data: data })
            .then((response) => {
                dispatch(setInvoiceData(row))
                setClientSecret(response.paymentIntent);
                setstripeModal(true)
            }).catch((err) => {
                console.error(err.message);
            });
    };

    const handleTab = (tab) => {
        setactive(tab)
    };

    const handleViewDetails = (row) => {
        navigate(`/invoices/${row?._id}`, { state: { recieptData: row } })
    };

    const fetchinvoices = async () => {
        setloading(true)
        await getInvoices('invoice', usertype === 'company' ? 'employee' : 'customer', active === 'paid' ? 'complete' : 'pending')
            .then((res) => {
                if (res.success === true) {
                    setinvoices(res.invoices)
                    setloading(false)
                } else {
                    setloading(false)
                    setinvoices([])
                }
            }).catch((err) => {
                setloading(false)
                setinvoices([])
            })
    }

    useEffect(() => {
        fetchinvoices()
    }, [active])

    const handleViewReciept = (row) => {
        setmodalOpen(true)
        setrecieptData(row)
    }

    useEffect(() => {
        if (recieptData?.order?.estimate) {

            const estimateLabour = recieptData?.order?.estimate?.labour || [];
            const additionalLabour = recieptData?.labour || [];
            const materials = [
                ...(recieptData?.order?.estimate?.material || []),
                ...(recieptData?.material || [])
            ].map(material => ({
                id: material.id,
                labourName: material.materialName,
                totalPrice: material.totalPrice,
                labourUnit: material.materialUnit,
                labourPrice: material.materialPrice,
                laboutUnitType: material.materialUnitType,
            }));
            setlabourData([...estimateLabour, ...additionalLabour, ...materials]);
            setmaterialData([recieptData?.order?.estimate?.material, ...recieptData?.material]);
        }
    }, [recieptData]);

    const columns = [
        {
            name: 'Invoice #',
            sortable: true,
            cell: row => (
                <button className='hover:text-blue-300 hover:underline' onClick={() => handleViewDetails(row)}>{row?.invoice_id}</button>
            ),
            minWidth: '120px'
        },
        {
            name: 'Invoice Date',
            sortable: true,
            selector: row => moment(row?.date).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        {
            name: 'Due Date',
            sortable: true,
            selector: row => moment(row?.invoiceDueDate).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        {
            name: usertype === 'company' ? 'Customer' : 'Service Provider',
            sortable: true,
            selector: row => row?.user?.name || row?.to_id?.name,
            minWidth: '180px'
        },
        {
            name: 'Address',
            sortable: true,
            selector: row => row?.user?.address || row?.to_id?.address,
            minWidth: '280px'
        },
        {
            name: 'Amount',
            sortable: true,
            selector: row => "$" + (row?.totalPrice || 0),
            minWidth: '100px'
        },
        {
            name: 'e-Receipt',
            sortable: false,
            cell: row => (
                <div className="flex">
                    <button onClick={() => handleViewReciept(row)} className="bg_primary rounded-3 text_white p-2 plusJakara_medium">e-Receipt</button>
                </div>
            ),
            minWidth: '150px'
        },
        // ...(usertype === 'customer' ? [
        //     {
        //         name: 'Action',
        //         minWidth: '200px',
        //         sortable: false,
        //         cell: row => (
        //             <div className="flex">
        //                 {row?.user?.sub_type === 'free' || row?.user?.sub_type === 'basic' ? (
        //                     row?.status === 'pending' ? <button
        //                         disabled={completeLoading}
        //                         onClick={() => handleSubmit(row)}
        //                         className="flex gap-1 items-center justify-center rounded-2 bg_primary px-3 py-2"
        //                     >
        //                         <span className="text_white whitespace-nowrap plusJakara_regular">
        //                             {row?._id === statusId && completeLoading ? (
        //                                 <Spinner size="sm" className="text_white" style={{ color: 'white' }} />
        //                             ) : (
        //                                 'Complete Order'
        //                             )}
        //                         </span>
        //                     </button> :
        //                         <button
        //                             disabled
        //                             className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] px-2 py-2"
        //                             style={{ minWidth: '120px' }}
        //                         >
        //                             <span className="text_black text-xs plusJakara_regular">Invoice Paid</span>
        //                         </button>
        //                 ) : (row?.status === 'complete' ? (
        //                     <button
        //                         disabled
        //                         className="flex gap-1 items-center justify-center rounded-2 bg-[#f4f4f4] px-2 py-2"
        //                         style={{ minWidth: '120px' }}
        //                     >
        //                         <span className="text_black text-xs plusJakara_regular">Invoice Paid</span>
        //                     </button>
        //                 ) : (
        //                     <button
        //                         className="flex gap-1 items-center justify-center rounded-2 bg_primary px-2 py-2"
        //                         style={{ minWidth: '120px' }}
        //                         onClick={() => handleCreatePayment(row)}
        //                     >
        //                         <span className="text_white whitespace-nowrap text-xs plusJakara_regular">Pay Invoice</span>
        //                     </button>
        //                 ))}
        //             </div>
        //         ),
        //     }
        // ] : []),
        {
            name: 'Pay Invoice',
            cell: row => (
                <button className="btn-view-details ms-3 bg_light rounded-2 p-2" onClick={() => handleViewDetails(row)}>
                    <FaEye />
                </button>
            ),
            minWidth: '50px'
        }
    ];

    const handleSubmit = async (row) => {
        setstatusId(row?._id)
        setcompleteLoading(true)
        await completeOrder(row?.order?._id).then((res) => {
            if (res.success === true) {
                message.success("Order completed successfully");
                setcompleteLoading(false)
                fetchinvoices()
            } else {
                message.error("Failed to Order completiing");
                setcompleteLoading(false)
            }
        }).catch((err) => {
            console.error("Error Order completiing:", err);
            message.error(err.response.data.message)
            setcompleteLoading(false)
        })
    }

    const columns3 = [
        {
            name: 'Labour & Material',
            sortable: true,
            minWidth: '400px',
            maxWidth: '400px',
            cell: row => (<span className='plusJakara_medium text-[16px]'>{row?.labourName}</span>)
        },
        {
            name: 'Units',
            sortable: true,
            maxWidth: '90px',
            minWidth: '90px',
            // maxWidth: '250px',
            selector: row => row?.labourUnit
        },
        {
            name: 'Type',
            sortable: true,
            maxWidth: '90px',
            minWidth: '90px',
            // maxWidth: '250px',
            selector: row => row?.laboutUnitType
        },
        {
            name: 'Price',
            sortable: true,
            maxWidth: '90px',
            minWidth: '90px',
            // maxWidth: '250px',
            selector: row => '$ ' + row?.labourPrice
        },
        {
            name: 'Total',
            sortable: true,
            maxWidth: '90px',
            minWidth: '90px',
            cell: row => (<span className='plusJakara_medium'>${parseInt(row?.labourPrice) * parseInt(row?.labourUnit)}</span>)
        },
    ]

    const downloadInvoiceAsPDF = () => {
        const invoiceContent = document.getElementById('invoice-content');
        const options = {
            filename: `Invoice_${recieptData?.order?.order_id || 'download'}.pdf`,
            jsPDF: { format: 'a4' },
        };

        // Generate PDF and download
        html2pdf().set(options).from(invoiceContent).save();
    };

    return (
        <>
            {usertype === 'company' &&
                <StripeConnectError />}
            <main className={`${usertype === 'company' ? ((!userData?.accountId_verified && (userData?.sub_type !== 'free' && userData?.sub_type !== 'basic')) ? 'px-3 px-md-5 py-2' : 'p-3 p-md-5') : 'pb-5'} w-full`}>
                {usertype !== 'company' &&
                    <HeaderNavigation page='Invoices' serviceName={active === 'unPaid' ? 'Un Paid Invoices' : 'Paid Invoices'} />}
                <Container fluid={usertype === 'company'} className={`${modal && 'blur-sm'} ${usertype === 'company' ? '' : 'mt-5'}`}>
                    <div className="flex w-full flex-col gap-4">
                        {usertype === 'company' &&
                            <div className="flex gap-3 items-center justify-between w-full">
                                <h4 className="text_black plusJakara_semibold">Un Paid Invoices</h4>
                            </div>
                        }
                        {usertype === 'customer' && <div className={`flex mb-4 overflow-auto rounded-3 ${usertype !== 'company' ? 'bg_light' : 'bg_white'} max-w-md`}>
                            <button onClick={() => handleTab('unPaid')}
                                className={`px-4 py-[10px] w-full  plusJakara_medium ${active === 'unPaid' ? 'bg_primary text_white ' : 'text_secondary2'} flex justify-center items-center gap-2`}>
                                Un Paid
                            </button>
                            <button onClick={() => handleTab('paid')}
                                className={`px-4 py-[10px] w-full  plusJakara_medium ${active === 'paid' ? 'bg_primary text_white ' : 'text_secondary2'} flex justify-center items-center gap-2`}>
                                Paid
                            </button>
                        </div>}
                        <div className="flex w-full flex-col">
                            {loading ? (
                                <div className='my-5 flex justify-center w-full items-center'>
                                    <Spinner size="sm" className='text_primary' style={{ color: "#000" }} />
                                </div>) :
                                (!invoices || invoices.length === 0) ? (
                                    <div className='flex justify-center items-center w-full my-5'>
                                        <span className="plusJakara_medium md:text-lg">No Invoice Found</span>
                                    </div>
                                ) : <DTables columns={columns} data={invoices} />}
                        </div>
                    </div>
                </Container>

                <Modal
                    centered
                    open={modalOpen}
                    footer={null}
                    width={900}
                    zIndex={9999}
                    onCancel={() => setmodalOpen(false)}
                >
                    <div className={`flex w-full ${!recieptData?.order?.estimate && ' gap-3 gap-md-5'} flex-col`} style={{ height: '90vh', overflowY: 'auto' }}>
                        <div id="invoice-content" className='p-4'>
                            <h1 className="text_secondary2 text-center plusJakara_medium text-2xl md:text-4xl">INVOICE</h1>
                            <div className="flex justify-between mb-4 items-center w-full">
                                <img src={userData?.coverPic || recieptData?.user?.coverPic} className='rounded-3' style={{ width: '120px', height: '100px', objectFit: 'cover' }} alt="" />
                                {/* <h1 className="text_secondary2 plusJakara_medium text-2xl md:text-4xl">INVOICE</h1> */}
                            </div>
                            <div className="flex justify-between mb-4 items-start w-full">
                                <div className="flex flex-col gap-2 justify-center w-full">
                                    <h6 className="text_black mb-0 plusJakara_bold">BILLED TO:</h6>
                                    <div className="flex flex-col w-full">
                                        <span className="text_secondary mb-0 whitespace-nowrap plusJakara_semibold">{recieptData?.to_id?.name || userData?.name}</span>
                                        <span className="text_secondary mb-0 whitespace-nowrap plusJakara_semibold">{recieptData?.to_id?.phone || userData?.phone}</span>
                                        <span
                                            onClick={() =>
                                                MapClick(recieptData?.to_id?.location?.coordinates[1] || userData?.location?.coordinates[1],
                                                    recieptData?.to_id?.location?.coordinates[0] || userData?.location?.coordinates[0]
                                                )}
                                            className="text_secondary mb-0 cursor-pointer hover:underline whitespace-nowrap plusJakara_semibold">{recieptData?.to_id?.address || userData?.address}</span>
                                    </div>
                                </div>
                                <div className="flex justify-end w-full">
                                    <div className="flex flex-col gap-2 items-end justify-end">
                                        <div className="flex gap-3 items-center w-full justify-between">
                                            <h6 className="text_black mb-0 plusJakara_bold text-start w-full whitespace-nowrap">Invoice No:</h6>
                                            <h6 className="text_secondary mb-0 whitespace-nowrap text-end w-full plusJakara_semibold">#{recieptData?.order?.order_id}</h6>
                                        </div>
                                        <div className="flex gap-3 items-center w-full justify-between">
                                            <h6 className="text_black mb-0 plusJakara_bold text-start w-full whitespace-nowrap">Invoice Date:</h6>
                                            <h6 className="text_secondary mb-0 whitespace-nowrap text-end w-full plusJakara_semibold">{moment(recieptData?.date).format('ddd DD MMM YYYY')}</h6>
                                        </div>
                                        <div className="flex gap-3 items-center w-full justify-between">
                                            <h6 className="text_black mb-0 plusJakara_bold text-start w-full whitespace-nowrap">Invoice Due Date:</h6>
                                            <h6 className="text_secondary mb-0 whitespace-nowrap text-end w-full plusJakara_semibold">{moment(recieptData?.invoiceDueDate).format('ddd DD MMM YYYY')}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="flex w-full flex-col gap-4">
                                {recieptData?.order?.estimate &&
                                    <DTables columns={columns3} data={labourData} />}
                                {recieptData?.order?.service ?
                                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                                        <div className="flex w-full justify-start">
                                            <div className="flex justify-between" style={{ width: '250px' }}>
                                                <h6 className="text_secondary2 mb-0 plusJakara_semibold">Sub Total </h6>
                                                <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                    ${parseInt(recieptData?.order?.service?.totalPrice || 0)}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="flex w-full justify-start">
                                            <div className="flex justify-between" style={{ width: '250px' }}>
                                                <h6 className="text_secondary2 mb-0 plusJakara_semibold">Discount ({recieptData?.order?.service?.dis_price || 0}$)</h6>
                                                <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                    ${parseInt(recieptData?.order?.service?.dis_price).toFixed(1)}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="flex w-full justify-start">
                                            <div className="flex justify-between" style={{ width: '250px' }}>
                                                <h6 className="text_secondary2 mb-0 plusJakara_semibold">Tax ({(recieptData?.order?.service?.tax) || 0}%)</h6>
                                                <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">
                                                    ${(parseInt(recieptData?.order?.service?.totalPrice) * parseInt(recieptData?.order?.service?.tax) / 100).toFixed(1)}
                                                </h6>
                                            </div>
                                        </div>
                                        <div className="flex mt-3 w-full justify-start">
                                            <div className="flex justify-between" style={{ width: '250px' }}>
                                                <h5 className="text_primary mb-0 plusJakara_semibold">Total Price </h5>
                                                <h5 className="text_black plusJakara_semibold mb whitespace-nowrap-0">
                                                    ${recieptData?.totalPrice.toFixed(1)}
                                                </h5>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="flex flex-col gap-2 items-end justify-end w-full">
                                        <div className="flex w-full justify-end">
                                            <div className="flex justify-between" style={{ width: '250px' }}>
                                                <h6 className="text_secondary2 mb-0 plusJakara_semibold">Sub Total </h6>
                                                <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">${recieptData?.totalPrice.toFixed(1)}</h6>
                                            </div>
                                        </div>
                                        <div className="flex w-full justify-end">
                                            <div className="flex justify-between" style={{ width: '250px' }}>
                                                <h6 className="text_secondary2 mb-0 plusJakara_semibold">Discount &ensp;({recieptData?.discount}%)</h6>
                                                <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0"> ${((recieptData?.totalPrice * (parseInt(recieptData?.discount) || 0)) / 100).toFixed(1)}</h6>
                                            </div>
                                        </div>
                                        <div className="flex w-full justify-end">
                                            <div className="flex justify-between" style={{ width: '250px' }}>
                                                <h6 className="text_secondary2 mb-0 plusJakara_semibold">Tax &ensp; ({recieptData?.tax}%) </h6>
                                                <h6 className="text_black plusJakara_semibold whitespace-nowrap mb-0">${((recieptData?.totalPrice * (parseInt(recieptData?.tax) || 0)) / 100).toFixed(1)}</h6>
                                            </div>
                                        </div>
                                        <div className="flex mt-3 w-full justify-end">
                                            <div className="flex justify-between" style={{ width: '250px' }}>
                                                <h5 className="text_primary mb-0 plusJakara_semibold">Total Price </h5>
                                                <h5 className="text_black plusJakara_semibold whitespace-nowrap mb-0">${(recieptData?.subtotal.toFixed(1))}</h5>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        <div className={`flex justify-center ${!recieptData?.order?.estimate && 'mt-[5rem]'} pe-4 w-full`}>
                            <button onClick={downloadInvoiceAsPDF} style={{ maxWidth: '300px', width: '100%' }} className="bg_primary rounded-5 px-5 py-[12px] plusJakara_medium text_white">
                                Download Invoice
                            </button>
                        </div>
                    </div>
                </Modal>
                <UpdrageModal setmodalOpen={setmodal} modalOpen={modal} />

                <Modal
                    open={stripeModal}
                    onCancel={() => setstripeModal(false)}
                    footer={false}
                    centered
                    style={{ zIndex: 9999 }}
                >
                    <div className="flex flex-col overflow-hidden gap-3 mt-4 w-full">
                        {clientSecret && (
                            <div className="w-full">
                                <Elements options={options} stripe={stripePromise}>
                                    <PaymentCheckCheckoutCustomer setIsLoading={setIsLoading} invoiceData={invoiceData} isTriger={isTriger} email={userData?.email} redirectURL="/payment-successfuly" setDisable={setIsProcessing} />
                                </Elements>
                            </div>
                        )}
                        <button
                            type='button'
                            disabled={clientSecret === '' || isLoading || isProcessing}
                            onClick={() => {
                                setIsTriger(true);
                            }}
                            className="w-100 py-[12px] mt-3 w-full px-5 plusJakara_medium rounded-5 bg_primary text_white">
                            {isLoading ? <Spinner size='sm' /> : 'Pay Invoice'}
                        </button>
                    </div>
                </Modal>
            </ main >
        </>
    );
};
export default InvoiceTable;
