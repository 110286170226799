/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// React Basic and Bootstrap
import React, { useEffect, useRef, useState } from "react";
import "react-phone-input-2/lib/style.css";

import { useSelector } from "react-redux";
import {
    Input,
    Label,
    Row
} from "reactstrap";
import { Autoplay, FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../style/swiper.css';
import { avataruser, cleaning2, coveravatar } from "../../icons/icon";
import { Modal } from "antd";

const OverViewProfile = () => {
    const [imageUrl, setImageUrl] = useState(null);
    const [imageUrlCover, setImageUrlCover] = useState(null);
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const userData = useSelector((state) => state.auth?.userData)
    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef()
    const [show, setshow] = useState(false)
    const [selectedItem, setselectedItem] = useState(null)

    useEffect(() => {
        if (userData) {
            setImageUrlCover(userData?.coverPic)
            setImageUrl(userData?.profilePicture)
        }
    }, [userData])

    const handleClickPortfolio = (row) => {
        setshow(true)
        setselectedItem(row)
    }
    const handleThumbnailClick = (item, index) => {
        setActiveIndex(index);
        swiperRef.current.slideTo(index);
    };
    const filterAvailability = userData?.availablity?.filter((availability) => availability.status === true)

    return (
        <React.Fragment>
            <div
                style={{ maxWidth: '800px', width: '100%' }}
            >
                <Row className="px-0">
                    {(usertype === 'company' || usertype === 'employee') && <>
                        <div className="mb-4 position-relative w-100">
                            {usertype === 'company' &&
                                <div className="flex flex-col items-center w-100 mb-3 gap-2">
                                    <img
                                        src={imageUrlCover || coveravatar}
                                        alt="Preview"
                                        style={{
                                            height: '13rem',
                                            width: '100%',
                                            objectFit: "cover",
                                        }}
                                        className="rounded-4 object-cover"
                                    />
                                </div>
                            }
                            <div className={`${usertype === 'company' ? 'absolute -bottom-10' : 'mt-5'} flex justify-center items-center w-full`}>
                                <img
                                    src={imageUrl || avataruser}
                                    alt="Preview"
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                    }}
                                    className="rounded-full object-cover"
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <Label className="form-label text_secondary2 max-md:text-smtext-lg plusJakara_medium" htmlFor="name">
                                About
                            </Label>
                            <Input
                                type="textarea"
                                className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                name="name"
                                id="name"
                                value={userData?.about}
                                readOnly
                                style={{ padding: '14px 24px', height: '150px' }}
                                placeholder="About"
                            />
                        </div> </>}
                    <div className="mb-3">
                        <div className="w-full px-0">
                            <Label className="form-label text_secondary2 max-md:text-smtext-lg plusJakara_medium" htmlFor="address">
                                Business Location
                            </Label>
                            <div className="flex justify-between items-center flex-wrap flex-md-nowrap w-full gap-2">
                                <Input
                                    type="text"
                                    value={userData?.address}
                                    readOnly
                                    className="form-control w-full rounded-3 text_secondary2 text-sm plusJakara_medium"
                                    name="country"
                                    id="country"
                                    style={{ padding: '14px', }}
                                    placeholder="Address"
                                />
                            </div>
                        </div>
                        <div className="flex gap-2 mt-3 items-center flex-wrap flex-lg-nowrap mb-3">
                            <div className="flex flex-col w-full">
                                <Input
                                    type="text"
                                    value={userData?.country}
                                    readOnly
                                    className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                    name="country"
                                    id="country"
                                    style={{ padding: '14px', }}
                                    placeholder="Country"
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <Input
                                    type="text"
                                    value={userData?.city}
                                    readOnly
                                    className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                    name="city"
                                    id="city"
                                    style={{ padding: '14px', }}
                                    placeholder="City"
                                />

                            </div>
                        </div>
                        <div className="flex gap-2 items-center flex-wrap flex-lg-nowrap mb-3">
                            <div className="flex flex-col w-full">
                                <Input
                                    type="text"
                                    readOnly
                                    value={userData?.state}
                                    className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                    name="state"
                                    id="state"
                                    style={{ padding: '14px', }}
                                    placeholder="State"
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <Input
                                    type="text"
                                    readOnly
                                    value={userData?.zipcode}
                                    className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                    name="postalCode"
                                    id="postalCode"
                                    style={{ padding: '14px', }}
                                    placeholder="Zip"
                                />
                            </div>
                        </div>
                    </div>
                    {usertype === 'company' && <>
                        <div className="flex flex-col w-full gap-2 mb-4">
                            <h6 className="text_secondary2 mb-0 plusJakara_medium text-lg">Insurance No:</h6>
                            <Input
                                type="text"
                                className="form-control rounded-2 text_secondary2 plusJakara_medium"
                                name="zipCode"
                                id="zipCode"
                                readOnly
                                value={userData?.insurance}
                                style={{ padding: '14px', }}
                                placeholder="Txvnjkol788900"
                            />
                        </div>
                        <div className="flex flex-col w-full gap-2 mb-4">
                            <h6 className="text_secondary2 mb-0 plusJakara_medium text-lg">License No:</h6>
                            <Input
                                type="text"
                                className="form-control rounded-2 text_secondary2 plusJakara_medium"
                                name="zipCode"
                                id="zipCode"
                                readOnly
                                value={userData?.license}
                                style={{ padding: '14px', }}
                                placeholder="Txvnjkol788900"
                            />
                        </div>
                        <div className="flex flex-col w-full gap-2 mb-4">
                            <h6 className="text_secondary2 mb-0 plusJakara_medium text-lg">Facebook:</h6>
                            <Input
                                type="text"
                                className="form-control rounded-2 text_secondary2 plusJakara_medium"
                                name="zipCode"
                                value={userData?.fblink}
                                id="zipCode"
                                readOnly
                                style={{ padding: '14px', }}
                                placeholder="www.cleaningservice.com"
                            />
                        </div>
                        <div className="flex flex-col w-full gap-2 mb-4">
                            <h6 className="text_secondary2 mb-0 plusJakara_medium text-lg">Linked In:</h6>
                            <Input
                                type="text"
                                className="form-control rounded-2 text_secondary2 plusJakara_medium"
                                name="zipCode"
                                value={userData?.linkedInlink}
                                id="zipCode"
                                readOnly
                                style={{ padding: '14px', }}
                                placeholder="facebook.com"
                            />
                        </div>
                        <div className="flex flex-col w-full gap-2 mb-4">
                            <h6 className="text_secondary2 mb-0 plusJakara_medium text-lg">Instagram:</h6>
                            <Input
                                type="text"
                                className="form-control rounded-2 text_secondary2 plusJakara_medium"
                                name="zipCode"
                                value={userData?.instalink}
                                id="zipCode"
                                readOnly
                                style={{ padding: '14px', }}
                                placeholder="facebook.com"
                            />
                        </div>
                        <div className="flex flex-col w-full gap-2 mb-4">
                            <h6 className="text_secondary2 mb-0 plusJakara_medium text-lg">Availability:</h6>
                            <div className="flex flex-col mb-3 gap-3 w-full">
                                {filterAvailability?.map((day, index) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{ backgroundColor: "#EBF0F5" }}
                                            className="px-4 rounded-3 py-[10px] flex gap-3 gap-md-5 justify-between"
                                        >
                                            <h6 style={{ maxWidth: '10rem', width: '100%' }} className="text_secondary2 mb-0 plusJakara_medium text-lg">
                                                {day.day}
                                            </h6>
                                            <h6 style={{ whiteSpace: "nowrap" }} className="text_secondary2 mb-0 plusJakara_regular">
                                                {new Date(day.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(day.end_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                            </h6>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-3 mb-4">
                            <h6 className="text_secondary2 mb-0 plusJakara_medium text-lg">Portfolio:</h6>
                            <div className="grid2">
                                {userData?.portfolio?.map((item, i) => (
                                    <div key={i} className="flex flex-col rounded-4 overflow-hidden border gap-3">
                                        <div className="home_swiper" style={{ width: '100%', height: 'auto' }}>
                                            <Swiper
                                                freeMode={true}
                                                modules={[FreeMode, Autoplay, Navigation]}
                                                navigation={true}
                                                pagination={{ clickable: true }}
                                                loop={true}
                                                className="mySwiper d-flex w-100 gap-3 relative"
                                                slidesPerView={1}
                                            >
                                                {item?.urls?.length > 0 ? (
                                                    item.urls.map((cat, index) => (
                                                        <SwiperSlide
                                                            key={index}
                                                            style={{ width: '100%', height: '10rem', overflow: 'hidden' }}
                                                        >
                                                            {cat?.type === 'video' && cat?.url ? (
                                                                <video
                                                                    src={cat.url}
                                                                    alt="Preview"
                                                                    style={{
                                                                        height: '100%',
                                                                        width: '100%',
                                                                        cursor: 'pointer',
                                                                        objectFit: 'cover',
                                                                    }}
                                                                    className="rounded-4 w-full bg_light object-cover"
                                                                    controls
                                                                />
                                                            ) : (
                                                                <img
                                                                    onClick={() => handleClickPortfolio(item, index)}
                                                                    src={cat.url || cleaning2}
                                                                    style={{
                                                                        height: '100%',
                                                                        width: '100%',
                                                                        cursor: 'pointer',
                                                                        objectFit: 'cover',
                                                                    }}
                                                                    alt="Project"
                                                                />
                                                            )}
                                                        </SwiperSlide>
                                                    ))
                                                ) : (
                                                    <SwiperSlide
                                                        style={{ width: '100%', height: '10rem', overflow: 'hidden' }}
                                                    >
                                                        <img
                                                            src={cleaning2}
                                                            onClick={() => handleClickPortfolio(item, 0)}
                                                            style={{
                                                                height: '100%',
                                                                width: '100%',
                                                                cursor: 'pointer',
                                                                objectFit: 'cover',
                                                            }}
                                                            alt="Default Project"
                                                        />
                                                    </SwiperSlide>
                                                )}
                                            </Swiper>
                                            <div onClick={() => handleClickPortfolio(item, 0)} className="p-2 flex flex-col cursor-pointer gap-2">
                                                <div className="flex justify-between gap-1 items-center">
                                                    <h6 className="text_black plusJakara_semibold mb-0 whitespace-nowrap">{item.title}</h6>
                                                </div>
                                                <span className="text_secondary2 plusJakara_regular line-clamp-2">{item.description}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>}
                </Row>
            </div>

            <Modal
                centered
                open={show}
                footer={null}
                width={600}
                zIndex={9999}
                onCancel={() => setshow(false)}
            >
                <div>
                    <h4 className="text_primary text-center mb-4 plusJakara_bold">View Portfolio Detail</h4>
                    <div className="flex flex-col w-full overflow-hidden gap-3">
                        <div className="home_swiper" style={{ width: '100%', height: 'auto' }}>
                            <Swiper
                                freeMode={true}
                                modules={[FreeMode, Autoplay, Navigation]}
                                navigation={true}
                                pagination={{ clickable: true }}
                                loop={true}
                                className="mySwiper d-flex w-full gap-3 relative"
                                slidesPerView={1}
                                onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                                onSwiper={(swiper) => (swiperRef.current = swiper)}
                                initialSlide={activeIndex}
                            >
                                {selectedItem?.urls?.length > 0 ? (
                                    selectedItem.urls.map((cat, index) => (
                                        <SwiperSlide
                                            key={index}
                                            className='rounded-4'
                                            style={{ width: '100%', height: '18rem', overflow: 'hidden' }}
                                        >
                                            {cat?.type === 'video' && cat?.url ? (
                                                <video
                                                    src={cat.url}
                                                    alt="Preview"
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        cursor: 'pointer',
                                                        objectFit: 'cover',
                                                    }}
                                                    className="rounded-4 w-full bg_light object-cover"
                                                    controls
                                                />
                                            ) : (
                                                <img
                                                    // onClick={() => handleClickPortfolio(cat, index)}
                                                    src={cat.url || cleaning2}
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        cursor: 'pointer',
                                                        objectFit: 'cover',
                                                    }}
                                                    alt="Project"
                                                />
                                            )}
                                        </SwiperSlide>
                                    ))
                                ) : (
                                    <SwiperSlide
                                        className='rounded-4'
                                        style={{ width: '100%', height: '18rem', overflow: 'hidden' }}
                                    >
                                        <img
                                            src={cleaning2}
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                cursor: 'pointer',
                                                objectFit: 'cover',
                                            }}
                                            alt="Default Project"
                                        />
                                    </SwiperSlide>
                                )}
                            </Swiper>
                            <div className="thumbnails flex justify-start gap-2 mt-2">
                                {selectedItem?.urls?.map((cat, index) => (
                                    <div
                                        key={index}
                                        className={`thumbnail relative cursor-pointer overflow-hidden rounded-2 ${activeIndex === index ? 'border-2 border-[#003F7D]' : 'border'}`}
                                        onClick={() => handleThumbnailClick(cat, index)}
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            overflow: 'hidden',
                                            transition: 'border-color 0.3s ease',
                                        }}
                                    >
                                        {cat?.type === 'video' ?
                                            <video
                                                src={cat.url}
                                                alt="Preview"
                                                style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    cursor: 'pointer',
                                                    objectFit: 'cover',
                                                }}
                                                controls
                                            /> :
                                            <img
                                                src={cat.url || cleaning2} style={{
                                                    height: '100%',
                                                    width: '100%',
                                                    cursor: 'pointer',
                                                    objectFit: 'cover',
                                                }} alt="Project" />}
                                    </div>
                                ))}
                            </div>
                            <div className="p-3 flex flex-col gap-2">
                                <div className="flex justify-between gap-2 items-center">
                                    <h4 className="text_black plusJakara_bold mb-0 whitespace-nowrap">{selectedItem?.title}</h4>
                                </div>
                                <span className="text_secondary2 plusJakara_regular">{selectedItem?.description}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

        </React.Fragment>
    );
};
export default OverViewProfile;
