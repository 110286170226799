/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// React Basic and Bootstrap
import React from "react";
import { FaArrowLeft } from "react-icons/fa6";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import {
  Container
} from "reactstrap";
import { cleaning5 } from "../icons/icon";
import PortfolioInfoForm from "../pages/common/portfolioInfoForm";

const PortfolioInfo = () => {

  return (
    <React.Fragment>
      <Container fluid className="flex justify-between px-0 w-full">
        <div className="bg_light vh-100 overflow-hidden w-100 w-lg-50 p-2 p-md-4">
          <div className="overflow-auto relative bg_white rounded-4 d-flex h-full w-full align-items-center flex-column px-3 py-2 py-lg-5 px-lg-5">
            <div className="absolute max-md:top-3" style={{ left: '20px', }}>
              <Link to='/availability-info'>
                <div className="bg_light w-9 md:w-12 h-9 md:h-12 flex justify-center items-center text_primary rounded-5">
                  <FaArrowLeft size={24} className="w-4 md:w-6" />
                </div>
              </Link>
            </div>
            <div className="mb-5 w-full flex items-center flex-col">
              <h1 className="plusJakara_semibold text-xl md:text-3xl text-center text_primary">Portfolio Information</h1>
              <h6 className="text_secondary2 mb-4 max-w-lg max-lg:text-sm plusJakara_regular text-center">To get started, please complete your profile. A complete profile helps customers know more about you and the services you offer.</h6>
            </div>
            <PortfolioInfoForm />
          </div>
        </div>
        <div className="relative d-none d-lg-block w-100">
          <div className="bg-overlay"></div>
          <img src={cleaning5} style={{ width: '100%', height: '100vh', objectFit: 'cover', position: 'relative' }} alt="" />
        </div>
      </Container>
    </React.Fragment>
  );
};
export default PortfolioInfo;