import React from 'react';
import { Container } from 'react-bootstrap';
import { offer1, offer2, offer3, offer4 } from '../../icons/icon';

const WhatWeOffer = () => {
    return (
        <div className='py-5'>
            <Container className='pb-5'>
                <div className="grid2 items-start w-100">
                    <div className="flex flex-col gap-1">
                        <div className="flex gap-1 items-end">
                            <div className="relative overflow-hidden">
                                <div className="bg-overlay rounded-4"></div>
                                <div className="absolute top-6 z-50 flex justify-center px-3 gap-2 text-center flex-col items-center">
                                    <h6 className="text_white plusJakara_medium">Estimates</h6>
                                    <span style={{ color: "#f5f5f5" }} className="plusJakara_regular text-xs">
                                        We offer all the estimates, contracts, service providers, and payment guarantee for you. Trust our quality services.
                                    </span>
                                </div>
                                <img src={offer1} style={{ width: '240px', height: "300px", objectFit: 'cover' }} className='rounded-4 overflow-hidden relative' alt="Estimates" />
                            </div>
                            <div className="relative overflow-hidden">
                                <div className="bg-overlay rounded-4"></div>
                                <div className="absolute top-6 z-50 flex justify-center px-3 gap-2 text-center flex-col items-center">
                                    <h6 className="text_white plusJakara_medium">Contracts</h6>
                                    <span style={{ color: "#f5f5f5" }} className="plusJakara_regular text-xs">
                                        Easily manage contracts with our secure and efficient system, designed to keep your projects on track.
                                    </span>
                                </div>
                                <img src={offer2} style={{ width: '220px', height: "265px", objectFit: 'cover' }} className='rounded-4 overflow-hidden relative' alt="Contracts" />
                            </div>
                        </div>
                        <div className="flex gap-1 items-start">
                            <div className="relative overflow-hidden">
                                <div className="bg-overlay rounded-4"></div>
                                <div className="absolute top-6 z-50 flex justify-center px-3 gap-2 text-center flex-col items-center">
                                    <h6 className="text_white plusJakara_medium">Service Provider</h6>
                                    <span style={{ color: "#f5f5f5" }} className="plusJakara_regular text-xs">
                                        Connect with trusted service providers to ensure high-quality results and a reliable process for your needs.
                                    </span>
                                </div>
                                <img src={offer3} style={{ width: '230px', height: "250px", objectFit: 'cover' }} className='rounded-4 overflow-hidden relative' alt="Service Provider" />
                            </div>
                            <div className="relative overflow-hidden">
                                <div className="bg-overlay rounded-4"></div>
                                <div className="absolute top-6 z-50 flex justify-center px-3 gap-2 text-center flex-col items-center">
                                    <h6 className="text_white plusJakara_medium">Payment Guarantee</h6>
                                    <span style={{ color: "#f5f5f5" }} className="plusJakara_regular text-xs">
                                        Enjoy peace of mind with our secure payment guarantee, ensuring your transactions are protected.
                                    </span>
                                </div>
                                <img src={offer4} style={{ width: '270px', height: "300px", objectFit: 'cover' }} className='rounded-4 overflow-hidden relative' alt="Payment Guarantee" />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 w-full">
                        <div className="flex flex-col gap-2 w-full">
                            <h4 className="text_primary mb-0 plusJakara_semibold">Estimates</h4>
                            <p className="plusJakara_medium text_secondary2">
                                Get accurate estimates for your services with transparent pricing. We provide upfront quotes to help you make informed decisions and avoid surprises.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <h4 className="text_primary mb-0 plusJakara_semibold">Contracts</h4>
                            <p className="plusJakara_medium text_secondary2">
                                Our contract management system helps streamline agreements, giving you full control over service terms and details. Trust us to keep your project running smoothly.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <h4 className="text_primary mb-0 plusJakara_semibold">Service Provider</h4>
                            <p className="plusJakara_medium text_secondary2">
                                Connect with top-rated professionals in your area. We ensure only vetted providers deliver services, keeping quality high and your satisfaction guaranteed.
                            </p>
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                            <h4 className="text_primary mb-0 plusJakara_semibold">Payment Guarantee</h4>
                            <p className="plusJakara_medium text_secondary2">
                                Our secure payment processing ensures that every transaction is safeguarded, so you can confidently use our platform. Your trust and security are our priorities.
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default WhatWeOffer;
