/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { message } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import randomColor from "randomcolor";
import React, { useEffect, useState } from "react";
import { momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Container } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../redux/axiosInstance";
import { useSocket } from "./messages/socketProvider";
import Scheduling from "./scheduling";

const ScheduleAdmin = () => {
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [modalOpen, setModalOpen] = useState(false)
    const [status, setstatus] = useState('')
    const [modalOpen2, setModalOpen2] = useState(false)
    const [moreData, setMoreData] = useState(null)
    const [scheduleData, setscheduleData] = useState(null)
    const [selected, setSelected] = useState(new Date());
    const [data1, setData1] = useState([]);
    const [activeView, setActiveView] = useState(Views.WEEK);
    const socket = useSocket();

    useEffect(() => {
        if (socket) {
            socket.emit('seen-schedule', {});
        }
    }, [socket])

    const handleMoreData = (row) => {
        console.log(row);
        setMoreData(row)
        if (row?.type === 'schedule') {
            setModalOpen2(true)
            setModalOpen(false)
        } else {
            setModalOpen(true)
        }
    }

    const handlePrev = () => {
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate);
            activeView === Views.WEEK
                ? newDate.setDate(prevDate.getDate() - 7)
                : newDate.setMonth(prevDate.getMonth() - 1);
            return newDate;
        });
    };
    const handleNext = () => {
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate);
            activeView === Views.WEEK
                ? newDate.setDate(prevDate.getDate() + 7)
                : newDate.setMonth(prevDate.getMonth() + 1);
            return newDate;
        });
    };

    const localizer = momentLocalizer(moment);

    const fetchData = async () => {
        const month = currentDate.toLocaleString("default", {
            month: "long",
        });

        // setData([]);

        setLoading(true);
        const body = {
            startDate: month?.toLowerCase(),
        };
        const body1 = {
            bookingDate: month?.toLowerCase(),
        };

        try {
            const [scheduleResponse, ordResponse] = await Promise.all([
                axiosInstance.put(`schedule/all/`, body),
                axiosInstance.put(`order/byDate/employee/all/pending/`, body1),
            ]);

            const schedules = scheduleResponse?.data?.schedules?.map((item) => ({
                title: item?.title,
                start: dayjs(item?.startDate).toDate(),
                end: dayjs(item?.startDate).toDate(),
                type: "schedule",
                item: item,
                bg: randomColor(),
            }));

            const ordersFromResponse = ordResponse?.data?.orders?.map((item) => {
                if (item?.estimate) {
                    const color = randomColor()
                    const materialLaborEvents = item?.estimate?.labour?.map(
                        (laborItem) => ({
                            title: laborItem?.labourName,
                            start: dayjs(laborItem?.startDate).toDate(),
                            end: dayjs(laborItem?.endDate).toDate(),
                            type: item?.type,
                            item: item,
                            orderId: item?.order_id,
                            bg: color,
                        })
                    );

                    return [...materialLaborEvents];
                } else {
                    return {
                        title: item?.service?.name,
                        start: dayjs(item?.bookingDate).toDate(),
                        end: dayjs(item?.bookingDate).toDate(),
                        type: item?.type,
                        item: item,
                        orderId: item?.order_id,
                        bg: randomColor(),
                    };
                }
            });
            const flattenedOrders = ordersFromResponse.flat();
            setData([...schedules, ...flattenedOrders]);
            setData1([...schedules, ...flattenedOrders]);

            setLoading(false);
            if (activeView === 1) {
                getDataByDate(selected);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const getDataByDate = (e) => {
        const selectedDate = new Date(e).toISOString().split("T")[0];
        const filterData = data1.filter((item) => {
            const eventDate = new Date(item?.start).toISOString().split("T")[0];
            return eventDate === selectedDate;
        });
        setData(filterData);
        setSelected(e);
    };

    useEffect(() => {
        fetchData();
    }, [selected, currentDate, activeView]);

    const handleSelectSlot = (slotInfo) => {
        setSelected(new Date(slotInfo.start));
        setCurrentDate(new Date(slotInfo.start));
    };

    const handleNavigateEvent = (e) => {
        if (e.type === 'schedule') {
            setscheduleData(e)
            setModalOpen2(true)
            setModalOpen(false)
        } else {
            navigate(`/orders/${e.item?._id}`, { state: { orderData: e.item } })
        }
    }

    const handleStatus = async (status) => {
        setstatus(status)
        try {
            setLoading(true);
            const response = await axiosInstance.put(`schedule/change/${status}/${scheduleData?.item?._id}`)
            if (response.data?.message) {
                if (response.data?.schedule?.status === "reject") {
                    message.success('Schedule Rejected successfully')
                } else {
                    navigate('/create-estimate', { state: { estimateUser: scheduleData?.item } })
                    message.success('Schedule Confirm successfully')
                }
            }
            setLoading(false);
            setModalOpen2(false);
        } catch (error) {
            console.log(error.response.data, "err in status change");
            setLoading(false);
            setModalOpen2(false);
        }
    };

    return (
        <main className='p-3 p-md-5'>
            <Container fluid={usertype === 'company'} className='p-3 p-md-4 bg_white rounded-4 w-full'>
                <div className="flex flex-col gap-3 w-full">
                    <div className="flex gap-4 items-start w-full">
                        <button
                            onClick={() => navigate(-1)}
                            className="bg_primary rounded-3 p-2"
                        >
                            <FaArrowLeft className='text_white' />
                        </button>
                        <h4 className="text_primary mb-0 plusJakara_semibold">My Scheduling</h4>
                    </div>
                </div>
                <Scheduling fullview={false} />

            </Container>
        </ main>
    );
};

export default ScheduleAdmin;