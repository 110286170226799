/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getProviders } from '../../api/provider';
import { setProvidersData } from '../../redux/services';
import '../../style/swiper.css';
import SkeletonLoader from '../common/skeletonLoader';
import ProviderPortfolioCard from './providerPortfolioCard';

const ProviderPortfolio = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const providersData = useSelector((state) => state?.services?.providersData);
    const userData = useSelector((state) => state?.auth?.userData);
    const [loading, setLoading] = useState(false);

    const fetchProviders = async () => {
        if (providersData?.length > 0) return;

        const body = {
            lat: userData?.location?.coordinates?.[1],
            lng: userData?.location?.coordinates?.[0],
        };

        setLoading(true);
        try {
            const response = await getProviders({ data: {} }, 1);
            if (response.success) {
                dispatch(setProvidersData(response.users));
            } else {
                dispatch(setProvidersData([]));
            }
        } catch (error) {
            dispatch(setProvidersData([]));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProviders();
    }, []);

    return (
        <Container className="pb-5 pt-4 py-md-5">
            {/* <div className="flex justify-between mb-3 w-full flex-wrap gap-3 items-start">
                <h6 className="text-xl md:text-3xl min-[1900px]:text-4xl mb-0 plusJakara_semibold text_black">
                    Service Providers <br />
                    <h6 className="text-2xl lg:text-3xl plusJakara_bold text_primary">Portfolio</h6>
                </h6>
                <button
                    onClick={() => navigate('/nearby-services')}
                    style={{ border: '1px solid #F6921E' }}
                    className="text_primary2 flex gap-2 items-center max-md:text-xs justify-center rounded-2 plusJakara_medium py-[6px] px-2 px-md-3"
                >
                    See more <FaArrowRight />
                </button>
            </div> */}
            {loading ? (
                <div className="grid5">
                    {[...Array(4)].map((_, i) => (
                        <SkeletonLoader key={i} />
                    ))}
                </div>
            ) : providersData?.length === 0 ? (
                <div className="flex justify-center items-center w-full my-5">
                    <span className="plusJakara_medium md:text-lg">No Service Provider Found</span>
                </div>
            ) : (
                // <div className="grid5">
                //     {providersData?.slice(0, 8).map((category, i) => (
                //         <ProviderPortfolioCard category={category} key={i} />
                //     ))}
                // </div>
                <div className="items_swiper w-full relative">
                    <div className="flex justify-between absolute -top-4 w-full flex-wrap gap-3 items-start">
                        <h6 className="text-xl md:text-3xl min-[1900px]:text-4xl mb-0 plusJakara_semibold text_black">
                            Service Providers <br />
                            <h6 className="text-2xl lg:text-3xl plusJakara_bold text_primary">Portfolio</h6>
                        </h6>
                    </div>
                    <Swiper
                        freeMode={true}
                        modules={[FreeMode, Autoplay, Navigation, Pagination]}
                        navigation={true}
                        className="mySwiper d-flex w-100 gap-3 relative"
                        breakpoints={{
                            320: { slidesPerView: 1 },
                            768: { slidesPerView: 2 },
                            1024: { slidesPerView: 3 },
                            1280: { slidesPerView: 4 },
                        }}
                    >
                        {providersData?.map((category, i) => (
                            <SwiperSlide
                                key={i}
                                style={{ height: 'auto', maxWidth: '400px' }}
                                className="pe-3 relative gap-2"
                            >
                                <ProviderPortfolioCard key={i} category={category} />
                            </SwiperSlide>
                        ))}
                        <SwiperSlide>
                            <div
                                className="flex w-full items-center justify-center"
                                style={{ height: '350px', width: '200px' }}
                            >
                                <button
                                    onClick={() => navigate('/nearby-services')}
                                    style={{ border: '1px solid #F6921E' }}
                                    className="text_primary2 flex gap-2 items-center max-md:text-xs justify-center rounded-2 plusJakara_medium py-[6px] px-2 px-md-3 mt-3"
                                >
                                    See More <FaArrowRight />
                                </button>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            )}
        </Container>
    );
};

export default ProviderPortfolio;
