/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import '../../style/main.css';
import EstimateForm from './estimateForm';
import { useSelector } from 'react-redux';
import UpdrageModal from '../common/upgradeModal';
import StripeConnectError from '../common/stripeConnectError';

const CreateEstimate = () => {
    const navigate = useNavigate()
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const [modal, setmodal] = useState(false)
    const userData = useSelector((state) => state.auth?.userData)

    useEffect(() => {
        if (userData?.sub_type === 'free') {
            setmodal(true)
        } else {
            setmodal(false)
        }
    }, [userData])

    return (
        <>
            <StripeConnectError />
            <main className={` w-full ${(!userData?.accountId_verified && (userData?.sub_type !== 'free' && userData?.sub_type !== 'basic')) ? 'px-3 px-md-5 py-2' : 'p-3 p-md-5'}`}>
                <Container fluid={usertype === 'company'} className={`p-4 ${modal && 'blur-sm'} bg_white rounded-4 w-full`}>
                    <div className="flex flex-col gap-3 mb-4 w-full">
                        <div className="flex gap-4 items-start w-full">
                            <button
                                onClick={() => navigate(-1)}
                                className="bg_primary rounded-3 p-2"
                            >
                                <FaArrowLeft className='text_white' />
                            </button>
                            <h4 className="text_primary mb-0 plusJakara_semibold">Create Estimate</h4>
                        </div>
                    </div>
                    <EstimateForm />
                </Container>

                <UpdrageModal setmodalOpen={setmodal} modalOpen={modal} />
            </main >
        </>
    );
};

export default CreateEstimate;
