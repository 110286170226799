import axiosInstance from "../redux/axiosInstance";

export const createEstimate = async ({ data }) => {
    try {
        const res = await axiosInstance.post(`estimate/create`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};


export const updateEstimate = async ({ data }, id) => {
    try {
        const res = await axiosInstance.put(`estimate/edit/${id}`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};


export const assignOrder = async ({ data }, id) => {
    try {
        const res = await axiosInstance.put(`order/employee/assign-users/${id}`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const updateContractSign = async ({ data }, id, status) => {
    try {
        const res = await axiosInstance.put(`order/${status}/sign/${id}`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const getEstimate = async (type) => {
    try {
        const res = await axiosInstance.get(`estimate/${type}`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const getContract = async (type) => {
    try {
        const res = await axiosInstance.get(`order/${type}/contract/all`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const sendReminder = async ({ data }) => {
    try {
        const res = await axiosInstance.post(`users/reminder`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};
