export const calculateFinalPrice = (
    totalPrice,
    discountPercentage,
    taxPercentage
) => {
    const discountAmount = (totalPrice * discountPercentage) / 100;
    const priceAfterDiscount = totalPrice - discountAmount;
    const taxAmount = (priceAfterDiscount * taxPercentage) / 100;
    const finalPrice = priceAfterDiscount + taxAmount;
    return finalPrice;
};