/* eslint-disable no-unused-vars */
import { Spinner } from 'react-bootstrap';
import { message, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteService } from '../api/service';
import { avataruser, cleaning2, coveravatar } from '../icons/icon';
import { setServiceProvider } from '../redux/generalRedux';

const ServiceItem2 = ({ category, companyService, portfolioPage }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showDelete, setshowDelete] = useState(false)
    const [deleteData, setdeleteData] = useState(null)
    const [loading, setloading] = useState(false)
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const isLogin = useSelector((state) => state.auth?.isLogin)

    const handleDeleteModal = (row) => {
        setshowDelete(true)
        setdeleteData(row)
    }

    const calculateTotal = () => {
        const tax = (category?.totalPrice * Number(category?.tax)) / 100;
        const taxedPrice = category?.totalPrice + tax;
        return (taxedPrice - category?.dis_price)?.toFixed(2);
    };

    const handledelete = async () => {
        setloading(true)
        await deleteService(deleteData?._id).then((res) => {
            if (res.service) {
                message.success('Service deleted successfully')
                setloading(false)
                setshowDelete(false)
            } else {
                setloading(false)
                setshowDelete(false)
                message.error('Failed to delete service')
            }
        }).catch((err) => {
            setloading(false)
            setshowDelete(false)
            console.error("Error deleting service:", err);
        })
    }

    return (
        <div className="bg_white flex flex-col w-full rounded-2 overflow-hidden border relative">
            <div onClick={() => navigate(`/service-detail/${category?._id}`, { state: { serviceDetail: category } })} className="relative cursor-pointer overflow-hidden">
                <div className="bg-overlay"></div>
                <div className="absolute top-2 z-50 right-2 flex justify-end w-full">
                    <div className="rounded-5 px-2 py-1 text-xs text_primary bg_white">{category?.category?.name}</div>
                </div>
                <img src={category?.images ? category?.images[0] : coveravatar} style={{ height: '12rem', cursor: 'pointer', width: '100%', objectFit: 'cover' }} className='relative img_hover' alt="" />
            </div>
            <div
                className='px-3 py-2 flex gap-2 flex-col w-full'>
                {/* {!companyService &&
                    <div className="flex gap-1 items-center">
                        <div style={{ width: '24px' }}>
                            <IoLocationSharp size={20} className='text_primary2' />
                        </div>
                        <span className="text_primary2 plusJakara_regular line-clamp-1">{category?.user?.address || 'Not found'}</span>
                    </div>
                } */}
                <div className="flex justify-between items-end w-full">
                    <div className="flex flex-col w-full gap-1">
                        <h6 onClick={() => navigate(`/service-detail/${category?._id}`, { state: { serviceDetail: category } })} className="plusJakara_medium mb-0 text-lg transition-all cursor-pointer hover:text-[#F6921E] line-clamp-1">{category?.name}</h6>
                        <div className="flex gap-2 flex-wrap items-center">
                            {category?.dis_price && (
                                <h6 className='plusJakara_medium mb-0 text_secondary2' style={{ textDecoration: 'line-through', }}>
                                    ${category?.totalPrice}
                                </h6>
                            )}
                            <h5 className="plusJakara_semibold whitespace-nowrap mb-0 text_primary">
                                ${calculateTotal()}
                            </h5>
                        </div>
                        <span className="text_secondary2 line-clamp-1 text-sm plusJakara_regular">{category?.description}</span>
                    </div>
                </div>
                {!companyService && !portfolioPage && <>
                    <hr className='my-1 text_secondary2' />
                    <div onClick={() => {
                        navigate(`/services/${category?.user?._id}`)
                        dispatch(setServiceProvider(category?.user))
                    }}
                        className="flex gap-2 cursor-pointer items-center">
                        <img src={category?.user?.profilePicture || avataruser} style={{ width: '30px', height: '30px', objectFit: 'cover', borderRadius: "8px" }} alt="" />
                        <span className="text_primary plusJakara_medium">{category?.user?.name}</span>
                    </div>
                </>}
                {companyService &&
                    <div className="flex w-full gap-3">
                        <button onClick={() => handleDeleteModal(category)} className="bg-red-500 text_white w-full rounded-3 py-2 plusJakara_medium">Delete</button>
                        <button onClick={() => navigate(`/service-list/update/${category?._id}`, { state: { serviceData: category } })} className="bg_primary text_white w-full rounded-3 py-2 plusJakara_medium">Update</button>
                    </div>}
            </div>

            <Modal open={showDelete} onCancel={() => setshowDelete(false)} centered footer={false}>
                <div className="flex flex-col gap-3 items-center text-center">
                    <h4 className="text_primary mb-0 plusJakara_bold">Delete Service</h4>
                    <h5 className="text_secondary2 mb-0 plusJakara_regular">Are you sure you want to delete this service?</h5>
                    <div className="flex gap-3 items-center justify-center w-full">
                        <button
                            onClick={handledelete}
                            disabled={loading}
                            className="bg-red-500 px-5 py-2 rounded-3 text_white plusJakara_medium">
                            {loading ? <Spinner size="sm" style={{ color: "white" }} /> : 'Delete'}
                        </button>
                    </div>
                </div>
            </Modal>
        </div >
    )
}

export default ServiceItem2