import { Spinner } from 'react-bootstrap';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { message } from "antd"; // Import Spin for loading indicator
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Label } from 'reactstrap';
import { completeSubscription } from '../../api/stripeApi';
import { setUserData } from '../../redux/loginForm';
import StripeLinkModal from './stripeLinkModal';

const PaymentCheckCheckout = ({ setShowStripe, setopenLinkStripeModal, clientSecret, subscriptionId }) => {
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [messages, _setMessages] = useState('');
  const userData = useSelector((state) => state.auth?.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`);
  };

  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return '';
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);
    try {
      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: { name },
        }
      });
      if (error) {
        setMessage(error.message);
        setIsLoading(false);
        return;
      }
      const data = { subscriptionId };
      await completeSubscription({ data })
        .then((res) => {
          if (res.status === true) {
            dispatch(setUserData(res.user));
            if (!userData?.accountId_verified) {
              setopenLinkStripeModal(true);
            } else {
              setopenLinkStripeModal(false);
            }
            message.success(res.message);
            setShowStripe(false)
          }
          setIsLoading(false);
        })
        .catch((err) => {
          message.error('Payment did not complete. Please try again');
          setIsLoading(false);
        });
    } catch (error) {
      message.error('An error occurred during payment');
      setIsLoading(false); // Stop loading on unexpected errors
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        '::placeholder': { color: '#aab7c4' },
        padding: '10px 12px',
        backgroundColor: '#f7f7f7',
        borderRadius: '4px',
        border: '1px solid #ccc',
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className="payment-form">
        <Label className="plusJakara_medium w-full text_black">
          Full name
        </Label>
        <Input
          type="text"
          id="name"
          required
          placeholder='Your Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="payment-input w-full plusJakara_regular"
        />
        <Label className="plusJakara_medium w-full text_black">
          Card Details
        </Label>
        <div className="card-element-container">
          <CardElement options={cardElementOptions} />
        </div>

        <div className="flex w-full mt-4">
          <button
            className="px-4 py-[12px] w-full rounded bg_primary text_white flex items-center justify-center"
            disabled={isLoading}
          >
            {isLoading ? <Spinner size="sm" className='text_white' style={{ color: 'white' }} /> : 'Subscribe'}
          </button>
        </div>
      </Form>


    </>
  );
};

export default PaymentCheckCheckout;
