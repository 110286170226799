/* eslint-disable no-unused-vars */
import { Col, DatePicker, message, Modal, Row } from 'antd';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Check, Plus } from 'react-feather';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input, Label } from "reactstrap";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../style/swiper.css';
// import * as Yup from "yup"
import { Spinner } from 'react-bootstrap';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import dayjs from 'dayjs';
import moment from 'moment';

import Autocomplete from 'react-google-autocomplete';
import { FaLocationDot, FaPhone } from 'react-icons/fa6';
import { IoCloseSharp } from 'react-icons/io5';
import { MdEmail } from 'react-icons/md';
import { PiDotsSixVerticalBold } from 'react-icons/pi';
import { SlCloudUpload } from 'react-icons/sl';
import { TbCurrentLocation } from 'react-icons/tb';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { createEstimate, updateEstimate } from '../../api/estimateApi';
import { uploadFile } from '../../api/uploadFile';
import { labor1, labor2, mapmarker, material1, material2 } from '../../icons/icon';
import axiosInstance from '../../redux/axiosInstance';
import ClientCard from './clientCard';
import LabourMaterialTableDesign from './labourMaterialTableDesign';
import { updateSchedule } from '../../api/order';
const { RangePicker } = DatePicker;


const EstimateForm = ({ estimateData }) => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const estimateUser = state?.estimateUser || null
    const [latLng, setLatLng] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedDates, setSelectedDates] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [listUsers, setListUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectUser, setSelectUser] = useState(null)
    const [email, setEmail] = useState('')
    const [noUser, setNoUser] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [imageUrls, setImageUrls] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const autocompleteRef2 = useRef()
    const [location, setLocation] = useState(null);
    const [map, setMap] = useState(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_APIKEY,
        libraries: ["places", "maps"]
    });

    useEffect(() => {
        if (estimateUser) {
            setEmail(estimateUser?.otherUser?.email)
            setSelectUser(estimateUser?.otherUser)
        }
    }, [estimateUser])

    const disabledDate = (current) => {
        return current && current < dayjs().endOf('day');
    };
    const today = dayjs().startOf('day').toDate();

    const validationSchema = Yup.object({
        projectTitle: Yup.string()
            .min(3, 'Project Title must be at least 3 characters')
            .required('Project Title is required'),
        projectDescription: Yup.string()
            .min(5, 'Project Description must be at least 5 characters')
            .required('Project Description is required'),
        projectStartDate: Yup.date()
            .required('Project Start Date is required')
            .min(today, 'Project Start Date must be greater than or equal to today'),
        projectEndDate: Yup.date()
            .min(
                Yup.ref('projectStartDate'),
                'End date cannot be before start date'
            )
            .required('Project End Date is required'),
        country: Yup.string().required('Country is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        postalCode: Yup.string()
            .required('Postal Code is required'),
        images: Yup.array()
            .min(1, 'At least one image is required'),
        labour: Yup.array().of(
            Yup.object().shape({
                labourName: Yup.string().required('Labour Name is required'),
                labourUnit: Yup.number().required('Unit is required').positive('Must be positive'),
                labourPrice: Yup.number().required('Price is required').positive('Must be positive'),
                labourTotal: Yup.number().required('Total is required').positive('Must be positive'),
            })
        ),
        material: Yup.array().of(
            Yup.object().shape({
                materialName: Yup.string().required('Material name is required'),
                materialUnit: Yup.number().required('Unit is required').positive('Must be positive'),
                materialPrice: Yup.number().required('Price is required').positive('Must be positive'),
                materialTotal: Yup.number().required('Total is required').positive('Must be positive'),
            })
        ),
    });

    const initialValues = {
        // clientEmail: '',
        projectTitle: '',
        projectStartDate: '',
        tax: '',
        dis_price: '',
        projectEndDate: '',
        country: '',
        city: '',
        state: '',
        postalCode: '',
        images: [],
        labour: [{ id: uuidv4(), laboutUnitType: 'unit', labourName: '', labourUnit: '', labourPrice: '', labourTotal: '', }],
        material: [{ id: uuidv4(), materialUnitType: 'unit', materialName: '', materialUnit: '', materialPrice: '', materialTotal: '' }],
    };

    const handleSubmit = async (values) => {
        const labourData = values.labour?.map((entry, index) => {
            const selectedDate = selectedDates[index] || [];
            return {
                id: uuidv4(),
                labourName: entry.labourName,
                labourUnit: entry.labourUnit,
                startDate: new Date(selectedDate[0]).toISOString() || null,
                endDate: new Date(selectedDate[1]).toISOString() || null,
                labourPrice: entry.labourPrice,
                laboutUnitType: entry.laboutUnitType || 'unit',
                labourTotal: entry.labourTotal,
            };
        });
        const totalPrice = values?.labour?.reduce((sum, labour) => sum + (parseFloat(labour.labourTotal) || 0), 0);
        const totalPrice2 = values?.material?.reduce((sum, material) => sum + (parseFloat(material.materialTotal) || 0), 0);
        const totalPrice3 = totalPrice + totalPrice2;
        // const discountPrice = calculateFinalPrice(totalPrice3, (parseFloat(values?.dis_price) || 0), (parseFloat(values?.tax) || 0));
        // setgrandTotal(discountPrice)
        const submissionData = {
            date: new Date(values?.projectStartDate).toISOString(),
            endDate: new Date(values?.projectEndDate).toISOString(),
            labour: labourData,
            material: values?.material,
            dis_price: values?.dis_price || 0,
            images: imageUrls,
            email: email,
            description: values?.projectDescription,
            name: values?.projectTitle,
            location: {
                lat: values?.lat,
                lng: values?.lng,
                address: values?.address,
                country: values?.country,
                city: values?.city,
                state: values?.state,
                zip: values?.postalCode,
            },
            tax: values?.tax || 0,
            time: new Date().toISOString(),
            to_id: selectUser?._id,
            totalPrice: totalPrice3,
        };
        setIsLoading(true)
        if (estimateData) {
            await updateEstimate({ data: submissionData }, values?._id).then((res) => {
                if (res.success === true) {
                    message.success("Estimate update successfully");
                    setIsLoading(false)
                    navigate('/estimates');
                } else {
                    message.error("Failed to update Estimate");
                    setIsLoading(false)
                }
            }).catch((err) => {
                console.error("Error creating Estimate:", err);
                setIsLoading(false)
            })
        } else {
            await createEstimate({ data: submissionData }).then((res) => {
                if (res.success === true) {
                    if (estimateUser) {
                        updateSchedule(estimateUser?._id).then((res) => {
                            if (res.success === true) {
                                message.success("Estimate created successfully");
                                setIsLoading(false)
                            } else {
                                message.success("Estimate created successfully");
                                setIsLoading(false)
                            }
                        }).catch((err) => {
                            console.error("Error updating Schedule:", err);
                            setIsLoading(false)
                        })
                    }
                    navigate('/estimates');
                } else {
                    message.error("Failed to create Estimate");
                    setIsLoading(false)
                }
            }).catch((err) => {
                console.error("Error creating Estimate:", err);
                setIsLoading(false)
            })
        }
    };

    const goToPreviousStep = () => {
        if (currentStep > 0) {
            setCurrentStep((prevStep) => prevStep - 1);
        }
    };

    const goToNextPage = (validateForm, setErrors, setTouched, currentStep, setCurrentStep) => {
        validateForm().then((errors) => {
            let filteredErrors = { ...errors };
            if (currentStep === 0) {
                delete filteredErrors.labour;
                delete filteredErrors.material;
                delete filteredErrors.tax;
            } else if (currentStep === 1) {
                delete filteredErrors.tax;
            }
            if (Object.keys(filteredErrors).length === 0) {
                setCurrentStep((prev) => prev + 1);
            } else {
                setTouched({
                    projectTitle: true,
                    projectDescription: true,
                    projectStartDate: true,
                    projectEndDate: true,
                    country: true,
                    city: true,
                    state: true,
                    postalCode: true,
                    images: true,
                });
                setErrors(filteredErrors);
            }
        });
    };

    const getClients = async (e) => {
        const email = e.target.value.trim()
        setEmail(email);
        if (email.length < 5) {
            console.log("Email must be at least 5 characters long");
            return;
        }
        setLoading(true);
        try {
            const res = await axiosInstance.get(`users/all/1/${email}`);
            if (res?.data?.users?.length) {
                setListUsers(res?.data?.users);
                setNoUser(false);
            } else
                setNoUser(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setNoUser(false);
            console.log("==========error:", error.response?.data);
        }
    };

    const handleSelectImages = (values, setFieldValue) => async (event) => {
        const selectedFiles = event.target.files;
        const updatedImageUrls = [...imageUrls];
        for (let i = 0; i < selectedFiles.length; i++) {
            const selectedFile = selectedFiles[i];
            const formData = new FormData();
            formData.append('image', selectedFile);
            setFileLoading(true);
            try {
                const res = await uploadFile({ data: formData });
                if (res.image) {
                    setFileLoading(false);
                    updatedImageUrls.push(res.image);
                    setFieldValue('images', [...values.images, res.image]);
                }
            } catch (err) {
                setFileLoading(false);
                console.error(`Error uploading file ${selectedFile.name}:`, err);
            }
        }
        setImageUrls(updatedImageUrls);
    };

    const removeImage = (index, setFieldValue) => {
        const updatedImages = imageUrls.filter((_, i) => i !== index);
        setImageUrls(updatedImages);
        setFieldValue('images', updatedImages);
    };

    const extractAddressComponents = (place) => {
        let city = '';
        let state = '';
        let country = '';
        let postalCode = '';

        place.address_components.forEach(component => {
            if (component.types.includes("locality")) {
                city = component.long_name;
            }
            if (component.types.includes("administrative_area_level_1")) {
                state = component.long_name;
            }
            if (component.types.includes("postal_code")) {
                postalCode = component.long_name;
            }
            if (component.types.includes("country")) {
                country = component.long_name;
            }
        });

        return { city, state, postalCode, country };
    };

    const handlePlaceSelected = (place, setFieldValue) => {
        if (place && place.formatted_address) {
            const { city, state, postalCode, country } = extractAddressComponents(place);
            const lat = place.geometry?.location.lat();
            const lng = place.geometry?.location.lng();
            if (!isNaN(lat) && !isNaN(lng)) {
                setFieldValue('address', place.formatted_address);
                setFieldValue('lat', lat);
                setFieldValue('lng', lng);
                setFieldValue('city', city);
                setFieldValue('state', state);
                setFieldValue('country', country);
                setFieldValue('postalCode', postalCode);
            } else {
                setFieldValue('address', 'Invalid coordinates');
            }
        } else {
            setFieldValue('address', 'Please select a valid address');
        }
    };

    const handleSetLocation = (latLng, setFieldValue) => {
        if (!latLng) return;

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK' && results[0]) {
                const place = results[0];
                const { city, state, postalCode, country } = extractAddressComponents(place);

                setFieldValue('address', place.formatted_address);
                setLocation(place?.formatted_address);
                setFieldValue('lat', latLng.lat);
                setFieldValue('lng', latLng.lng);
                setFieldValue('city', city);
                setFieldValue('state', state);
                setFieldValue('country', country);
                setFieldValue('postalCode', postalCode);
                setShowModal(false);
            } else {
                console.error(`Geocode failed: ${status}`);
                setFieldValue('address', 'Failed to retrieve address from coordinates');
            }
        });
    };

    const loadCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLatLng = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setLatLng(userLatLng);
                    if (map) {
                        map.panTo(userLatLng);
                    }
                },
                (error) => {
                    console.error("Geolocation failed: ", error);
                }
            );
        }
    };

    const onLoad = (mapInstance) => {
        setMap(mapInstance);
        loadCurrentLocation();
    };

    return (
        <div className="flex flex-col gap-3 rounded-3 w-full">
            <div className="flex gap-1 flex-col max-w-4xl m-auto mb-4 items-center w-full">
                <div className="steps-container flex justify-between px-3 items-center w-full">
                    {/* Step 1 */}
                    <div className="step flex items-center justify-center flex-col">
                        <div className={`step-icon w-8 h-8 rounded-full ${currentStep >= 0 ? "bg_primary" : "bg-gray-300"} flex items-center justify-center`}>
                            <Check color='#fff' size={18} />
                        </div>
                    </div>
                    <div className="line w-full h-0 border-dashed border-t border-gray-300 mx-2"></div>

                    {/* Step 2 */}
                    <div className="step flex items-center justify-center flex-col">
                        <div className={`step-icon w-8 h-8 rounded-full ${currentStep >= 1 ? "bg_primary" : "bg-gray-300"} flex items-center justify-center`}>
                            <Check color='#fff' size={18} />
                        </div>
                    </div>
                    <div className="line w-full h-0 border-dashed border-t border-gray-300 mx-2"></div>

                    {/* Step 2 */}
                    <div className="step flex items-center justify-center flex-col">
                        <div className={`step-icon w-8 h-8 rounded-full ${currentStep >= 2 ? "bg_primary" : "bg-gray-300"} flex items-center justify-center`}>
                            <Check color='#fff' size={18} />
                        </div>
                    </div>

                    <div className="line w-full h-0 border-dashed border-t border-gray-300 mx-2"></div>

                    {/* Step 3 */}
                    <div className="step flex items-center justify-center flex-col">
                        <div className={`step-icon w-8 h-8 rounded-full ${currentStep >= 3 ? "bg_primary" : "bg-gray-300"} flex items-center justify-center`}>
                            <Check color='#fff' size={18} />
                        </div>
                    </div>
                </div>
                <div className="steps-container flex justify-between items-start w-full">
                    <h6 className="text_primary text-sm plusJakara_medium whitespace-nowrap mt-2">Estimate Info</h6>
                    <h6 className="text_primary text-sm plusJakara_medium mt-2">Labour & <br />Material</h6>
                    <h6 className="text_primary text-sm plusJakara_medium mt-2">Scheduling</h6>
                    <h6 className="text_primary text-sm plusJakara_medium mt-2">Overview</h6>
                </div>
            </div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, validateForm, setErrors, setTouched, errors, touched }) => (
                    <Form>
                        {currentStep === 0 &&
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <div className="w-full px-0">
                                        <Label className="form-label text_primary plusJakara_semibold text-[16px]" >
                                            Clients Detail
                                        </Label>
                                    </div>
                                    <div className="mb-4 position-relative">
                                        {/* <Label className="form-label text_secondary2 max-md:text-smplusJakara_medium">
                                        Images
                                    </Label> */}
                                        <div className="flex flex-col mb-3 gap-2">
                                            <div className="flex flex-wrap gap-3">
                                                {imageUrls.map((url, index) => (
                                                    <div key={index} className="relative">
                                                        <img
                                                            src={url}
                                                            alt="Preview"
                                                            style={{ width: "80px", height: "60px", objectFit: "cover" }}
                                                            className="rounded-4 object-cover relative"
                                                        />
                                                        <IoCloseSharp
                                                            onClick={() => removeImage(index, setFieldValue)}
                                                            style={{
                                                                position: "absolute",
                                                                top: "0px",
                                                                right: "0px",
                                                                cursor: "pointer",
                                                                backgroundColor: "white",
                                                                borderRadius: "3px",
                                                                padding: "2px",
                                                            }}
                                                            size={18}
                                                        />
                                                    </div>
                                                ))}
                                                <label
                                                    style={{ cursor: "pointer" }}
                                                    htmlFor="fileInput"
                                                    className="cursor-pointer"
                                                >
                                                    {fileLoading ? (
                                                        <div
                                                            style={{ width: "80px", height: "60px" }}
                                                            className="border rounded-3 bg_light text_primary d-flex justify-content-center align-items-center"
                                                        >
                                                            <Spinner />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{ width: "80px", height: "60px" }}
                                                            className="border rounded-3 bg_light text_primary flex justify-center items-center"
                                                        >
                                                            <SlCloudUpload size={24} />
                                                        </div>
                                                    )}
                                                </label>
                                                <Input
                                                    type="file"
                                                    multiple
                                                    accept="image/*"
                                                    id="fileInput"
                                                    className="visually-hidden"
                                                    onChange={handleSelectImages(values, setFieldValue)}
                                                />
                                            </div>
                                            <ErrorMessage name={`images`} component="div" className="text-danger text-xs plusJakara_regular" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <Label className="form-label text_secondary2 max-md:text-smtext-sm plusJakara_regular" >
                                            Client Email
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                            value={email}
                                            disabled={estimateUser?.otherUser}
                                            onChange={getClients}
                                            style={{ padding: '12px' }}
                                            placeholder="Client Email"
                                        />
                                        {loading ? (
                                            <div className="my-4 w-full flex justify-center items-center">
                                                <Spinner size="sm" className='text_primary' />
                                            </div>
                                        ) : listUsers?.length ? (
                                            <div
                                                style={{
                                                    backgroundColor: 'white',
                                                    borderWidth: '0.6px',
                                                    borderColor: '#d3d3d3',
                                                    padding: '10px 15px',
                                                    borderRadius: '10px',
                                                    paddingBottom: 0,
                                                }}
                                            >
                                                {listUsers?.map((item, i) => (
                                                    <div
                                                        key={i}
                                                        className="cursor-pointer"
                                                        style={{ marginBottom: '10px' }}
                                                        onClick={() => {
                                                            setEmail(item?.email);
                                                            setSelectUser(item);
                                                            setListUsers([]);
                                                        }}
                                                    >
                                                        {item?.email}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : noUser ? (
                                            <div
                                                style={{
                                                    backgroundColor: 'white',
                                                    borderWidth: '0.6px',
                                                    borderColor: '#d3d3d3',
                                                    padding: '10px 15px',
                                                    borderRadius: '10px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <span>No Client Found</span>
                                            </div>
                                        ) : null}
                                        {selectUser?._id && (
                                            <div style={{ marginBottom: '20px' }}>
                                                <ClientCard item={selectUser} />
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex gap-2 mt-3 items-center flex-wrap flex-lg-nowrap mb-3">
                                        <div className="flex flex-col w-full">
                                            <Label className="form-label text_secondary2 max-md:text-smtext-sm plusJakara_regular" >
                                                Project Title
                                            </Label>
                                            <Field
                                                type="text"
                                                className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                                name="projectTitle"
                                                id="projectTitle"
                                                style={{ padding: '12px' }}
                                                placeholder="Mic john Bathroom"
                                            />
                                            <ErrorMessage name="projectTitle" component="div" className="text-danger text-xs plusJakara_regular" />
                                        </div>
                                    </div>
                                    <div className="flex gap-2 mt-3 items-center flex-wrap flex-lg-nowrap mb-3">
                                        <div className="flex flex-col w-full">
                                            <Label className="form-label text_secondary2 max-md:text-smtext-sm plusJakara_regular" >
                                                Project Start Date
                                            </Label>
                                            <Field
                                                type="date"
                                                className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                                name="projectStartDate"
                                                id="projectStartDate"
                                                style={{ padding: '12px' }}
                                                placeholder="Client Email"
                                            />
                                            <ErrorMessage name="projectStartDate" component="div" className="text-danger text-xs plusJakara_regular" />
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <Label className="form-label text_secondary2 max-md:text-smtext-sm plusJakara_regular" >
                                                Project End Date
                                            </Label>
                                            <Field
                                                type="date"
                                                className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                                name="projectEndDate"
                                                id="projectEndDate"
                                                style={{ padding: '12px' }}
                                                placeholder="Project Title"
                                            />
                                            <ErrorMessage name="projectEndDate" component="div" className="text-danger text-xs plusJakara_regular" />
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={24} md={12}>
                                    <div className="w-full px-0">
                                        <Label className="form-label text_primary plusJakara_semibold text-[16px]" htmlFor="address">
                                            Project / Estimate Location
                                        </Label>
                                        <button
                                            type="button"
                                            style={{ border: '2px solid #FF8E00' }}
                                            className="rounded-5 my-3 plusJakara_medium text_primary2 w-full py-[10px] flex justify-center gap-2"
                                            onClick={() => setShowModal(true)}
                                        >
                                            <TbCurrentLocation size={18} color='#F6921E' className="input-icon" />
                                            Set Location on Map
                                        </button>
                                        {location ? (
                                            <Field
                                                type="text"
                                                className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                                name="country"
                                                id="country"
                                                onChange={(e) => setLocation(e.target.value)}
                                                style={{ padding: '12px' }}
                                                placeholder="Country"
                                                value={location}
                                            />
                                        ) : (
                                            <>
                                                <Autocomplete
                                                    id="address"
                                                    name="address"
                                                    apiKey={process.env.REACT_APP_APIKEY}
                                                    className="w-full border search_input rounded-lg plusJakara_regular ps-3 py-[14px]"
                                                    ref={autocompleteRef2}
                                                    defaultValue={''}
                                                    placeholder="Address here..."
                                                    options={{ types: ['address'] }}
                                                    onPlaceSelected={(place) => handlePlaceSelected(place, setFieldValue)}
                                                />
                                                {touched.address && errors.address && (
                                                    <div className="text-danger text-xs plusJakara_regular">
                                                        {errors.address}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className="flex gap-2 mt-3 items-center flex-wrap flex-lg-nowrap mb-3">
                                        <div className="flex flex-col w-full">
                                            <Field
                                                type="text"
                                                className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                                name="country"
                                                id="country"
                                                style={{ padding: '12px' }}
                                                placeholder="Country"
                                            />
                                            <ErrorMessage name="country" component="div" className="text-danger text-xs plusJakara_regular" />
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <Field
                                                type="text"
                                                className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                                name="city"
                                                id="city"
                                                style={{ padding: '12px' }}
                                                placeholder="City"
                                            />
                                            <ErrorMessage name="city" component="div" className="text-danger text-xs plusJakara_regular" />
                                        </div>
                                    </div>

                                    <div className="flex gap-2 items-center flex-wrap flex-lg-nowrap mb-3">
                                        <div className="flex flex-col w-full">
                                            <Field
                                                type="text"
                                                className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                                name="state"
                                                id="state"
                                                style={{ padding: '12px' }}
                                                placeholder="State"
                                            />
                                            <ErrorMessage name="state" component="div" className="text-danger text-xs plusJakara_regular" />
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <Field
                                                type="text"
                                                className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                                name="postalCode"
                                                id="postalCode"
                                                style={{ padding: '12px' }}
                                                placeholder="Zip"
                                            />
                                            <ErrorMessage name="postalCode" component="div" className="text-danger text-xs plusJakara_regular" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full">
                                        <Label className="plusJakara_medium">Description</Label>
                                        <Field
                                            as='textarea'
                                            name={`projectDescription`}
                                            style={{ minHeight: '120px' }}
                                            className="form-control"
                                            placeholder="Description"
                                            type="text"
                                        />
                                        <ErrorMessage name={`projectDescription`} component="div" className="text-danger text-xs plusJakara_regular" />
                                    </div>
                                </Col>
                            </Row>}
                        {currentStep === 1 &&
                            <>
                                <div
                                    style={{ backgroundColor: "#f4f4f4", }}
                                    className="flex flex-col w-full relative shadow-sm p-2 rounded-3 gap-2 mb-3 items-center justify-between">
                                    <div className="flex gap-3 gap-md-5 items-center w-full">
                                        <div className="flex mt-1 gap-1 items-center">
                                            <PiDotsSixVerticalBold size={20} />
                                            <h6 className="text_secondary whitespace-nowrap text-sm mb-0 plusJakara_medium">Labour & Material</h6>
                                        </div>
                                        <div className="flex gap-3 w-full">
                                            <div className="flex items-center gap-2">
                                                <img src={labor2} style={{ height: "16px", width: "auto" }} alt="" />
                                                <span className="text_secondary text-sm plusJakara_medium">{values?.labour.length} Labors</span>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <img src={material2} style={{ height: "16px", width: "auto" }} alt="" />
                                                <span className="text_secondary text-sm plusJakara_medium">{values?.material.length} Materials</span>
                                            </div>
                                        </div>
                                    </div>
                                    <FieldArray name="labour">
                                        {({ push, remove }) => (
                                            <>
                                                {values.labour.map((entry, index) => (
                                                    <div
                                                        className='w-full relative'
                                                        key={index}
                                                    >
                                                        <Col xs={24}>
                                                            <div className="flex flex-col gap-3 items-start">
                                                                <div className="flex justify-between bg_white items-start rounded-2 p-3 w-full">
                                                                    <Col xs={13} className="w-full mt-[22px]">
                                                                        <div className="flex gap-2 items-start">
                                                                            <span className='text_secondary2 mt-[10px] whitespace-nowrap plusJakara_medium text-xs'>LABOUR</span>
                                                                            <div className="flex flex-col w-full">
                                                                                <Field
                                                                                    name={`labour[${index}].labourName`}
                                                                                    className="form-control border text-xs rounded-1 w-full"
                                                                                    style={{ backgroundColor: '#fefefe' }}
                                                                                    placeholder="Description"
                                                                                />
                                                                                <ErrorMessage name={`labour[${index}].labourName`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={2} className='w-full'>
                                                                        <span className='text_secondary2 plusJakara_medium text-xs'>UNIT TYPE</span>
                                                                        <Field
                                                                            as="select"
                                                                            className="border text-sm rounded-1 w-full"
                                                                            style={{ backgroundColor: '#fefefe', padding: '8px' }}
                                                                            name={`labour[${index}].laboutUnitType`}
                                                                        >
                                                                            <option value="unit">Unit</option>
                                                                            <option value="sqrft">Sqrft</option>
                                                                            <option value="inch">Inch</option>
                                                                        </Field>
                                                                        <ErrorMessage name={`labour[${index}].laboutUnitType`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </Col>
                                                                    <Col xs={2} className='w-full'>
                                                                        <span className='text_secondary2 plusJakara_medium text-xs'>{entry?.laboutUnitType?.toUpperCase()}</span>
                                                                        <Field
                                                                            name={`labour[${index}].labourUnit`}
                                                                            className="form-control border text-sm rounded-1 w-full"
                                                                            style={{ backgroundColor: '#fefefe' }}
                                                                            placeholder="Unit"
                                                                            type="number"
                                                                            onInput={(e) => {
                                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                            }}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`labour[${index}].labourUnit`, e.target.value);
                                                                                setFieldValue(`labour[${index}].labourTotal`, e.target.value * values.labour[index].labourPrice || 0);
                                                                            }}
                                                                        />
                                                                        <ErrorMessage name={`labour[${index}].labourUnit`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </Col>
                                                                    <Col xs={2} className='w-full'>
                                                                        <span className='text_secondary2 plusJakara_medium text-xs'>PER PRICE</span>
                                                                        <Field
                                                                            name={`labour[${index}].labourPrice`}

                                                                            className="form-control border text-sm rounded-1"
                                                                            style={{ backgroundColor: '#fefefe' }}
                                                                            placeholder="Price"
                                                                            type="number"
                                                                            onInput={(e) => {
                                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                            }}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`labour[${index}].labourPrice`, e.target.value);
                                                                                setFieldValue(`labour[${index}].labourTotal`, e.target.value * values.labour[index].labourUnit || 0);
                                                                            }}
                                                                        />
                                                                        <ErrorMessage name={`labour[${index}].labourPrice`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </Col>
                                                                    <Col xs={2} className='w-full'>
                                                                        <span className='text_secondary2 plusJakara_medium text-xs'>TOTAL PRICE</span>
                                                                        <Field
                                                                            name={`labour[${index}].labourTotal`}
                                                                            className="form-control border text-sm rounded-1"
                                                                            style={{ backgroundColor: '#f4f4f4' }}
                                                                            placeholder="Total Price"
                                                                            type="number"
                                                                            disabled
                                                                        />
                                                                        <ErrorMessage name={`labour[${index}].labourTotal`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </Col>
                                                                    <Col xs={2}>
                                                                        <div className="flex gap-2 w-full items-center justify-start mt-3">
                                                                            {index === 0 && (
                                                                                <button
                                                                                    style={{ backgroundColor: '#f4f4f4' }}
                                                                                    className="text_secondary px-1 flex gap-1 items-center py-[10px] rounded-1 plusJakara_medium"
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        push({
                                                                                            id: uuidv4(),
                                                                                            laborName: '',
                                                                                            laborUnit: '',
                                                                                            laboutUnitType: 'unit',
                                                                                            laborPrice: '',
                                                                                            laborTotal: '',
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    <div className="w-5">
                                                                                        <Plus size={18} />
                                                                                    </div>
                                                                                    <img src={labor1} style={{ height: '16px' }} alt="" />
                                                                                    Labour
                                                                                </button>)}
                                                                            {index > 0 && (
                                                                                // <div className="py-2">
                                                                                <button
                                                                                    style={{ backgroundColor: '#f4f4f4' }}
                                                                                    type="button"
                                                                                    className="px-2 text-red-500 whitespace-nowrap flex gap-1 items-center py-[10px] rounded-1 plusJakara_medium"
                                                                                    onClick={() => remove(index)}
                                                                                >
                                                                                    Delete Labour
                                                                                </button>
                                                                                // </div>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </FieldArray>
                                    <FieldArray name="material">
                                        {({ push, remove }) => (
                                            <>
                                                {values.material.map((entry, index) => (
                                                    <div
                                                        className='w-full relative'
                                                        key={index}
                                                    >
                                                        <Col xs={24}>
                                                            <div className="flex flex-col gap-1 items-start">
                                                                <div className="flex justify-between bg_white items-start rounded-2 p-2 w-full">
                                                                    <Col xs={13} className="w-full mt-[22px]">
                                                                        <div className="flex gap-2 items-start">
                                                                            <span className='text_secondary2 mt-[10px] whitespace-nowrap plusJakara_medium text-xs'>MATERIAL</span>
                                                                            <div className="flex flex-col w-full">
                                                                                <Field
                                                                                    name={`material[${index}].materialName`}
                                                                                    className="form-control border text-xs rounded-1 w-full"
                                                                                    style={{ backgroundColor: '#fefefe' }}
                                                                                    placeholder="Description"
                                                                                />
                                                                                <ErrorMessage name={`material[${index}].materialName`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs={2} className='w-full'>
                                                                        <span className='text_secondary2 plusJakara_medium text-xs'>UNIT TYPE</span>
                                                                        <Field
                                                                            as="select"
                                                                            className="border text-xs rounded-1 w-full"
                                                                            style={{ backgroundColor: '#fefefe', padding: '8px' }}
                                                                            name={`material[${index}].materialUnitType`}
                                                                        >
                                                                            <option value="unit">Unit</option>
                                                                            <option value="sqrft">Sqrft</option>
                                                                            <option value="inch">Inch</option>
                                                                        </Field>
                                                                        <ErrorMessage name={`material[${index}].materialUnitType`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </Col>
                                                                    <Col xs={2} className='w-full'>
                                                                        <span className='text_secondary2 plusJakara_medium text-xs'>{entry?.materialUnitType?.toUpperCase()}</span>
                                                                        <Field
                                                                            name={`material[${index}].materialUnit`}
                                                                            className="form-control border text-xs rounded-1 w-full"
                                                                            style={{ backgroundColor: '#fefefe' }}
                                                                            placeholder="Unit"
                                                                            type="number"
                                                                            onInput={(e) => {
                                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                            }}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`material[${index}].materialUnit`, e.target.value);
                                                                                setFieldValue(`material[${index}].materialTotal`, e.target.value * values.material[index].materialPrice || 0);
                                                                            }}
                                                                        />
                                                                        <ErrorMessage name={`material[${index}].materialUnit`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </Col>
                                                                    <Col xs={2} className='w-full'>
                                                                        <span className='text_secondary2 plusJakara_medium text-xs'>PER PRICE</span>
                                                                        <Field
                                                                            name={`material[${index}].materialPrice`}

                                                                            className="form-control border text-xs rounded-1"
                                                                            style={{ backgroundColor: '#fefefe' }}
                                                                            placeholder="Price"
                                                                            type="number"
                                                                            onInput={(e) => {
                                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                            }}
                                                                            onChange={(e) => {
                                                                                setFieldValue(`material[${index}].materialPrice`, e.target.value);
                                                                                setFieldValue(`material[${index}].materialTotal`, e.target.value * values.material[index].materialUnit || 0);
                                                                            }}
                                                                        />
                                                                        <ErrorMessage name={`material[${index}].materialPrice`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </Col>
                                                                    <Col xs={2} className='w-full'>
                                                                        <span className='text_secondary2 plusJakara_medium text-xs'>TOTAL PRICE</span>
                                                                        <Field
                                                                            name={`material[${index}].materialTotal`}
                                                                            className="form-control border text-xs rounded-1"
                                                                            style={{ backgroundColor: '#f4f4f4' }}
                                                                            placeholder="Total Price"
                                                                            type="number"
                                                                            disabled
                                                                        />
                                                                        <ErrorMessage name={`material[${index}].materialTotal`} component="div" className="text-danger text-xs plusJakara_regular" />
                                                                    </Col>
                                                                    <Col xs={2}>
                                                                        <div className="flex gap-2 w-full items-center justify-start mt-3">
                                                                            {index === 0 && (
                                                                                <button
                                                                                    style={{ backgroundColor: '#f4f4f4' }}
                                                                                    className="text_secondary px-1 flex gap-1 items-center py-[10px] rounded-1 plusJakara_medium"
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        push({
                                                                                            id: uuidv4(),
                                                                                            materialName: '',
                                                                                            materialUnit: '',
                                                                                            materialUnitType: 'unit',
                                                                                            materialPrice: '',
                                                                                            materialTotal: '',
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    <div className="w-5">
                                                                                        <Plus size={18} />
                                                                                    </div>
                                                                                    <img src={material1} style={{ height: '16px' }} alt="" />
                                                                                    Material
                                                                                </button>
                                                                            )}
                                                                            {index > 0 && (
                                                                                // <div className="py-2">
                                                                                <button
                                                                                    style={{ backgroundColor: '#f4f4f4' }}
                                                                                    type="button"
                                                                                    className="px-2 text-red-500 whitespace-nowrap flex gap-1 items-center py-[10px] rounded-1 plusJakara_medium"
                                                                                    onClick={() => remove(index)}
                                                                                >
                                                                                    Delete Material
                                                                                </button>
                                                                                // </div>
                                                                            )}
                                                                        </div>
                                                                    </Col>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                                <div className="flex justify-end w-full">
                                    <Col xs={24} md={8} className="flex flex-col gap-2 px-3 py-1 w-full">
                                        <h6 className="text_secondary plusJakara_medium">Tax & Discount</h6>
                                        <div className="flex mb-3 gap-2 flex-wrap items-start w-full">
                                            <div className="w-full">
                                                <Label
                                                    className="form-label text_secondary2 max-md:text-smtext-sm mb-1 plusJakara_medium"
                                                    htmlFor="dis_price"
                                                >
                                                    Discount
                                                </Label>
                                                <Field
                                                    name={`dis_price`}
                                                    style={{ padding: '8px' }}
                                                    className="form-control text-sm"
                                                    placeholder="0%"
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                        if (parseFloat(e.target.value) > 100) {
                                                            e.target.value = 100;
                                                        }
                                                    }}
                                                    type="number"
                                                />
                                                <ErrorMessage name={`dis_price`} component="div" className="text-danger text-xs plusJakara_regular" />
                                            </div>
                                            <div className="w-full">
                                                <Label
                                                    className="form-label text_secondary2 max-md:text-smtext-sm mb-1 plusJakara_medium"
                                                    htmlFor="tax"
                                                >
                                                    Tax
                                                </Label>
                                                <Field
                                                    name={`tax`}
                                                    style={{ padding: '8px' }}
                                                    className="form-control text-sm"
                                                    placeholder="0%"
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                        if (parseFloat(e.target.value) > 100) {
                                                            e.target.value = 100;
                                                        }
                                                    }}
                                                    type="number"
                                                />
                                                <ErrorMessage name={`tax`} component="div" className="text-danger text-xs plusJakara_regular" />
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </>}
                        {currentStep === 2 &&
                            <div className='w-full'>
                                <div className="flex justify-between gap-4 mb-4 w-full flex-wrap flex-lg-nowrap">
                                    <div className="flex flex-col gap-1 w-full">
                                        <h6 className="text_secondary2 mb-0 plusJakara_semibold">Project Title</h6>
                                        <span className="text_black mb-2 plusJakara_semibold text-sm">{values?.projectTitle}</span>
                                        <h6 className="text_secondary2 mb-0 plusJakara_semibold">Project Description</h6>
                                        <span className="text_black mb-2 plusJakara_semibold text-sm">{values?.projectDescription}</span>
                                    </div>
                                    <div className="flex flex-col gap-1 w-full">
                                        <h6 className="text_secondary2 mb-0 plusJakara_semibold">Project Start Date</h6>
                                        <span className="text_black mb-2 plusJakara_semibold text-sm">{moment(values?.projectStartDate).format('ddd DD MMM YYYY')}</span>
                                        <h6 className="text_secondary2 mb-0 plusJakara_semibold">Project End Date</h6>
                                        <span className="text_black mb-2 plusJakara_semibold text-sm">{moment(values?.projectEndDate).format('ddd DD MMM YYYY')}</span>
                                    </div>
                                </div>
                                <Label
                                    className="form-label text_secondary2 max-md:text-smplusJakara_medium"
                                    htmlFor="scheduling"
                                >
                                    Scheduling
                                </Label>
                                <div className="flex justify-between flex-wrap gap-3 w-full">
                                    {values?.labour.map((category, index) => (
                                        <div key={index} className="w-full md:w-[48%] bg_light rounded-3 px-3 py-2 flex justify-between items-center">
                                            <h6 className="text_primary mb-0 w-full plusJakara_bold">{category.labourName}</h6>
                                            <div className="flex gap-3 w-full justify-end">
                                                <RangePicker
                                                    required
                                                    maxDate={dayjs(values?.projectEndDate)}
                                                    disabledDate={disabledDate}
                                                    onChange={(dates) => {
                                                        setSelectedDates((prev) => ({
                                                            ...prev,
                                                            [index]: dates,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>}
                        {currentStep === 3 &&
                            <>
                                <div className="flex justify-between gap-4 w-full flex-wrap flex-lg-nowrap">
                                    <div className="flex gap-3 items-start w-full">
                                        <img src={selectUser?.profilePicture} style={{ width: '120px', height: '100px', objectFit: 'cover', borderRadius: '12px' }} alt="" />
                                        <div className="flex flex-col w-full">
                                            <h5 className="text_black plusJakara_semibold">{selectUser?.name}</h5>
                                            <div className="flex gap-2 items-center">
                                                <FaLocationDot className='text_primary' />
                                                <span className="text_secondary2 plusJakara_medium">{selectUser?.address}</span>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                                <FaPhone className='text_primary' />
                                                <span className="text_secondary2 plusJakara_medium">{selectUser?.phone}</span>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                                <MdEmail className='text_primary' />
                                                <span className="text_secondary2 plusJakara_medium">{selectUser?.email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-3 items-start justify-end w-full">
                                        <div className="flex justify-between gap-3 gap-md-5 items-center flex-wrap">
                                            <div className="flex flex-col gap-2">
                                                <h6 className="text_secondary2 plusJakara_semibold">Project Start Date</h6>
                                                <span className="text_black plusJakara_bold">{moment(values?.projectStartDate).format('ddd DD MMM YYYY')}</span>
                                            </div>
                                            <div className="flex flex-col gap-2">
                                                <h6 className="text_secondary2 plusJakara_semibold">Project End Date</h6>
                                                <span className="text_black plusJakara_bold">{moment(values?.projectEndDate).format('ddd DD MMM YYYY')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex mt-4 flex-wrap gap-3 flex-col flex-md-nowrap justify-between w-full">
                                    <hr style={{ color: "#828282" }} />
                                    <Col xs={24} md={11} xl={18}>
                                        <div className="flex flex-col">
                                            <h5 className="text_primary mb-3 plusJakara_semibold">Description</h5>
                                            <h6 className="text_secondary plusJakara_regular">{values?.projectDescription}</h6>
                                        </div>
                                    </Col>
                                </div>
                                <hr style={{ color: "#828282" }} />
                                <div className="flex flex-col gap-1 mb-4 w-full">
                                    <Label
                                        className="form-label text_secondary2 max-md:text-smplusJakara_medium"
                                        htmlFor="scheduling"
                                    >
                                        Scheduling
                                    </Label>
                                    <div className="flex justify-between flex-wrap gap-3 w-full">
                                        {values?.labour?.map((category, index) => {
                                            const selectedDate = selectedDates[index] || []
                                            return (
                                                <div key={index} className="w-full md:w-[48%] bg_light border rounded-3 px-3 py-2 flex justify-between items-center">
                                                    <div className="flex flex-col w-full gap-1">
                                                        <h6 className="text_secondary2 mb-0 w-full text-sm plusJakara_semibold">LABOUR</h6>
                                                        <h6 className="text_primary mb-0 w-full plusJakara_bold">{category.labourName}</h6>
                                                    </div>
                                                    <div className="flex gap-2 items-center w-full justify-end">
                                                        <span className="text_secondary2 text-sm whitespace-nowrap plusJakara_medium">
                                                            {moment(selectedDate[0]).format('ddd DD MMM YYYY')}
                                                        </span>
                                                        <span className="text_black text-sm plusJakara_bold">TO</span>
                                                        <span className="text_secondary2 text-sm whitespace-nowrap plusJakara_medium">
                                                            {moment(selectedDate[1]).format('ddd DD MMM YYYY')}
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="flex w-full flex-col gap-4">
                                    <LabourMaterialTableDesign
                                        labour={values?.labour}
                                        material={values?.material}
                                        dis_price={values?.dis_price}
                                        tax={values?.tax}
                                    />
                                </div>
                            </>
                        }
                        <div className={`flex ${currentStep === 0 ? 'justify-end' : 'justify-between'} w-full mt-4`}>
                            {currentStep > 0 &&
                                <div className="flex justify-start w-full">
                                    <button
                                        type='button'
                                        className="w-fit rounded-2 plusJakara_medium text-sm text_primary"
                                        style={{ width: '150px', padding: '12px', border: '1px solid #003F7D' }}
                                        onClick={goToPreviousStep}
                                    >
                                        Back
                                    </button>
                                </div>}
                            {currentStep < 3 && (
                                <div className="flex justify-end w-full">
                                    <button
                                        type="button"
                                        className="w-fit rounded-2 bg_primary plusJakara_medium text-sm text_white"
                                        style={{ width: '150px', padding: '12px' }}
                                        onClick={() => goToNextPage(validateForm, setTouched, setErrors, currentStep, setCurrentStep)}
                                    >
                                        Continue
                                    </button>
                                </div>
                            )}
                            {currentStep === 3 && (
                                <div className="flex justify-end w-full">
                                    <button
                                        className="w-fit rounded-2 bg_primary plusJakara_medium text-sm text_white"
                                        disabled={isLoading}
                                        style={{ width: '150px', padding: '12px' }}
                                        type="submit"
                                    >
                                        {isLoading ? <Spinner size="sm"></Spinner> : " Send Estimate"}
                                    </button>
                                </div>
                            )}
                        </div>

                        <Modal
                            zIndex={999}
                            width={600}
                            open={showModal}
                            footer={null}
                            onCancel={() => setShowModal(false)}
                            centered
                        >
                            <h5 className="plusJakara_medium mt-3 mb-2 text_primary">Select Location</h5>

                            {isLoaded && (
                                <div className="rounded-3 overflow-hidden" style={{ width: '100%', height: '400px' }}>
                                    <GoogleMap
                                        center={{ lat: latLng?.lat, lng: latLng?.lng }}
                                        zoom={10}
                                        mapContainerStyle={{ width: '100%', height: '100%' }}
                                        onLoad={onLoad}
                                    >
                                        {latLng && (
                                            <Marker
                                                icon={{
                                                    url: mapmarker,
                                                    scaledSize: new window.google.maps.Size(30, 30),
                                                }}
                                                position={{ lat: latLng.lat, lng: latLng.lng }}
                                                draggable={true}
                                                onDragEnd={(e) => {
                                                    const draggedLatLng = {
                                                        lat: e.latLng.lat(),
                                                        lng: e.latLng.lng(),
                                                    };
                                                    setLatLng(draggedLatLng);
                                                }}
                                            />
                                        )}
                                    </GoogleMap>
                                </div>
                            )}

                            <div className="mt-4">
                                <button
                                    className="py-3 px-4 rounded-5 bg_primary text_white plusJakara_medium w-full"
                                    onClick={() => handleSetLocation(latLng, setFieldValue)}
                                >
                                    Set Location
                                </button>
                            </div>
                        </Modal>
                    </Form >
                )}
            </Formik >


        </div >
    );
};
export default EstimateForm;