/* eslint-disable no-unused-vars */
import React from 'react';
import { Container } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import '../../style/main.css';
import ServiceForm from './serviceForm';
import { useSelector } from 'react-redux';
import StripeConnectError from '../common/stripeConnectError';

const AddService = () => {
    const navigate = useNavigate()
    const userData = useSelector((state) => state.auth?.userData)
    const usertype = useSelector((state) => state.auth?.userData?.type)

    return (
        <>
            {usertype === 'company' &&
                <StripeConnectError />}
            <main className={`${((!userData?.accountId_verified && (userData?.sub_type !== 'free' && userData?.sub_type !== 'basic')) ? 'px-3 px-md-5 py-2' : 'p-3 p-md-5')}`}>
                <Container fluid={usertype === 'company'} className={`p-4 bg_white rounded-4 w-full`}>
                    <div className="flex flex-col gap-3 mb-4 w-full">
                        <div className="flex gap-4 items-start w-full">
                            <button
                                onClick={() => navigate(-1)}
                                className="bg_primary rounded-3 p-2"
                            >
                                <FaArrowLeft className='text_white' />
                            </button>
                            <h4 className="text_primary mb-0 plusJakara_semibold">Add Service</h4>
                        </div>
                    </div>
                    <ServiceForm />
                </Container>
            </main >
        </>
    );
};

export default AddService;
