/* eslint-disable no-unused-vars */
import { Spinner } from 'react-bootstrap';
import { message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Edit, Trash2 } from 'react-feather';
import { FaEye } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { deleteService, getMyServices } from '../../api/service';
import DTables from "../../DataTable/DTable";
import '../../style/swiper.css';
import HeaderNavigation from '../common/headerNavigation';
import StripeConnectError from '../common/stripeConnectError';

const ServiceList = () => {
    const navigate = useNavigate()
    const [showDelete, setshowDelete] = useState(false)
    const [deleteData, setdeleteData] = useState(null)
    const [loading, setloading] = useState([])
    const userData = useSelector((state) => state.auth?.userData)
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const [services, setservices] = useState([])

    const fetchServices = async () => {
        setloading(true)
        await getMyServices()
            .then((res) => {
                if (res.success === true) {
                    setservices(res.services)
                    setloading(false)
                } else {
                    setloading(false)
                    setservices([])
                }
            }).catch((err) => {
                setloading(false)
                setservices([])
            })
    }

    const calculateTotal = (row) => {
        const tax = (row?.totalPrice * Number(row?.tax)) / 100;
        const taxedPrice = row?.totalPrice + tax;
        return (taxedPrice - row?.dis_price)?.toFixed(2);
    };


    const handleDeleteModal = (row) => {
        setshowDelete(true)
        setdeleteData(row)
    }

    useEffect(() => {
        fetchServices()
    }, [])

    const handleViewDetails = (row) => {
        navigate(`/service-detail/${row?._id}`, { state: { serviceDetail: row } })
    };

    const columns = [
        {
            name: 'Service Image',
            sortable: true,
            minWidth: '150px',
            cell: row => (
                <div className="p-1">
                    <img src={row?.images[0]} alt="" style={{ width: "50px", height: '50px', objectFit: 'cover', borderRadius: '8px' }} />
                </div>
            ),
        },
        {
            name: 'Service Name',
            sortable: true,
            selector: row => row?.name,
            minWidth: '150px'
        },
        {
            name: 'State',
            sortable: true,
            selector: row => (row?.cities?.[0]?.state) || 'Not found',
            minWidth: '150px'
        },
        {
            name: 'Cities',
            sortable: true,
            cell: row => (
                <div className="flex gap-1 items-center w-full flex-wrap">
                    {row?.cities?.map((item, i) => (
                        <span
                            style={{ fontSize: '14px' }}
                            key={i} className="text_secondary2 plusJakara_medium">
                            {item?.city}
                            {i < row.cities.length - 1 && (
                                <span> ,</span>
                            )}
                        </span>
                    ))}
                </div>
            ),
            minWidth: '350px'
        },
        {
            name: 'Category',
            sortable: true,
            selector: row => row?.category?.name,
            minWidth: '150px'
        },
        {
            name: 'Discount ($)',
            sortable: true,
            selector: row => '$' + (row?.dis_price || 0) || 'Not found',
            minWidth: '150px'
        },
        {
            name: 'Tax (%)',
            sortable: true,
            selector: row => (row?.tax || 0) + '%' || 'Not found',
            minWidth: '150px'
        },
        {
            name: 'Amount',
            sortable: true,
            cell: row => (
                <span className='plusJakara_medium '>${calculateTotal(row)}</span>
            ),
            minWidth: '100px'
        },
        {
            name: 'View Service',
            cell: row => (
                <button className="btn-view-details ms-3 bg_light rounded-2 p-2" onClick={() => handleViewDetails(row)}>
                    <FaEye />
                </button>
            ),
            minWidth: '50px'
        },
        ...(usertype === 'company' && [{
            name: 'Action',
            sortable: false,
            cell: row => (
                <div className="flex gap-2">
                    <div className="flex w-full gap-3">
                        <button onClick={() => handleDeleteModal(row)} className="bg-red-500 text_white w-full rounded-1 p-[6px] plusJakara_medium"><Trash2 size={16} /></button>
                        <button onClick={() => navigate(`/service-list/update/${row?._id}`, { state: { serviceData: row } })} className="bg_primary text_white w-full rounded-1 p-[6px] plusJakara_medium"><Edit size={16} /></button>
                    </div>
                </div>
            ),
            minWidth: '220px'
        },]),
    ];

    const handledelete = async () => {
        setloading(true)
        await deleteService(deleteData?._id).then((res) => {
            if (res.service) {
                message.success('Service deleted successfully')
                setloading(false)
                setshowDelete(false)
            } else {
                setloading(false)
                setshowDelete(false)
                message.error('Failed to delete service')
            }
        }).catch((err) => {
            setloading(false)
            setshowDelete(false)
            console.error("Error deleting service:", err);
        })
    }

    return (
        <>
            {usertype === 'company' &&
                <StripeConnectError />}
            <main className={`${usertype === 'company' ? ((!userData?.accountId_verified && (userData?.sub_type !== 'free' && userData?.sub_type !== 'basic')) ? 'px-3 px-md-5 py-2' : 'p-3 p-md-5') : 'pb-5'} w-full`}>
                {usertype !== 'company' &&
                    <HeaderNavigation page='Fixed Services' serviceName='Services' />}
                <Container fluid={usertype === 'company'} className={`${usertype === 'company' ? '' : 'mt-5'}`}>
                    <div className="flex w-full flex-col gap-4">
                        {usertype === 'company' &&
                            <div className="flex gap-3 items-center justify-between w-full">
                                <h4 className="text_black plusJakara_semibold">My Services</h4>
                            </div>}
                        <div className="flex w-full flex-col">
                            {loading ? (
                                <div className='my-5 flex justify-center w-full items-center'>
                                    <Spinner size="sm" className='text_primary' style={{ color: "#000" }} />
                                </div>) :
                                (!services || services.length === 0) ? (
                                    <div className='flex justify-center items-center w-full my-5'>
                                        <span className="plusJakara_medium md:text-lg">No Service Found</span>
                                    </div>
                                ) : <DTables columns={columns} data={services} />}
                        </div>
                    </div>
                </Container>

                <Modal open={showDelete} onCancel={() => setshowDelete(false)} centered footer={false}>
                    <div className="flex flex-col gap-3 items-center text-center">
                        <h4 className="text_primary mb-0 plusJakara_bold">Delete Service</h4>
                        <h6 className="text_secondary2 mb-0 plusJakara_regular">Are you sure you want to delete this service?</h6>
                        <div className="flex gap-3 items-center justify-center w-full">
                            <button
                                onClick={handledelete}
                                disabled={loading}
                                className="bg-red-500 px-5 py-2 rounded-3 text_white plusJakara_medium">
                                {loading ? <Spinner size="sm" style={{ color: "white" }} /> : 'Delete'}
                            </button>
                        </div>
                    </div>
                </Modal>

            </ main >
        </>
    );
};

export default ServiceList;