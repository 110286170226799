import axiosInstance from "../redux/axiosInstance";

export const updateEstimate = async ({ data }, id) => {
    try {
        const res = await axiosInstance.put(`estimate/edit/${id}`, data);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const getBlogs = async (type, page) => {
    try {
        const res = await axiosInstance.get(`blog/admin/${type}/${page}`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};


export const getBlogCategory = async () => {
    try {
        const res = await axiosInstance.get(`blog_cat/admin/all`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};