import React from 'react'
import { cat1 } from '../icons/icon'

const CategoryItem = ({ category, selectedCategory }) => {
    return (
        <div className="flex flex-col gap-1 gap-md-2 items-center">
            <button
                // style={{ border: selectedCategory?._id === category?._id ? '2px solid #003F7D' : '0.25px solid #003F7D', width: '6rem', height: '6rem', }}
                className=' rounded-2 flex justify-center items-center'>
                <img src={category?.image || cat1} className='w-10 md:w-14' style={{ height: 'auto' }} alt="" />
            </button>
            <h6 className="plusJakara_semibold max-md:text-sm text-center">{category?.name || 'Not found'}</h6>
        </div>
    )
}

export default CategoryItem;