import { createSlice } from '@reduxjs/toolkit';

export const serviceSlice = createSlice({
  name: 'services',
  initialState: {
    serviceData: {},
    categories: [],
    services: [],
    homeBanners: [],
    coupons: [],
    blogData: [],
    blogBanner: [],
    serviceBanner: [],
    blogCategories: [],
    invoiceData: {},
    pagesCount: {},
    providersData: []
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload
    },
    setBlogCategories: (state, action) => {
      state.blogCategories = action.payload
    },
    setCoupons: (state, action) => {
      state.coupons = action.payload
    },
    setservices: (state, action) => {
      state.services = action.payload
    },
    setBlogData: (state, action) => {
      state.blogData = action.payload
    },
    setHomeBanners: (state, action) => {
      state.homeBanners = action.payload
    },
    setServiceBanner: (state, action) => {
      state.serviceBanner = action.payload
    },
    setBlogBanner: (state, action) => {
      state.blogBanner = action.payload
    },
    setProvidersData: (state, action) => {
      state.providersData = action.payload
    },

    setInvoiceData: (state, action) => {
      state.invoiceData = action.payload
    },
    setServiceData: (state, action) => {
      state.serviceData = action.payload
    },
    setPagesCount: (state, action) => {
      state.pagesCount = action.payload
    }
  },
});

export const { setServiceData, setCoupons, setBlogCategories, setServiceBanner, setBlogBanner, setBlogData, setInvoiceData, setservices, setProvidersData, setPagesCount, setHomeBanners, serviceData, categories, setCategories, } = serviceSlice.actions;
export default serviceSlice.reducer;