/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// React Basic and Bootstrap

import { message } from "antd";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Trash2 } from "react-feather";
import { CiImageOn } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import { IoVideocamOutline } from "react-icons/io5";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Col,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";
import { updateUser } from "../../api/signup";
import { uploadFile, uploadVideo } from "../../api/uploadFile";
import { setLogin, setUserData } from "../../redux/loginForm";


const PortfolioInfoForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [fileLoading, setFileLoading] = useState(false);
  const [fileLoading2, setFileLoading2] = useState(false);
  const usertype = useSelector((state) => state.auth?.userData?.type)
  const isLogin = useSelector((state) => state.auth?.isLogin)
  const userData = useSelector((state) => state.auth?.userData)
  const [isLoading, setisLoading] = useState(false)
  const [savedProjects, setSavedProjects] = useState([]);

  const handleFileChange = (index, setFieldValue, values) => async (event) => {
    const selectedFiles = event.target.files;
    const urls = [];

    if (selectedFiles.length > 0) {
      setFileLoading(true);

      for (let i = 0; i < selectedFiles.length; i++) {
        const formData = new FormData();
        formData.append('image', selectedFiles[i]);

        try {
          const res = await uploadFile({ data: formData });
          if (res) {
            urls.push({
              type: 'image',
              url: res.image,
            });
          }
        } catch (err) {
          message.error('Failed to upload file');
        }
      }
      setFileLoading(false);
      setFieldValue(`project[${index}].urls`, [...(values.project[index].urls || []), ...urls]);
    }
  };

  useEffect(() => {
    if (userData?.portfolio) {
      setSavedProjects(userData?.portfolio);
    }
  }, [userData])

  const handleFileChange2 = (index, setFieldValue, values) => async (event) => {
    const selectedFiles = event.target.files;
    const urls = [];

    if (selectedFiles.length > 0) {
      setFileLoading2(true);

      for (let i = 0; i < selectedFiles.length; i++) {
        const formData = new FormData();
        formData.append('video', selectedFiles[i]);

        try {
          const res = await uploadVideo({ data: formData });
          if (res) {
            urls.push({
              type: 'video',
              url: res.video,
            });
          }
        } catch (err) {
          message.error('Failed to upload file');
        }
      }
      setFileLoading2(false);
      setFieldValue(`project[${index}].urls`, [...(values.project[index].urls || []), ...urls]);
    }
  };

  const validationSchema = Yup.object({
    // facebook: Yup.string()
    //   .url("Invalid URL format")
    //   .required("Facebook link is required"),
    // linkedin: Yup.string()
    //   .url("Invalid URL format")
    //   .required("LinkedIn link is required"),
    // instagram: Yup.string()
    //   .url("Invalid URL format")
    //   .required("Instagram link is required"),
  })

  const initialValues = {
    facebook: userData?.fblink || "",
    linkedin: userData?.linkedInlink || "",
    instagram: userData?.instalink || "",
    project: [
      {
        title: '',
        description: '',
        type: '',
        urls: [],
      }
    ]
  };

  const handleSubmit = async (values) => {
    setisLoading(true);
    const data = {
      fblink: values?.facebook,
      linkedInlink: values?.linkedin,
      instalink: values?.instagram,
      portfolio: savedProjects
    };
    await updateUser({ data: data }).then((res) => {
      if (res.data.success === true) {
        setisLoading(false);
        message.success('Profile updated successfully');
        dispatch(setUserData(res.data.user))
        if (isLogin) {
          navigate('/business-profile?selected=overview')
        } else {
          dispatch(setLogin(true))
        }
      }
    }).catch((err) => {
      message.error('Failed to update profile');
      setisLoading(false);
    })
  }

  return (
    <div style={{ maxWidth: '800px', width: '100%' }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form className="w-full">
            <FieldArray name="project">
              {({ push, remove }) => (
                <>
                  {values.project.map((entry, index) => (
                    <div key={index} style={{ border: '1px solid #d3d3d3' }} className="rounded-4 relative p-3 mb-3">
                      {index > 0 && (
                        <div className="absolute -top-2 flex justify-end -right-4 w-full">
                          <button
                            type="button"
                            className="bg-red-500 text-white rounded-5 p-1"
                            onClick={() => remove(index)}
                          >
                            <Trash2 size={18} />
                          </button>
                        </div>
                      )}
                      <div className="mb-2 w-full">
                        <Label className="plusJakara_medium">Title</Label>
                        <Field
                          className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                          type="text"
                          style={{
                            padding: '10px'
                          }}
                          name={`project[${index}].title`}
                          placeholder="Enter Title"
                        />
                        <ErrorMessage
                          component="div" className="text-danger text-xs plusJakara_regular mt-1"
                          name={`project[${index}].title`} />
                      </div>
                      <div className="flex justify-around my-4 w-full gap-3 gap-md-5">
                        <button type="button" className="w-full">
                          <label htmlFor={`fileInput2${index}`} style={{ cursor: "pointer" }} className="cursor-pointer w-full">
                            {fileLoading ? (
                              <div className="h-[80px] md:h-[100px] w-full border rounded-4 bg_light text_primary d-flex justify-content-center align-items-center">
                                <Spinner />
                              </div>
                            ) : entry.image && entry.type === 'image' ? (
                              <img
                                src={entry.image}
                                alt="Preview"
                                style={{ height: "100px", objectFit: "cover" }}
                                className="rounded-4 w-full bg_light object-cover"
                              />
                            ) : (
                              <div style={{ border: '1px solid #d3d3d3', height: '100px' }} className="rounded-4 w-full text_secondary2 relative flex flex-col gap-1 justify-center items-center">
                                <CiImageOn size={34} />
                                <span className="plusJakara_medium text-xs md:text-sm">Upload Image</span>
                              </div>
                            )}
                          </label>
                          <Input
                            type="file"
                            multiple
                            id={`fileInput2${index}`}
                            className="visually-hidden"
                            onChange={handleFileChange(index, setFieldValue, values)}
                          />
                        </button>
                        <button type="button" className="w-full">
                          <label style={{ cursor: "pointer" }} htmlFor={`fileInput${index}`} className="cursor-pointer w-full">
                            {fileLoading2 ? (
                              <div className="h-[80px] md:h-[100px] w-full border rounded-4 bg_light text_primary d-flex justify-content-center align-items-center">
                                <Spinner />
                              </div>
                            ) : entry.image && entry.type === 'video' ? (
                              <video
                                src={entry.image}
                                alt="Preview"
                                style={{ height: "100px", objectFit: "cover" }}
                                className="rounded-4 w-full bg_light object-cover"
                                controls
                              />
                            ) : (
                              <div style={{ height: "100px", border: '1px solid #d3d3d3' }} className="rounded-4 w-full text_secondary2 relative flex flex-col gap-1 justify-center items-center">
                                <IoVideocamOutline size={34} />
                                <span className="plusJakara_medium text-xs md:text-sm">Upload Video</span>
                              </div>
                            )}
                          </label>
                          <Input
                            type="file"
                            multiple
                            id={`fileInput${index}`}
                            accept="video/*"
                            className="visually-hidden"
                            onChange={handleFileChange2(index, setFieldValue, values)}
                          />
                        </button>
                      </div>
                      <div className="mb-2 md:mb-4 flex gap-3 items-center w-full flex-wrap">
                        {values.project[index].urls?.map((file, i) => (
                          <div key={i} className="relative">
                            <button
                              type="button"
                              className="absolute -top-2 bg_light rounded-5 -right-2"
                              onClick={() => {
                                const updatedFiles = values.project[index].urls.filter((_, idx) => idx !== i);
                                setFieldValue(`project[${index}].urls`, updatedFiles);
                              }}
                            >
                              <IoIosClose size={20} />
                            </button>
                            {file.type === 'image' ? (
                              <img src={file.url} alt="Image Preview" className="rounded-2" style={{ height: '80px', objectFit: 'cover', width: '100px' }} />
                            ) : (
                              <video src={file.url} alt="Video Preview" className="rounded-2" controls style={{ height: '80px', objectFit: 'cover', width: '100px' }} />
                            )}
                          </div>
                        ))}
                      </div>

                      <div className="mb-3 w-full">
                        <Label className="plusJakara_medium">Description</Label>
                        <Field
                          as="textarea"
                          style={{ padding: "10px", minHeight: '100px' }}
                          className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                          name={`project[${index}].description`}
                          placeholder="Enter Description"
                        />
                        <ErrorMessage
                          component="div" className="text-danger text-xs plusJakara_regular mt-1"
                          name={`project[${index}].description`} />
                      </div>
                    </div>
                  ))}

                  <div className="flex w-full justify-end">
                    <button
                      className="bg_primary text_white px-3 py-2 rounded-3 plusJakara_medium"
                      type="button"
                      disabled={fileLoading || fileLoading2}
                      onClick={() => {
                        const currentTitle = values.project[0].title;
                        const currentDescription = values.project[0].description;
                        const currentImages = values.project[0].urls;
                        if (currentTitle && currentDescription) {
                          setSavedProjects([...savedProjects, {
                            title: currentTitle,
                            description: currentDescription,
                            urls: currentImages,
                          }]);
                          setFieldValue('project[0].title', '');
                          setFieldValue('project[0].description', '');
                          setFieldValue('project[0].urls', []);
                        } else {
                          message.error('Please fill all fields before adding a project.');
                        }
                      }}
                    >
                      Add Project
                    </button>
                  </div>
                  <div className="mb-2 md:mb-4 flex gap-3 items-center w-full flex-wrap relative">
                    {savedProjects &&
                      savedProjects?.map((item, i) => (
                        <div className="relative">
                          <button
                            type="button"
                            className="absolute top-0  z-20 right-0 bg_light text_black"
                            onClick={() => {
                              const newProjects = savedProjects.filter((_, index) => index !== i);
                              setSavedProjects(newProjects);
                            }}
                          >
                            <IoIosClose size={20} />
                          </button>
                          {item?.urls[0]?.type === 'video' ?
                            <video
                              src={item?.urls[0].url}
                              alt="Preview"
                              style={{ height: "100px", width: '140px', objectFit: "cover" }}
                              className="rounded-4 w-full bg_light object-cover"
                              controls
                            /> :
                            <img src={item?.urls[0].url} className="rounded-3 relative" alt="Project" style={{ height: "100px", width: "140px", objectFit: "cover", position: 'relative' }} />
                          }
                        </div>
                      ))}
                  </div>
                </>
              )}
            </FieldArray>
            <div className="mb-4">
              <Label
                className="form-label text_secondary2 max-md:text-smplusJakara_medium"
                htmlFor="facebook"
              >
                Facebook Link
              </Label>
              <Field
                type="text"
                className="form-control rounded-3 text_secondary2 plusJakara_medium"
                name="facebook"
                id="facebook"
                style={{
                  padding: "14px 16px",
                }}
                value={values.facebook || ""}
                placeholder="facebook.com/username"
                onChange={(e) => {
                  setFieldValue(`facebook`, e.target.value);
                }}
              />
              <ErrorMessage
                component="div" className="text-danger text-xs plusJakara_regular mt-1"
                name='facebook'
              />
            </div>
            <div className="mb-4">
              <Label
                className="form-label text_secondary2 max-md:text-smplusJakara_medium"
                htmlFor="instagram"
              >
                Instagram Link
              </Label>
              <Field
                type="text"
                className="form-control rounded-3 text_secondary2 plusJakara_medium"
                name="instagram"
                id="instagram"
                style={{
                  padding: "14px 16px",
                }}
                placeholder="instagram.com/username"
                onChange={(e) => {
                  setFieldValue(`instagram`, e.target.value);
                }}
                value={values.instagram || ""}
              />
              <ErrorMessage
                component="div" className="text-danger text-xs plusJakara_regular mt-1"
                name='instagram'
              />
            </div>
            <div className="mb-4">
              <Label
                className="form-label text_secondary2 max-md:text-smplusJakara_medium"
                htmlFor="linkedin"
              >
                LinkedIn Link
              </Label>
              <Field
                type="text"
                className="form-control rounded-3 text_secondary2 plusJakara_medium"
                name="linkedin"
                id="linkedin"
                style={{
                  padding: "14px 16px",
                }}
                placeholder="linkedin.com/username"
                onChange={(e) => {
                  setFieldValue(`linkedin`, e.target.value);
                }}
                value={values.linkedin || ""}
              />

              <ErrorMessage
                component="div" className="text-danger text-xs plusJakara_regular mt-1"
                name='linkedin' />
            </div>
            <Col lg={12} className="mb-0 mt-4">
              <div className="d-grid">
                <button className="py-[12px] md:py-3 max-md:text-sm rounded-3 bg_primary plusJakara_medium text_white" disabled={isLoading} type="submit">
                  {isLoading ? <Spinner size="sm" /> : "Submit"}
                </button>
              </div>
            </Col>
          </Form>)}
      </Formik>
    </div >
  );
};
export default PortfolioInfoForm;
