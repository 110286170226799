/* eslint-disable no-unused-vars */
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa6';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AvailabilityInfoForm from './common/availabilityInfoForm';
import BusinessInformationForm from './common/businessInformationForm';
import PersonalInformationForm from './common/personalInformationForm';
import PortfolioInfoForm from './common/portfolioInfoForm';
import PricingInfoForm from './common/pricingInfoForm';
import OverViewProfile from './common/overviewProfile';
import { useSelector } from 'react-redux';
import LinkStripeAccount from './stripe/linkStripeAccount';

const BusinessProfile = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const userData = useSelector((state) => state.auth?.userData)
    const [active, setActive] = useState('overview');

    const handleTab = (tab) => {
        const params = new URLSearchParams(searchParams.toString())
        params.set('selected', tab);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`)
        setActive(tab);
    };

    useEffect(() => {
        if (searchParams.get('selected')) {
            setActive(searchParams.get('selected'));
        } else {
            const params = new URLSearchParams(searchParams.toString());
            params.set('selected', 'overview');
            window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
            setActive('overview');
        }
    }, [searchParams]);

    return (
        <main className={`p-3 px-md-5 py-4 w-full`}>
            <Container fluid className='p-3 p-md-4 bg_white rounded-4 w-full'>
                <div className="flex gap-4 mb-4 items-start w-full">
                    <button
                        onClick={() => navigate('/dashboard')}
                        className="bg_primary rounded-3 p-2"
                    >
                        <FaArrowLeft className='text_white' />
                    </button>
                    <h4 className="text_primary mb-0 plusJakara_semibold">My Account</h4>
                </div>
                <Row gutter={16} className='justify-between gap-3 flex-wrap flex-md-nowrap'>
                    <Col xs={24} md={6} xl={6}>
                        <div className="border w-full flex flex-col items-start">
                            <button
                                onClick={() => handleTab('overview')}
                                className={`text-sm border-b border-b-gray-200 ${active === 'overview' ? 'border-r-2 border-r-[#1c2e4a]' : ""} w-full py-[12px] text-start px-4 text_black plusJakara_medium`}
                            >
                                Overview
                            </button>
                            {usertype === 'company' &&
                                <button
                                    onClick={() => handleTab('subscription')}
                                    className={`text-sm border-b border-b-gray-200 ${active === 'subscription' ? 'border-r-2 border-r-[#1c2e4a]' : ""} w-full py-[12px] text-start px-4 text_black plusJakara_medium`}
                                >
                                    Subscription Plan
                                </button>}
                            <button
                                onClick={() => handleTab('personal-information')}
                                className={`text-sm border-b border-b-gray-200 ${active === 'personal-information' ? 'border-r-2 border-r-[#1c2e4a]' : ""} w-full py-[12px] text-start px-4 text_black plusJakara_medium`}
                            >
                                Personal Information
                            </button>
                            {usertype === 'company' &&
                                <>
                                    <button
                                        onClick={() => handleTab('business-information')}
                                        className={`text-sm border-b border-b-gray-200 ${active === 'business-information' ? 'border-r-2 border-r-[#1c2e4a]' : ""} w-full py-[12px] text-start px-4 text_black plusJakara_medium`}
                                    >
                                        Business Information
                                    </button>
                                    <button
                                        onClick={() => handleTab('availability')}
                                        className={`text-sm border-b border-b-gray-200 ${active === 'availability' ? 'border-r-2 border-r-[#1c2e4a]' : ""} w-full py-[12px] text-start px-4 text_black plusJakara_medium`}
                                    >
                                        Availability
                                    </button>
                                    <button
                                        onClick={() => handleTab('portfolio')}
                                        className={`text-sm border-b border-b-gray-200 ${active === 'portfolio' ? 'border-r-2 border-r-[#1c2e4a]' : ""} w-full py-[12px] text-start px-4 text_black plusJakara_medium`}
                                    >
                                        Portfolio
                                    </button>
                                    {(userData.sub_type !== 'free' && userData.sub_type !== 'basic') && (
                                        <button
                                            onClick={() => handleTab('connect-stripe')}
                                            className={`text-sm border-b border-b-gray-200 ${active === 'connect-stripe' ? 'border-r-2 border-r-[#1c2e4a]' : ""} w-full py-[12px] text-start px-4 text_black plusJakara_medium`}
                                        >
                                            Connect Stripe
                                        </button>
                                    )}
                                </>}
                        </div>
                    </Col>
                    <Col xs={24} md={17} xl={18}>
                        {active === 'personal-information' &&
                            <div style={{ maxHeight: '70vh', height: '100%', overflowY: 'auto' }} className="border rounded-3 px-4 py-3 w-full">
                                <h5 className="text_black mb-4 plusJakara_semibold">Ediit Personal Information</h5>
                                <div className="flex justify-center w-full">
                                    <PersonalInformationForm />
                                </div>
                            </div>
                        }
                        {active === 'overview' &&
                            <div style={{ maxHeight: '70vh', height: '100%', overflowY: 'auto' }} className="border rounded-3 px-4 py-3 w-full">
                                <h5 className="text_black mb-4 plusJakara_semibold">{usertype === 'company' ? 'Company' : 'Employee'} Details</h5>
                                <div className="flex justify-center w-full">
                                    <OverViewProfile />
                                </div>
                            </div>
                        }
                        {active === 'business-information' &&
                            <div style={{ maxHeight: '70vh', height: '100%', overflowY: 'auto' }} className="border rounded-3 px-4 py-3 w-full">
                                <h5 className="text_black mb-4 plusJakara_semibold">Edit Business Information</h5>
                                <div className="flex justify-center w-full">
                                    <BusinessInformationForm />
                                </div>
                            </div>
                        }
                        {active === 'availability' &&
                            <div style={{ maxHeight: '70vh', height: '100%', overflowY: 'auto' }} className="border rounded-3 px-4 py-3 w-full">
                                <h5 className="text_black mb-4 plusJakara_semibold">Edit Availability</h5>
                                <div className="flex justify-center w-full">
                                    <AvailabilityInfoForm />
                                </div>
                            </div>
                        }
                        {active === 'portfolio' &&
                            <div style={{ maxHeight: '70vh', height: '100%', overflowY: 'auto' }} className="border rounded-3 px-4 py-3 w-full">
                                <h5 className="text_black mb-4 plusJakara_semibold">Edit Portfolio</h5>
                                <div className="flex justify-center w-full">
                                    <PortfolioInfoForm />
                                </div>
                            </div>
                        }
                        {active === 'subscription' &&
                            <div style={{ maxHeight: '70vh', height: '100%', overflowY: 'auto' }} className="border rounded-3 px-4 py-3 w-full">
                                <h5 className="text_black mb-4 plusJakara_semibold">Subscription</h5>
                                <div className="flex justify-center w-full">
                                    <PricingInfoForm />
                                </div>
                            </div>
                        }
                        {active === 'connect-stripe' &&
                            <div style={{ maxHeight: '70vh', height: '100%', overflowY: 'auto' }} className="border rounded-3 px-4 py-3 w-full">
                                <h5 className="text_black mb-4 plusJakara_semibold">Connect Stripe</h5>
                                <div className="flex justify-center w-full">
                                    <LinkStripeAccount />
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
        </main >
    );
}

export default BusinessProfile;
