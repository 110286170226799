import axiosInstance from '../redux/axiosInstance';

// service by id
export const sendNewMsg = async ({ data }) => {
  try {
    const res = await axiosInstance.post(`msg/send`,
      {
        to_id: data.to_id,
        message: data.message,
      }
    );
    return res;
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};

// get related service bg id
export const getSpecificUserChat = async ({ id, lastId = "" }) => {
  try {
    const res = await axiosInstance.get(
      `msg/messages/${id}/${lastId}`,
    );
    return res;
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};

export const getChatList = async () => {
  try {
    const res = await axiosInstance.get(
      // `msg/conversations/` + id,
      `msg/conversations`,
    );
    return res;
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};

// get related service bg id
export const notifySeen = async (id) => {
  try {
    const res = await axiosInstance.put(
      `msg / seen / ${id}`,
      {},
    );
    return res;
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};
export const getRelatedWHById = async ({ lastId, id }) => {
  try {
    const res = await axiosInstance.post(
      `service / getWhById / ${id}`,
      {
        last_id: lastId,
      },
    );
    return res;
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};