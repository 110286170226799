import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { nopage } from '../icons/icon'

const NotFound = () => {
    const navigate = useNavigate()
    const usertype = useSelector((state) => state.auth?.userData?.type)

    return (
        <main style={{
            // background: 'linear-gradient(114.77deg, #E9ECEF 84.53%, #E3E4D1 90.6%, #C3B82D 99.25%)',
            height: '88vh'
        }}
            className='flex justify-center bg_light flex-col gap-4 items-center'
        >
            <img src={nopage} style={{ width: '350px', height: 'auto' }} alt="" />
            <button onClick={() => navigate(`${usertype === 'company' ? '/dashboard' : '/'}`)} style={{ width: '400px', padding: '12px' }}
                className="bg_primary text_white rounded-3 roboto_medium">Go to Home Page
            </button>
        </main>
    )
}

export default NotFound