import React from 'react';

const ServiceItem2Skeleton = () => {
    return (
        <div className="bg_white flex flex-col w-full rounded-2 overflow-hidden border relative animate-pulse">
            {/* Image Placeholder */}
            <div className="relative h-48 w-full bg-gray-200">
                <div className="absolute top-2 right-2 h-5 w-16 bg-gray-300 rounded"></div>
            </div>

            {/* Content Placeholder */}
            <div className="px-3 py-2 flex gap-2 flex-col w-full">
                {/* Title */}
                <div className="h-6 w-3/4 bg-gray-300 rounded"></div>

                {/* Price Section */}
                <div className="flex gap-2 items-center mt-2">
                    <div className="h-5 w-1/4 bg-gray-300 rounded"></div>
                    <div className="h-5 w-1/6 bg-gray-300 rounded"></div>
                </div>

                {/* Description */}
                <div className="h-4 w-full bg-gray-300 rounded mt-2"></div>
                <div className="h-4 w-5/6 bg-gray-300 rounded"></div>

                {/* User Info Placeholder */}
                <div className="flex items-center gap-2 mt-3">
                    <div className="h-8 w-8 bg-gray-300 rounded-full"></div>
                    <div className="h-5 w-1/3 bg-gray-300 rounded"></div>
                </div>
            </div>
        </div>
    );
};

export default ServiceItem2Skeleton;
