import React from 'react'
import HeaderNavigation from './common/headerNavigation'
import { Container } from 'react-bootstrap'
import WhatWeOffer from './homeComponents/whatWeOffer'

const About = () => {
    return (
        <main className='pb-5'>
            <HeaderNavigation page='About us' serviceName='About us' />
            <Container className='py-5'>
                <h1 className="plusJakara_semibold text-4xl lg:text-5xl text-center text_dark">Our Story</h1>
                <div className="flex flex-col items-center gap-1 my-5 w-full justify-center">
                    <div style={{ border: '1.13px solid #F45734', width: '100px', height: '2px' }}></div>
                    <div style={{ border: '1.13px solid #F45734', width: '100px', height: '2px' }}></div>
                </div>
                <div className="flex justify-between flex-wrap mb-5 flex-md-nowrap gap-2 gap-md-5 items-center">
                    <span className="text_secondary plusJakara_regular">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus lorem id penatibus imperdiet. Turpis egestas ultricies purus auctor tincidunt lacus nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus lorem id penatibus imperdiet. Turpis egestas ultricies purus auctor tincidunt lacus nunc. </span>
                    <span className="text_secondary plusJakara_regular">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus lorem id penatibus imperdiet. Turpis egestas ultricies purus auctor tincidunt lacus nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus lorem id penatibus imperdiet. Turpis egestas ultricies purus auctor tincidunt lacus nunc. </span>
                </div>
                <h1 className="plusJakara_semibold text-4xl lg:text-5xl text-center text_primary">Why We are the best?</h1>
                <WhatWeOffer />
            </Container>
        </main>
    )
}

export default About