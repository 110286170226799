/* eslint-disable no-unused-vars */
import moment from "moment";
import React from "react";


const ChatMessage = ({ message, timestamp, left, type }) => {
  return (
    <div>
      <div className={`pb-3 ${left ? "chat-message-left " : "chat-message-right"}`}>
        <div>
          <div
            className={`flex-shrink-1 plusJakara_regular text-sm ${left ? "chat_card_left" : "chat_card_right"
              }`}
            style={{ padding: "0.7rem 0.9rem", marginBottom: "0.2rem", wordWrap: "break-word" }}
          >
            {type === 'image' ? (
              <div className=' relative selected_img'>
                <img src={message} alt="Selected" className="w-100 rounded-3 h-100" />
              </div>
            ) : type === 'video' ? (
              <div className='relative selected_img'>
                <video src={message} controls className="w-100 rounded-3 h-100" />

              </div>
            ) : type === 'url' ? (
              <div className="position-relative">
                <a href={message} target="__blank" className="text_white">
                  <span className={`${left ? 'text_primary' : 'text_secondary2'}`}>
                    {message}
                  </span>
                </a>
              </div>
            ) : <span>
              {message}
            </span>}
          </div>
          <div
            className={`text-nowrap text-xs plusJakara_regular ${left ? "chat-message-left" : "chat-message-right"
              }`}
            style={{ color: "#848FAC" }}
          >
            {moment(timestamp).fromNow()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
