/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCategories } from "../../redux/services";
import { getCategory } from "../../api/category";
import CategoryItem from "../categoryItem";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Categories = ({ onSelectCategory, selectedCategory, navigateOnCategoryClick }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const categories = useSelector((state) => state.services.categories);

    const handleFetchCategories = async () => {
        if (categories.length === 0) {
            setLoading(true);
            try {
                const res = await getCategory();
                if (res.success) {
                    dispatch(setCategories(res.categories));
                }
            } catch (error) {
                console.error("Failed to fetch categories", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleCategoryClick = (category) => {
        onSelectCategory(category);
        if (navigateOnCategoryClick) {
            navigate("/nearby-services", { state: { category: category._id } });
        }
    };

    useEffect(() => {
        handleFetchCategories();
    }, []);

    return (
        <Container>
            {loading ? (
                <div className="my-5 flex gap-3 overflow-hidden">
                    {Array(10)
                        .fill("")
                        .map((_, index) => (
                            <Skeleton
                                key={index}
                                height={100}
                                width={120}
                                className="rounded-lg"
                                style={{ flexShrink: 0 }}
                            />
                        ))}
                </div>
            ) : categories.length === 0 ? (
                <div className="flex justify-center items-center w-full my-5">
                    <span className="plusJakara_medium md:text-lg">No Category Found</span>
                </div>
            ) : (
                <div className="items_swiper relative">
                    <Swiper
                        freeMode={true}
                        modules={[FreeMode, Autoplay, Navigation, Pagination]}
                        navigation={true}
                        className="mySwiper d-flex w-100 relative"
                        slidesPerView={"auto"}
                    >
                        {categories.map((category) => (
                            <SwiperSlide
                                key={category._id}
                                onClick={() => handleCategoryClick(category)}
                                style={{ height: "auto" }}
                                className="relative max-w-24 md:max-w-32 mt-3"
                            >
                                <CategoryItem
                                    selectedCategory={selectedCategory}
                                    category={category}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            )}
        </Container>
    );
};

export default Categories;
