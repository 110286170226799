import React from 'react';
import { Container } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa6';
import { cleaning12 } from '../../icons/icon';
import { useNavigate } from 'react-router-dom';

const ShopServices = () => {
    const navigate = useNavigate()


    return (
        <div className='py-1 py-md-5'>
            <Container className='py-5'>
                <div className="grid2 w-100">
                    <div className="relative w-full">
                        <img src={cleaning12} className='relative max-w-[250px] md:max-w-[550px] max-h-[400px] md:max-h-[650px]' style={{ height: '100%', width: '100%', objectFit: "cover", borderRadius: '24px', zIndex: 2 }} alt="" />
                        <div className="absolute bg_lightprimary max-w-[250px] md:max-w-[550px] max-h-[400px] md:max-h-[650px] top-2 md:top-6 left-2 md:left-6" style={{ height: '100%', width: '100%', objectFit: "cover", borderRadius: '24px', zIndex: 1 }}></div>
                    </div>
                    <div className="flex flex-col gap-3 gap-md-4 justify-start">
                        <div className="flex gap-2 justify-start flex-col w-full items-start">
                            <span className="plusJakara_semibold text-2xl lg:text-4xl text-center text_black">Looking to book a </span>
                            <span className="plusJakara_semibold text-2xl lg:text-4xl text_primary">fixed price Service</span>
                        </div>
                        <span className="plusJakara_regular md:text-lg text_lightsecondary">
                            Plumbing, Handyman, House Cleaning and More...
                        </span>
                        <div className="flex flex-col gap-3 w-full items-start">
                            <div className="flex gap-3 gap-md-4 items-start justify-start">
                                <div className='mt-3 h-2 w-2 bg_primary rounded-full'></div>
                                <div className="flex flex-col gap-1 gap-md-2 w-full items-start">
                                    <h4 className="text_primary mb-0 text-lg md:text-xl plusJakara_semibold">Request service</h4>
                                    <span className="plusJakara_regular max-md:text-sm text_lightsecondary">
                                        Easily request a customized cleaning service based on your home’s specific needs.
                                    </span>
                                </div>
                            </div>
                            <div className="flex gap-3 gap-md-4 items-start justify-start">
                                <div className='mt-3 h-2 w-2 bg_primary rounded-full'></div>
                                <div className="flex flex-col gap-1 gap-md-2 w-full items-start">
                                    <h4 className="text_primary mb-0 text-lg md:text-xl plusJakara_semibold">Book A Time</h4>
                                    <span className="plusJakara_regular max-md:text-sm text_lightsecondary">
                                        Schedule your preferred time for a professional cleaning service at your convenience.
                                    </span>
                                </div>
                            </div>
                            <div className="flex gap-3 gap-md-4 items-start justify-start">
                                <div className='mt-3 h-2 w-2 bg_primary rounded-full'></div>
                                <div className="flex flex-col gap-1 gap-md-2 w-full items-start">
                                    <h4 className="text_primary mb-0 text-lg md:text-xl plusJakara_semibold">Pay Online</h4>
                                    <span className="plusJakara_regular max-md:text-sm text_lightsecondary">
                                        Securely pay online for your home cleaning service with no hassle.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <button onClick={() => navigate('/all-services')} className="bg_primary w-fit flex gap-2 gap-md-3 items-center mt-3 px-3 px-md-5 ms-4 py-2 py-md-3 max-md:text-sm rounded-3 text_white plusJakara_medium">Shop Services <FaArrowRight /> </button>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default ShopServices