/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { Dropdown, message, Modal } from "antd";
import moment from "moment";
import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Camera, ChevronLeft, File, Plus, Send, X } from "react-feather";
import { IoVideocamOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Input, Label } from "reactstrap";
import { createSchedule } from "../../api/order";
import { uploadDocument, uploadFile, uploadVideo } from "../../api/uploadFile";
import { avatar } from "../../icons/icon";
import axiosInstance from "../../redux/axiosInstance";
import { setServiceProvider } from "../../redux/generalRedux";
import ChatMessage from "./chatMessage";
import {
  useActiveChat,
  useChatList,
  useChatUser,
  useResponsiveChat,
} from "./context";
import { useSocket } from "./socketProvider";

const ChatMessageList = () => {
  const socket = useSocket();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [scheduleTitle, setscheduleTitle] = useState('')
  const [showPopup, setShowPopup] = useState(false);
  const [chatMsg, setChatMsg] = useState([]);
  const [type, setType] = useState('text');
  const chatMessagesRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileLoading2, setFileLoading2] = useState(false);
  const [fileLoading3, setFileLoading3] = useState(false);
  const [modal, setmodal] = useState(false)
  const [imageUrl, setImageUrl] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [highlightedDate, setHighlightedDate] = useState(null);
  const userData = useSelector((state) => state?.auth?.userData);
  const userType = useSelector((state) => state?.auth?.userType);
  const [newMsg, setNewMsg] = useState(false);
  const { setResponsiveChat } = useResponsiveChat();
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  //// context APi
  const { chatUser } = useChatUser();
  const { activeChatId } = useActiveChat();
  const { setChatListData } = useChatList();

  useEffect(() => {
    if (socket) {
      socket.emit('seen-msg', {});
    }
  }, [socket])

  const handleDateSelect = (date) => {
    setHighlightedDate(date);
  };


  const customDayClassName = (date) => {
    const isAvailable = selectedDates.some(d => moment(d).isSame(date, 'day'));
    const isHighlighted = moment(date).isSame(highlightedDate, 'day');
    if (isHighlighted) {
      return 'react-datepicker__day--highlighted';
    }
    return isAvailable ? '' : 'react-datepicker__day--disabled';
  };

  useEffect(() => {
    if (chatUser?.otherUser?.availablity) {
      const availableDays = chatUser.otherUser.availablity
        .filter(day => day.status)
        .map(day => day.day)
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();

      const dates = [];
      for (let i = 0; i < 31; i++) {
        const date = new Date(currentYear, currentMonth, i + 1);
        const dayName = moment(date).format('ddd');
        if (availableDays.includes(dayName)) {
          dates.push(date);
        }
      }
      setSelectedDates(dates);
    }
  }, [chatUser]);

  useEffect(() => {
    if (socket) {
      socket.on("send-message", (message) => {
        if (
          activeChatId === message?.sender ||
          userData?._id === message?.sender
        ) {
          setChatMsg((prevMessages) => {
            setNewMsg(true);
            const uniqueMessagesSet = new Set(prevMessages.map(JSON.stringify));
            if (!uniqueMessagesSet.has(JSON.stringify(message))) {
              const updatedMessages = [...prevMessages, message];
              return updatedMessages;
            }
            return prevMessages;
          });
        }
      });
      if (socket) {
        socket.emit('seen-msg', { recipientId: chatUser?.otherUser?._id });
      }
      socket.on("conversation-count", (count) => {
      });
      socket.on("conversation-list", (newConversation) => {
        setChatListData((prevChatList) => {
          const conversationIndex = prevChatList.findIndex(
            (chat) => chat._id === newConversation?.conversation._id
          );
          if (conversationIndex !== -1) {
            const updatedChatList = [...prevChatList];
            updatedChatList[conversationIndex] = newConversation?.conversation;
            return updatedChatList.sort((a, b) => {
              const lastMsgA = a?.lastMsg;
              const lastMsgB = b?.lastMsg;
              if (!lastMsgA || !lastMsgB) {
                return 0;
              }
              const createdAtA = new Date(lastMsgA.createdAt);
              const createdAtB = new Date(lastMsgB.createdAt);
              return createdAtB - createdAtA; // Sort in descending order for newest first
            });
          } else {
            const updatedChatList = [...prevChatList, newConversation?.conversation].sort(
              (a, b) => {
                const lastMsgA = a?.lastMsg;
                const lastMsgB = b?.lastMsg;
                if (!lastMsgA || !lastMsgB) {
                  return 0;
                }
                const createdAtA = new Date(lastMsgA.createdAt);
                const createdAtB = new Date(lastMsgB.createdAt);
                return createdAtB - createdAtA; // Sort in descending order for newest first
              }
            );
            return updatedChatList;
          }
        });
      });
    }
  }, [activeChatId]);

  const sendMessage = async (e) => {
    const currentDate = new Date();
    e.preventDefault();
    const input = document.getElementById("chatInput");
    const message = input.value;
    const data = {
      recipientId: chatUser?.otherUser?._id,
      messageText: type === 'text' ? message : type === 'image' ? imageUrl : type === 'video' ? videoUrl : type === 'url' ? documentUrl : message,
      name: userData?.name,
      type: type,
    };
    // setChatMsg([...chatMsg, data]);
    // if (message.trim() !== "") {
    chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    await socket.emit("send-message", data);
    // if (socket) {
    //   socket.emit('seen-msg', { recipientId: chatUser?.otherUser?._id });
    // }
    input.value = "";
    handleClear()
    // }
  };
  const [lastId, setLastId] = useState(0);
  const [count, setCount] = useState(0);

  const handleShowPopup = () => {
    // if (chatUser?.otherUser?.sub_type === 'free') {
    //   message.info('Scheduling with this provider is currently unavailable as their plan has not been upgraded.')
    //   return
    // } else {
    setShowPopup(true)
    // }
  }

  const getUserChat = async (userId) => {
    await axiosInstance
      .get(`msg/messages/${userId}`)
      .then(async (result) => {
        if (result?.data?.success) {
          setNewMsg(true);
          setCount(result.data.count);
          setLastId(0);
          if (socket) {
            socket.emit('seen-msg', { recipientId: chatUser?.otherUser?._id });
          }
          setChatMsg(result?.data?.messages?.reverse());
        }
        setIsLoading3(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading3(false);
      });
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append('image', selectedFile);
      setFileLoading(true);
      await uploadFile({ data: formData })
        .then((res) => {
          if (res) {
            setImageUrl(res.image)
            setType('image');
            setFileLoading(false);
          }
        }).catch((err) => {
          message.error('Failed to upload file');
          setFileLoading(false);
        })
    }
  };

  const handleFileChange2 = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append('video', selectedFile);
      setFileLoading2(true);
      await uploadVideo({ data: formData })
        .then((res) => {
          if (res) {
            setType('video');
            setVideoUrl(res.video)
            setFileLoading2(false);
          }
        }).catch((err) => {
          message.error('Failed to upload file');
          setFileLoading2(false);
        })
    }
  };

  const handleFileChange3 = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      setFileLoading3(true);
      await uploadDocument({ data: formData })
        .then((res) => {
          if (res) {
            setType('url');
            setDocumentUrl(res.image)
            setFileLoading3(false);
          }
        }).catch((err) => {
          message.error('Failed to upload file');
          setFileLoading3(false);
        })
    }
  };

  const handleClear = () => {
    setImageUrl('');
    setVideoUrl('');
    setDocumentUrl('');
    setType('text');
  };

  const items1 = [
    {
      label: <button type="button" className="w-full">
        <label htmlFor={`fileInput1`} style={{ cursor: "pointer" }} className="cursor-pointer w-full">
          {fileLoading ? (
            <div style={{ height: '100px' }} className="w-full border rounded-4 bg_light text_primary d-flex justify-content-center align-items-center">
              <Spinner size="sm" />
            </div>
          ) : imageUrl ? (
            <img
              src={imageUrl}
              alt="Preview"
              style={{ height: "100px", objectFit: "cover" }}
              className="rounded-4 w-full bg_light object-cover"
            />
          ) : (
            <div className="rounded-4 w-full text_black relative flex gap-3 justify-start items-center">
              <Camera size={20} />
              <span className="plusJakara_medium">Upload Image</span>
            </div>
          )}
        </label>
        <Input
          type="file"
          id={`fileInput1`}
          accept="image/*"
          className="visually-hidden"
          onChange={handleFileChange}
        />
      </button>,
      key: '0',
    },
    {
      label: <button type="button" className="w-full">
        <label style={{ cursor: "pointer" }} htmlFor={`fileInput2`} className="cursor-pointer w-full">
          {fileLoading2 ? (
            <div style={{ height: '100px' }} className="w-full border rounded-4 bg_light text_primary d-flex justify-content-center align-items-center">
              <Spinner size="sm" />
            </div>
          ) : videoUrl ? (
            <video
              src={videoUrl}
              alt="Preview"
              style={{ height: "100px", objectFit: "cover" }}
              className="rounded-4 w-full bg_light object-cover"
              controls
            />
          ) : (
            <div className="rounded-4 w-full text_black relative flex gap-3 justify-start items-center">
              <IoVideocamOutline size={24} />
              <span className="plusJakara_medium">Upload Video</span>
            </div>
          )}
        </label>
        <Input
          type="file"
          id={`fileInput2`}
          accept="video/*"
          className="visually-hidden"
          onChange={handleFileChange2}
        />
      </button>,
      key: '1',
    },
    {
      label:
        <button type="button" className="w-full">
          <label style={{ cursor: "pointer" }} htmlFor={`fileInput3`} className="cursor-pointer w-full">
            {fileLoading2 ? (
              <div style={{ height: '100px' }} className="w-full border rounded-4 bg_light text_primary d-flex justify-content-center align-items-center">
                <Spinner size="sm" />
              </div>
            ) : documentUrl ? (
              <a href={documentUrl} target="__blank" className="text_black plusJakara_medium hover:underline">{documentUrl}</a>
            ) : (
              <div className="rounded-4 w-full text_black relative flex gap-3 justify-start items-center">
                <File size={22} />
                <span className="plusJakara_medium">Upload Document</span>
              </div>
            )}
          </label>
          <Input
            type="file"
            id={`fileInput3`}
            accept=".pdf,.doc,.docx,.txt,.xls,.xlsx"
            className="visually-hidden"
            onChange={handleFileChange3}
          />
        </button>,
      key: '2',
    },
  ]

  const handleChatClick = async (userId) => {
    setIsLoading3(true);
    getUserChat(userId);
  };

  useLayoutEffect(() => {
    handleChatClick(activeChatId);
  }, [activeChatId]);
  useEffect(() => {
    if (chatMsg?.length > 0) {
      if (lastId === 0) {
        chatMessagesRef.current.scrollTop =
          chatMessagesRef.current.scrollHeight;
      } else {
        if (newMsg) {
          chatMessagesRef.current.scrollTop =
            chatMessagesRef.current.scrollHeight;
          setNewMsg(true);
        }
      }
    }
  }, [chatMsg]);

  const handleScroll = async () => {
    if (
      chatMessagesRef.current.scrollTop === 0 &&
      lastId + 30 < count &&
      !isLoading3
    ) {
      setNewMsg(false);
      setIsLoading2(true);
      await axiosInstance
        .get(`msg/messages/${activeChatId}/${lastId + 30}`)
        .then(async (result) => {
          if (result?.data?.success) {
            setLastId(lastId + 30);
            const data = [...result?.data?.messages?.reverse(), ...chatMsg];
            setChatMsg(data);
            setIsLoading2(false);
          }
          setIsLoading3(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading3(false);
        });
    } else setIsLoading2(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!highlightedDate) {
      message.error("Please select a date");
      return;
    }
    const data = {
      startDate: new Date(highlightedDate).toISOString(),
      title: scheduleTitle,
      to_id: chatUser?.otherUser?._id
    }
    setIsLoading(true)
    await createSchedule({ data: data }).then((res) => {
      if (res.success === true) {
        message.success("Scheduled successfully");
        setIsLoading(false)
        setShowPopup(false)
        setscheduleTitle('')
        setHighlightedDate(null)
      } else {
        message.error("Failed to Scheduled");
        setIsLoading(false)
      }
    }).catch((err) => {
      console.error("Error Scheduled:", err);
      message.error(err.response.data.message)
      setIsLoading(false)
    })
  }

  return (
    <div className="chat_height overflow-hidden position-relative">
      <div className="flex justify-between w-full p-2 max-md:mt-[4rem] items-center">
        <div className="d-flex align-items-center w-full border-b border-b-gray-200">
          <div>
            <button
              className="d_left_button"
              onClick={() => {
                setResponsiveChat(false);
              }}
            >
              <ChevronLeft />
            </button>
          </div>
          <div className="w-100 py-2 px-3 d-flex justify-content-between align-items-center">
            <div className="d-flex gap-1 algin-items-center">
              {/* <img src={chatUser?.otherUser?.profileImage ? global.BASEURL + "/" + chatUser?.otherUser?.profileImage : profile} className="rounded-circle bg-white" alt=""
              style={{ height: '48px', width: "48px", objectFit: "cover" }}
              /> */}
              <div style={{ minWidth: "55px" }}>
                <img
                  src={chatUser?.otherUser?.profilePicture || avatar}
                  alt=""
                  className="chat_profile_img"
                />
              </div>
              {/* <RiProfileFill /> */}
              <div className="d-flex  flex-column">
                <span className=" text_black plusJakara_medium">{`${chatUser?.otherUser?.name}`}</span>
                <span className="plusJakara_regular text_black text-sm ">
                  {chatUser?.otherUser?.email}
                </span>
              </div>
            </div>
          </div>
          {userType === 'company' ?
            <button onClick={() => navigate('/create-estimate', { state: { estimateUser: chatUser } })} className="text_white bg_primary text-xs px-3 py-2 rounded-2 plusJakara_regular whitespace-nowrap">Create Estimate</button> :
            <div className="flex gap-2 items-center">
              <button style={{ border: '1.3px solid #003F7D' }} onClick={handleShowPopup} type="button" className="w-full plusJakara_medium rounded-2 whitespace-nowrap px-2 py-1 text_primary">
                Add Schedule
              </button>
              <button onClick={() => {
                dispatch(setServiceProvider(chatUser?.otherUser))
                navigate(`/services/${chatUser?.otherUser?._id}?selected=services`)
              }} type="button" className="w-full plusJakara_medium whitespace-nowrap bg_primary px-2 py-1 rounded-2 text_white">
                Fixed Services
              </button>
            </div>}
        </div>
      </div>

      <div className="position-relative">
        <div
          ref={chatMessagesRef}
          onScroll={handleScroll}
          className="chat-messages scrolbar px-2 py-3"
        >
          {isLoading3 ? (
            <div className="text-center w-100">
              <Spinner
                style={{
                  width: "20px",
                  height: "20px",
                  marginTop: "3px",
                  borderWidth: "0.15em",
                }}
                animation="border"
                role="status"
              >
                <span className="plusJakara_regular visually-hidden">
                  Loading...
                </span>
              </Spinner>
            </div>
          ) : (
            <>
              {isLoading2 && (
                <div className="text-center">
                  {" "}
                  <Spinner
                    style={{
                      width: "20px",
                      height: "20px",
                      marginTop: "3px",
                      borderWidth: "0.15em",
                    }}
                    animation="border"
                    role="status"
                  ></Spinner>
                </div>
              )}
              {chatMsg?.length > 0 &&
                chatMsg?.map((msg, index) => (
                  <Fragment key={index}>
                    <ChatMessage
                      left={userData?._id === msg?.sender ? false : true}
                      message={msg?.message}
                      type={msg?.type}
                      timestamp={`${msg?.createdAt}`}
                    />
                  </Fragment>
                ))}
            </>
          )}
        </div>
      </div>
      <form onSubmit={sendMessage} className="px-3">
        <div className="flex w-full justify-end items-end">
          {fileLoading || fileLoading2 || fileLoading3 ? (
            <div className="loader">
              <div className="p-3 p-md-5 flex justify-center items-center w-full">
                <Spinner size="sm" className="text_primary" />
              </div>
            </div>
          ) : type === 'image' ? (
            <div className=' relative selected_img bg-[#f4f4f4] rounded-3'>
              <img src={imageUrl} alt="Selected" className="w-100 rounded-3 h-100" />
              <button className="absolute -top-2 -right-2 bg_light p-1 rounded-full" onClick={handleClear}>
                <X />
              </button>
            </div>
          ) : type === 'video' ? (
            <div className='relative selected_img bg-[#f4f4f4] rounded-3'>
              <video src={videoUrl} controls className="w-100 rounded-3 h-100" />
              <button className="absolute -top-2 -right-2 bg_light p-1 rounded-full" onClick={handleClear}>
                <X />
              </button>
            </div>
          ) : type === 'url' ? (
            <div className="position-relative">
              <a href={documentUrl} target="__blank" className="text-black">
                {documentUrl}
              </a>
              <button className="absolute -top-2 -right-2 bg_light p-1 rounded-full" onClick={handleClear}>
                <X />
              </button>
            </div>
          ) : ''}
        </div>
        <div className="w-100">
          <div className="d-flex gap-2 my-3">
            <Dropdown
              menu={{
                items: items1,
              }}
              trigger={['click']}
            >
              <button type="button" className="btn btn-light">
                <Plus />
              </button>
            </Dropdown>
            <div className="position-relative w-100">
              <input
                type="text"
                style={{ padding: "10px" }}
                disabled={isLoading3 || imageUrl || videoUrl || documentUrl}
                id="chatInput"
                required
                className="form-control rounded-3 plusJakara_regular ps-3 text-base "
                placeholder="Send Message..."
              />
            </div>
            <button
              disabled={isLoading3 || fileLoading || fileLoading2 || fileLoading3}
              className="rounded-3 py-[10px] h-fit px-[16px] bg_primary"
              type="submit"
            >
              <Send className="text-white p-0 m-0" style={{ width: "1rem" }} />
            </button>
          </div>
        </div>
      </form>

      <Modal
        open={showPopup}
        onCancel={() => setShowPopup(false)}
        footer={false}
        width={600}
        centered
        maskClosable={false}
      >
        <Form onSubmit={handleSubmit}>
          <h6 className="text_primary plusJakara_medium w-full text-center">Add Schedule</h6>
          <div className="mb-3">
            <Label className="form-label text_secondary2 max-md:text-sm  plusJakara_regular">
              Title
            </Label>
            <Input
              type="text"
              className="form-control rounded-3"
              name="email"
              id="email"
              required
              style={{ padding: '10px 12px', }}
              placeholder="Enter Title here..."
              onChange={(e) => setscheduleTitle(e.target.value)}
              value={scheduleTitle}
            />
          </div>
          <div className="mb-3">
            <Label className="form-label text_secondary2 max-md:text-sm plusJakara_regular">
              Select Date
            </Label>
            <DatePicker
              selected={highlightedDate}
              required
              minDate={new Date()}
              onChange={handleDateSelect}
              includeDates={selectedDates}
              inline
              dayClassName={customDayClassName}
              className="bg-gray-800 rounded-md"
              calendarClassName="bg-gray-800 text-white"
            />
          </div>
          <button type="submit" disabled={isLoading} style={{ padding: '16px', width: '100%' }} className="bg_primary text_white w-full rounded-3 plusJakara_medium">{isLoading ? <Spinner size="sm" style={{ color: 'white' }} /> : 'Schedule'}</button>
        </Form>
      </Modal>

    </div>
  );
};
export default ChatMessageList;