/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Blogs from './homeComponents2/blogs';
import BusinessWork from './homeComponents2/businessWork';
import Categories from './homeComponents2/categories';
import HomeHeader2 from './homeComponents2/homeHeader2';
import HomeServices from './homeComponents2/homeServices';
import ProviderPortfolio from './homeComponents2/providerPortfolio';
import ShopServices from './homeComponents2/shopServices';
import HomeCoupons from './homeComponents2/homeCoupons';
import { getCoupon } from '../api/coupon';
import { useDispatch, useSelector } from 'react-redux';
import { setCoupons } from '../redux/services';


const Home2 = () => {
    const dispatch = useDispatch();
    const coupons = useSelector((state) => state.services?.coupons);
    const [loading, setLoading] = useState(true);
    const handleCategorySelect = (category) => {
    };

    const handleFetchCoupons = async () => {
        if (coupons?.length > 0) {
            setLoading(false);
            return;
        }

        setLoading(true);
        try {
            const res = await getCoupon(1);
            const fetchedCoupons = res.success ? res.coupons : [];
            dispatch(setCoupons(fetchedCoupons));
        } catch (error) {
            console.error('Failed to fetch coupons:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!coupons?.length) {
            handleFetchCoupons();
        } else {
            setLoading(false);
        }
    }, [coupons]);


    return (
        <React.Fragment>
            <HomeHeader2 />
            <Categories navigateOnCategoryClick={true} onSelectCategory={handleCategorySelect} />
            <ProviderPortfolio />
            <HomeServices />
            {coupons?.length > 0 &&
                <HomeCoupons />}
            <BusinessWork />
            <ShopServices />
            <Blogs />
        </React.Fragment >
    );
}

export default Home2;