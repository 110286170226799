import React from 'react';
import '../../style/main.css';

const SkeletonLoader = () => {
    return (
        <div className="flex flex-col rounded-4 border gap-3 overflow-hidden skeleton-box">
            <div className="skeleton-img rounded-3" style={{ height: '13rem', width: '100%' }}></div>
            <div className="p-3 flex flex-col gap-2">
                <div className="skeleton-text" style={{ height: '20px', width: '70%' }}></div>
                <div className="skeleton-text" style={{ height: '15px', width: '50%' }}></div>
            </div>
        </div>
    );
};

export default SkeletonLoader;
