/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Spinner } from 'react-bootstrap';
import { useJsApiLoader } from '@react-google-maps/api';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa6';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { getEmployeeTasks } from '../../api/employee';
import EarningChart from '../../common/earningChart';
import DTable from '../../DataTable/DTable';
import '../../style/swiper.css';

const getTime = (start, end) => {
    if (!end) {
        return "...";
    }

    const checkIn = moment(start);
    const checkOut = moment(end);

    const duration = moment.duration(checkOut.diff(checkIn));
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const timeDiff = `${days > 0 ? days + "d " : ""}${String(hours).padStart(
        2,
        "0"
    )}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    return timeDiff;
};

const EmployeeDetail = () => {
    const { state } = useLocation()
    const employeeData = state?.employeeData || null
    const [searchParams, setSearchParams] = useSearchParams()
    const [active, setActive] = useState('tasks');
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([])
    const [loading, setloading] = useState(false)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_APIKEY,
        libraries: ['places', "maps"]
    });
    const { id } = useParams();

    const handleTab = (tab) => {
        const data = {
            selected: tab
        }
        setSearchParams(data)
    };


    const handleViewDetails = (row) => {
        navigate(`/employee-list/${row?._id}/detail`, { state: { employeeTaskDetail: row } })
    };

    useEffect(() => {
        if (employeeData || id) {
            setloading(true)
            getEmployeeTasks(employeeData?._id || id)
                .then((res) => {
                    if (res.success === true) {
                        setTasks(res?.orders);
                        setloading(false)
                    } else {
                        setTasks([]);
                        setloading(false)
                    }
                }).catch((err) => {
                    setloading(false)
                    setTasks([]);
                })
        }
    }, [employeeData]);

    useEffect(() => {
        if (searchParams.get('selected')) {
            setActive(searchParams.get('selected'))
        } else {
            const data = {
                selected: 'tasks'
            }
            setSearchParams(data)
        }
    }, [searchParams]);

    const columns = [
        {
            name: 'Order Id',
            sortable: true,
            selector: row => '#' + row?.order_id,
            minWidth: '100px'
        },
        {
            name: 'Title',
            sortable: true,
            selector: row => row?.estimate?.name,
            minWidth: '150px'
        },
        {
            name: 'Address',
            sortable: true,
            selector: row => row?.address || 'Not Found',
            minWidth: '250px'
        },
        {
            name: 'Project Start Date',
            sortable: true,
            selector: row => moment(row?.estimate?.date).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        {
            name: 'Project End Date',
            sortable: true,
            selector: row => moment(row?.estimate?.endDate).format('ddd DD MMM YYYY'),
            minWidth: '150px'
        },
        {
            name: 'Status',
            sortable: true,
            cell: row => (
                <div
                    className="px-2 py-1 w-fit text-center rounded-2"
                    style={{ backgroundColor: "#f6911e1b" }}
                >
                    <span className="plusJakara_medium text_primary2">
                        {row?.status === 'pending' ? 'In Progress' : 'Completed'}
                    </span>
                </div>
            ),
            minWidth: '100px'
        },
        {
            name: 'View Detail',
            cell: row => (
                <button className="btn-view-details ms-3 bg_light rounded-2 p-2" onClick={() => handleViewDetails(row)}>
                    <FaEye />
                </button>
            ),
            minWidth: '50px'
        }
    ];

    return (
        <main className='p-3 p-md-5 w-full'>
            <Container fluid className='bg_white rounded-3 p-4 w-full'>
                <div className="flex flex-col mb-4 gap-3 w-full">
                    <div className="flex gap-4 items-center w-full">
                        <button
                            onClick={() => navigate('/employee-list')}
                            className="bg_primary rounded-3 p-2"
                        >
                            <FaArrowLeft className='text_white' />
                        </button>
                        <h4 className="text_primary mb-0 plusJakara_semibold">Employee Task Details</h4>
                    </div>
                </div>
                <Row gutter={16}>
                    <Col xs={24} >
                        <div className="flex flex-col gap-4 w-full">
                            {/* <div style={{ backgroundColor: '#fff' }} className="flex gap-3 items-center shadow-sm border rounded-2 p-3">
                                <img alt='' src={avatar} style={{ width: "80px", height: "80px", borderRadius: '5px' }} />
                                <div className="flex flex-col gap-1 items-start">
                                    <h5 className="text_primary mb-0 plusJakara_semibold">Jack Service Cleaner</h5>
                                    <div className="flex gap-1 items-center">
                                        <FaStar color='#FF8A00' size={18} />
                                        <FaStar color='#FF8A00' size={18} />
                                        <FaStar color='#FF8A00' size={18} />
                                        <FaStar color='#FF8A00' size={18} />
                                        <FaStar color='#FF8A00' size={18} />
                                    </div>
                                    <h6 className="text_black plusJakara_medium mb-0">5 Reviews</h6>
                                </div>
                            </div> */}
                            <div className="flex flex-col">
                                <div style={{ backgroundColor: '#fff', maxHeight: "75vh", overflowY: 'hidden', height: '100%' }} className="flex gap-4 flex-col items-center shadow-sm border rounded-2 p-3">
                                    <div className="flex gap-3 items-center mb- w-full" style={{ borderBottom: '1px solid #EBEEF7' }}>
                                        <button onClick={() => handleTab('tasks')}
                                            style={{ borderBottom: active === 'tasks' ? '2px solid #F6921E' : '' }}
                                            className={`px-3 py-2 text-lg plusJakara_medium ${active === 'tasks' ? 'text_primary2' : 'text_secondary2'} flex items-center justify-center gap-2`}>
                                            Tasks
                                        </button>
                                        <button onClick={() => handleTab('stats')}
                                            style={{ borderBottom: active === 'stats' ? '2px solid #F6921E' : '' }}
                                            className={`px-3 py-2 text-lg plusJakara_medium ${active === 'stats' ? 'text_primary2' : 'text_secondary2'} flex items-center justify-center gap-2`}>
                                            Profile
                                        </button>
                                    </div>
                                    <div style={{ overflowY: 'auto' }} className="flex flex-col gap-4 items-start w-full">
                                        {active === 'stats' &&
                                            <>
                                                <div className="gridDashboard6 mb-3 w-100">
                                                    <div className="w-100 p-3 rounded-3 gap-3 flex justify-between flex-col items-center" style={{ backgroundColor: '#FFE2E5' }}>
                                                        <h5 className="plusJakara_medium">Completed</h5>
                                                        <h3 className="plusJakara_bold mb-0">232</h3>
                                                    </div>
                                                    <div className="w-100 p-3 rounded-3 gap-3 flex justify-between flex-col items-center" style={{ backgroundColor: '#FFF4DE' }}>
                                                        <h5 className="plusJakara_medium">Assigned</h5>
                                                        <h3 className="plusJakara_bold mb-0">232</h3>
                                                    </div>
                                                    <div className="w-100 p-3 rounded-3 gap-3 flex justify-between flex-col items-center" style={{ backgroundColor: '#DCFCE7' }}>
                                                        <h5 className="plusJakara_medium">Pending</h5>
                                                        <h3 className="plusJakara_bold mb-0">232</h3>
                                                    </div>
                                                    <div className="w-100 p-3 rounded-3 gap-3 flex justify-between flex-col items-center" style={{ backgroundColor: '#FBFFD4' }}>
                                                        <h5 className="plusJakara_medium">Projects</h5>
                                                        <h3 className="plusJakara_bold mb-0">232</h3>
                                                    </div>
                                                    <div className="w-100 p-3 rounded-3 gap-3 flex justify-between flex-col items-center" style={{ backgroundColor: '#F3E8FF' }}>
                                                        <h5 className="plusJakara_medium">Tasks</h5>
                                                        <h3 className="plusJakara_bold mb-0">232</h3>
                                                    </div>
                                                </div>
                                                <EarningChart />
                                            </>
                                        }
                                        {active === 'tasks' &&
                                            loading ? (
                                            <div className='my-5 flex justify-center w-full items-center' >
                                                <Spinner size="sm" className='text_primary' style={{ color: "#000" }} />
                                            </div>) :
                                            (!tasks || tasks.length === 0) ? (
                                                <div className='flex justify-center items-center w-full my-5'>
                                                    <span className="plusJakara_medium md:text-lg">No Task Found</span>
                                                </div>) : <DTable columns={columns} data={tasks} />}
                                    </div>
                                </div>
                            </div>
                        </div >
                    </Col >
                </Row >
            </Container >
        </ main >
    );
};

export default EmployeeDetail;
