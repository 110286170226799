import { axiosInstance2 } from '../redux/axiosInstance';

// Upload File Function
export const uploadFile = async ({ data }) => {

    try {
        const res = await axiosInstance2.post(`image/upload`, data);
        // console.log(res.data, "res");
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

// Upload Document Function
export const uploadDocument = async ({ data }) => {
    try {
        const res = await axiosInstance2.post('image/file', data);
        console.log('File Upload Response:', res?.data);
        return res?.data;
    } catch (error) {
        console.error('File Upload Error:', error?.response?.data || error.message);
        throw error;
    }
};

// Upload Video Function


export const uploadVideo = async ({ data }) => {

    try {
        const res = await axiosInstance2.post(`video/upload`, data);
        // console.log(res.data, "res");
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};
