/* eslint-disable no-unused-vars */
// React Basic and Bootstrap
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Card,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Row
} from "reactstrap";

import { useFormik } from "formik";
import { Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { ArrowLeft } from "react-feather";
import { FaArrowLeft } from "react-icons/fa6";
import { forgotPassword } from "../api/signup";
import { message } from "antd";

const ForgotPassword = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [phone, setPhone] = useState("1");
    const [isLoading, setisLoading] = useState(false)
    const [activeTab, setActiveTab] = useState("phone");
    const [showPassword, setShowPassword] = useState(false);
    const validationSchema = Yup.object().shape({
        // email: Yup.string()
        //     .email("Enter a valid email").required('Email is required')
        // .test(
        //     "emailRequired",
        //     "Email is required",
        //     function (value) {
        //         return this.parent.activeTabs === "email" ? !!value : true;
        //     }
        // ),
        // phone: Yup.string()
        //     .test(
        //         "phoneRequired",
        //         "Phone number is required",
        //         function (value) {
        //             return this.parent.activeTabs === "phone" ? !!value : true;
        //         }
        //     )
        //     .matches(/^\+?[0-9]{10,15}$/, "Phone number is not valid")
        //     .min(10, "Phone number must be at least 10 digits")
        //     .max(15, "Phone number must be at most 15 digits"),
    });
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            phone: "",
        },
        validationSchema,

        onSubmit: async (values, { resetForm }) => {
            setisLoading(true);
            await forgotPassword("+" + values.phone)
                .then((res) => {
                    if (res.data.success === true) {
                        navigate("/reset-password", { state: { forgotData: { ...res.data, phone: ("+" + values?.phone) } } });
                        message.success(res.data.message)
                    } else {
                        setisLoading(false)
                    }
                }).catch((err) => {
                    message.error(err.response.data.message)
                    setisLoading(false)
                }).finally(() => {
                    setisLoading(false)
                })
        },
    });

    const handlePhoneChange = (value) => {
        validation.setFieldValue('phone', value);
        validation.setFieldTouched('phone', true, true);
    };

    return (
        <React.Fragment>
            <Container fluid style={{ backgroundColor: '#F5F5F5', paddingTop: "5rem", paddingBottom: "1rem" }} className="flex justify-center min-vh-100 items-center w-full">
                <Card className="bg_white rounded-4 max-w-xl d-flex align-items-center flex-column py-5 max-lg:px-8 lg:px-12">
                    <div className="absolute top-11" style={{ left: '30px', }}>
                        <Link to={-1}>
                            <div className="bg_light flex justify-center items-center w-10 md:w-12 h-10 md:h-12 text_primary rounded-5">
                                <FaArrowLeft size={24} />
                            </div>
                        </Link>
                    </div>
                    <h1 className="plusJakara_semibold text-xl lg:text-2xl text-center text_primary">Forget Password?</h1>
                    <Form
                        style={{ maxWidth: '650px', width: '100%' }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                        className=" mt-4 auth_form">
                        <Row>
                            <div className="w-full mb-3 px-0">
                                <Label className="form-label text_secondary2 max-md:text-sm  plusJakara_regular" htmlFor="phone">
                                    Phone Number
                                </Label>
                                <div className="d-flex tab-content w-full justify-content-center flex-column align-items-center gap-1">
                                    <PhoneInput
                                        country={"us"}
                                        placeholder="Phone Number"
                                        value={validation.values.phone}
                                        onChange={handlePhoneChange}
                                        onBlur={validation.handleBlur}
                                        className="phon_inp text_secondarydark plusJakara_medium"
                                        disableSearchIcon={true}
                                    />
                                    {validation.touched.phone && validation.errors.phone && (
                                        <FormFeedback type="invalid" className="d-block">{validation.errors.phone}</FormFeedback>
                                    )}
                                </div>
                            </div>
                            {/* <div className="flex w-full bg_light mb-4 px-0 rounded-5">
                                <button type="button" onClick={() => {
                                    setActiveTab('phone')
                                    validation.setFieldValue('activeTabs', 'phone')
                                }} className={`rounded-5 w-full py-3 plusJakara_medium text_secondary2 ${activeTab === 'phone' ? 'bg_primary text_white' : 'text_secondary2'} `}>Phone Number</button>
                                <button type="button" onClick={() => {
                                    setActiveTab('email')
                                    validation.setFieldValue('activeTabs', 'email')
                                }} className={`rounded-5 w-full py-3 plusJakara_medium text_secondary2 ${activeTab === 'email' ? 'bg_primary text_white' : 'text_secondary2'} `}>Email</button>
                            </div>
                            {activeTab === 'phone' &&
}
                            {activeTab === 'email' && */}
                            {/* <div className="my-3 px-0">
                                <Label className="form-label text_secondary2 max-md:text-sm 
max-md:text-sm plusJakara_regular" htmlFor="email">
                                    Email
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control rounded-3"
                                    name="email"
                                    id="email"
                                    style={{ padding: '12px 16px', }}
                                    placeholder="Enter your Email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                    invalid={
                                        validation.touched.email && validation.errors.email ? true : false
                                    }
                                />
                                {validation.touched.email && validation.errors.email ? (
                                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                ) : null}
                            </div> */}
                            {/* } */}
                            <Col lg={12} className="mb-0 mt-3 px-0">
                                <div className="d-grid">
                                    <button className="py-[12px] md:py-3 max-md:text-sm rounded-5 bg_primary plusJakara_medium text_white" disabled={isLoading} type="submit">
                                        {isLoading ? <Spinner size="sm"></Spinner> :
                                            "Reset Password"}
                                    </button>
                                </div>
                            </Col>
                            <Col className="text-center mt-4">
                                <div className="flex gap-1 justify-center items-center w-full">
                                    <h6 className="text_primary mb-0">
                                        Remembered your password?
                                    </h6>{" "}
                                    <Link
                                        to="/login"
                                        className="text_primary2 mb-0 plusJakara_medium"
                                    >
                                        Sign In
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Container >
        </React.Fragment >
    );
};
export default ForgotPassword;
