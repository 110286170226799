/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { customerSideServices } from '../api/service';
import { setServiceBanner, setservices } from '../redux/services';
import '../style/swiper.css';
import HeaderNavigationImage from './common/headerNavigationImage';
import ServiceItem2Skeleton from './common/serviceItem2Skeleton';
import Categories from './homeComponents2/categories';
import { useSocket } from './messages/socketProvider';
import ServiceItem2 from './serviceItem2';
import { debounce } from 'lodash';
import { allHomeBanners } from '../api/coupon';

const AllServices = () => {
    const navigate = useNavigate();
    const serviceBanner = useSelector((state) => state.services?.serviceBanner);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showMoreLoading, setShowMoreLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const services = useSelector((state) => state?.services?.services);
    const socket = useSocket();

    useEffect(() => {
        if (socket) {
            socket.emit('seen-service', {});
        }
    }, [socket]);

    const fetchServices = async (isShowMore = false) => {
        if (services?.length > 0 && !selectedCategory) return;
        if (isShowMore) {
            setShowMoreLoading(true);
        } else {
            setLoading(true);
        }
        try {
            const res = await customerSideServices({ data: { category: selectedCategory?._id } }, page);
            if (page === 1) {
                dispatch(setservices(res?.services || []));
            } else {
                dispatch(setservices((prevServices) => [...prevServices, ...(res?.services || [])]));
            }
            setCount(res?.count?.totalPage || 0);
        } catch (err) {
            if (page === 1) {
                dispatch(setservices([]));
            }
        } finally {
            if (isShowMore) {
                setShowMoreLoading(false);
            } else {
                setLoading(false);
            }
        }
    };

    const handleSelectCategory = (category) => {
        setSelectedCategory(category);
        fetchData(category._id);
    };

    const handleShowMore = () => {
        setPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
        fetchServices(page > 1);
    }, [selectedCategory, page]);

    const fetchData = useCallback(
        debounce(async (categoryId = null) => {
            try {
                const res = await allHomeBanners('service', categoryId || '');
                if (res?.data) {
                    dispatch(setServiceBanner(res?.data?.banners));
                }
            } catch (error) {
                console.error("Error fetching banners", error);
            }
        }, 500),
        []
    );

    useEffect(() => {
        if (!serviceBanner?.length) {
            fetchData();
        }
        return () => {
            fetchData.cancel();
        };
    }, [serviceBanner]);

    return (
        <main className="pb-5">
            <div className="mb-4 w-full">
                <HeaderNavigationImage
                    data={serviceBanner[0]}
                    page="Fix Services"
                    serviceName={`${selectedCategory ? selectedCategory?.name : 'All'} Services`}
                />
            </div>
            <Categories onSelectCategory={handleSelectCategory} selectedCategory={selectedCategory} />
            <Container>
                <h6 className="text-3xl lg:text-4xl plusJakara_bold text_black">
                    Fix <span className="text-3xl lg:text-4xl plusJakara_bold text_primary"> Services</span>
                </h6>
                {loading ? (
                    <div className="grid5 mt-4">
                        {Array(4)
                            .fill(0)
                            .map((_, index) => (
                                <ServiceItem2Skeleton key={index} />
                            ))}
                    </div>
                ) : !services || services.length === 0 ? (
                    <div className="flex justify-center items-center w-full my-5">
                        <span className="plusJakara_medium md:text-lg">No Service Found</span>
                    </div>
                ) : (
                    <div className="grid5 mb-3 py-4">
                        {services.map((category, i) => (
                            <ServiceItem2 key={i} category={category} />
                        ))}
                    </div>
                )}
                {showMoreLoading ?
                    <div className="flex justify-center w-full">
                        <Spinner />
                    </div> :
                    page < count && (
                        <div className="flex justify-center w-full">
                            <button
                                onClick={handleShowMore}
                                disabled={showMoreLoading}
                                className="bg_primary py-3 px-4 rounded-3 text_white plusJakara_semibold"
                            >
                                Show More
                            </button>
                        </div>
                    )}
            </Container>
        </main>
    );
};

export default AllServices;
