import React from 'react'
import { Container } from 'react-bootstrap'
import { businesscommunication, businesscreation, businessestimate, businessinvoice, businessmanagment, businessschedule } from '../../icons/icon'
import { useNavigate } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa6'

const BusinessWork = () => {
    const navigate = useNavigate()

    return (
        <Container className='pb-2 pb-md-5'>
            <div className="relative w-full">
                <h1 className="plusJakara_semibold relative text-2xl md:text-4xl min-[1900px]:text-5xl text-center text_dark">For Your Business</h1>
                <div className="absolute right-0 top-10 md:top-0 p-3">
                    <button onClick={() => navigate('/subscription-plan')} style={{ border: '1px solid #F6921E' }} className="text_primary2 flex gap-2 items-center max-md:text-xs justify-center rounded-2 plusJakara_medium py-[6px] px-2 px-md-3">See Plans <FaArrowRight /></button>
                </div>
            </div>
            <div className="gridWork1 w-100" style={{ marginTop: '5rem', marginBottom: "2rem" }}>
                <div className="rounded-4 px-2 px-md-3 py-3 py-md-4 w-full" style={{ border: '1.33px solid #2325362B' }}>
                    <div className="flex w-full justify-start gap-3 gap-md-4 items-start">
                        <img src={businessschedule} style={{ width: 'auto', }} className='h-8 md:h-10' alt="" />
                        <div className=' w-full flex flex-col gap-2'>
                            <h5 className="text_black mb-0 plusJakara_semibold">Scheduling</h5>
                            <span className="text_lightsecondary text-sm plusJakara_regular">Craft ideas that move the hearts, create
                                impact through innovation</span>
                        </div>
                    </div>
                </div>
                <div className="rounded-4 px-2 px-md-3 py-3 py-md-4 w-full" style={{ border: '1.33px solid #2325362B' }}>
                    <div className="flex w-full justify-start gap-3 gap-md-4 items-start">
                        <img src={businessinvoice} style={{ width: 'auto', }} className='h-8 md:h-10' alt="" />
                        <div className=' w-full flex flex-col gap-2'>
                            <h5 className="text_black mb-0 plusJakara_semibold">Invoicing</h5>
                            <span className="text_lightsecondary text-sm plusJakara_regular">Join the fleet of changemakers to reinvent
                                and co-create disruptive momentum</span>
                        </div>
                    </div>
                </div>
                <div className="rounded-4 px-2 px-md-3 py-3 py-md-4 w-full" style={{ border: '1.33px solid #2325362B' }}>
                    <div className="flex w-full justify-start gap-3 gap-md-4 items-start">
                        <img src={businessestimate} style={{ width: 'auto', }} className='h-8 md:h-10' alt="" />
                        <div className=' w-full flex flex-col gap-2'>
                            <h5 className="text_black mb-0 plusJakara_semibold">Estimation</h5>
                            <span className="text_lightsecondary text-sm plusJakara_regular">Coordinate development& lead processes
                                to drive sales and build funnels</span>
                        </div>
                    </div>
                </div>
                <div className="rounded-4 px-2 px-md-3 py-3 py-md-4 w-full" style={{ border: '1.33px solid #2325362B' }}>
                    <div className="flex w-full justify-start gap-3 gap-md-4 items-start">
                        <img src={businesscommunication} style={{ width: 'auto', }} className='h-8 md:h-10' alt="" />
                        <div className=' w-full flex flex-col gap-2'>
                            <h5 className="text_black mb-0 plusJakara_semibold">Communication</h5>
                            <span className="text_lightsecondary text-sm plusJakara_regular">Join the fleet of changemakers to reinvent
                                and co-create disruptive momentum.</span>
                        </div>
                    </div>
                </div>
                <div className="rounded-4 px-2 px-md-3 py-3 py-md-4 w-full" style={{ border: '1.33px solid #2325362B' }}>
                    <div className="flex w-full justify-start gap-3 gap-md-4 items-start">
                        <img src={businesscreation} style={{ width: 'auto', }} className='h-8 md:h-10' alt="" />
                        <div className=' w-full flex flex-col gap-2'>
                            <h5 className="text_black mb-0 plusJakara_semibold">Fix Service Creation</h5>
                            <span className="text_lightsecondary text-sm plusJakara_regular">Curate content and information to build
                                demand and turn the tides of growth.</span>
                        </div>
                    </div>
                </div>
                <div className="rounded-4 px-2 px-md-3 py-3 py-md-4 w-full" style={{ border: '1.33px solid #2325362B' }}>
                    <div className="flex w-full justify-start gap-3 gap-md-4 items-start">
                        <img src={businessmanagment} style={{ width: 'auto', }} className='h-8 md:h-10' alt="" />
                        <div className=' w-full flex flex-col gap-2'>
                            <h5 className="text_black mb-0 plusJakara_semibold">Employ Management</h5>
                            <span className="text_lightsecondary text-sm plusJakara_regular">Curate content and information to build
                                demand and turn the tides of growth</span>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default BusinessWork