/* eslint-disable no-unused-vars */
import moment from 'moment';
import React from 'react';
import { IoIosTimer } from 'react-icons/io';
import { IoPricetagOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const BlogItem = ({ category, showView = true }) => {
    const navigate = useNavigate()

    return (
        <div onClick={() => navigate(`/all-blogs/${category?._id}`, { state: { blogData: category } })} className="bg_white flex flex-col w-full overflow-hidden relative">
            <div className="relative overflow-hidden">
                <img src={category?.image} style={{ height: '15rem', cursor: 'pointer', width: '100%', objectFit: 'cover' }} className='relative img_hover' alt="" />
            </div>
            <div
                className='py-3 flex gap-2 flex-col w-full'>
                <div className="flex items-center gap-2 w-full">
                    <span className="text_black plusJakara_semibold text-sm">{category?.cat?.name}</span>
                    <div className='mt-1' style={{ width: '4px', height: '4px', borderRadius: "50%", backgroundColor: "black" }}></div>
                    <span className="text_black plusJakara_semibold text-sm">{moment(category?.createdAt).format('MMM DD,YYYY')}</span>
                </div>
                <div className="flex flex-col">
                    <h5 className="plusJakara_semibold transition-all cursor-pointer hover:text-[#F6921E] line-clamp-2">{category?.title}</h5>
                    <span className="text_black plusJakara_medium line-clamp-2" dangerouslySetInnerHTML={{ __html: category?.description }} />
                </div>
            </div>
        </div>
    )
}

export default BlogItem