/* eslint-disable no-unused-vars */
import { Spinner } from 'react-bootstrap';
import { message } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BiLogoGmail, BiSolidSend, BiSupport } from "react-icons/bi";
import { FaMapLocationDot } from 'react-icons/fa6';
import { WiTime7 } from "react-icons/wi";
import * as Yup from "yup";
import axiosInstance from '../redux/axiosInstance';
import HeaderNavigation from './common/headerNavigation';

const Contact = () => {
    const [loading, setloading] = useState(false)

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        subject: Yup.string().required("Subject is required"),
        msg: Yup.string().required("Message is required"),
        email: Yup.string()
            .email("Invalid email format")
            .required("Email is required"),
    });

    const handleSubmit = async (values, { resetForm }) => {
        setloading(true);
        await axiosInstance.post('support/create', values)
            .then((res) => {
                if (res.status === 201) {
                    setloading(false);
                    message.success('Support Sent Successfully');
                    resetForm();
                }
            }).catch((err) => {
                setloading(false);
                message.error('Failed to send support message');
            });
    };


    return (
        <main className='pb-5'>
            <HeaderNavigation page='Contact us' serviceName='Contact us' />
            <Container className="my-5" fluid="xxl">
                <Row gutter={16} className="g-3">
                    <Col xs={12} sm={12} md={12} lg={4} xl={5}>
                        <Row className="g-3">
                            <Col xs={12} sm={6} md={4} lg={12} xl={6}>
                                <div className="bg_white shadow-sm border rounded-[20px] cursor-pointer gap-3 flex flex-col justify-center items-center px-[15px] py-[20px] transition-transform duration-500 hover:-translate-y-2 h-[100%]">
                                    <div className="bg_primary rounded-[50%] w-[4.5rem] h-[4.5rem] text-white flex items-center justify-center">
                                        <FaMapLocationDot className="text-[2rem]" />
                                    </div>
                                    <div>
                                        <h4 className="color-0 text-[1.1rem] text-center plusJakara_medium mb-2">
                                            Office Address
                                        </h4>
                                        <h5 className="color-1 text-[0.9rem] text-center plusJakara_regular">
                                            25/B Milford Elm Drive Road, New York, USA
                                        </h5>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={12} xl={6}>
                                <div className="bg_white shadow-sm border rounded-[20px] cursor-pointer gap-3 flex flex-col justify-center items-center px-[15px] py-[20px] transition-transform duration-500 hover:-translate-y-2 h-[100%]">
                                    <div className="bg_primary rounded-[50%] w-[4.5rem] h-[4.5rem] text-white flex items-center justify-center">
                                        <BiSupport className="text-[2rem]" />
                                    </div>
                                    <div>
                                        <h4 className="color-0 text-[1.1rem] text-center plusJakara_medium mb-2">
                                            Call Us
                                        </h4>
                                        <h5 className="color-1 text-[0.9rem] text-center plusJakara_regular">
                                            +2 123 4565 788
                                        </h5>
                                        <h5 className="color-1 text-[0.9rem] text-center plusJakara_regular">
                                            +2 123 4565 789
                                        </h5>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={12} xl={6}>
                                <div className="bg_white shadow-sm border rounded-[20px] cursor-pointer gap-3 flex flex-col justify-center items-center px-[15px] py-[20px] transition-transform duration-500 hover:-translate-y-2 h-[100%]">
                                    <div className="bg_primary rounded-[50%] w-[4.5rem] h-[4.5rem] text-white flex items-center justify-center">
                                        <BiLogoGmail className="text-[2rem]" />
                                    </div>
                                    <div>
                                        <h4 className="color-0 text-[1.1rem] text-center plusJakara_medium mb-2">
                                            Email Us
                                        </h4>
                                        <h5 className="color-1 text-[0.9rem] text-center plusJakara_regular">
                                            info@example.com
                                        </h5>
                                        <h5 className="color-1 text-[0.9rem] text-center plusJakara_regular">
                                            support@example.com
                                        </h5>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={6} md={4} lg={12} xl={6}>
                                <div className="bg_white shadow-sm border rounded-[20px] cursor-pointer gap-3 flex flex-col justify-center items-center px-[15px] py-[20px] transition-transform duration-500 hover:-translate-y-2 h-[100%]">
                                    <div className="bg_primary rounded-[50%] w-[4.5rem] h-[4.5rem] text-white flex items-center justify-center">
                                        <WiTime7 className="text-[2rem]" />
                                    </div>
                                    <div>
                                        <h4 className="color-0 text-[1.1rem] text-center plusJakara_medium mb-2">
                                            Open Time
                                        </h4>
                                        <h5 className="color-1 text-[0.9rem] text-center plusJakara_regular">
                                            Mon - Sat (10AM - 05PM)
                                        </h5>
                                        <h5 className="color-1 text-[0.9rem] text-center plusJakara_regular">
                                            Sunday - <span className="color-2"> Closed</span>
                                        </h5>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} xl={7}>
                        <section className="bg_white shadow-sm border rounded-3 gap-3 px-4 py-4">
                            <h4 className="plusJakara_bold text-[1.4rem] color-0 mb-2">
                                Get In Touch
                            </h4>
                            <h5 className="color-1 text-[1rem] plusJakara_regular mb-4">
                                It is a long established fact that a reader will be distracted
                                by the readable content of a page words which even slightly when
                                looking at its layout.
                            </h5>
                            <Formik
                                initialValues={{
                                    name: "",
                                    email: "",
                                    subject: "",
                                    msg: "",
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ setFieldValue, handleChange, handleBlur, values }) => (
                                    <Form>
                                        <Row>
                                            <Col xl={6}>
                                                <div className="mb-3 custom_control">
                                                    <Field
                                                        type="text"
                                                        placeholder="Your Name"
                                                        id="name"
                                                        name="name"
                                                        style={{
                                                            padding: '12px'
                                                        }}
                                                        className="form-control plusJakara_medium rounded-3"
                                                        onChange={handleChange}
                                                    />
                                                    <ErrorMessage
                                                        name="name"
                                                        component="div"
                                                        className="text-danger text-sm plusJakara_regular"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl={6}>
                                                <div className="mb-3 custom_control">
                                                    <Field
                                                        type="email"
                                                        placeholder="Your Email"
                                                        id="email"
                                                        name="email"
                                                        style={{ padding: '12px' }}
                                                        className="form-control plusJakara_medium rounded-3"
                                                        onChange={handleChange}
                                                    />
                                                    <ErrorMessage
                                                        name="email"
                                                        component="div"
                                                        className="text-danger text-sm plusJakara_regular"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl={12}>
                                                <div className="mb-3 custom_control">
                                                    <Field
                                                        type="subject"
                                                        placeholder="Your Subject"
                                                        id="subject"
                                                        name="subject"
                                                        style={{ padding: '12px' }}
                                                        className="form-control plusJakara_medium rounded-3"
                                                        onChange={handleChange}
                                                    />
                                                    <ErrorMessage
                                                        name="subject"
                                                        component="div"
                                                        className="text-danger text-sm plusJakara_regular"
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl={12}>
                                                <div className="mb-3 custom_control">
                                                    <Field
                                                        as="textarea"
                                                        rows="5"
                                                        placeholder="Your Message"
                                                        id="msg"
                                                        name="msg"
                                                        style={{ padding: '12px' }}
                                                        className="form-control plusJakara_medium rounded-3"
                                                        onChange={handleChange}
                                                    />
                                                    <ErrorMessage
                                                        name="msg"
                                                        component="div"
                                                        className="text-danger text-sm plusJakara_regular"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <button
                                            type="submit"
                                            disabled={loading}
                                            className="w-[100%] d-flex align-items-center justify-content-center gap-2 rounded-[10px] h-[3rem] bg_primary text_white plusJakara_medium mt-4 text-[1rem]"
                                        >
                                            {loading ? <Spinner size="sm" style={{ color: "#fff" }} /> :
                                                <>Send Message <BiSolidSend />
                                                </>}
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </section>
                    </Col>
                </Row>
            </Container>
        </main>
    )
}

export default Contact