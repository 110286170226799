import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import StripeLinkModal from '../stripe/stripeLinkModal'

const StripeConnectError = () => {
    const [modalOpen, setmodalOpen] = useState(false)
    const userData = useSelector((state) => state.auth?.userData)

    return (
        <>
            {(!userData?.accountId_verified && (userData?.sub_type !== 'free' && userData?.sub_type !== 'basic')) && (
                <Alert variant='danger' className="container flex items-center justify-center gap-2 w-full my-4">
                    <h6 className="mb-0 plusJakara_medium">
                        Please link your Stripe account to enable online payments from your customers. Without linking your account, you will be unable to receive payments online.
                    </h6>
                    <button
                        onClick={() => setmodalOpen(true)}
                        className="w-fit rounded-2 whitespace-nowrap bg_primary plusJakara_medium px-2 py-1 text-xs text_white"
                    >
                        Connect now
                    </button>
                </Alert>
            )}
            <StripeLinkModal setstripeModal={setmodalOpen} stripeModal={modalOpen} />
        </>
    )
}

export default StripeConnectError