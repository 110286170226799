/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// React Basic and Bootstrap
import { message } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import {
    Col,
    Form,
    FormFeedback,
    Input,
    Label,
    Row
} from "reactstrap";
import * as Yup from "yup";
import { getCategory, getSubcat } from "../../api/category";
import { updateUser } from "../../api/signup";
import { setUserData } from "../../redux/loginForm";

const BusinessInformationForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isLogin = useSelector((state) => state.auth?.isLogin)
    const [categories, setcategories] = useState([])
    const [subcategories, setsubcategories] = useState([])
    const usertype = useSelector((state) => state.auth?.userData?.type)
    const userData = useSelector((state) => state.auth?.userData)
    const [isLoading, setisLoading] = useState(false)

    const handleFetchCategories = async () => {
        await getCategory()
            .then((res) => {
                if (res.success === true) {
                    setcategories(res.categories)
                }
            })
            .catch((err) => {
            })
    };

    useEffect(() => {
        handleFetchCategories()
    }, [])

    const handlecategorySelect = async (row) => {
        await getSubcat(row?.value)
            .then((res) => {
                if (res.success === true) {
                    setsubcategories(res.subcategories)
                } else {
                    setsubcategories([])
                }
            })
            .catch((err) => {
            })
    }

    useEffect(() => {
        if (userData) {
            const category = {
                value: userData.category?._id,
                label: userData.category?.name,
            }
            const subcategories = userData.subcategories.map((item) => ({
                value: item._id,
                label: item.name,
            }))
            if (userData?.category) {
                validation.setFieldValue('category', category || null);
            }
            validation.setFieldValue('subcategory', subcategories || []);
            validation.setFieldValue('insurance', userData.insurance || '');
            validation.setFieldValue('license', userData.license || '');
        }
    }, [userData]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            category: null,
            subcategory: [],
            insurance: "",
            license: "",
        },
        validationSchema: Yup.object().shape({
            category: Yup.object()
                .required("Please select a Category"),
            subcategory: Yup.array()
                .min(1, "Please select at least one Sub category"),
            insurance: Yup.string()
                .required("Insurance Number is required"),
            // license: Yup.string()
            //   .required("License Number is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            setisLoading(true);
            const data = {
                category: values.category.value,
                subcategories: values.subcategory.map((item) => item.value),
                insurance: values.insurance,
                license: values.license,
            };
            await updateUser({ data: data }).then((res) => {
                if (res.data.success === true) {
                    setisLoading(false);
                    dispatch(setUserData(res.data.user))
                    if (isLogin) {
                        message.success('Profile updated successfully');
                        navigate('/business-profile?selected=overview', { replace: true })
                    } else {
                        navigate('/availability-info', { replace: true })
                    }
                }
            }).catch((err) => {
                message.error('Failed to update profile');
                setisLoading(false);
            })
        }
    });

    return (
        <React.Fragment>
            <Form
                style={{ maxWidth: '800px', width: '100%' }}
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
                className="auth_form"
            >
                <Row>
                    <div className="mb-3 w-full px-0">
                        <Label className="form-label text_secondary2 max-md:text-sm  plusJakara_regular" htmlFor="category">
                            Select your Service Category
                        </Label>
                        <div className="flex flex-col w-full">
                            <Select
                                name="category"
                                id="category"
                                placeholder="Select category"
                                options={categories?.map((item) => ({
                                    label: item.name,
                                    value: item._id,
                                }))}
                                value={validation.values.category}
                                onChange={(selectedOption) => {
                                    handlecategorySelect(selectedOption);
                                    validation.setFieldValue("subcategory", null);
                                    validation.setFieldValue("category", selectedOption);
                                }}
                                onBlur={() => validation.setFieldTouched("category", true)}
                            />
                            {validation.touched.category && validation.errors.category && (
                                <p className="text-danger plusJakara_regular text-sm mt-1">{validation.errors.category}</p>
                            )}
                        </div>
                    </div>

                    {/* Subcategory Field */}
                    <div className="mb-3 w-full px-0">
                        <Label className="form-label text_secondary2 max-md:text-sm  plusJakara_regular" htmlFor="subcategory">
                            Select your Service Subcategories
                        </Label>
                        <div className="flex flex-col w-full">
                            <Select
                                name="subcategory"
                                id="subcategory"
                                isMulti
                                placeholder="Select sub category"
                                options={subcategories?.map((item) => ({
                                    label: item.name,
                                    value: item._id,
                                }))}
                                value={validation.values.subcategory}
                                onChange={(selectedOption) => {
                                    validation.setFieldValue("subcategory", selectedOption);
                                }}
                                onBlur={() => validation.setFieldTouched("subcategory", true)}
                            />
                            {validation.touched.subcategory && validation.errors.subcategory && (
                                <p className="text-danger plusJakara_regular text-sm mt-1">{validation.errors.subcategory}</p>
                            )}
                        </div>
                    </div>

                    {/* Insurance Field */}
                    <div className="mb-3 w-full px-0">
                        <Label className="form-label text_secondary2 max-md:text-sm  plusJakara_regular" htmlFor="insurance">
                            Insurance Number
                        </Label>
                        <div className="flex flex-col w-full">
                            <Input
                                type="text"
                                className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                name="insurance"
                                id="insurance"
                                style={{ padding: '14px 16px' }}
                                placeholder="Insurance Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.insurance || ""}
                                invalid={validation.touched.insurance && validation.errors.insurance ? true : false}
                            />
                            {validation.touched.insurance && validation.errors.insurance && (
                                <FormFeedback type="invalid">{validation.errors.insurance}</FormFeedback>
                            )}
                        </div>
                    </div>

                    {/* License Field */}
                    <div className="mb-3 w-full px-0">
                        <Label className="form-label text_secondary2 max-md:text-s plusJakara_regular" htmlFor="license">
                            License Number
                        </Label>
                        <div className="flex flex-col w-full">
                            <Input
                                type="text"
                                className="form-control rounded-3 text_secondary2 text-sm plusJakara_medium"
                                name="license"
                                id="license"
                                style={{ padding: '14px 16px' }}
                                placeholder="License Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.license || ""}
                                invalid={validation.touched.license && validation.errors.license ? true : false}
                            />
                            {validation.touched.license && validation.errors.license && (
                                <FormFeedback type="invalid">{validation.errors.license}</FormFeedback>
                            )}
                        </div>
                    </div>
                    <div className="w-full flex p-0 mt-3 justify-center">
                        <button className="py-[10px] py-md-3 max-md:text-sm rounded-3 w-full bg_primary plusJakara_medium text_white" disabled={isLoading} type="submit">
                            {isLoading ? <Spinner size="sm" /> : "Submit"}
                        </button>
                    </div>
                </Row>
            </Form>
        </React.Fragment>
    );
};
export default BusinessInformationForm;