import axiosInstance from "../redux/axiosInstance";

export const getCategory = async () => {
    try {
        const res = await axiosInstance.get(`category/admin/all`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};

export const getSubcat = async (catid) => {
    try {
        const res = await axiosInstance.get(`subcat/${catid}`);
        return res?.data
    } catch (error) {
        console.log(error, "error");
        throw error;
    }
};


export const allFaqCategories = async (page) => {
    try {
        const res = await axiosInstance.get(`faq-cat/all`);
        return res;
    } catch (error) {
        console.log(error, "error");
    }
}

export const allfaqs = async (page, cat) => {

    try {
        const res = await axiosInstance.get(`faq/admin/${page}?catId=${cat}`)
        return res;
    } catch (error) {
        console.log(error, "error");
    }
}

export const getStaticPage = async (type) => {

    try {
        const res = await axiosInstance.get(`pages/${type}`)
        return res;
    } catch (error) {
        console.log(error, "error");
    }
}
